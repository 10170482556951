import React, { useCallback, useEffect, useState } from "react";
import { ErrorPanel, Loader } from "components";
import { EventType, IQuestionnaire } from "interfaces";
import { T } from "i18n";
import { VisitStageProps } from "../Visit";
import { useAPI } from "api";
import { VisitPerson } from "containers/Visit/VisitPerson";

function urlWithCallbackLocation(url: string): string {
  const locURL = new URL(window.location.href);

  const qxURL = new URL(url);
  qxURL.searchParams.set("callback", encodeURI(locURL.href));

  return qxURL.href;
}

export function Questionnaire({
  stage,
  cohortId,
  isComplete,
  person,
}: VisitStageProps<`${EventType}_QUESTIONNAIRE`>): JSX.Element {
  const questionnaireAPI = useAPI("questionnaire");

  const [questionnaire, setQuestionnaire] = useState<IQuestionnaire>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();

  const eventType = stage.split("_")[0] as EventType;

  const getQuestionnaire = useCallback((): Promise<void> => {
    setLoading(true);
    return questionnaireAPI
      .getQuestionnaire(cohortId, eventType)
      .then(setQuestionnaire)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [cohortId, questionnaireAPI, eventType]);

  useEffect((): void => {
    getQuestionnaire();
  }, [getQuestionnaire]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <ErrorPanel
        title={T("containers.questionnaire.errors.title")}
        label={T("containers.questionnaire.errors.title")}
      >
        {error.message}
      </ErrorPanel>
    );
  }

  if (isComplete) {
    return (
      <>
        {person && <VisitPerson person={person} />}
        <p data-testid="complete">{T("containers.questionnaire.completed")}</p>
      </>
    );
  }

  const qxURL = urlWithCallbackLocation(questionnaire?.url || "");

  return (
    <>
      {person && <VisitPerson person={person} />}
      <a href={qxURL} data-testid="questionnaire-link" className="nhsuk-button">
        {T("containers.questionnaire.instruction")}
      </a>
    </>
  );
}
