import React, { useMemo, useState } from "react";
import { ICase, ICaseList, IFieldSorter, FieldSortFunc } from "interfaces";
import { ResultLocationState } from "../Result/Result";
import { T } from "i18n";
import { Table } from "nhsuk-react-components";
import { useHistory } from "react-router-dom";
import { Tag } from "nhsuk-react-components-extensions";
import { getCaseStatusProgress } from "./CaseStatusProgress";
import { CaseSearchFilters } from "./CaseSearch";
import { DateTime } from "luxon";
import { SortArrows } from "components/SortArrows";

interface ResultsTableProps {
  caseList: ICaseList;
  showUnread: boolean;
  showInProgress: boolean;
  showClosed: boolean;
  searchFilters: CaseSearchFilters;
}

const CaseStatusTag = ({ caseItem }: { caseItem: ICase }): JSX.Element => {
  switch (getCaseStatusProgress(caseItem)) {
    case "CLOSED":
      return (
        <Tag color="green">{T("containers.results.table.tags.closed")}</Tag>
      );
    case "UNREAD":
      return (
        <Tag color="yellow">{T("containers.results.table.tags.unread")}</Tag>
      );
    case "IN_PROGRESS":
      return (
        <Tag color="blue">{T("containers.results.table.tags.inProgress")}</Tag>
      );
  }
};

function displayTimeStamp(timestamp: DateTime | undefined): string {
  if (timestamp) {
    const formattedTimeStamp = [
      timestamp.toFormat("dd LLL yyyy"),
      ", ",
      timestamp.toFormat("HH.mm"),
    ].join("");
    return formattedTimeStamp;
  }
  return "-";
}

export const ResultsTable = ({
  caseList,
  showUnread,
  showInProgress,
  showClosed,
  searchFilters,
}: ResultsTableProps): JSX.Element => {
  const history = useHistory<ResultLocationState>();
  const [currentSorter, setCurrentSorter] = useState<IFieldSorter<ICase>>({
    field: "reportDate",
    isDescending: true,
  });

  const filteredCases = useMemo(
    () =>
      caseList.cases
        .filter((item) => {
          switch (getCaseStatusProgress(item)) {
            case "UNREAD":
              return showUnread;
            case "IN_PROGRESS":
              return showInProgress;
            case "CLOSED":
              return showClosed;
          }
          return true;
        })
        .filter((item) => {
          if (searchFilters.value === "") {
            return true;
          }
          switch (searchFilters.type) {
            case "participantId": {
              return searchFilters.value.trim() === item.studyId;
            }
            case "name": {
              return item.fullName
                .toLowerCase()
                .includes(searchFilters.value.toLowerCase());
            }
            case "cohortId": {
              return (
                searchFilters.value.toUpperCase().trim() ===
                item.cohortId.toUpperCase()
              );
            }
          }
          return true;
        })
        .sort(FieldSortFunc<ICase>(currentSorter)),
    [
      caseList,
      searchFilters,
      showUnread,
      showInProgress,
      showClosed,
      currentSorter,
    ]
  );

  if (filteredCases.length === 0) {
    return (
      <div data-testid="results-empty">
        {T("containers.results.table.noResults")}
      </div>
    );
  }

  return (
    <Table style={{ textAlign: "center" }} data-testid="results-table">
      <Table.Head>
        <Table.Row>
          <Table.Cell>{T("containers.results.table.status")} </Table.Cell>
          <Table.Cell>{T("containers.results.table.fullName")}</Table.Cell>
          <Table.Cell>
            {T("containers.results.table.bloodDraw")}{" "}
            <SortArrows
              field="bloodCollectionTime"
              currentSorter={currentSorter}
              setCurrentSorter={setCurrentSorter}
            />
          </Table.Cell>
          <Table.Cell>
            {T("containers.results.table.progress")}{" "}
            <SortArrows
              field="progress"
              currentSorter={currentSorter}
              setCurrentSorter={setCurrentSorter}
            />
          </Table.Cell>
          <Table.Cell>
            {T("containers.results.table.reportIssued")}{" "}
            <SortArrows
              field="reportDate"
              currentSorter={currentSorter}
              setCurrentSorter={setCurrentSorter}
            />
          </Table.Cell>
          <Table.Cell>
            {T("containers.results.table.daysSince")}{" "}
            <SortArrows
              field="lastUpdatedTime"
              currentSorter={currentSorter}
              setCurrentSorter={setCurrentSorter}
            />
          </Table.Cell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {filteredCases.map((caseItem) => {
          return (
            <Table.Row
              key={caseItem.caseReference}
              data-testid={`results-row-${caseItem.caseReference}`}
              style={{ cursor: "pointer" }}
              onClick={() =>
                history.push(`/results/${caseItem.caseReference}`, {
                  reportISODate: caseItem.reportDate?.toISODate(),
                  studyID: caseItem.studyId,
                })
              }
            >
              <Table.Cell>
                <CaseStatusTag caseItem={caseItem} />
              </Table.Cell>
              <Table.Cell>
                {caseItem.fullName} <br />
                {caseItem.studyId}
              </Table.Cell>
              <Table.Cell data-testid="results-cell-blood-draw">
                {displayTimeStamp(caseItem.bloodCollectionTime)}
              </Table.Cell>
              <Table.Cell data-testid="results-cell-progress">
                {`${caseItem.progress}/${caseItem.progressTotal}`}
              </Table.Cell>
              <Table.Cell data-testid="results-cell-report-date">
                {displayTimeStamp(caseItem.reportDate)}
              </Table.Cell>
              <Table.Cell data-testid="results-cell-last-updated-by">
                {caseItem.lastUpdatedBy && (
                  <>
                    {caseItem.lastUpdatedBy} <br />
                  </>
                )}
                {displayTimeStamp(caseItem.lastUpdatedTime)}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};
