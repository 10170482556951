import React from "react";
import { IAddress } from "interfaces";
import { formatCapitalisation } from "../formatters";

type IAddressElementWrappers = {
  [n in keyof IAddress]: (contentToWrap: IAddress[n]) => JSX.Element;
};

interface AddressProps {
  address: IAddress;
  wrappers?: Partial<IAddressElementWrappers>;
  properCase?: boolean;
}

export const Address = ({
  address,
  wrappers,
  properCase = false,
}: AddressProps): JSX.Element => {
  const fields: (keyof IAddress)[] = [
    "address1",
    "address2",
    "townOrCity",
    "county",
    "postcode",
  ];

  const formatField = (field: keyof IAddress): string => {
    if (properCase && field !== "postcode") {
      return formatCapitalisation(address[field]);
    }
    return address[field];
  };

  const lines = fields
    .filter((field: keyof IAddress) => address[field] !== "")
    .map((field: keyof IAddress) => {
      const value = formatField(field);
      const wrapper = wrappers ? wrappers[field] : undefined;
      const element = wrapper ? wrapper(value) : <>{value}</>;
      return { element, field };
    });

  return (
    <>
      {lines.map(({ field, element }, index) => (
        <React.Fragment key={field}>
          {element}
          {index < lines.length - 1 && <br />}
        </React.Fragment>
      ))}
    </>
  );
};
