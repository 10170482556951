import React from "react";
import { Checkboxes, Fieldset } from "nhsuk-react-components";
import { FormFieldProps } from "@grailbio/components-ecrf";
import { ReadOnlyFormField } from "./ReadOnlyFormField";

export function EnumSet(props: FormFieldProps): JSX.Element {
  const { definition, value, setValue, readOnly, disabled } = props;
  const { enumOptions, label, apiName } = definition;
  const currentValues = value ? value.split(",") : [];
  const exclusiveOptions = enumOptions
    ? enumOptions
        .filter((option) => option.exclusive)
        .map((option) => option.value)
    : [];

  const onCheckBoxClicked = (input: string): void => {
    let newValues: string[] = [];
    if (currentValues.includes(input)) {
      // Remove
      newValues = currentValues.filter((item) => item !== input);
    } else {
      // Add
      if (!currentValues.includes(input) && !exclusiveOptions.includes(input)) {
        newValues = [...currentValues, input];
      }
      if (exclusiveOptions.includes(input)) {
        newValues = [input];
      }
    }
    setValue(newValues.join(","));
  };

  if (readOnly) {
    return (
      <ReadOnlyFormField
        label={label}
        value={enumOptions
          ?.filter((option) => currentValues.includes(option.value))
          .map((option) => option.label)
          .join(", ")}
      />
    );
  }

  return (
    <Fieldset>
      <Fieldset.Legend>{label}</Fieldset.Legend>
      <Checkboxes name={apiName} id={apiName}>
        {enumOptions &&
          enumOptions.map((option) => (
            <Checkboxes.Box
              data-testid={option.value}
              disabled={definition.systemPopulated || disabled}
              key={option.value}
              onChange={(e) => {
                onCheckBoxClicked(e.currentTarget.value);
              }}
              value={option.value}
              checked={currentValues.includes(option.value)}
            >
              {option.label}
            </Checkboxes.Box>
          ))}
      </Checkboxes>
    </Fieldset>
  );
}
