import React, { useState } from "react";
import { Button, Col, Row } from "nhsuk-react-components";
import { T } from "i18n";
import { Tag } from "nhsuk-react-components-extensions";
import { notCompletedAppointment } from "errors/blood";
import { useAPI } from "api";

export const BloodReDrawStatus = ({
  reDraw,
  cohortId,
  setReDraw,
  setError,
}: {
  cohortId: string;
  reDraw: boolean;
  setReDraw: (newReDraw: boolean) => void;
  setError: (error: string) => void;
}): JSX.Element => {
  const bloodAPI = useAPI("blood");
  const [isLoading, setLoading] = useState(false);

  const markReDrawNeeded = (): void => {
    setLoading(true);
    bloodAPI
      .markReDrawNeeded(cohortId)
      .then(() => setReDraw(true))
      .catch(({ message }) => {
        switch (message) {
          case notCompletedAppointment:
            setError(
              T(
                "components.person.blood.reDraw.error.add.notCompletedAppointment"
              )
            );
            break;
          default:
            setError(message);
            break;
        }
      })
      .finally(() => setLoading(false));
  };

  const removeReDraw = (): void => {
    setLoading(true);
    bloodAPI
      .removeReDraw(cohortId)
      .then(() => setReDraw(false))
      .catch(({ message }) => {
        switch (message) {
          case notCompletedAppointment:
            setError(
              T(
                "components.person.blood.reDraw.error.remove.notCompletedAppointment"
              )
            );
            break;
          default:
            setError(message);
            break;
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {reDraw ? (
        <Row>
          <Col width="one-third">
            <Tag data-testid="re-draw-needed" color="red">
              {T("components.person.blood.reDraw.needed")}
            </Tag>
          </Col>
          <Col width="one-third">
            <Button
              data-testid="remove-re-draw"
              disabled={isLoading}
              onClick={removeReDraw}
            >
              {T("components.person.blood.reDraw.remove")}
            </Button>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col width="one-third">
            <Button
              data-testid="add-re-draw"
              disabled={isLoading}
              onClick={markReDrawNeeded}
            >
              {T("components.person.blood.reDraw.add")}
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};
