import React from "react";
import { IAccessibilityOptions, IAppointment } from "interfaces";
import { T } from "i18n";

import {
  AccessibleParkingIcon,
  HearingIcon,
  InterpreterIcon,
  SignLanguageIcon,
  StepFreeIcon,
  VisualIcon,
  WheelchairIcon,
} from "./accessibility-icons";
import { getInterpreterLanguageName } from "../../helpers";

type Requirement = keyof IAccessibilityOptions;

const label = (requirement: Requirement): string =>
  T(`components.accessibilityRequirements.${requirement}`);

function accessibilityRequirements(appointment: IAppointment): Requirement[] {
  const requirements: Requirement[] = [];
  const requirementKeys: Requirement[] = [
    "interpreter",
    "parking",
    "stepFree",
    "wheelchair",
    "visual",
    "hearing",
    "signLanguage",
  ];

  for (const requirement of requirementKeys) {
    if (appointment.accessibility.options[requirement]) {
      requirements.push(requirement);
    }
  }

  return requirements;
}

function AccessibilityIcon({
  size,
  title,
  stroke,
  requirement,
}: React.PropsWithChildren<{
  title: string;
  size?: number;
  stroke?: number;
  requirement: Requirement;
}>): JSX.Element | null {
  let iconComponent: JSX.Element;
  switch (requirement) {
    case "wheelchair":
      iconComponent = (
        <WheelchairIcon title={title} size={size} stroke={stroke} />
      );
      break;
    case "stepFree":
      iconComponent = (
        <StepFreeIcon title={title} size={size} stroke={stroke} />
      );
      break;
    case "parking":
      iconComponent = (
        <AccessibleParkingIcon title={title} size={size} stroke={stroke} />
      );
      break;
    case "visual":
      iconComponent = <VisualIcon title={title} size={size} stroke={stroke} />;
      break;
    case "hearing":
      iconComponent = <HearingIcon title={title} size={size} stroke={stroke} />;
      break;
    case "signLanguage":
      iconComponent = (
        <SignLanguageIcon title={title} size={size} stroke={stroke} />
      );
      break;
    case "interpreter":
      iconComponent = (
        <InterpreterIcon title={title} size={size} stroke={stroke} />
      );
      break;
    default:
      return null;
  }

  return (
    <>
      {iconComponent}
      <span className="nhsuk-u-font-weight-bold">{title}</span>
    </>
  );
}

export function AccessibilityRequirements({
  appointment,
  children,
}: React.PropsWithChildren<{
  appointment: IAppointment;
}>): JSX.Element | null {
  const requirements: Requirement[] = accessibilityRequirements(appointment);
  const { interpreterLanguage } = appointment.accessibility;

  if (requirements.length === 0) {
    return (
      <div
        aria-label={T("components.accessibilityRequirements.none")}
        style={{ gridColumnStart: "label" }}
      >
        {T("components.accessibilityRequirements.none")}
      </div>
    );
  }

  return (
    <div
      style={{
        display: "grid",
        gap: "0.25rem",
        gridTemplate: "'icon label' 1fr / 2rem auto",
      }}
    >
      {requirements.map((givenRequirement) => (
        <AccessibilityIcon
          key={givenRequirement}
          requirement={givenRequirement}
          size={28}
          stroke={12}
          title={`${label(givenRequirement)}${
            givenRequirement === "interpreter"
              ? ` (${getInterpreterLanguageName(interpreterLanguage)})`
              : ""
          }`}
        />
      ))}
    </div>
  );
}
