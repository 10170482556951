import Auth from "@aws-amplify/auth";
import axios, {
  AxiosError,
  AxiosInstance,
  InternalAxiosRequestConfig,
} from "axios";
import { AppointmentClient, IAppointmentClient } from "./appointment";
import { AuditClient, IAuditClient } from "./audit";
import { BloodClient, HTTPBloodClient } from "./blood";
import { CheckInClient } from "./checkin";
import { ConsentClient, HTTPConsentClient } from "./consent";
import { DataClient, IDataClient } from "./data";
import { DocumentClient, IDocumentClient } from "./document";
import { EligibilityClient, IEligibilityClient } from "./eligibility";
import { FormClient, IFormClient } from "./form";
import { GpClient, IGpClient } from "./gp";
import { HTTPStudyEventsClient, StudyEventsClient } from "./studyevents";
import { INoteClient, NoteClient } from "./notes/client";
import { IParticipantClient, ParticipantClient } from "./participant";
import { IPeopleClient, PeopleClient } from "./people";
import { IQuestionnaireClient, QuestionnaireClient } from "./questionnaire";
import { IRegistrationClient, RegistrationClient } from "./registration";
import { IReportClient, ReportClient } from "./report";
import { IResultsClient, ResultsClient } from "./results";
import { ISurveyClient, SurveyClient } from "./survey";
import { IUnitClient, UnitClient } from "./unit";
import { IUserClient, UserClient } from "./user";
import { IVideoClient, VideoClient } from "./video";
import { IVisitClient, VisitClient } from "./visit";
import { InfoClient } from "./info";
import { ParticipantAuth } from "./participantAuth/participantAuth";
import { T } from "i18n";
import { VoucherClient } from "./vouchers";
import { createContext, useContext } from "react";
import { env } from "config";
import { AuditClientV2, IAuditClientV2 } from "./auditv2/client";

interface ServerError {
  edcError?: boolean;
  submissionError?: boolean;
  error: string;
}

function isFailedRequest(
  error: any
): error is Required<AxiosError<ServerError>> {
  return error.response !== undefined;
}

export interface APIClient {
  people: IPeopleClient;
  data: IDataClient;
  survey: ISurveyClient;
  document: IDocumentClient;
  registration: IRegistrationClient;
  gp: IGpClient;
  appointment: IAppointmentClient;
  participantAuth: ParticipantAuth;
  unit: IUnitClient;
  video: IVideoClient;
  note: INoteClient;
  results: IResultsClient;
  consent: ConsentClient;
  vouchers: VoucherClient;
  visit: IVisitClient;
  forms: IFormClient;
  user: IUserClient;
  eligibility: IEligibilityClient;
  participant: IParticipantClient;
  questionnaire: IQuestionnaireClient;
  report: IReportClient;
  blood: BloodClient;
  checkIn: CheckInClient;
  studyEvents: StudyEventsClient;
  info: InfoClient;
  audit: IAuditClient;
  auditv2: IAuditClientV2;
}

export type IAPIClient = APIClient;

export class HTTPClient implements IAPIClient {
  public readonly people: IPeopleClient;
  public readonly data: IDataClient;
  public readonly survey: ISurveyClient;
  public readonly registration: IRegistrationClient;
  public readonly gp: IGpClient;
  public readonly appointment: IAppointmentClient;
  public readonly participantAuth: ParticipantAuth;
  public readonly unit: IUnitClient;
  public readonly video: IVideoClient;
  public readonly document: IDocumentClient;
  public readonly note: INoteClient;
  public readonly results: IResultsClient;
  public readonly consent: ConsentClient;
  public readonly vouchers: VoucherClient;
  public readonly visit: IVisitClient;
  public readonly forms: IFormClient;
  public readonly user: IUserClient;
  public readonly eligibility: IEligibilityClient;
  public readonly participant: IParticipantClient;
  public readonly questionnaire: IQuestionnaireClient;
  public readonly report: IReportClient;
  public readonly blood: BloodClient;
  public readonly checkIn: CheckInClient;
  public readonly studyEvents: StudyEventsClient;
  public readonly info: InfoClient;
  public readonly audit: IAuditClient;
  public readonly auditv2: IAuditClientV2;

  public constructor(
    protected readonly authedHttp: AxiosInstance,
    protected readonly unauthedHttp: AxiosInstance
  ) {
    authedHttp.interceptors.response.use(
      undefined,
      HTTPClient.errorMessageInterceptor
    );
    this.participantAuth = new ParticipantAuth(unauthedHttp);
    authedHttp.interceptors.request.use(
      HTTPClient.authInterceptor(this.participantAuth)
    );
    this.data = new DataClient(authedHttp);
    this.document = new DocumentClient(unauthedHttp);
    this.people = new PeopleClient(authedHttp);
    this.registration = new RegistrationClient(authedHttp);
    this.gp = new GpClient(unauthedHttp);
    this.survey = new SurveyClient(unauthedHttp);
    this.appointment = new AppointmentClient(authedHttp);
    this.unit = new UnitClient(authedHttp);
    this.video = new VideoClient(authedHttp);
    this.note = new NoteClient(authedHttp);
    this.results = new ResultsClient(authedHttp);
    this.consent = new HTTPConsentClient(authedHttp);
    this.vouchers = new VoucherClient(authedHttp);
    this.visit = new VisitClient(authedHttp);
    this.forms = new FormClient(authedHttp);
    this.user = new UserClient(authedHttp);
    this.eligibility = new EligibilityClient(authedHttp);
    this.participant = new ParticipantClient(authedHttp);
    this.questionnaire = new QuestionnaireClient(authedHttp);
    this.report = new ReportClient(authedHttp);
    this.blood = new HTTPBloodClient(authedHttp);
    this.checkIn = new CheckInClient(authedHttp);
    this.studyEvents = new HTTPStudyEventsClient(authedHttp);
    this.info = new InfoClient(unauthedHttp);
    this.audit = new AuditClient(authedHttp);
    this.auditv2 = new AuditClientV2(authedHttp);
  }

  private static authInterceptor =
    (participantAuth: ParticipantAuth) =>
    async (
      req: InternalAxiosRequestConfig
    ): Promise<InternalAxiosRequestConfig> => {
      let token: string | undefined;
      try {
        const session = await Auth.currentSession();
        token = session.getAccessToken().getJwtToken();
      } catch (error) {
        token = await participantAuth.getToken();
      }
      req.headers.authorization = token ? `Bearer ${token}` : undefined;
      return req;
    };

  private static errorMessageInterceptor = (error: any): Promise<any> => {
    if (isFailedRequest(error)) {
      const {
        status,
        data: { edcError, error: errorMessage, submissionError },
      } = error.response;
      if (edcError) {
        switch (status) {
          case 400: {
            const edcJSON = JSON.parse(errorMessage.split("bad request:")[1]);
            const edcErrors = edcJSON.errors.join("\n");
            error.message = T("errors.edc.400", { message: edcErrors });
            break;
          }
          case 500: {
            error.message = T("errors.edc.500");
            break;
          }
        }
      } else if (submissionError) {
        switch (status) {
          case 400: {
            error.message = T("errors.submission", { message: errorMessage });
            break;
          }
          case 500: {
            error.message = T("errors.500");
            break;
          }
        }
      } else {
        switch (status) {
          case 400: {
            error.message = T("errors.400");
            break;
          }
          case 401: {
            error.message = T("errors.401");
            break;
          }
          case 403: {
            error.message = T("errors.403");
            break;
          }
          case 404: {
            error.message = T("errors.404");
            break;
          }
          case 409: {
            error.message = T("errors.409");
            break;
          }
          case 422: {
            error.message = T("errors.422");
            break;
          }
          case 500: {
            error.message = T("errors.500");
            break;
          }
        }
      }
    }
    return Promise.reject(error);
  };
}

const baseURL = `${env.REACT_APP_API_URL || ""}/api/v1`;

const authService = axios.create({ baseURL });
const unauthedService = axios.create({ baseURL });

export const API = new HTTPClient(authService, unauthedService);

export const APIContext = createContext<APIClient>(API);

export const useAPI = <T extends keyof APIClient>(key: T): APIClient[T] =>
  useContext(APIContext)[key];
