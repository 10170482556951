import React, { useEffect } from "react";
import { DateTime } from "luxon";
import { DateTimePickerWithDefault } from "../../DateTimePickerWithDefault";
import { FormFieldProps } from "@grailbio/components-ecrf";
import {
  IDateInputValue,
  IDateSetter,
  IDateTimeInputValue,
  ITimeInputValue,
  ITimeSetter,
} from "interfaces";

import { ReadOnlyFormField } from "./ReadOnlyFormField";
import { dateTimeInputToDateTime } from "helpers";
import { useDateTime } from "hooks";

function dateTimeInputToISOString(dateTime: IDateTimeInputValue): string {
  const {
    date: { day, month, year },
    time: { hour, minute },
  } = dateTime;
  if ([day, month, year, hour, minute].some((s) => s === "")) {
    return "";
  }
  try {
    const value = dateTimeInputToDateTime(dateTime);
    if (value.isValid) {
      return value.toISO({
        suppressMilliseconds: true,
        includeOffset: true,
      });
    }
  } catch {
    // pass
  }
  // this is a placeholder for every invalid input
  return "INVALID";
}

interface DateTimeInputProps extends FormFieldProps {
  RenderDateTimeInput?: (props: RenderDateTimeInputProps) => JSX.Element;
}

interface RenderDateTimeInputProps {
  disabled?: boolean;
  setDate: IDateSetter;
  setTime: ITimeSetter;
  ariaLabel: string;
  date: IDateInputValue;
  time: ITimeInputValue;
}

export function DateTimeInput(props: DateTimeInputProps): JSX.Element {
  const {
    definition,
    value,
    setValue,
    readOnly,
    disabled,
    RenderDateTimeInput = DateTimePickerWithDefault,
  } = props;
  const { label } = definition;

  const [dateTime, , setter] = useDateTime();

  useEffect(() => {
    if (value !== "") {
      const parsed = DateTime.fromISO(value);
      if (parsed.isValid) {
        setter.setDate({
          day: parsed.day.toString(),
          month: parsed.month.toString(),
          year: parsed.year.toString(),
        });
        setter.setTime({
          hour: parsed.hour.toString(),
          minute: parsed.minute.toString(),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const candidateValue = dateTimeInputToISOString(dateTime);
    if (value !== candidateValue) {
      setValue(candidateValue);
    }
  }, [dateTime, value, setValue]);

  if (readOnly) {
    return (
      <ReadOnlyFormField
        label={label}
        value={DateTime.fromISO(value).toFormat("dd MMM yyyy, HH:mm")}
      />
    );
  }

  return (
    <RenderDateTimeInput
      disabled={definition.systemPopulated || disabled}
      setDate={setter.setDate}
      setTime={setter.setTime}
      ariaLabel={label}
      date={dateTime.date}
      time={dateTime.time}
    />
  );
}
