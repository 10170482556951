import { AxiosInstance } from "axios";

export interface ICheckInClient {
  checkIn(unitCode: string, cohortId: string): Promise<void>;
}

export class CheckInClient implements ICheckInClient {
  public constructor(protected readonly http: AxiosInstance) {}

  checkIn(unitCode: string, cohortId: string): Promise<void> {
    return this.http.post(`/check-in`, {
      unitCode,
      cohortId,
    });
  }
}
