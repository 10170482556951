import "./Schedule.scss";
import React, { useCallback } from "react";
import { Address } from "./Address";
import { AppointmentTimeSlot } from "./AppointmentTimeSlot";
import { Label, ListPanel, Table } from "nhsuk-react-components";
import { DateTime } from "luxon";
import {
  IAppointment,
  IAppointmentSlotWithAvailability,
  ISchedule,
  IUnitCapacitiesUpdateInput,
  IUnitCapacityPerDay,
  IUnitCapacityStatus,
  IUnitCapacityUpdateInput,
} from "interfaces";
import { T } from "i18n";
import { formatAddress, formatTime } from "formatters";
import { UnitCapacityTable } from "components/UnitCapacityTable";
import { CapacityUpdateDiff } from "helpers/updateCapacityDiffs";

type SummaryRowProps = {
  startTime: DateTime;
  cohortID: string;
};

const AppointmentSummaryRow = ({
  startTime,
  cohortID,
}: SummaryRowProps): JSX.Element => {
  return (
    <Table.Row>
      <Table.Cell>
        <p>{formatTime(startTime)}</p>
      </Table.Cell>
      <Table.Cell>
        <p>{cohortID}</p>
      </Table.Cell>
    </Table.Row>
  );
};

type DatePanelProps = {
  date: DateTime;
  capacities: IUnitCapacityPerDay[];
  slots: JSX.Element[];
  appointments: JSX.Element[];
  bulkUpdateCapacities: (input: CapacityUpdateDiff[]) => Promise<void>;
  updateStatuses: (
    input: Omit<IUnitCapacitiesUpdateInput, "unitCode" | "newCapacity">
  ) => Promise<void>;
};

const SchedulePanelByDate = ({
  date,
  capacities,
  slots,
  appointments,
  bulkUpdateCapacities,
  updateStatuses,
}: DatePanelProps): JSX.Element => {
  const isoDateString = date.toISODate();
  const updateStatus = useCallback(
    (newStatus: IUnitCapacityStatus) =>
      updateStatuses({
        start: isoDateString,
        end: isoDateString,
        newStatus,
      }),
    [isoDateString, updateStatuses]
  );
  return (
    <ListPanel.Panel
      key={isoDateString}
      label={date.toFormat("dd LLL")}
      labelProps={{ id: "datePanel" }}
    >
      <UnitCapacityTable
        capacities={capacities}
        bulkUpdate={bulkUpdateCapacities}
        publish={() => updateStatus("PUBLISHED")}
        setInactive={() => updateStatus("DRAFT")}
      />
      {slots}
      <hr />
      <h3>{T("components.schedule.appointments.heading")}</h3>
      {appointments.length > 0 && (
        <Table data-testid="appointmentSummaryTable">
          <Table.Head>
            <Table.Row>
              <Table.Cell>
                {T("components.schedule.appointments.table.headers.time")}
              </Table.Cell>
              <Table.Cell>
                {T("components.schedule.appointments.table.headers.cohortID")}
              </Table.Cell>
            </Table.Row>
          </Table.Head>
          <Table.Body>{appointments}</Table.Body>
        </Table>
      )}
      {appointments.length === 0 && (
        <div aria-label="no-active-appointments">
          {T("components.schedule.appointments.noTable")}
        </div>
      )}
    </ListPanel.Panel>
  );
};

type ScheduleProps = {
  dates: DateTime[];
  locations: ISchedule[];
  capacities: IUnitCapacityPerDay[];
  appointments: IAppointment[];
  bulkUpdateCapacities: (
    input: Omit<IUnitCapacityUpdateInput, "unitCode">[]
  ) => Promise<void>;
  updateStatuses: (
    input: Omit<IUnitCapacitiesUpdateInput, "unitCode" | "newCapacity">
  ) => Promise<void>;
};

export const Schedule = ({
  dates,
  locations,
  capacities,
  appointments,
  bulkUpdateCapacities,
  updateStatuses,
}: ScheduleProps): JSX.Element => {
  const schedulePerLocation = locations.map((location) => {
    return {
      address: location.address,
      slotsPerDay: location.appointmentSlots.reduce(
        (
          groups: { [key: string]: IAppointmentSlotWithAvailability[] },
          item
        ) => {
          const day = item.from.startOf("day").toISODate();
          const group = groups[day] || [];
          group.push(item);
          groups[day] = group;
          return groups;
        },
        {}
      ),
    };
  });

  const capacitiesPerDay = capacities.reduce(
    (groups: { [key: string]: IUnitCapacityPerDay[] }, item) => {
      const day = item.activeFrom.startOf("day").toISODate();
      const group = groups[day] || [];
      group.push(item);
      groups[day] = group;
      return groups;
    },
    {}
  );

  const appointmentsPerDay = appointments.reduce(
    (groups: { [key: string]: IAppointment[] }, item) => {
      const day = item.appointmentStartTime.startOf("day").toISODate();
      const group = groups[day] || [];
      group.push(item);
      groups[day] = group;
      return groups;
    },
    {}
  );

  const listPanels = dates.map((date) => {
    const day = date.startOf("day");
    const isoDay = day.toISODate();
    let capacities;
    if (capacitiesPerDay && capacitiesPerDay[isoDay]) {
      capacities = capacitiesPerDay[isoDay];
    }

    const slots = schedulePerLocation.map((location) => {
      if (location.slotsPerDay[isoDay]) {
        const slots = location.slotsPerDay[isoDay].map((slot) => (
          <AppointmentTimeSlot
            key={slot.from.toFormat("dd-MM-yyyy HH:mm")}
            datetime={slot.from}
            available={slot.available}
          />
        ));
        if (slots.length > 0) {
          return (
            <div
              key={formatAddress(location.address)}
              data-testid="unitAddress"
            >
              <Label>Unit Address:</Label>
              <Address address={location.address} />
              {slots.length > 0 && (
                <div className="time-slots-row">{slots}</div>
              )}
            </div>
          );
        }
      }
      return null;
    });

    const filteredSlots = slots.reduce(
      (acc, value) => (value !== null ? [...acc, value] : acc),
      [] as JSX.Element[]
    );

    let appointments;
    if (appointmentsPerDay && appointmentsPerDay[isoDay]) {
      appointments = appointmentsPerDay[isoDay].map((appointment) => (
        <AppointmentSummaryRow
          key={appointment.appointmentStartTime.toFormat("dd-MM-yyyy HH:mm")}
          startTime={appointment.appointmentStartTime}
          cohortID={appointment.cohortId}
        />
      ));
    }

    return (
      <SchedulePanelByDate
        key={isoDay}
        date={day}
        capacities={capacities || []}
        slots={filteredSlots}
        appointments={appointments || []}
        bulkUpdateCapacities={bulkUpdateCapacities}
        updateStatuses={updateStatuses}
      />
    );
  });

  return <ListPanel>{listPanels}</ListPanel>;
};
