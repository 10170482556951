import React from "react";
import { Path } from "history";
import { Redirect, RouteProps } from "react-router";
import { Role } from "../IUser";
import { Route } from "react-router-dom";
import { hasRole } from "../hasRole";
import { useAuth } from "../useAuth";

export interface RestrictedRouteProps extends RouteProps {
  redirect: Path;
  roles: Role[];
}

export const RestrictedRoute = ({
  roles,
  redirect = "/",
  ...route
}: RestrictedRouteProps): JSX.Element | null => {
  const { user } = useAuth();

  if (hasRole(user, roles)) {
    return <Route {...route} />;
  }

  return <Redirect to={redirect} />;
};
