import React, { useEffect } from "react";
import { useHistory } from "react-router";

type ScrollToTopProps = {
  children: React.ReactElement;
};

export const ScrollToTop = ({ children }: ScrollToTopProps): JSX.Element => {
  const history = useHistory();
  useEffect(() => {
    history.listen((location, event) => {
      if (event === "PUSH") {
        window.scrollTo(0, 0);
      }
    });
  }, [history]);

  return children;
};
