import React, { useEffect, useReducer } from "react";

import { AuthContext, IAuthContext, MFA, UserAuthState } from "./AuthContext";
import { IUser } from "./IUser";

export interface AuthClient<T> {
  user?: T;
  isClientUser: (user: IUser | T) => user is T;
  onInit: (events: AuthEvents) => Promise<IUser | T | undefined>;
  onSignIn: (
    username: string,
    password: string,
    events: AuthEvents
  ) => Promise<IUser | T | undefined>;
  onCompleteNewPassword: (
    user: T,
    newPassword: string,
    events: AuthEvents
  ) => Promise<IUser | T | undefined>;
  onSignOut: () => Promise<void>;
  onConfirmSignIn: (
    user: T,
    code: string,
    mfaType: MFA
  ) => Promise<IUser | undefined>;
  onConfirmDevice: (user: T, code: string) => Promise<IUser | undefined>;
  getCurrentUser: () => Promise<IUser | undefined>;
}

interface AuthProviderProps<T> {
  client: AuthClient<T>;
}

export interface AuthEvents {
  loading: () => void;
  initialized: () => void;
  update: (state: UserAuthState) => void;
  setDeviceRegistrationCode: (deviceRegistrationCode: string) => void;
  signIn: (user?: IUser) => void;
  signOut: () => void;
  error: (error: any) => void;
  setUser: (user?: IUser) => void;
}

type Initialized = { type: "initialized" };
type Loading = { type: "loading" };
type Update = { type: "update"; state: UserAuthState };
type SignIn = { type: "signIn"; user?: IUser };
type SignOut = { type: "signOut" };
type AuthError = { type: "error"; error?: any };
type SetDeviceRegistrationCode = {
  type: "setDeviceRegistrationCode";
  deviceRegistrationCode: string;
};
type SetUser = { type: "setUser"; user?: IUser };

type Action =
  | Initialized
  | Loading
  | Update
  | SignIn
  | SignOut
  | AuthError
  | SetDeviceRegistrationCode
  | SetUser;

interface IAuthState {
  user?: IUser;
  userAuthState?: UserAuthState;
  isInitialized: boolean;
  deviceRegistrationCode?: string;
  isLoading: boolean;
  error?: any;
}

export const reducer = (state: IAuthState, action: Action): IAuthState => {
  switch (action.type) {
    case "loading":
      return { ...state, isLoading: true, error: undefined };
    case "initialized":
      return { ...state, isInitialized: true };
    case "update":
      return {
        ...state,
        userAuthState: action.state,
        isLoading: false,
      };
    case "setDeviceRegistrationCode":
      return {
        ...state,
        deviceRegistrationCode: action.deviceRegistrationCode,
        isLoading: false,
      };
    case "signIn":
      return {
        ...state,
        user: action.user,
        isLoading: false,
      };
    case "signOut":
      return {
        ...state,
        user: undefined,
        deviceRegistrationCode: undefined,
        userAuthState: undefined,
        isLoading: false,
      };
    case "error":
      return { ...state, error: action.error, isLoading: false };
    case "setUser":
      return {
        ...state,
        user: action.user,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const initialState: IAuthState = {
  isLoading: true,
  isInitialized: false,
};

export const AuthProvider = <T,>({
  client: provider,
  children,
}: React.PropsWithChildren<AuthProviderProps<T>>): JSX.Element => {
  const [
    {
      user,
      isLoading,
      userAuthState,
      isInitialized,
      error,
      deviceRegistrationCode,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  const events: AuthEvents = {
    error: (error: any) => dispatch({ type: "error", error }),
    initialized: () => dispatch({ type: "initialized" }),
    loading: () => dispatch({ type: "loading" }),
    update: (state: UserAuthState) => dispatch({ type: "update", state }),
    setDeviceRegistrationCode: (deviceRegistrationCode: string) =>
      dispatch({ type: "setDeviceRegistrationCode", deviceRegistrationCode }),
    signIn: (user: IUser | undefined) => dispatch({ type: "signIn", user }),
    signOut: () => dispatch({ type: "signOut" }),
    setUser: (user?: IUser) => dispatch({ type: "setUser", user }),
  };

  useEffect(() => {
    events.loading();
    provider
      .onInit(events)
      .then((user) => {
        if (user) {
          if (provider.isClientUser(user)) {
            provider.user = user;
          } else {
            events.signIn(user);
          }
        }
      })
      .catch(events.signOut)
      .finally(events.initialized);
    // eslint-disable-next-line
  }, []);

  const context: IAuthContext = {
    user,
    isInitialized,
    isLoading,
    userAuthState,
    deviceRegistrationCode,
    error,
    signIn: async (username: string, password: string): Promise<void> => {
      events.loading();
      await provider
        .onSignIn(username, password, events)
        .then((user) => {
          if (user) {
            if (provider.isClientUser(user)) {
              provider.user = user;
            } else {
              events.signIn(user);
            }
          }
        })
        .catch((error) => {
          events.error(error);
        });
    },
    completeNewPassword: async (newPassword: string): Promise<void> => {
      events.loading();
      if (provider.user) {
        await provider
          .onCompleteNewPassword(provider.user, newPassword, events)
          .then((user) => {
            if (user) {
              if (provider.isClientUser(user)) {
                provider.user = user;
              } else {
                events.signIn(user);
              }
            }
          })
          .catch((error) => {
            events.error(error);
          });
      }
    },
    confirmSignIn: async (code: string, mfaType: MFA): Promise<void> => {
      events.loading();
      if (provider.user) {
        await provider
          .onConfirmSignIn(provider.user, code, mfaType)
          .then((user) => {
            events.signIn(user);
          })
          .catch((error) => events.error(error));
      }
    },
    signOut: async (): Promise<void> => {
      events.loading();
      try {
        await provider.onSignOut();
        events.signOut();
      } catch (error) {
        events.error(error);
      }
    },
    confirmDevice: async (code: string): Promise<void> => {
      events.loading();
      if (provider.user) {
        try {
          const user = await provider.onConfirmDevice(provider.user, code);
          events.signIn(user);
        } catch (error) {
          events.error(error);
        }
      }
    },
    refresh: async (): Promise<void> => {
      events.loading();
      const user = await provider.getCurrentUser();
      events.setUser(user);
    },
  };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};
