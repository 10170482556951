import { UserArchiveReason } from "enums/UserArchiveReason";

export type IUser = ActiveUser | ArchivedUser;

// Explicit type guard functions in order to narrow in filters
export const isActiveUser = (user: IUser): user is ActiveUser => {
  return user.status === "ACTIVE";
};
export const isArchivedUser = (user: IUser): user is ArchivedUser => {
  return user.status === "ARCHIVED";
};

type UserInfo = {
  id: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  roles: string[];
};

export type ActiveUser = { status: "ACTIVE" } & UserInfo;

export type ArchivedUser = {
  status: "ARCHIVED";
  archiveInfo: {
    archivedAt: string;
    archivedBy: string;
    reason: UserArchiveReason;
    note?: string;
  };
} & UserInfo;
