import React, { SyntheticEvent, useState } from "react";
import { T } from "i18n";
import { ErrorPanel } from "./ErrorPanel";
import { Button, Form, Table, Textarea } from "nhsuk-react-components";
import { isLessThanMaxCharLimit } from "validations/isLessThanMaxCharLimit";
import { INote } from "interfaces";

interface NotesProps {
  notes: INote[];
  createNote: (content: string) => Promise<any>;
  maxNoteLength?: number;
}

export const Notes = ({
  notes,
  createNote,
  maxNoteLength = 255,
}: NotesProps) => {
  const [newNote, setNewNote] = useState<string>("");
  const [newNoteLoading, setNewNoteLoading] = useState<boolean>(false);
  const [newNoteError, setNewNoteError] = useState<string>();
  const [noteLengthError, setNoteLengthError] = useState<string>();

  const onSubmitForm = async (
    e: SyntheticEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setNewNoteLoading(true);

    const noteExceedMaxCharLimit = isLessThanMaxCharLimit(
      {
        string: newNote,
        maxCharLimit: maxNoteLength,
      },
      T("containers.participant.notes.errors.lengthExceeded", {
        maxNoteLength,
      })
    );
    setNoteLengthError(noteExceedMaxCharLimit);
    if (noteExceedMaxCharLimit) {
      setNewNoteLoading(false);
      return;
    }

    createNote(newNote)
      .catch(({ message }) => {
        setNewNoteError(message);
      })
      .finally(() => {
        setNewNoteLoading(false);
        setNewNote("");
      });
  };

  return (
    <>
      {newNoteError && (
        <ErrorPanel
          title={T("containers.participant.notes.errors.save")}
          label={T("containers.participant.notes.errors.save")}
        >
          {newNoteError}
        </ErrorPanel>
      )}

      <Table label="notes">
        <Table.Head>
          <Table.Row>
            <Table.Cell>
              {T("containers.participant.notes.headings.note")}
            </Table.Cell>
            <Table.Cell width="20%">
              {T("containers.participant.notes.headings.author")}
            </Table.Cell>
            <Table.Cell width="20%">
              {T("containers.participant.notes.headings.createdAt")}
            </Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {notes.length === 0 && (
            <Table.Row>
              <Table.Cell colSpan={3}>
                {T("containers.participant.notes.empty")}
              </Table.Cell>
            </Table.Row>
          )}

          {notes.map((note, index) => (
            <Table.Row key={String(index)}>
              <Table.Cell>{note.content}</Table.Cell>
              <Table.Cell style={{ textAlign: "end" }}>
                {note.createdBy}
              </Table.Cell>
              <Table.Cell width="20%">
                {note.createdAt.toFormat("dd/LL/yyyy HH:mm")}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <Form onSubmit={onSubmitForm}>
        <Textarea
          label={T("containers.participant.notes.actions.add.label")}
          aria-label={T("containers.participant.notes.actions.add.label")}
          onChange={(e) => setNewNote(e.currentTarget.value)}
          value={newNote}
          error={noteLengthError}
          maxLength={maxNoteLength}
        />
        <Button
          secondary
          disabled={!newNote || newNoteLoading}
          aria-label={T("containers.participant.notes.actions.add.button")}
        >
          {T("containers.participant.notes.actions.add.button")}
        </Button>
      </Form>
    </>
  );
};
