import React, { useCallback, useEffect, useState } from "react";
import { Button } from "nhsuk-react-components";
import { ConsentForm } from "./ConsentForm";
import { ConsentResult } from "./ConsentResult";
import { ErrorPanel, Loader } from "components";
import { IndividualConsent, useAPI } from "api";
import { T } from "i18n";
import { VisitPerson } from "containers/Visit/VisitPerson";
import { VisitStageProps } from "../../Visit";

export const Consent = ({
  cohortId,
  person,
  onComplete,
}: VisitStageProps<"D1_CONSENT">): JSX.Element => {
  const consentAPI = useAPI("consent");

  const [accepted, setAccepted] = useState<boolean>(false);
  const [declined, setDeclined] = useState<boolean>(false);
  const [consent, setConsent] = useState<IndividualConsent | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();
  const [reConsent, setReConsent] = useState<boolean>(false);

  const getConsent = useCallback((): Promise<void> => {
    setLoading(true);
    return consentAPI
      .find(cohortId)
      .then(setConsent)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [cohortId, consentAPI]);

  const submitConsent = useCallback((): Promise<void> => {
    setLoading(true);
    return getConsent()
      .then(onComplete)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [getConsent, onComplete]);

  useEffect((): void => {
    getConsent();
    if (consent?.expired) {
      setReConsent(consent.expired);
    }
  }, [getConsent, consent?.expired]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <ErrorPanel
        title={T("containers.consent.errors.title")}
        label={T("containers.consent.errors.title")}
      >
        {error.message}
      </ErrorPanel>
    );
  }

  if (consent && !reConsent) {
    return (
      <>
        {person && <VisitPerson person={person} />}
        <ConsentResult consent={consent} />
      </>
    );
  }

  if (accepted) {
    return (
      <ConsentForm
        cohortId={cohortId}
        disabled={loading}
        onSubmit={submitConsent}
        person={person}
      />
    );
  }

  if (declined) {
    return (
      <>
        <p data-testid="consent-declined">
          {T("containers.preConsent.declined")}
        </p>
      </>
    );
  }

  return (
    <div data-testid="pre-consent-page">
      <h1>{T("containers.preConsent.title")}</h1>
      {person && <VisitPerson person={person} />}
      {reConsent && (
        <p data-testid="reconsent-required">
          {T("containers.preConsent.reConsent")}
        </p>
      )}
      <p>{T("containers.preConsent.message")}</p>
      <Button
        data-testid="consent-accept-button"
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          setAccepted(true);
        }}
      >
        {T("containers.preConsent.actions.yes")}
      </Button>
      &nbsp;
      <Button
        data-testid="consent-decline-button"
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          setDeclined(true);
        }}
      >
        {T("containers.preConsent.actions.no")}
      </Button>
    </div>
  );
};
