import React from "react";
import { DateTime } from "luxon";
import { Label, Pagination } from "nhsuk-react-components";
import { T } from "i18n";

type WeekSelectorProps = {
  date: DateTime;
  setDate: (date: DateTime) => void;
};

export const WeekSelector = ({
  date,
  setDate,
}: WeekSelectorProps): JSX.Element => {
  const previous = (): void => {
    const newDate = date.minus({ days: 7 });
    setDate(newDate);
  };
  const next = (): void => {
    const newDate = date.plus({ days: 7 });
    setDate(newDate);
  };

  return (
    <>
      <Label
        data-testid="dateRange"
        style={{ textAlign: "center" }}
        isPageHeading
      >
        {`${date.toFormat("dd LLL yyyy")} - ${date
          .plus({ days: 6 })
          .toFormat("dd LLL yyyy")}`}
      </Label>
      <Pagination>
        <Pagination.Link data-testid="previous" previous onClick={previous}>
          {T("components.weekSelector.previous")}
        </Pagination.Link>
        <Pagination.Link data-testid="next" next onClick={next}>
          {T("components.weekSelector.next")}
        </Pagination.Link>
      </Pagination>
    </>
  );
};
