import { DateTime } from "luxon";
import { IDateTimeInputValue } from "../interfaces";
import { ValidationFunction } from "./ValidationFunction";

export const isValidDateTimeBoolean = ({
  date: { year, month, day },
  time: { hour, minute },
}: IDateTimeInputValue): boolean =>
  DateTime.local(
    parseInt(year, 10),
    parseInt(month, 10),
    parseInt(day, 10),
    parseInt(hour, 10),
    parseInt(minute, 10)
  ).isValid;

export const isValidDateTime: ValidationFunction<IDateTimeInputValue> = (
  input: IDateTimeInputValue,
  message: string
): string => (isValidDateTimeBoolean(input) ? "" : message);
