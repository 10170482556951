import React, { useState } from "react";
import {
  AsyncCall,
  CallState,
  err,
  loading,
  notCalled,
  ok,
} from "helpers/AsyncCall";
import {
  BackLink,
  Button,
  Input,
  InsetText,
  WarningCallout,
} from "nhsuk-react-components";
import { ErrorPanel, Loader } from "components";
import { T } from "i18n";
import { Trans } from "react-i18next";
import { useAPI } from "api";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { AppointmentCancelReason } from "enums/AppointmentCancelReason";

export const MarkOutsideEngland = (): JSX.Element => {
  const { cohortId } = useParams<{ cohortId: string }>();
  const { push } = useHistory();

  const [confirmText, setConfirmText] = useState("");

  const personAPI = useAPI("people");
  const appointmentAPI = useAPI("appointment");
  const [updateStatus, setUpdateStatus] =
    useState<AsyncCall<undefined>>(notCalled);

  const handleConfirm = (): void => {
    setUpdateStatus(loading);
    personAPI
      .setOutsideOfEngland(cohortId, true)
      .then(async () => {
        // considered low impact enough that we won't display error if these fail
        await Promise.allSettled([
          appointmentAPI.cancelAppointment(
            cohortId,
            AppointmentCancelReason.OUTSIDE_OF_ENGLAND
          ),
          personAPI.setContactStatus(cohortId, {
            canEmail: false,
            canSMS: false,
          }),
        ]);
        setUpdateStatus(ok(undefined));
      })
      .catch((error) => setUpdateStatus(err(error)));
  };

  return (
    <main className="nhsuk-main-wrapper">
      <BackLink
        onClick={() => push(`/participant/${cohortId}`)}
        data-testid="mark-outside-england-back-link"
        aria-label={T("containers.wizard.previous")}
      >
        {T("containers.wizard.previous")}
      </BackLink>

      <h1>{T("containers.outsideEngland.title")}</h1>

      <WarningCallout
        label={T("containers.outsideEngland.warning.label")}
        data-testid="warning-text"
      >
        <WarningCallout.Label>
          {T("containers.outsideEngland.warning.label")}
        </WarningCallout.Label>
        <p>{T("containers.outsideEngland.warning.text.intro")}</p>
        <p>
          <strong>
            {T("containers.outsideEngland.warning.text.reminders.title")}
          </strong>
        </p>
        <ul>
          <li>
            {T("containers.outsideEngland.warning.text.reminders.appointments")}
          </li>
          <li>
            {T(
              "containers.outsideEngland.warning.text.reminders.notifications"
            )}
          </li>
          <li>
            {T("containers.outsideEngland.warning.text.reminders.letters")}
          </li>
        </ul>
      </WarningCallout>

      <Trans i18nKey="containers.outsideEngland.instruction" />

      <Input
        disabled={updateStatus.state === CallState.Ok}
        type="text"
        data-testid="confirm-outside-england-input"
        value={confirmText}
        onChange={(e) => setConfirmText(e.currentTarget.value)}
        width="20"
      />
      <Button
        disabled={
          confirmText !== "confirm" ||
          [CallState.Loading, CallState.Ok].includes(updateStatus.state)
        }
        data-testid="confirm-outside-england-button"
        onClick={handleConfirm}
      >
        {T("containers.outsideEngland.button")}
      </Button>

      {updateStatus.state === CallState.Loading && <Loader />}
      {updateStatus.state === CallState.Error && (
        <ErrorPanel
          title={T("containers.outsideEngland.error")}
          label={T("containers.outsideEngland.error")}
        >
          {updateStatus.error.message}
        </ErrorPanel>
      )}
      {updateStatus.state === CallState.Ok && (
        <InsetText data-testid="outside-england-confirmation">
          {T("containers.outsideEngland.confirmation")}
        </InsetText>
      )}
    </main>
  );
};
