import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  Select,
} from "nhsuk-react-components";
import { T } from "../../../i18n";
import React, { PropsWithRef, useState } from "react";
import "./CaseSearch.scss";

type SearchType = "participantId" | "name" | "cohortId";

export interface CaseSearchFilters {
  type: SearchType;
  value: string;
}

export const defaultSearchfilters: CaseSearchFilters = {
  type: "participantId",
  value: "",
};

interface CaseSearchParams {
  onSearchParamsChanged: (params: CaseSearchFilters) => void;
}

export const CaseSearch = (
  props: PropsWithRef<CaseSearchParams>
): JSX.Element => {
  const { onSearchParamsChanged } = props;

  const [searchParams, setSearchParams] =
    useState<CaseSearchFilters>(defaultSearchfilters);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onSearchParamsChanged(searchParams);
      }}
    >
      <Label bold size="m">
        {T("containers.results.searchHeader")}
      </Label>
      <Row>
        <Col width="full">
          <div className="case-search-form-container">
            <Select
              data-testid="case-result-search-type-select"
              onChange={(e) => {
                const newValue = e.currentTarget.value as SearchType;
                setSearchParams((oldParams) => ({
                  ...oldParams,
                  type: newValue,
                }));
              }}
              value={searchParams.type}
            >
              <Select.Option value={"participantId" as SearchType}>
                {T("containers.results.searchFilters.participantId")}
              </Select.Option>
              <Select.Option value={"name" as SearchType}>
                {T("containers.results.searchFilters.name")}
              </Select.Option>
              <Select.Option value={"cohortId" as SearchType}>
                {T("containers.results.searchFilters.cohortId")}
              </Select.Option>
            </Select>
            <div className="nhsuk-u-width-full">
              <Input
                data-testid="case-result-search-input"
                type="text"
                width={30}
                className="nhsuk-u-margin-left-2 nhsuk-u-width-full"
                value={searchParams.value}
                onChange={(e) => {
                  const newValue = e.currentTarget.value;
                  setSearchParams((oldParams) => ({
                    ...oldParams,
                    value: newValue,
                  }));
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col width="full">
          <Button
            type="submit"
            data-testid="case-result-search-button"
            aria-label={T("containers.results.searchButton")}
          >
            {T("containers.results.searchButton")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
