import React, { useState } from "react";
import { useParams } from "react-router";
import { BackLink, Button, Select } from "nhsuk-react-components";
import { T } from "i18n";
import { useHistory } from "react-router-dom";
import { UserArchiveReason } from "enums/UserArchiveReason";
import { formatCapitalisation } from "formatters";
import { ErrorPanel, LimitedTextArea } from "components";
import { useAPI } from "api";

export function ArchiveUser(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  const userAPI = useAPI("user");

  const [reason, setReason] = useState<UserArchiveReason>();
  const [note, setNote] = useState("");
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState<Error>();

  const goBack = () => push(`/user/${id}`);
  const archiveUser = () => {
    if (!reason) {
      return;
    }
    setUpdating(true);
    userAPI
      .archiveUser(id, reason, note)
      .then(goBack)
      .catch((error) => {
        setError(error);
        setUpdating(false);
      });
  };

  return (
    <main className="nhsuk-main-wrapper">
      <BackLink
        onClick={goBack}
        data-testid="archive-back-link"
        aria-label={T("containers.wizard.previous")}
      >
        {T("containers.wizard.previous")}
      </BackLink>
      <h1>{T("containers.user.archive.title")}</h1>
      <Select
        label={T("containers.user.archive.reasonLabel")}
        defaultValue=""
        value={reason}
        onChange={(e) => setReason(e.currentTarget.value as UserArchiveReason)}
      >
        <Select.Option value="" disabled={true}>
          {T("containers.user.archive.reasonPlaceholder")}
        </Select.Option>
        {Object.values(UserArchiveReason).map((reason) => (
          <Select.Option key={reason} value={reason}>
            {formatCapitalisation(reason, "_")}
          </Select.Option>
        ))}
      </Select>
      <LimitedTextArea
        label={T("containers.user.archive.noteLabel")}
        onChange={(e) => setNote(e.currentTarget.value)}
        value={note}
        maxLength={255}
        rows={4}
      />
      <Button
        data-testid="archive-user-button"
        style={{ background: "#d5281b" }}
        onClick={archiveUser}
        disabled={!reason || updating}
      >
        {T("containers.user.archive.action")}
      </Button>
      <Button
        secondary
        style={{ marginLeft: "24px" }}
        onClick={goBack}
        disabled={updating}
      >
        {T("containers.user.archive.cancel")}
      </Button>
      {error && (
        <ErrorPanel
          title={T("containers.user.archive.error")}
          label={T("containers.user.archive.error")}
        >
          {error.message}
        </ErrorPanel>
      )}
    </main>
  );
}
