import { AxiosInstance } from "axios";
import { AuditDetail, AuditEvents } from "../../interfaces/IAuditEventV2";

export interface IAuditClientV2 {
  getAuditEvents(
    eventTypes: string[],
    from: string,
    to: string,
    pageNumber: number,
    pageSize: number,
    sortDirection: string,
    user?: string,
    resourceId?: string
  ): Promise<AuditEvents>;
  getAuditDetails(
    resourceType: string,
    actionType: string,
    version: string
  ): Promise<AuditDetail[]>;
}

export class AuditClientV2 implements IAuditClientV2 {
  public constructor(protected readonly http: AxiosInstance) {}

  async getAuditEvents(
    eventTypes: string[],
    from: string,
    to: string,
    pageNumber: number,
    pageSize: number,
    sortDirection: "ASC" | "DESC",
    user?: string,
    resourceId?: string
  ): Promise<AuditEvents> {
    const params: Record<string, any> = {
      from: from,
      to: to,
      pageSize: pageSize,
      pageNumber: pageNumber,
      sortDirection: sortDirection,
    };
    if (eventTypes.length > 0) {
      params["eventTypes"] = eventTypes;
    }
    if (user) {
      params["user"] = user;
    }
    if (resourceId) {
      params["resourceId"] = resourceId;
    }
    const response = await this.http.get("/audit-events", {
      params,
    });
    return response.data;
  }

  async getAuditDetails(
    resourceType: string,
    actionType: string,
    version: string
  ): Promise<AuditDetail[]> {
    const response = await this.http.get("/audit/detail", {
      params: {
        resourceType: resourceType,
        actionType: actionType,
        version: version,
      },
    });
    return response.data;
  }
}
