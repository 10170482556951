import Auth from "@aws-amplify/auth";
import React, { SyntheticEvent, useState } from "react";
import {
  Button,
  ErrorSummary,
  Fieldset,
  Form,
  Input,
} from "nhsuk-react-components";
import { T } from "i18n";
import { hasWords } from "../../validations";
import { useHistory } from "react-router-dom";

export function SetPassword(): JSX.Element {
  const [username, setUsername] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [confirmationCodeError, setConfirmationCodeError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordCheckError, setPasswordCheckError] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();

  const { push } = useHistory();

  const onSubmitForm = async (
    e: SyntheticEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    if (!validate()) {
      return;
    }

    setLoading(true);
    try {
      await Auth.forgotPasswordSubmit(username, confirmationCode, password);
      push("/sign-in");
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const validate = (): boolean => {
    setError(undefined);
    setUsernameError("");
    setConfirmationCodeError("");
    setPasswordError("");
    setPasswordCheckError("");

    let valid = true;
    const usernameHasWords = hasWords(
      username,
      T("containers.user.setPassword.username.errors.empty")
    );
    if (usernameHasWords !== "") {
      valid = false;
      setUsernameError(usernameHasWords);
    }
    const confirmationCodeHasWords = hasWords(
      confirmationCode,
      T("containers.user.setPassword.confirmationCode.errors.empty")
    );
    if (confirmationCodeHasWords !== "") {
      valid = false;
      setConfirmationCodeError(confirmationCodeHasWords);
    }
    const passwordHasWords = hasWords(
      password,
      T("containers.user.setPassword.password.errors.empty")
    );
    if (passwordHasWords !== "") {
      valid = false;
      setPasswordError(passwordHasWords);
    }
    const passwordCheckHasWords = hasWords(
      password,
      T("containers.user.setPassword.passwordCheck.errors.empty")
    );
    if (passwordCheckHasWords !== "") {
      valid = false;
      setPasswordCheckError(passwordCheckHasWords);
    }
    const passwordCheckSame = password === passwordCheck;
    if (!passwordCheckSame) {
      valid = false;
      setPasswordCheckError(
        T("containers.user.setPassword.passwordCheck.errors.different")
      );
    }
    return valid;
  };

  return (
    <main className="nhsuk-main-wrapper">
      <Form data-testid="form" onSubmit={onSubmitForm}>
        <Fieldset>
          <Fieldset.Legend isPageHeading>
            {T("containers.user.setPassword.title")}
          </Fieldset.Legend>
          <Input
            type="text"
            data-testid="username"
            label={T("containers.user.setPassword.username.label")}
            aria-label={T("containers.user.setPassword.username.label")}
            value={username}
            onChange={(e) => setUsername(e.currentTarget.value)}
            error={usernameError}
            width="20"
          />
          <Input
            type="text"
            data-testid="confirmation-code"
            label={T("containers.user.setPassword.confirmationCode.label")}
            aria-label={T("containers.user.setPassword.confirmationCode.label")}
            value={confirmationCode}
            onChange={(e) => setConfirmationCode(e.currentTarget.value)}
            error={confirmationCodeError}
            width="20"
          />
          <Input
            type="password"
            data-testid="password"
            label={T("containers.user.setPassword.password.label")}
            aria-label={T("containers.user.setPassword.password.label")}
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
            error={passwordError}
            width="20"
          />
          <Input
            type="password"
            data-testid="password-check"
            label={T("containers.user.setPassword.passwordCheck.label")}
            aria-label={T("containers.user.setPassword.passwordCheck.label")}
            value={passwordCheck}
            onChange={(e) => setPasswordCheck(e.currentTarget.value)}
            error={passwordCheckError}
            width="20"
          />
        </Fieldset>
        {error && (
          <ErrorSummary
            aria-label={T("containers.user.setPassword.submissionError.label")}
            role="alert"
            tabIndex={-1}
          >
            <ErrorSummary.Title data-testid="error-summary-title">
              {T("containers.user.setPassword.submissionError.label")}
            </ErrorSummary.Title>
            <ErrorSummary.Body data-testid="error-summary-body">
              {error.message}
            </ErrorSummary.Body>
          </ErrorSummary>
        )}
        <Button
          data-testid="submit-button"
          aria-label={T("containers.user.setPassword.actions.submit")}
          disabled={loading}
        >
          {T("containers.user.setPassword.actions.submit")}
        </Button>
      </Form>
    </main>
  );
}
