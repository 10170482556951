import React, { useState } from "react";
import { Button, ErrorMessage, Table } from "nhsuk-react-components";
import { T } from "i18n";
import { UnitCapacityRow } from "components/UnitCapacityRow";
import { IUnitCapacityPerDay, IUnitCapacityStatus } from "interfaces";
import {
  CapacityUpdateDiff,
  capacityUpdateKey,
  updateCapacityDiffs,
} from "helpers/updateCapacityDiffs";

interface CapacityTableProps {
  capacities: IUnitCapacityPerDay[];
  bulkUpdate: (input: CapacityUpdateDiff[]) => Promise<void>;
  publish: () => Promise<void>;
  setInactive: () => Promise<void>;
}

type RowKey = string;

export function UnitCapacityTable({
  capacities,
  bulkUpdate,
  publish,
  setInactive,
}: CapacityTableProps) {
  const [updating, setUpdating] = useState(false);
  const [updateError, setUpdateError] = useState("");
  const [capacityUpdateDiffs, setCapacityUpdateDiffs] = useState<
    Record<RowKey, CapacityUpdateDiff>
  >({});
  const [invalidCapacities, setInvalidCapacities] = useState<
    Record<RowKey, boolean>
  >({});
  const errorInRow = Object.values(invalidCapacities).some((val) => val);
  const changesMade = Object.keys(capacityUpdateDiffs).length > 0;

  const updateCapacity = (
    rowKey: RowKey,
    capacity: IUnitCapacityPerDay,
    newCapacity: number
  ) => {
    setInvalidCapacities({
      ...invalidCapacities,
      [rowKey]: false,
    });
    setCapacityUpdateDiffs(
      updateCapacityDiffs(
        capacityUpdateDiffs,
        capacity,
        "newCapacity",
        newCapacity
      )
    );
  };

  const updateStatus = (
    capacity: IUnitCapacityPerDay,
    newStatus: IUnitCapacityStatus
  ) => {
    setCapacityUpdateDiffs(
      updateCapacityDiffs(capacityUpdateDiffs, capacity, "newStatus", newStatus)
    );
  };

  const publishCapacity = () => {
    setUpdateError("");
    setUpdating(true);
    publish()
      .then(() => {
        setCapacityUpdateDiffs({});
      })
      .catch((e) => {
        const status = e.response?.status;
        switch (status) {
          case 503:
            setUpdateError(T("components.schedule.errors.cachingIssue"));
            break;
          default:
            setUpdateError(T("components.schedule.errors.genericError"));
        }
      })
      .finally(() => setUpdating(false));
  };

  const setInactiveCapacity = () => {
    setUpdateError("");
    setUpdating(true);
    setInactive()
      .then(() => {
        setCapacityUpdateDiffs({});
      })
      .catch((e) => {
        const status = e.response?.status;
        switch (status) {
          case 503:
            setUpdateError(T("components.schedule.errors.cachingIssue"));
            break;
          default:
            setUpdateError(T("components.schedule.errors.genericError"));
        }
      })
      .finally(() => setUpdating(false));
  };

  const saveChanges = () => {
    setUpdateError("");
    setUpdating(true);
    bulkUpdate(Object.values(capacityUpdateDiffs))
      .then(() => {
        setCapacityUpdateDiffs({});
      })
      .catch((e) => {
        const status = e.response?.status;
        switch (status) {
          case 422:
            setUpdateError(
              T("components.schedule.errors.appointmentsAlreadyBooked")
            );
            break;
          case 503:
            setUpdateError(T("components.schedule.errors.cachingIssue"));
            break;
          default:
            setUpdateError(T("components.schedule.errors.genericError"));
        }
      })
      .finally(() => setUpdating(false));
  };

  return (
    <>
      <Table data-testid="capacitySummaryTable">
        <Table.Head>
          <Table.Row>
            <Table.Cell>
              {T("components.schedule.table.headers.from")}
            </Table.Cell>
            <Table.Cell>{T("components.schedule.table.headers.to")}</Table.Cell>
            <Table.Cell>
              {T("components.schedule.table.headers.capacity")}
            </Table.Cell>
            <Table.Cell />
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {capacities.map((capacity) => {
            const rowKey = capacityUpdateKey(capacity);
            return (
              <UnitCapacityRow
                key={rowKey}
                capacity={capacity}
                error={invalidCapacities[rowKey]}
                disabled={updating}
                updateCapacity={(input) => {
                  updateCapacity(rowKey, capacity, input);
                }}
                updateStatus={(status) => {
                  updateStatus(capacity, status);
                }}
                setInvalidCapacity={() => {
                  setInvalidCapacities({
                    ...invalidCapacities,
                    [rowKey]: true,
                  });
                }}
              />
            );
          })}
        </Table.Body>
      </Table>
      <div className="button-row">
        <Button
          data-testid="saveChanges"
          disabled={!changesMade || errorInRow || updating}
          onClick={saveChanges}
        >
          {updating
            ? T("components.schedule.actions.saving")
            : T("components.schedule.actions.saveChanges")}
        </Button>
        <Button
          data-testid="publish"
          disabled={errorInRow || updating}
          onClick={publishCapacity}
        >
          {T("components.schedule.actions.publish")}
        </Button>
        <Button
          data-testid="makeInactive"
          disabled={errorInRow || updating}
          secondary
          onClick={setInactiveCapacity}
        >
          {T("components.schedule.actions.makeInactive")}
        </Button>
      </div>
      {updateError && (
        <ErrorMessage style={{ whiteSpace: "normal" }}>
          {updateError}
        </ErrorMessage>
      )}
    </>
  );
}
