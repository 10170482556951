import React, { useEffect, useMemo } from "react";
import { BiologicalSex } from "../../enums";
import {
  BiologicalSexInput,
  FormValidity,
  NameInput,
  ValiditySummary,
} from "../../components";
import { DateInput } from "nhsuk-react-components";
import { IDateInputValue, IDateSetter } from "../../interfaces";
import { T } from "i18n";
import {
  hasWords,
  isValidBirthDate,
  isValidDateDay,
  isValidDateMonth,
  isValidDateYear,
} from "../../validations";

interface PersonalDetailFormProps {
  firstName?: string;
  firstNameChanged?: (newVal: string) => void;
  lastName?: string;
  lastNameChanged?: (newVal: string) => void;
  dateOfBirthInput?: IDateInputValue;
  dateOfBirthInputChanged?: IDateSetter;
  biologicalSex?: BiologicalSex;
  biologicalSexChanged?: (newVal: BiologicalSex) => void;
  validity?: FormValidity;
  validityUpdated?: (validity: FormValidity) => void;
}

const ids = {
  firstName: "first-name-input",
  lastName: "last-name-input",
  dateOfBirth: {
    combined: "dob-input",
    day: "dob-input-day",
    month: "dob-input-month",
    year: "dob-input-year",
  },
  biologicalSex: "biological-sex-input",
};

export const PersonalDetailForm = ({
  firstName = "",
  firstNameChanged,
  lastName = "",
  lastNameChanged,
  dateOfBirthInput = {
    day: "",
    month: "",
    year: "",
  },
  dateOfBirthInputChanged,
  biologicalSex,
  biologicalSexChanged,
  validity = new FormValidity(),
  validityUpdated,
}: PersonalDetailFormProps): JSX.Element => {
  const dateOfBirthErrors = useMemo(
    () => ({
      combined: validity.getFirstErrorForId(ids.dateOfBirth.combined)?.message,
      day: validity.getFirstErrorForId(ids.dateOfBirth.day)?.message,
      month: validity.getFirstErrorForId(ids.dateOfBirth.month)?.message,
      year: validity.getFirstErrorForId(ids.dateOfBirth.year)?.message,
    }),
    [validity]
  );

  useEffect(() => {
    const currentValidity = new FormValidity();

    const firstNameError = hasWords(
      firstName,
      T("containers.registration.fields.firstName.errors.empty")
    );
    currentValidity.addError(firstNameError, ids.firstName);

    const lastNameError = hasWords(
      lastName,
      T("containers.registration.fields.lastName.errors.empty")
    );
    currentValidity.addError(lastNameError, ids.lastName);

    const dateOfBirthDayError = isValidDateDay(
      dateOfBirthInput.day,
      T("containers.registration.fields.dateOfBirth.errors.invalidDay")
    );
    currentValidity.addError(dateOfBirthDayError, ids.dateOfBirth.day);

    const dateOfBirthMonthError = isValidDateMonth(
      dateOfBirthInput.month,
      T("containers.registration.fields.dateOfBirth.errors.invalidMonth")
    );
    currentValidity.addError(dateOfBirthMonthError, ids.dateOfBirth.month);

    const dateOfBirthYearError = isValidDateYear(
      dateOfBirthInput.year,
      T("containers.registration.fields.dateOfBirth.errors.invalidYearFormat"),
      T("containers.registration.fields.dateOfBirth.errors.invalidYearRange")
    );
    currentValidity.addError(dateOfBirthYearError, ids.dateOfBirth.year);

    if (
      dateOfBirthDayError === "" &&
      dateOfBirthMonthError === "" &&
      dateOfBirthYearError === ""
    ) {
      const dateOfBirthError = isValidBirthDate(
        dateOfBirthInput,
        T("containers.registration.fields.dateOfBirth.errors.invalid")
      );
      currentValidity.addError(dateOfBirthError, ids.dateOfBirth.combined);
    }

    if (!biologicalSex) {
      currentValidity.addError(
        T("containers.registration.fields.biologicalSex.errors.invalid"),
        ids.biologicalSex
      );
    }

    validityUpdated && validityUpdated(currentValidity);
  }, [firstName, lastName, dateOfBirthInput, biologicalSex, validityUpdated]);

  return (
    <>
      <ValiditySummary validity={validity} />
      <NameInput
        firstName={firstName}
        firstNameChanged={firstNameChanged}
        firstNameError={validity.getFirstErrorForId(ids.firstName)?.message}
        lastName={lastName}
        lastNameChanged={lastNameChanged}
        lastNameError={validity.getFirstErrorForId(ids.lastName)?.message}
        elementIDs={{ firstName: ids.firstName, lastName: ids.lastName }}
      />
      <DateInput
        label={T("containers.registration.fields.dateOfBirth.label")}
        id={ids.dateOfBirth.combined}
        aria-label={T("containers.registration.fields.dateOfBirth.label")}
        hint={T("containers.registration.fields.dateOfBirth.hint")}
        value={dateOfBirthInput}
        error={`${Object.values(dateOfBirthErrors)
          .filter((s) => s !== undefined)
          .join("\n")}`}
        onChange={(e) =>
          dateOfBirthInputChanged &&
          dateOfBirthInputChanged(e.currentTarget.value)
        }
      >
        <DateInput.Day error={!!dateOfBirthErrors.day} />
        <DateInput.Month error={!!dateOfBirthErrors.month} />
        <DateInput.Year error={!!dateOfBirthErrors.year} />
      </DateInput>
      <BiologicalSexInput
        biologicalSex={biologicalSex}
        biologicalSexChanged={biologicalSexChanged}
        biologicalSexError={
          validity.getFirstErrorForId(ids.biologicalSex)?.message
        }
        elementIDs={{ biologicalSex: ids.biologicalSex }}
      />
    </>
  );
};
