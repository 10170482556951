import React, { useEffect, useState } from "react";
import { Fieldset, Radios } from "nhsuk-react-components";
import { FormFieldProps } from "@grailbio/components-ecrf";
import { ReadOnlyFormField } from "./ReadOnlyFormField";

export function ShortEnumInput(props: FormFieldProps): JSX.Element {
  const { definition, value, setValue, readOnly, disabled } = props;
  const { enumOptions, label } = definition;

  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    if (value !== inputValue) {
      setValue(inputValue);
    }
    if (definition.systemPopulated) {
      if (definition.values) {
        setInputValue(definition.values[0]);
      }
    }
  }, [
    value,
    setValue,
    inputValue,
    definition.values,
    definition.systemPopulated,
  ]);

  if (readOnly) {
    return (
      <ReadOnlyFormField
        label={label}
        value={enumOptions?.find((option) => option.value === value)?.label}
      />
    );
  }

  return (
    <Fieldset>
      <Fieldset.Legend>{label}</Fieldset.Legend>
      <Radios value={value}>
        {enumOptions &&
          enumOptions.map((option) => (
            <Radios.Radio
              disabled={definition.systemPopulated || disabled}
              key={option.value}
              onChange={(e) => {
                setInputValue(e.currentTarget.value);
              }}
              value={option.value}
              checked={option.value === inputValue}
            >
              {option.label}
            </Radios.Radio>
          ))}
      </Radios>
    </Fieldset>
  );
}
