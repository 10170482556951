import React from "react";
import { ErrorSummary } from "nhsuk-react-components";
import { T } from "i18n";

export interface FieldError {
  message: string;
  fieldId: string;
}
export class FormValidity {
  valid: boolean;
  errors: FieldError[];
  constructor() {
    this.valid = true;
    this.errors = [];
  }

  addError(message: string, fieldId: string): void {
    if (message === "") {
      return;
    }
    this.valid = false;
    this.errors.push({ message, fieldId });
  }

  getFirstErrorForId(id: string): FieldError | undefined {
    if (this.valid) {
      return undefined;
    }
    return this.errors.find((val) => val.fieldId === id);
  }
}
interface ErrorParams {
  err: FieldError;
}

const Error = ({ err }: ErrorParams): JSX.Element => (
  <ErrorSummary.Item
    data-testid={`#${err.fieldId}`}
    href={`#${err.fieldId}`}
    key={`${err.fieldId}${err.message}`}
  >
    {err.message}
  </ErrorSummary.Item>
);

export interface ErrorValiditySummary {
  validity: FormValidity;
}

export const ValiditySummary = ({
  validity,
}: ErrorValiditySummary): JSX.Element => {
  switch (validity.valid) {
    case true:
      return <></>;
    case false:
      return (
        <ErrorSummary>
          <ErrorSummary.Title>
            {T("components.formValidity.summaryTitle")}
          </ErrorSummary.Title>
          <ErrorSummary.List>
            {validity.errors.map((err) => (
              <Error key={`${err.fieldId}`} err={err} />
            ))}
          </ErrorSummary.List>
        </ErrorSummary>
      );
  }
};
