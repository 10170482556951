import React, { useMemo } from "react";
import { Container, Header } from "nhsuk-react-components";
import { T } from "i18n";
import { getParser } from "bowser";

import { CallCentreNoteParagraph } from "./CallCentreNote";

const BROWSER_MAP = {
  "internet explorer": ">=11",
  chrome: ">=89",
  edge: ">=89",
  firefox: ">=87",
  opera: ">=73",
  safari: ">=8",
  mobile: {
    chrome: ">=90",
    firefox: ">=87",
    safari: ">=12",
  },
};

const UnsupportedBrowserScreen = (): JSX.Element | null => {
  return (
    <>
      <Header transactional serviceName={T("application")}>
        <Header.Container>
          <Header.Logo />
        </Header.Container>
      </Header>
      <Container className="main-container">
        <main className="nhsuk-main-wrapper">
          <h4>{T("components.supportedBrowser.title")}</h4>
          <CallCentreNoteParagraph />
        </main>
      </Container>
    </>
  );
};

export function SupportedBrowser({
  children,
}: React.PropsWithChildren<unknown>): JSX.Element {
  const parser = useMemo(() => getParser(window.navigator.userAgent), []);

  if (parser.satisfies(BROWSER_MAP)) {
    return <>{children}</>;
  }

  return <UnsupportedBrowserScreen />;
}
