import { ValidationFunction } from "./ValidationFunction";
import {
  removeNonDigitsAndCallingCode,
  splitPhoneNumber,
} from "helpers/phoneNumber";

// We need at least 4 numbers, and any amount of "-", "(", ")", "+", or space
const phoneRegex = /^([-() +]*[0-9][-() +]*){4,}$/;

export const isPhoneNumber = (potentialPhoneNumber: string): boolean =>
  phoneRegex.test(potentialPhoneNumber);

// UK mobile phone numbers have the 07 prefix and 10 or 11 digits.
const ukMobilePhoneWithoutCallingCodeRegex = /^(7|07)[\d]{8,9}$/;

export const isUKMobilePhoneNumber = (
  potentialMobilePhoneNumber: string
): boolean =>
  ukMobilePhoneWithoutCallingCodeRegex.test(potentialMobilePhoneNumber);

// UK landline phone numbers have the 01 or 02 prefix; mobile phone numbers have the 07 prefix; all have 10 or 11 digits.
const ukPhoneWithoutCallingCodeRegex = /^[0]?[1|2|7][\d]{8,9}$/;

export const isUKPhoneNumber = (potentialPhoneNumber: string): boolean =>
  ukPhoneWithoutCallingCodeRegex.test(potentialPhoneNumber);

export const isValidPhoneNumber: ValidationFunction<string> = (
  value: string,
  message: string
): string => {
  const { nationalNumber } = splitPhoneNumber(value, "+44");
  if (isPhoneNumber(nationalNumber)) {
    return "";
  }
  return message;
};

export const isValidUKPhoneNumber: ValidationFunction<string> = (
  value: string,
  message: string
): string => {
  const nationalNumber = removeNonDigitsAndCallingCode(value, "+44");

  if (isUKPhoneNumber(nationalNumber)) {
    return "";
  }
  return message;
};

export const isValidUKMobilePhoneNumber: ValidationFunction<string> = (
  value: string,
  message: string
): string => {
  if (!value) {
    return "";
  }
  const phoneNumber = removeNonDigitsAndCallingCode(value, "+44");

  if (isUKMobilePhoneNumber(phoneNumber)) {
    return "";
  }
  return message;
};
