export const removeNonDigitsAndCallingCode = (
  phoneNumber: string,
  callingCode: string
): string => {
  // remove leading 00 from phone number
  let fullNumber = phoneNumber.replace(/^00/, "");

  // extract digits from calling code and fullNumber
  const callingCodeInts = callingCode.match(/\d+/g)?.join("");
  const fullNumberInts = fullNumber.match(/\d+/g)?.join("");

  // removing calling code if the full number starts with it
  if (
    callingCodeInts !== undefined &&
    fullNumberInts !== undefined &&
    fullNumberInts.lastIndexOf(callingCodeInts) === 0
  ) {
    fullNumber = fullNumber.replace(callingCodeInts, "");
  }

  // remove special characters from full number
  return fullNumber.replace(/[+]|\s|-|\(|\)/g, "");
};
