import {
  ISelfReportedCancerHistory,
  ISelfReportedCancerStatusUpdate,
} from "interfaces/ISelfReportedCancerStatus";
import { useAPI } from "api/API";
import { useCallback, useEffect, useState } from "react";
import { AsyncCall, err, loading, notCalled, ok } from "helpers/AsyncCall";
import { DateTime } from "luxon";

export const useFetchSelfReportedCancerHistory = (
  cohortId: string,
  refetch: boolean
): AsyncCall<ISelfReportedCancerHistory> => {
  const personAPI = useAPI("people");
  const [selfReportedCancerHistory, setSelfReportedCancerHistory] =
    useState<AsyncCall<ISelfReportedCancerHistory>>(notCalled);

  useEffect((): void => {
    if (!refetch) {
      return;
    }
    personAPI
      .getSelfReportedCancerHistory(cohortId)
      .then((result) => setSelfReportedCancerHistory(ok(result)))
      .catch((result) => setSelfReportedCancerHistory(err(result)));
  }, [refetch, cohortId, personAPI]);

  return selfReportedCancerHistory;
};

type updater = (cohortId: string, value: boolean) => void;

export const useUpdateSelfReportedCancerStatus = (): [
  AsyncCall<undefined>,
  updater
] => {
  const personAPI = useAPI("people");
  const [updateStatus, setUpdateStatus] =
    useState<AsyncCall<undefined>>(notCalled);

  const handleUpdate = useCallback(
    (cohortId: string, value: boolean): void => {
      const update: ISelfReportedCancerStatusUpdate = {
        selfReportedCancer: value,
        timestamp: DateTime.now(),
      };
      setUpdateStatus(loading);
      personAPI
        .setSelfReportedCancerStatus(cohortId, update)
        .then(() => setUpdateStatus(ok(undefined)))
        .catch((result) => setUpdateStatus(err(result)));
    },
    [personAPI]
  );

  return [updateStatus, handleUpdate];
};
