import React, { FC, HTMLProps } from "react";
import classNames from "classnames";
import { ArrowRight } from "./ArrowRight";
import { ArrowLeft } from "./ArrowLeft";
import "./PaginationWithNumbers.scss";

interface PaginationLinkProps extends HTMLProps<HTMLAnchorElement> {
  previous?: boolean;
  next?: boolean;
}

interface PaginationNumbersProps extends HTMLProps<HTMLAnchorElement> {
  totalPages: number;
  currentPage: number;
  onClickFn: (pageNumber: number) => void;
}

const PaginationLink: FC<PaginationLinkProps> = ({
  className,
  children,
  previous,
  next,
  ...rest
}) => (
  <li
    className={classNames(
      { "nhsuk-pagination-item--previous": previous },
      { "nhsuk-pagination-item--next": next },
      "pagination-ends"
    )}
  >
    <a
      className={classNames(
        "nhsuk-pagination__link",
        { "nhsuk-pagination__link--prev": previous },
        { "nhsuk-pagination__link--next": next },
        className
      )}
      {...rest}
    >
      <span className="nhsuk-pagination__title">
        {previous ? "Previous" : null}
        {next ? "Next" : null}
      </span>
      <span className="nhsuk-u-visually-hidden">:</span>
      <span className="nhsuk-pagination__page">{children}</span>
      {previous ? <ArrowLeft /> : null}
      {next ? <ArrowRight /> : null}
    </a>
  </li>
);

export function getPageNumbersToShow(totalPages: number, currentPage: number) {
  const items: (number | string)[] = [];
  const allPages = Array.from({ length: totalPages }, (_, i) => i + 1);

  // If there are less than 8 pages, show all pages
  // If the current page is 4 or less, show the first 5 pages
  // If the current page is less than 4 from the end, show the last 5 pages
  // Otherwise, show the current page, the page before it, the page after it and the first and last pages
  if (totalPages <= 7) {
    items.push(...allPages);
  } else {
    if (currentPage <= 4) {
      items.push(...allPages.slice(0, 5));
      items.push("⋯");
      items.push(totalPages);
    } else if (currentPage >= totalPages - 3) {
      items.push(1);
      items.push("⋯");
      items.push(...allPages.slice(totalPages - 5, totalPages + 1));
    } else {
      items.push(1);
      items.push("⋯");
      items.push(...allPages.slice(currentPage - 2, currentPage + 1));
      items.push("⋯");
      items.push(totalPages);
    }
  }
  return items;
}

const PaginationNumbers: FC<PaginationNumbersProps> = ({
  className,
  children,
  totalPages,
  currentPage,
  onClickFn,
  ...rest
}) => {
  const numbers = getPageNumbersToShow(totalPages, currentPage);

  return (
    <div>
      {numbers && (
        <li className={"pagination-numbers"}>
          {numbers.map((n, i) => {
            if (typeof n === "number" && n !== currentPage) {
              return (
                <a
                  className={"pagination-number"}
                  onClick={() => onClickFn(n)}
                  key={n}
                  {...rest}
                >
                  {n}
                </a>
              );
            } else {
              return (
                <span
                  className={classNames(
                    { "pagination-number": n === currentPage },
                    { "active-number": n === currentPage },
                    { ellipses: typeof n === "string" }
                  )}
                  key={n === currentPage ? n : "ellipses" + i}
                >
                  {n}
                </span>
              );
            }
          })}
        </li>
      )}
    </div>
  );
};

interface Pagination extends FC<HTMLProps<HTMLDivElement>> {
  Link: FC<PaginationLinkProps>;
  Numbers: FC<PaginationNumbersProps>;
}

export const PaginationWithNumbers: Pagination = ({
  className,
  children,
  role = "navigation",
  "aria-label": ariaLabel = "Pagination",
  ...rest
}) => (
  <nav role={role} aria-label={ariaLabel} {...rest}>
    <ul
      className={classNames(
        "custom-pagination",
        "nhsuk-list nhsuk-pagination__list"
      )}
    >
      {children}
    </ul>
  </nav>
);

PaginationWithNumbers.Link = PaginationLink;
PaginationWithNumbers.Numbers = PaginationNumbers;
