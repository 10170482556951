import {
  APIAppointment,
  Duration,
  apiAppointmentToAppointment,
} from "../appointment";
import { APIPerson, apiPersonToPerson } from "api/people";
import { APIVisit, apiVisitToVisit } from "api/visit";
import { AxiosInstance } from "axios";
import { DateTime } from "luxon";
import { EDCSiteId } from "enums";
import {
  IAddress,
  IAppointment,
  IBloodDrawStatus,
  ISchedule,
  IUnitCapacities,
  IUnitCapacitiesUpdateInput,
  IUnitCapacityInput,
  IUnitCapacityPerDay,
  IUnitCapacityStatus,
  IUnitCapacityUpdateInput,
  IUnitLocation,
  IUnitSchedule,
  IUnits,
} from "interfaces";
import {
  activeToBeforeActiveFrom,
  cacheBustFailed,
  dateInPast,
  overlapsExistingCapacity,
  overlapsExistingLocation,
  unitCodeExists,
} from "errors/unitManagement";
import { badRequest, unknownError } from "errors";

interface UnitLocation {
  unitCode: string;
  activeFrom: string;
  activeTo: string;
  address: IAddress;
}

const apiUnitLocationToUnitLocation = ({
  activeFrom,
  activeTo,
  ...rest
}: UnitLocation): IUnitLocation => ({
  ...rest,
  activeFrom: DateTime.fromISO(activeFrom),
  activeTo: DateTime.fromISO(activeTo),
});

type capacityResponse = {
  activeFrom: string;
  activeTo: string;
  capacity: number;
  status: IUnitCapacityStatus;
};

type slotResponse = {
  from: string;
  to: string;
  available: number;
};

type locationResponse = {
  address: IAddress;
  appointmentSlots: slotResponse[];
};

export interface IUnitClient {
  findUnits(): Promise<IUnits>;

  createUnit(
    code: string,
    edcSiteId: EDCSiteId,
    accessible: boolean
  ): Promise<void>;

  createUnitLocation(input: IUnitLocation, directions?: string): Promise<void>;

  createUnitAvailability(input: IUnitCapacityInput): Promise<void>;

  findUnitLocationsByDate(
    date: DateTime,
    accessibilityRequired: boolean
  ): Promise<IUnitLocation[]>;

  findCapacities(
    unitCode: string,
    startDate: DateTime,
    endDate: DateTime,
    appointmentLength?: Duration
  ): Promise<IUnitCapacities>;

  findUnitLocationsByUnit(unitCode: string): Promise<IUnitLocation[]>;

  updateUnitCapacity(input: IUnitCapacityUpdateInput): Promise<void>;

  updateUnitCapacities(input: IUnitCapacitiesUpdateInput): Promise<void>;

  bulkUpdateUnitCapacitiesWithinADay(
    input: IUnitCapacityUpdateInput[]
  ): Promise<void>;

  getSchedule(unitCode: string, date: DateTime): Promise<IUnitSchedule>;

  getAppointmentsByDateRange(
    unitCode: string,
    startDate: DateTime,
    endDate: DateTime,
    status: string
  ): Promise<IAppointment[]>;
}

export class UnitClient implements IUnitClient {
  public constructor(protected readonly http: AxiosInstance) {}

  public async findUnits(): Promise<IUnits> {
    const response = await this.http.get("/unit", {});
    return response.data;
  }

  public async createUnit(
    code: string,
    edcSiteId: EDCSiteId,
    accessible: boolean
  ): Promise<void> {
    return this.http
      .post("/unit", {
        code,
        edcSiteId,
        accessible,
      })
      .then(() => {
        // We don't expose the result
      })
      .catch((error) => {
        const status = error.response?.status;
        switch (status) {
          case 409:
            throw new Error(unitCodeExists);
          default:
            throw new Error(unknownError);
        }
      });
  }

  public async findUnitLocationsByDate(
    date: DateTime,
    accessibilityRequired: boolean
  ): Promise<IUnitLocation[]> {
    const response = await this.http.get("/unit-location", {
      params: {
        date: date.toISODate(),
        accessibilityRequired,
      },
    });
    return response.data.map(apiUnitLocationToUnitLocation);
  }

  public async findUnitLocationsByUnit(
    unitCode: string
  ): Promise<IUnitLocation[]> {
    const response = await this.http.get(`/unit/${unitCode}/location`, {});
    return response.data.map(apiUnitLocationToUnitLocation);
  }

  public async createUnitLocation(
    input: IUnitLocation,
    directions?: string
  ): Promise<void> {
    const { unitCode, ...unitLocationWithoutUnitCode } = input;
    return this.http
      .post(`/unit/${unitCode}/location`, {
        ...unitLocationWithoutUnitCode,
        directions,
      })
      .then(() => {
        // We don't expose the result
      })
      .catch((error) => {
        const status = error.response?.status;
        const errorMessage = error.response.data.error;
        switch (status) {
          case 409:
            throw new Error(overlapsExistingLocation);
          case 400:
            if (errorMessage === "ActiveTo must be after ActiveFrom") {
              throw new Error(activeToBeforeActiveFrom);
            }
            throw new Error(badRequest);
          case 503:
            throw new Error(cacheBustFailed);
          default:
            throw new Error(unknownError);
        }
      });
  }

  public async createUnitAvailability(
    input: IUnitCapacityInput
  ): Promise<void> {
    const { unitCode, ...body } = input;
    return this.http
      .post(`/unit/${unitCode}/availability`, body)
      .then(() => {
        // We don't expose the result
      })
      .catch((error) => {
        const status = error.response?.status;
        const errorMessage = error.response.data.error;
        switch (status) {
          case 409:
            throw new Error(overlapsExistingCapacity);
          case 400:
            if (
              errorMessage === "setting capacity in the past is not possible"
            ) {
              throw new Error(dateInPast);
            } else if (errorMessage) {
              throw new Error(errorMessage);
            }
            throw new Error(badRequest);
          case 503:
            throw new Error(cacheBustFailed);
          default:
            throw new Error(unknownError);
        }
      });
  }

  public async findCapacities(
    unitCode: string,
    startDate: DateTime,
    endDate: DateTime,
    appointmentLengthMinutes?: Duration
  ): Promise<IUnitCapacities> {
    const response = await this.http.get<
      any,
      {
        data: {
          availabilities: capacityResponse[];
          locations: locationResponse[];
        };
      }
    >(`/unit/${unitCode}/availability`, {
      params: {
        start: startDate.toISODate(),
        end: endDate.toISODate(),
        appointmentLength: appointmentLengthMinutes,
      },
    });

    let availabilities: IUnitCapacityPerDay[] = [];
    if (response.data.availabilities) {
      availabilities = response.data.availabilities.map((availability) => ({
        activeFrom: DateTime.fromISO(availability.activeFrom),
        activeTo: DateTime.fromISO(availability.activeTo),
        capacity: availability.capacity,
        status: availability.status,
      }));
    }
    let locations: ISchedule[] = [];
    if (response.data.locations) {
      locations = response.data.locations.map((location) => ({
        address: location.address,
        appointmentSlots: location.appointmentSlots.map((slot) => ({
          from: DateTime.fromISO(slot.from),
          to: DateTime.fromISO(slot.to),
          available: slot.available,
        })),
      }));
    }
    return {
      availabilities,
      locations,
    };
  }

  public async updateUnitCapacity(
    input: IUnitCapacityUpdateInput
  ): Promise<void> {
    const { unitCode, ...body } = input;
    await this.http.put(`/unit/${unitCode}/availability`, body);
  }

  public async updateUnitCapacities(
    input: IUnitCapacitiesUpdateInput
  ): Promise<void> {
    const { unitCode, start, end, ...body } = input;
    await this.http.put(`/unit/${unitCode}/availabilities`, body, {
      params: {
        start,
        end,
      },
    });
  }

  public bulkUpdateUnitCapacitiesWithinADay(
    input: IUnitCapacityUpdateInput[]
  ): Promise<void> {
    if (input.length < 1) {
      return Promise.resolve();
    }
    const { unitCode } = input[0];
    if (!input.every((update) => update.unitCode === unitCode)) {
      return Promise.reject("bulk updates must be to same unit");
    }
    return this.http.put(`/unit/${unitCode}/day-availabilities`, input);
  }

  public async getSchedule(
    unitCode: string,
    date: DateTime
  ): Promise<IUnitSchedule> {
    return this.http
      .get(`/unit/${unitCode}/schedule`, {
        params: {
          date: date.toUTC().toISODate(),
        },
      })
      .then((response) => ({
        schedule: response.data.schedule.map(
          ({
            appointments,
            person,
            visits,
            blood,
          }: {
            person: APIPerson;
            appointments: APIAppointment[];
            visits: APIVisit[];
            blood: IBloodDrawStatus;
          }) => ({
            appointments: appointments.map(apiAppointmentToAppointment),
            visits: visits.map(apiVisitToVisit),
            person: apiPersonToPerson(person),
            blood,
          })
        ),
      }));
  }

  public async getAppointmentsByDateRange(
    unitCode: string,
    startDate: DateTime,
    endDate: DateTime,
    status: string
  ): Promise<IAppointment[]> {
    const response = await this.http.get("/appointments", {
      params: {
        unitCode,
        startDate: startDate.toISODate(),
        endDate: endDate.toISODate(),
        status,
      },
    });
    return response.data.map(apiAppointmentToAppointment);
  }
}
