import React, { useEffect, useState } from "react";
import { Address, Loader } from "components";
import { Button, Label, Table, WarningCallout } from "nhsuk-react-components";
import { IUnitLocation } from "interfaces";
import { T } from "i18n";
import { useAPI } from "api";
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router";

export interface LocationState {
  error: string;
}

export function UnitLocation(): JSX.Element {
  const history = useHistory();
  const { unitCode } = useParams<{ unitCode: string }>();
  const [locations, setLocations] = useState<IUnitLocation[]>();
  const [loading, setLoading] = useState(false);
  const location = useLocation<LocationState>();

  const onAddLocationClick = (): void => {
    const path = `/unit/${unitCode}/location/add`;
    history.push(path);
  };

  const unitAPI = useAPI("unit");

  useEffect(() => {
    setLoading(true);
    unitAPI
      .findUnitLocationsByUnit(unitCode)
      .then((unitLocations) => {
        const locations = unitLocations.map((location) => ({
          activeFrom: location.activeFrom,
          activeTo: location.activeTo,
          address: location.address,
          unitCode: location.unitCode,
        }));
        setLocations(locations);
      })
      .finally(() => setLoading(false));
  }, [unitCode, unitAPI]);

  const locationTable = locations?.map((location) => {
    return (
      <Table.Row key={location.activeFrom.toFormat("dd LLL yyyy HH:mm")}>
        <Table.Cell>
          {location.activeFrom.toFormat("dd LLL yyyy HH:mm")}
        </Table.Cell>
        <Table.Cell>
          {location.activeTo.toFormat("dd LLL yyyy HH:mm")}
        </Table.Cell>
        <Table.Cell>
          <Address address={location.address} />
        </Table.Cell>
      </Table.Row>
    );
  });
  return (
    <main className="nhsuk-main-wrapper">
      {location.state?.error && (
        <WarningCallout>
          <WarningCallout.Label>
            {T("containers.unit.location.add.errors.cache.label")}
          </WarningCallout.Label>
          <p>{location.state.error}</p>
        </WarningCallout>
      )}
      <Label isPageHeading>{unitCode}</Label>
      <Button
        aria-label={T("containers.unit.location.actions.add")}
        onClick={(event: React.MouseEvent) => {
          onAddLocationClick();
        }}
      >
        {T("containers.unit.location.actions.add")}
      </Button>
      {loading ? (
        <Loader />
      ) : (
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Cell>
                {T("containers.unit.location.table.header.from")}
              </Table.Cell>
              <Table.Cell>
                {T("containers.unit.location.table.header.to")}
              </Table.Cell>
              <Table.Cell>
                {T("containers.unit.location.table.header.location")}
              </Table.Cell>
            </Table.Row>
          </Table.Head>
          <Table.Body>{locationTable}</Table.Body>
        </Table>
      )}
    </main>
  );
}
