import { Button, Label, SummaryList } from "nhsuk-react-components";
import { T } from "../../i18n";
import { formatAppointmentDate } from "../../formatters";
import { Address } from "../../components";
import { Trans } from "react-i18next";
import React, { useCallback, useEffect, useState } from "react";
import { Bookable, IAppointment, IPerson } from "../../interfaces";
import { useAPI } from "../../api";
import { useAuth } from "../../auth";
import { useHistory } from "react-router";

interface ParticipantAppointmentManagementProps {
  person: IPerson;
  bookability: Bookable;
}

export function ParticipantAppointmentManagement({
  person,
  bookability,
}: ParticipantAppointmentManagementProps): JSX.Element {
  const appointmentAPI = useAPI("appointment");
  const { user } = useAuth();
  const { push } = useHistory();
  const [currentAppointment, setCurrentAppointment] = useState<
    IAppointment | undefined
  >(undefined);
  useEffect(() => {
    appointmentAPI.getAppointments(person.cohortId).then((appointments) => {
      setCurrentAppointment(
        appointments.find((app) => app.status === "BOOKED")
      );
    });
  }, [person, appointmentAPI, setCurrentAppointment]);

  const goToRebookingFlow = useCallback(() => {
    let appointmentPageURL = `/registration/${person.cohortId}/appointment?isRebooking=true&appointmentType=${bookability.type}`;

    if (bookability.window) {
      appointmentPageURL += `&from=${bookability.window.from}`;

      if (!user) {
        appointmentPageURL += `&to=${bookability.window.to}`;
      }
    }
    push(appointmentPageURL);
  }, [person, bookability, push, user]);
  return (
    <main className="nhsuk-main-wrapper">
      <Label isPageHeading data-testid="existing-title">
        {T(`containers.bookingConfirmation.existing.title`)}
      </Label>
      <SummaryList>
        <SummaryList.Row>
          <SummaryList.Key>
            {T(`containers.bookingConfirmation.fields.name.label`)}
          </SummaryList.Key>
          <SummaryList.Value>
            {person?.firstName + " " + person?.lastName}
          </SummaryList.Value>
        </SummaryList.Row>
        <SummaryList.Row>
          <SummaryList.Key>
            {T(`containers.bookingConfirmation.fields.cohortId.label`)}
          </SummaryList.Key>
          <SummaryList.Value>{person?.cohortId}</SummaryList.Value>
        </SummaryList.Row>
        <SummaryList.Row>
          <SummaryList.Key>
            {T(`containers.bookingConfirmation.fields.slot.label`)}
          </SummaryList.Key>
          <SummaryList.Value data-testid="existing-time">
            {currentAppointment !== undefined
              ? formatAppointmentDate(currentAppointment.appointmentStartTime)
              : ""}
          </SummaryList.Value>
        </SummaryList.Row>
        <SummaryList.Row>
          <SummaryList.Key>
            {T(`containers.bookingConfirmation.fields.address.label`)}
          </SummaryList.Key>
          <SummaryList.Value data-testid="existing-location">
            {currentAppointment !== undefined && (
              <Address address={currentAppointment.appointmentLocation} />
            )}
          </SummaryList.Value>
        </SummaryList.Row>
      </SummaryList>
      <Label size="l">
        {T(`containers.bookingConfirmation.changeAppointment.title`)}
      </Label>
      <Trans i18nKey="containers.bookingConfirmation.changeAppointment.description1" />
      <Button data-testid="change-appt-btn" onClick={goToRebookingFlow}>
        {T("containers.bookingConfirmation.changeAppointment.button")}
      </Button>
    </main>
  );
}
