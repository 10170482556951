/* tslint:disable */

import {
  IDateInputValue,
  IDateTimeError,
  IDateTimeInputValue,
  IDateTimeSetter,
  IDateTimeValidator,
  ITimeInputValue,
} from "../interfaces";
import { T } from "i18n";
import { isValidDateTime, isValidDateTimeBoolean } from "../validations";
import { useMemo, useState } from "react";

export const useDateTime = (): readonly [
  IDateTimeInputValue,
  IDateTimeError,
  IDateTimeSetter,
  IDateTimeValidator,
  boolean
] => {
  const [date, setDate] = useState<IDateInputValue>({
    day: "",
    month: "",
    year: "",
  });
  const [time, setTime] = useState<ITimeInputValue>({
    hour: "",
    minute: "",
  });
  const [dateTimeError, setDateTimeError] = useState("");

  const dateTime = useMemo(() => {
    return {
      date,
      time,
    };
  }, [date, time]);

  const validate = (): boolean => {
    const errMessage = isValidDateTime(
      dateTime,
      T("components.dateTimeInput.errors.invalid")
    );
    setDateTimeError(errMessage);
    return errMessage === "";
  };

  const isValid = useMemo(() => {
    return isValidDateTimeBoolean(dateTime);
  }, [dateTime]);

  const setDateTime = {
    setDate,
    setTime,
  };

  return [dateTime, dateTimeError, setDateTime, validate, isValid] as const;
};
