import React, { useState } from "react";
import { Button, Input, Label } from "nhsuk-react-components";
import { ErrorPanel } from "components";
import { T } from "i18n";
import { useAuth } from "auth/useAuth";

export const UpdatePassword = (): JSX.Element => {
  const { isLoading, completeNewPassword, error } = useAuth();

  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [passwordCheckError, setPasswordCheckError] = useState("");

  const stringError = (): JSX.Element => {
    if (!error) {
      return <></>;
    }
    switch (error.code) {
      case "InvalidPasswordException":
        return (
          <>
            <p>{T("containers.login.updatePassword.errors.invalidPassword")}</p>
            <ul>
              <li>
                {T(
                  "containers.login.updatePassword.errors.requirements.length"
                )}
              </li>
              <li>
                {T(
                  "containers.login.updatePassword.errors.requirements.lowercase"
                )}
              </li>
              <li>
                {T(
                  "containers.login.updatePassword.errors.requirements.uppercase"
                )}
              </li>
              <li>
                {T(
                  "containers.login.updatePassword.errors.requirements.number"
                )}
              </li>
              <li>
                {T(
                  "containers.login.updatePassword.errors.requirements.symbol"
                )}
              </li>
            </ul>
          </>
        );
      default:
        return T("containers.login.updatePassword.errors.unknown");
    }
  };

  const updatePassword = (): void => {
    setPasswordCheckError("");
    const passwordCheckSame = password === passwordCheck;
    if (!passwordCheckSame) {
      setPasswordCheckError(
        T("containers.login.updatePassword.errors.different")
      );
      return;
    }
    completeNewPassword(password);
  };

  return (
    <>
      <Label className="nhsuk-u-margin-top-5" size="m">
        {T(`containers.login.updatePassword.title`)}
      </Label>
      <Input
        type="password"
        data-testid="password"
        label={T("containers.login.updatePassword.password.label")}
        aria-label={T("containers.login.updatePassword.password.label")}
        value={password}
        onChange={(e) => setPassword(e.currentTarget.value)}
        width="20"
      />
      <Input
        type="password"
        data-testid="password-check"
        label={T("containers.login.updatePassword.passwordCheck.label")}
        aria-label={T("containers.login.updatePassword.passwordCheck.label")}
        value={passwordCheck}
        onChange={(e) => setPasswordCheck(e.currentTarget.value)}
        error={passwordCheckError}
        width="20"
      />
      <Button
        disabled={isLoading || passwordCheck === "" || password === ""}
        data-testid="updatePassword"
        onClick={updatePassword}
      >
        {isLoading
          ? T(`containers.login.updatePassword.loading`)
          : T(`containers.login.updatePassword.label`)}
      </Button>
      {error ? (
        <ErrorPanel
          label="submission-error"
          title={
            <span id="error-summary-title">
              {T("containers.login.errors.title")}
            </span>
          }
        >
          {stringError()}
        </ErrorPanel>
      ) : (
        <></>
      )}
    </>
  );
};
