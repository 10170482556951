import React from "react";

import { AccessibilityIconProps } from "./types";

export function AccessibleParkingIcon({
  title,
  stroke = 4,
  color = "#005EB8",
  size = 16,
}: React.PropsWithChildren<AccessibilityIconProps>): JSX.Element {
  return (
    <svg width={size} height={size} viewBox="0 0 240 240" aria-label={title}>
      <g id="Accessibility">
        <path
          d="M120,20.72a99.5,99.5,0,1,0,99.49,99.5A99.53,99.53,0,0,0,120,20.72Zm-1.45,19.8a12.87,12.87,0,1,1-12.84,12.83A12.77,12.77,0,0,1,118.52,40.52Zm55.75,41.54L136,86.89v38.29l18.56,61.9a7.19,7.19,0,0,1-5.24,8.76,7.08,7.08,0,0,1-8.76-5.11l-19-56.37h-5.86L98.23,191.91a7.2,7.2,0,0,1-9.38,4c-3.73-1.45-6-5.66-4.49-9.45l16.08-60.79v-39L65.18,81.85a6.42,6.42,0,0,1-5.8-7.1,6.71,6.71,0,0,1,7.18-6.08l42.85,3.66h18.77l45.61-3.79a6.57,6.57,0,0,1,6.83,6.34A6.8,6.8,0,0,1,174.27,82.06Z"
          fill="none"
          stroke={color}
          strokeWidth={stroke}
          strokeMiterlimit="10"
        />
      </g>
    </svg>
  );
}
