import React from "react";
import { ITimeInputValue, ITimeSetter } from "interfaces";
import { Input } from "nhsuk-react-components";
import { T } from "i18n";

interface ITimeInputProps extends ITimeInputValue {
  disabled?: boolean;
  setTime: ITimeSetter;
  ariaLabel?: string;
  "data-testid"?: string;
}

export const TimeInput = ({
  disabled = false,
  ariaLabel,
  hour,
  minute,
  setTime,
  ...props
}: ITimeInputProps): JSX.Element => (
  <div className="nhsuk-date-input" aria-label={ariaLabel} {...props}>
    <div className="nhsuk-date-input__item">
      <Input
        data-testid="nhs-time-input-h"
        disabled={disabled}
        className="removeblock"
        width={2}
        label={T("components.timeInput.fields.hour.label")}
        value={hour}
        onChange={({ currentTarget: { value } }) =>
          setTime({ hour: value, minute })
        }
      />
    </div>
    <div className="nhsuk-date-input__item">
      <Input
        data-testid="nhs-time-input-m"
        disabled={disabled}
        className="removeblock"
        width={2}
        label={T("components.timeInput.fields.minute.label")}
        value={minute}
        onChange={({ currentTarget: { value } }) =>
          setTime({ hour, minute: value })
        }
      />
    </div>
  </div>
);
