import React, { useState } from "react";
import { Button } from "nhsuk-react-components";
import { ErrorPanel } from "../../components";
import { T } from "../../i18n";
import { useAPI } from "../../api";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";

export function ResetPasswordConfirmation(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [success, setSuccess] = useState(false);

  const history = useHistory();

  const userAPI = useAPI("user");
  const { id } = useParams<{ id: string }>();

  const resetPassword = (): void => {
    setLoading(true);
    userAPI
      .resetPassword(id)
      .then(() => setSuccess(true))
      .catch((err) => {
        setSuccess(false);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <main className="nhsuk-main-wrapper">
      <h3>{T("containers.user.resetPassword.title")}</h3>
      <p>{T("containers.user.resetPassword.content")}</p>
      <Button
        data-testid="reset-password-confirmation-ok-button"
        className="nhsuk-button-margin-right"
        disabled={loading || success}
        onClick={resetPassword}
      >
        {T("containers.user.resetPassword.actions.ok")}
      </Button>
      <Button
        data-testid="reset-password-confirmation-no-button"
        disabled={loading || success}
        onClick={() => history.push(`/user/${id}`)}
      >
        {T("containers.user.resetPassword.actions.cancel")}
      </Button>
      {error && (
        <ErrorPanel
          data-testid="reset-password-confirmation-error-panel"
          label={T("containers.user.resetPassword.errors.title")}
          title={T("containers.user.resetPassword.errors.title")}
        >
          {error.message}
        </ErrorPanel>
      )}
      {success && (
        <p data-testid="reset-password-confirmation-success-msg">
          {T("containers.user.resetPassword.successMessage")}
        </p>
      )}
    </main>
  );
}
