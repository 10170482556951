import React from "react";
import { IPerson } from "interfaces";
import { T } from "i18n";
import { VisitPerson } from "containers/Visit/VisitPerson";

interface CreateParticipantResultProps {
  person: IPerson;
}

export const ParticipateResult = ({
  person,
}: CreateParticipantResultProps): JSX.Element => {
  const { studyId } = person;
  return (
    <>
      <h1>{T("containers.participate.result.title")}</h1>
      {person && <VisitPerson person={person} />}
      <p>{T("containers.participate.result.message", { studyId })}</p>
    </>
  );
};
