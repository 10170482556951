import React from "react";
import { Button } from "nhsuk-react-components";
import { T } from "../../i18n";
import { useHistory } from "react-router-dom";

interface WithdrawParticipantProps {
  cohortId: string;
}

export const WithdrawParticipant = ({
  cohortId,
}: WithdrawParticipantProps): JSX.Element => {
  const history = useHistory();

  return (
    <>
      <h4>{T("components.person.withdrawParticipant.withdraw")}</h4>
      <Button
        onClick={() => {
          history.push(`/participant/${cohortId}/withdraw`);
        }}
        data-testid="withdraw-button"
      >
        {T("components.person.withdrawParticipant.withdraw")}
      </Button>
    </>
  );
};
