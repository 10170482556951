import "./Search.scss";
import React, { useCallback, useState } from "react";
import { AsyncStatus, useAsync } from "hooks";
import {
  Button,
  Col,
  Container,
  Form,
  Label,
  Row,
  Select,
} from "nhsuk-react-components";
import { ErrorPanel, Loader } from "components";
import { ISearchResult } from "interfaces";
import { SearchInput } from "./SearchInput";
import { SearchResults } from "./SearchResults";

import { T } from "i18n";
import { useAPI } from "api";

export const Search = (): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchField, setSearchField] = useState("generic");
  const peopleAPI = useAPI("people");
  const search = useCallback(
    () => peopleAPI.getPeople(searchTerm, searchField, 100),
    [peopleAPI, searchTerm, searchField]
  );
  const { execute, status, value, error } = useAsync<ISearchResult[], Error>(
    search,
    false
  );
  const searchButtonDisabled =
    (searchField === "generic" &&
      (searchTerm.trim().length < 3 || status === AsyncStatus.PENDING)) ||
    (searchField !== "generic" &&
      (searchTerm.trim().length < 2 || status === AsyncStatus.PENDING));
  return (
    <Container>
      <Row>
        <Col width="full">
          <Label isPageHeading style={{ paddingTop: "1em" }}>
            {T("components.search.heading")}
          </Label>
          <Label size="m" style={{ paddingBottom: "1em" }}>
            {T("components.search.searchInstructions")}
          </Label>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              execute();
            }}
          >
            <Row>
              <Col width="one-quarter">
                <Select
                  className="nhsuk-search-select"
                  onChange={(e) => {
                    e.preventDefault();
                    setSearchField(e.currentTarget.value);
                  }}
                  aria-label={T("components.search.searchMenu.label")}
                  placeholder={T("components.search.searchMenu.placeholder")}
                >
                  <Select.Option value="generic">
                    {T("components.search.searchMenu.placeholder")}
                  </Select.Option>
                  <Select.Option value="cohortId">
                    {T("components.search.searchMenu.options.cohortId")}
                  </Select.Option>
                  <Select.Option value="studyId">
                    {T("components.search.searchMenu.options.participantId")}
                  </Select.Option>
                  <Select.Option value="nhsNumber">
                    {T("components.search.searchMenu.options.nhsNumber")}
                  </Select.Option>
                  <Select.Option value="name">
                    {T("components.search.searchMenu.options.name")}
                  </Select.Option>
                </Select>
              </Col>
              <Col width="three-quarters">
                <SearchInput
                  aria-label={T("components.search.searchBox.label")}
                  placeholder={T("components.search.searchBox.placeholder")}
                  value={searchTerm}
                  onChange={(e) => {
                    e.preventDefault();
                    setSearchTerm(e.currentTarget.value);
                  }}
                />
              </Col>
            </Row>
            <Button
              aria-label={T("components.search.button.label")}
              disabled={searchButtonDisabled}
            >
              Search
            </Button>
          </Form>
          {status === AsyncStatus.PENDING && <Loader />}
          {error && (
            <ErrorPanel
              title={T("containers.search.errors.searchErrorTitle")}
              label={T("containers.search.errors.label")}
            >
              <p>{error.message}</p>
            </ErrorPanel>
          )}
          {value && <SearchResults results={value} />}
        </Col>
      </Row>
    </Container>
  );
};
