import React, { useEffect, useState } from "react";
import { ErrorPanel, Loader } from "components";
import { IUnit } from "interfaces";
import { Link } from "react-router-dom";
import { SummaryList } from "nhsuk-react-components";
import { T } from "i18n";
import { useAPI } from "api";

export function Units(): JSX.Element {
  const [loading, setLoading] = useState<boolean>();
  const [units, setUnits] = useState<IUnit[]>([]);
  const [error, setError] = useState<string>();

  const unitAPI = useAPI("unit");

  useEffect(() => {
    setLoading(true);
    unitAPI
      .findUnits()
      .then(({ units }) => setUnits(units || []))
      .catch(({ message }) => setError(message))
      .finally(() => setLoading(false));
  }, [unitAPI]);

  if (error) {
    return (
      <ErrorPanel
        title={T("containers.unit.select.error")}
        label={T("containers.unit.select.error")}
      >
        {error}
      </ErrorPanel>
    );
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <main className="nhsuk-main-wrapper">
      <div>
        <h5>{T("containers.unit.select.title")}</h5>
        <Link
          aria-label={T("containers.unit.select.actions.addUnit")}
          className="nhsuk-button"
          to="/unit/add"
        >
          {T("containers.unit.select.actions.addUnit")}
        </Link>
      </div>
      <SummaryList data-testid="unit-list">
        {units.map(({ code, edcSiteId }) => (
          <SummaryList.Row
            key={code}
            role="listitem"
            data-testid="unit-list-item"
          >
            <SummaryList.Key
              data-testid="unit-list-item-label"
              aria-label={T("containers.unit.select.label", { code })}
            >
              {code}
            </SummaryList.Key>
            <SummaryList.Value>{edcSiteId}</SummaryList.Value>
            <SummaryList.Actions>
              <Link
                aria-label={T("containers.unit.select.actions.location")}
                className="nhsuk-button"
                to={`/unit/${code}/location`}
              >
                {T("containers.unit.select.actions.location")}
              </Link>
            </SummaryList.Actions>
            <SummaryList.Actions>
              <Link
                aria-label={T("containers.unit.select.actions.capacity")}
                className="nhsuk-button"
                to={`/unit/${code}/capacity`}
              >
                {T("containers.unit.select.actions.capacity")}
              </Link>
            </SummaryList.Actions>
          </SummaryList.Row>
        ))}
      </SummaryList>
    </main>
  );
}
