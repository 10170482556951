import React, { useCallback, useEffect, useState } from "react";
import { AssetStore } from "assetstore";
import { Button, Checkboxes, Fieldset, Form } from "nhsuk-react-components";
import { ErrorPanel } from "components";
import { IPerson } from "interfaces/IPerson";
import { T } from "i18n";
import { VisitPerson } from "containers/Visit/VisitPerson";
import { useAPI } from "api";

interface VideoFormProps {
  filename: string;
  cohortId: string;
  person?: IPerson;
  onSubmitSuccess: () => void;
}

// English, Bengali, Gujarati, Punjabi, Urdu
// ISO639-2 standard
const tracks = ["en", "bn", "gu", "pa", "ur"] as const;

export const VideoForm = (props: VideoFormProps): JSX.Element => {
  const { filename, cohortId, person, onSubmitSuccess } = props;

  const videoAPI = useAPI("video");

  const [watched, setWatched] = useState<boolean>(false);
  const [videoURL, setVideoURL] = useState<string>();
  const [bytesLoaded, setBytesLoaded] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const loadVideoURL = useCallback(async (): Promise<void> => {
    if (videoURL !== undefined) {
      return;
    }

    type VideoAssetStore = AssetStore<{
      filename: string;
      data: Blob;
    }>;

    // Try and load the video blob but if there is an error don't prevent
    // re-downloading.
    try {
      setLoading(true);

      const store: VideoAssetStore = await AssetStore.open("video");
      const record = await store.get("video");

      // If the record exists, is for the same filename, and has data then use
      // this data as the video to play.
      if (record && record.filename === filename && record.data !== undefined) {
        const objectURL = URL.createObjectURL(record.data);
        setVideoURL(objectURL);
        setLoading(false);
        return;
      }
    } catch ({ message }) {
      setError(message);
    }

    // If the video blob failed to load, was not found, or the file name has
    // changed then download and store it.
    try {
      setBytesLoaded(0);
      const { data } = await videoAPI.getVideo(filename, ({ loaded }) =>
        setBytesLoaded(loaded)
      );
      setVideoURL(URL.createObjectURL(data));

      // Only store the new video blob after the URL has been set
      const store: VideoAssetStore = await AssetStore.open("video");
      await store.put("video", { filename, data });
    } catch ({ message }) {
      setError(message);
    } finally {
      setBytesLoaded(undefined);
      setLoading(false);
    }
  }, [filename, videoAPI, videoURL]);

  useEffect(() => {
    loadVideoURL();
    return () => {
      if (videoURL !== undefined) {
        URL.revokeObjectURL(videoURL);
      }
    };
  }, [videoURL, loadVideoURL]);

  const onSubmitForm = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setLoading(true);
    videoAPI
      .create({ cohortId, version: filename })
      .then(() => {
        setLoading(false);
        onSubmitSuccess();
      })
      .catch(({ message }) => {
        setLoading(false);
        setError(message);
      });
  };

  const toggleChoice = (event: React.ChangeEvent<HTMLInputElement>): void =>
    setWatched(event.currentTarget.checked);

  return (
    <>
      <h1 data-testid="video-title">{T("containers.video.title")}</h1>
      {person && <VisitPerson person={person} />}

      <Form onSubmit={onSubmitForm}>
        <Fieldset aria-describedby="video-hint">
          <Fieldset.Legend>
            {T("containers.video.form.instruction")}
          </Fieldset.Legend>
          {bytesLoaded !== undefined && (
            <div data-testid="video-download-progress">
              {T("containers.video.download", {
                mb: Math.round((bytesLoaded / 1024 ** 2) * 100) / 100,
              })}
            </div>
          )}
          {videoURL !== undefined && (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video
              data-testid="video-player"
              controls
              width="100%"
              controlsList="nodownload"
              disablePictureInPicture
              src={videoURL}
            >
              {tracks.map((languageCode) => (
                <track
                  key={languageCode}
                  data-testid={`${filename}-${languageCode}-subtitle`}
                  src={`${process.env.PUBLIC_URL}/videos/${filename}-${languageCode}.vtt`}
                  kind="captions"
                  srcLang={languageCode}
                  label={T(
                    `containers.video.form.subtitleLabels.${languageCode}`
                  )}
                />
              ))}
            </video>
          )}
          <Checkboxes
            name="accept"
            id="accept"
            hint={T("containers.video.form.hint")}
            data-testid="video-accepted"
          >
            <Checkboxes.Box
              data-testid="mark-video-watched"
              value="accept"
              checked={watched}
              onChange={toggleChoice}
              disabled={videoURL === undefined}
            >
              {T("containers.video.form.acknowledgement")}
            </Checkboxes.Box>
          </Checkboxes>
        </Fieldset>
        <Button
          data-testid="submit-watched-video"
          disabled={!watched || loading}
        >
          {T("containers.video.form.submit")}
        </Button>
        {error && (
          <ErrorPanel
            label="submission-error"
            title={
              <span id="error-summary-title">
                {T("containers.video.errors.title")}
              </span>
            }
          >
            {error}
          </ErrorPanel>
        )}
      </Form>
    </>
  );
};
