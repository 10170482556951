import React from "react";
import { env } from "config";

export type Version = `${number}.${number}.${number}`;

const isValidVersion = (version: Version): boolean =>
  version.match(/^\d+\.\d+.\d+$/) !== null;

type Comparator = "<" | "<=" | "=" | ">" | ">=" | "";

const isValidComparator = (comparator: Comparator): boolean =>
  comparator.match(/^[<=>]{1,2}$/) !== null;

const comparators: { [key in Comparator]: Array<Comparison> } = {
  "<": [-1],
  "<=": [-1, 0],
  "=": [0],
  ">": [1],
  ">=": [0, 1],
  "": [0],
};

type Comparison = -1 | 0 | 1;

export function compare(v1: Version, v2: Version): Comparison {
  const v1Parts = v1.split(".");
  const v2Parts = v2.split(".");
  const max = Math.max(v1Parts.length, v2Parts.length);

  // assume all parts are numeric
  for (let i = 0; i < max; i++) {
    if (v1Parts[i] < v2Parts[i]) {
      return -1;
    }
    if (v1Parts[i] > v2Parts[i]) {
      return 1;
    }
  }
  return 0;
}

export type FeatureVersion = `${Comparator} ${Version}`;

interface FeatureProps {
  version: FeatureVersion;
}

export function Feature({
  version,
  children,
}: React.PropsWithChildren<FeatureProps>): JSX.Element | null {
  if (env.REACT_APP_FEATURE_FLAGS_DISABLED === "true") {
    return <>{children}</>;
  }

  const currentVersion = process.env.REACT_APP_VERSION as Version;
  const parts = version.split(" ");

  const givenComparator = parts[0] as Comparator;
  if (!isValidComparator(givenComparator)) {
    return null;
  }

  const givenVersion = parts[1] as Version;
  if (!isValidVersion(givenVersion)) {
    return null;
  }

  const versionComparison = compare(currentVersion, givenVersion);
  if (comparators[givenComparator].includes(versionComparison)) {
    return <>{children}</>;
  }
  return null;
}
