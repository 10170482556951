import { AxiosInstance } from "axios";
import { DateTime } from "luxon";
import { IStudyEvent } from "interfaces";

export interface StudyEventsClient {
  getStudyEvents(cohortId: string): Promise<IStudyEvent[]>;
}

interface APIStudyEvent
  extends Omit<
    IStudyEvent,
    "createdAt" | "predictedActionDate" | "readinessDate"
  > {
  predictedActionDate: string;
  createdAt: string;
  readinessDate?: string;
}

export class HTTPStudyEventsClient implements StudyEventsClient {
  public constructor(protected readonly http: AxiosInstance) {}

  async getStudyEvents(cohortId: string): Promise<IStudyEvent[]> {
    const response = await this.http.get("/study-events", {
      params: {
        cohort: cohortId,
      },
    });
    return response.data.map((studyEvent: APIStudyEvent) => ({
      ...studyEvent,
      createdAt: DateTime.fromISO(studyEvent.createdAt),
      predictedActionDate: DateTime.fromISO(studyEvent.predictedActionDate),
      readinessDate: studyEvent.readinessDate
        ? DateTime.fromISO(studyEvent.readinessDate)
        : undefined,
    }));
  }
}
