export interface Resource {
  source: string;
  type: string;
  internalId?: string;
  externalId: string;
  summary: string;
  version?: string;
}

export interface Event {
  source: string;
  externalID: string;
  timestamp: string;
  action: string;
  summary: string;
  user: {
    email: string;
  };
  resource: Resource;
  secondaryResource?: Resource;
}

export interface AuditEvents {
  content: Event[];
  pageable: Pageable;
  totalElements: number;
  totalPages: number;
  last: boolean;
  size: number;
  number: number;
  sort: Sort;
  numberOfElements: number;
  first: boolean;
  empty: boolean;
}

export interface AuditDetail {
  field: string;
  old: string;
  new: string;
}

interface Pageable {
  pageNumber: number;
  pageSize: number;
  sort: Sort;
  offset: number;
  unpaged: boolean;
  paged: boolean;
}

interface Sort {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
}

export const emptyAuditEvents = {
  content: [],
  number: 0,
  empty: true,
  first: true,
  last: true,
  numberOfElements: 0,
  pageable: {
    pageNumber: 0,
    totalPages: 0,
    paged: true,
    sort: {
      sorted: true,
      empty: true,
      unsorted: false,
    },
    offset: 0,
    pageSize: 0,
    unpaged: false,
  },
  size: 0,
  sort: {
    sorted: true,
    empty: true,
    unsorted: false,
  },
  totalElements: 0,
  totalPages: 0,
};
