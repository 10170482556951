import React from "react";
import { DatePickerWithDefault } from "./DatePickerWithDefault";
import {
  IDateTimeInputValue,
  IDateTimeSetter,
  ITimeInputValue,
  ITimeSetter,
} from "interfaces";
import { TimeInput } from "./TimeInput";

interface IDateTimePickerWithDefaultProps
  extends IDateTimeInputValue,
    IDateTimeSetter {
  time: ITimeInputValue;
  setTime: ITimeSetter;
  disabled?: boolean;
  error?: string;
}

export const DateTimePickerWithDefault = (
  props: IDateTimePickerWithDefaultProps
): JSX.Element => {
  const { time, setTime, disabled = false } = props;

  return (
    <>
      <DatePickerWithDefault {...props} />
      <TimeInput {...time} disabled={disabled} setTime={setTime} />
    </>
  );
};
