import React, { useCallback, useEffect, useState } from "react";
import { Button, Checkboxes } from "nhsuk-react-components";
import { ErrorPanel } from "components";
import { IPerson } from "../../interfaces";
import { Link, useHistory, useParams } from "react-router-dom";
import { PersonDetails, PersonFields } from "containers/Person";
import { T } from "i18n";
import { useAPI } from "api";

const personFields = new Set<PersonFields>([
  "name",
  "nhsNumber",
  "dateOfBirth",
  "mailingAddress",
  "phoneNumber",
  "mobilePhoneNumber",
  "email",
  "gpPractice",
]);

export function ConfirmDetails(): JSX.Element {
  const { push } = useHistory();
  const { cohortId, unitCode } = useParams<{
    cohortId: string;
    unitCode: string;
  }>();

  const checkInAPI = useAPI("checkIn");
  const personAPI = useAPI("people");

  const [checked, setChecked] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [submissionError, setSubmissionError] = useState<Error>();
  const [person, setPerson] = useState<IPerson>();
  const [loadPersonError, setLoadPersonError] = useState<Error>();
  const [loadingPerson, setLoadingPerson] = useState<boolean>(false);

  useEffect(() => {
    setLoadingPerson(true);
    personAPI
      .getPerson(cohortId)
      .then(setPerson)
      .catch(setLoadPersonError)
      .finally(() => setLoadingPerson(false));
  }, [personAPI, cohortId]);

  const checkIn = useCallback(() => {
    setSubmitting(true);
    checkInAPI
      .checkIn(unitCode, cohortId)
      .then(() => {
        setSubmitting(false);
        push(`/visit/${cohortId}`);
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.response && error.response.status === 409) {
          error.message =
            "The previous appointment for this participant was not completed. Please use this link to abort the previous appointment before proceeding with the check-in:";
        }
        setSubmissionError(error);
      });
  }, [cohortId, push, unitCode, checkInAPI]);

  return (
    <div className="nhsuk-main-wrapper">
      <h1>{T("containers.visit.confirm.title")}</h1>

      {person && (
        <PersonDetails
          person={person}
          setPerson={setPerson}
          displayFields={personFields}
        />
      )}

      {submissionError && (
        <ErrorPanel
          label={T("containers.visit.errors.submissionError")}
          title={T("containers.visit.errors.submissionError")}
        >
          {submissionError.message}
          <br />
          <Link
            data-testid="abort-link"
            to={`/visit/${cohortId}?stage=ABORT_APPOINTMENT`}
          >
            {T(`containers.visit.links.abortAppointment`)}
          </Link>
        </ErrorPanel>
      )}

      {loadPersonError && (
        <ErrorPanel
          label={T("containers.visit.errors.loadPersonError")}
          title={T("containers.visit.errors.loadPersonError")}
        >
          {loadPersonError.message}
        </ErrorPanel>
      )}

      <h2>{T("containers.visit.confirm.hint")}</h2>
      <Checkboxes name="confirm" id="confirm">
        <Checkboxes.Box
          value="accept"
          disabled={loadingPerson || submitting}
          checked={checked}
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
            setChecked(event.currentTarget.checked)
          }
          data-testid="visit-confirm"
        >
          {T("containers.visit.confirm.acknowledgement")}
        </Checkboxes.Box>
      </Checkboxes>

      <div className="visit-navigation">
        <Button
          data-testid={`check-in-button-${cohortId}`}
          disabled={loadingPerson || submitting || !checked}
          onClick={() => checkIn()}
        >
          {submitting
            ? T(
                "containers.checkIn.fields.appointments.actions.checkIn.loading"
              )
            : T("containers.checkIn.fields.appointments.actions.checkIn.label")}
        </Button>
      </div>
    </div>
  );
}
