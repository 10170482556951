/* tslint:disable */

import { FormValidity } from "components/FormValidity";
import { IAddress, IAddressFieldIDs, IAddressSetter } from "../interfaces";
import { T } from "i18n";
import {
  anyWords,
  hasWords,
  isPostcode,
  isValidPostcode,
} from "../validations";
import { useCallback, useMemo, useState } from "react";

const emptyAddress = {
  address1: "",
  address2: "",
  county: "",
  locality: "",
  postcode: "",
  townOrCity: "",
};

export type IAddressValidator = (validity: FormValidity) => void;

export const useAddress = (
  initialValues: IAddress = emptyAddress,
  allowPartialAddress?: boolean
): readonly [
  IAddress,
  IAddressSetter,
  IAddressFieldIDs,
  IAddressValidator,
  boolean
] => {
  const [address1, setAddress1] = useState(initialValues.address1);
  const [address2, setAddress2] = useState(initialValues.address2);
  const [county, setCounty] = useState(initialValues.county);
  const [postcode, setPostcode] = useState(initialValues.postcode);
  const [town, setTown] = useState(initialValues.townOrCity);

  const ids = {
    address1ID: "address1-input",
    address2ID: "address2-input",
    countyID: "county-input",
    postcodeID: "postcode-input",
    townOrCityID: "town-or-city-input",
  };

  const addressSetter = useMemo(
    () => ({
      setAddress1,
      setAddress2,
      setCounty,
      setPostcode,
      setTownOrCity: setTown,
    }),
    [setAddress1, setAddress2, setCounty, setPostcode, setTown]
  );

  const validate = useCallback(
    (validity: FormValidity): void => {
      if (!allowPartialAddress) {
        const address1Error = hasWords(
          address1,
          T("components.addressInput.fields.address1.errors.empty")
        );
        validity.addError(address1Error, ids.address1ID);
      }
      const townError = hasWords(
        town,
        T("components.addressInput.fields.town.errors.empty")
      );
      validity.addError(townError, ids.townOrCityID);
      const postcodeError = isValidPostcode(
        postcode,
        T("components.addressInput.fields.postcode.errors.invalid")
      );
      validity.addError(postcodeError, ids.postcodeID);
    },
    [
      address1,
      allowPartialAddress,
      ids.address1ID,
      ids.postcodeID,
      ids.townOrCityID,
      postcode,
      town,
    ]
  );

  const isValid = useMemo(() => {
    if (allowPartialAddress) {
      return isPostcode(postcode) && anyWords(town);
    }
    return anyWords(address1) && isPostcode(postcode) && anyWords(town);
  }, [address1, postcode, town, allowPartialAddress]);

  return [
    {
      address1,
      address2,
      county,
      postcode,
      townOrCity: town,
    },
    addressSetter,
    ids,
    validate,
    isValid,
  ] as const;
};
