import { ValidationFunction } from "./ValidationFunction";
import { VoucherStatus } from "../enums";

const validStates: VoucherStatus[] = ["ACCEPTED", "DECLINED"];

function isVoucherStatus(givenStatus: any): givenStatus is VoucherStatus {
  return validStates.includes(givenStatus as any);
}

export const isValidVoucherStatus: ValidationFunction<string> = (
  type: unknown,
  message: string
): string => (isVoucherStatus(type) ? "" : message);
