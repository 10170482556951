import { Hint } from "nhsuk-react-components";
import { T } from "../../i18n";
import React from "react";

export const BookingProgress = ({
  step: stepNumber,
  maxSteps = 10,
}: {
  step: number;
  maxSteps?: number;
}): JSX.Element => {
  return (
    <Hint data-testid="wizard-progress">
      {T("containers.registration.sections.progressMessage", {
        stepNumber,
        maxSteps: maxSteps,
      })}
    </Hint>
  );
};
