import { AppointmentType } from "enums";
import { DateTime } from "luxon";
import { IAddress } from "./IAddress";

export interface IAppointment {
  cohortId: string;
  status: "BOOKED" | "CANCELLED" | "ATTENDED" | "NO_SHOW";
  type: "D1" | "Y1" | "Y2";
  appointmentStartTime: DateTime;
  appointmentEndTime: DateTime;
  appointmentLocation: IAddress;
  accessibility: IAccessibility;
  unitCode: string;
  inProgress?: boolean;
}

export const interpreterLanguages = [
  "ALBANIAN",
  "AMHARIC",
  "ARABIC",
  "BENGALI",
  "BRAZILIAN_PORTUGUESE",
  "BRITISH_SIGN_LANGUAGE",
  "BULGARIAN",
  "CANTONESE",
  "CROATIAN",
  "CZECH",
  "DARI",
  "DUTCH",
  "EGYPTIAN_ARABIC",
  "ESTONIAN",
  "FARSI",
  "FARSI_IRAN",
  "FRENCH",
  "GERMAN",
  "GREEK",
  "GUJARATI",
  "HEBREW",
  "HINDI",
  "HUNGARIAN",
  "ITALIAN",
  "JAPANESE",
  "KASHMIRI",
  "KOREAN",
  "KURDISH",
  "LATVIAN",
  "LITHUANIAN",
  "MALAYALAM",
  "MALTESE",
  "MANDARIN",
  "NEPALI",
  "PASHTO",
  "PERSIAN_FARSI",
  "POLISH",
  "PORTUGUESE",
  "PUNJABI",
  "ROMANIAN",
  "RUSSIAN",
  "SERBIAN",
  "SERBIAN_SLOVAK",
  "SINHALA",
  "SLOVAK",
  "SOMALI",
  "SPANISH",
  "SWAHILI",
  "SYLHETI",
  "TAMIL",
  "THAI",
  "TIGRINYA",
  "TURKISH",
  "TWI",
  "UKRAINIAN",
  "URDU",
  "VIETNAMESE",
] as const;

export type IInterpreterLanguage = typeof interpreterLanguages[number];

export const selectableInterpreterLanguages = [
  "NOT_APPLICABLE",
  // SERBIAN_SLOVAK was split into two separate values SERBIAN & SLOVAK
  // but we have existing participants with the old value. The combined
  // value will not be selectable on the frontend but we still need to be
  // able to deserialise / display it
  ...interpreterLanguages.filter((value) => value !== "SERBIAN_SLOVAK"),
] as const;

export type ISelectableInterpreterLanguage =
  typeof selectableInterpreterLanguages[number];

export interface IAccessibilityOptions {
  wheelchair: boolean;
  parking: boolean;
  stepFree: boolean;
  interpreter: boolean;
  visual: boolean;
  hearing: boolean;
  signLanguage: boolean;
}

export interface IAccessibility {
  options: IAccessibilityOptions;
  interpreterLanguage: ISelectableInterpreterLanguage;
}

export type Bookability = Bookable | NotBookable;

export interface Bookable {
  bookable: true;
  hasExistingAppointment: boolean;
  type: AppointmentType;
  window?: {
    from: string;
    to: string;
  };
}

export interface NotBookable {
  bookable: false;
  reason: NotBookableReason;
}

export type NotBookableReason =
  | "VISIT_COMPLETED"
  | "PERSON_WITHDRAWN"
  | "NOT_AUTHORISED"
  | "PERSON_OUTSIDE_ENGLAND"
  | "EXCLUDED";
