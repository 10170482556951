import { AxiosInstance } from "axios";
import {
  CaseContact,
  CaseFieldState,
  CreateResultNote,
  ECaseContactType,
  ECaseField,
  ICase,
  ICaseList,
  ICaseState,
  INote,
} from "interfaces";
import { CaseStatus } from "enums";
import { DateTime } from "luxon";
import { IResult, VisitType } from "../../interfaces/IResult";

export interface IResultsClient {
  getCases: (
    includeOpen: boolean,
    includeClosed: boolean
  ) => Promise<ICaseList>;
  getReportPDF: (caseReference: string) => Promise<Blob>;
  getCaseState: (caseReference: string) => Promise<ICaseState>;
  saveCase: (
    caseReference: string,
    status: CaseStatus,
    state: CaseFieldState,
    contactHistory: CaseContact[]
  ) => Promise<ICaseState>;
  getNotes: (caseReference: string) => Promise<INote[]>;
  createNote: (note: CreateResultNote) => Promise<void>;
  getResultForVisit: (
    cohortId: string,
    visitType: VisitType
  ) => Promise<IResult>;
}

export interface APICase
  extends Omit<
    ICase,
    "reportDate" | "bloodCollectionTime" | "status" | "lastUpdatedTime"
  > {
  reportDate: string;
  bloodCollectionTime: string | undefined;
  status: string;
  lastUpdatedTime: string | undefined;
}

interface APICaseList {
  cases: APICase[];
}

export interface APICaseState {
  cohortId: string;
  status: CaseStatus;
  state: APICaseFieldState[];
  contactHistory: APICaseContact[];
  lastUpdatedBy: string;
  lastUpdatedTime: string;
}

export interface APICaseFieldState {
  field: ECaseField;
  value: string;
}

export interface APICaseContact {
  type: ECaseContactType;
  date: string;
  note: string;
}

const apiResultsToResults = (input: APICaseList): ICaseList => {
  if (input.cases) {
    const cases = input.cases.map(apiCaseToCase);
    return {
      cases,
    };
  }
  return { cases: [] };
};

const apiCaseToCase = (input: APICase): ICase => {
  const reportDate = DateTime.fromISO(input.reportDate).startOf("day");
  return {
    ...input,
    reportDate,
    bloodCollectionTime: input.bloodCollectionTime
      ? DateTime.fromISO(input.bloodCollectionTime)
      : undefined,
    status: input.status as CaseStatus,
    lastUpdatedTime: input.lastUpdatedTime
      ? DateTime.fromISO(input.lastUpdatedTime)
      : undefined,
  };
};

const apiCaseStatetoCaseState = (input: APICaseState): ICaseState => {
  const state: CaseFieldState = {
    ID_CONFIRMATION: "",
    PARTICIPANT_DOCS_MAILED: "",
    REFERRAL_ACCEPTED: "",
    REFERRAL_REJECTED: "",
    REFERRAL_SUBMITTED: "",
    GP_DOCS_MAILED: "",
    ECRF_FORM_SUBMITTED: "",
    CLOSE_NOTE: "",
  };
  input.state.forEach((item) => {
    state[item.field] = item.value;
  });

  const contactHistory: CaseContact[] = [];
  input.contactHistory.forEach((item: APICaseContact) => {
    const contact: CaseContact = {
      type: item.type,
      date: DateTime.fromISO(item.date),
      note: item.note,
    };
    contactHistory.push(contact);
  });
  const lastUpdatedTime = DateTime.fromISO(input.lastUpdatedTime);
  return {
    ...input,
    state,
    contactHistory,
    lastUpdatedTime,
  };
};

export class ResultsClient implements IResultsClient {
  public constructor(protected readonly http: AxiosInstance) {}

  public async getCases(
    includeOpen: boolean,
    includeClosed: boolean
  ): Promise<ICaseList> {
    const params = new URLSearchParams();
    if (includeOpen) {
      params.append("status", "OPEN");
    }
    if (includeClosed) {
      params.append("status", "CLOSED");
    }
    const response = await this.http.get("/cases", { params });
    return apiResultsToResults(response.data);
  }

  public async getCaseState(caseReference: string): Promise<ICaseState> {
    const response = await this.http.get(`/cases/${caseReference}`, {});
    return apiCaseStatetoCaseState(response.data);
  }

  public async saveCase(
    caseReference: string,
    status: CaseStatus,
    stateInput: CaseFieldState,
    contactHistory: CaseContact[]
  ): Promise<ICaseState> {
    const state = Object.entries(stateInput).map(([key, value]) => ({
      field: key,
      value,
    }));
    const response = await this.http.post(`/cases/${caseReference}/state`, {
      status,
      state,
      contactHistory,
    });
    return apiCaseStatetoCaseState(response.data);
  }

  public getReportPDF(caseReference: string): Promise<Blob> {
    return this.http
      .get(`/case/${caseReference}/report`, {
        responseType: "blob",
      })
      .then((response) => response.data);
  }

  public async getNotes(caseReference: string): Promise<INote[]> {
    const response = await this.http.get(`/case/${caseReference}/notes`);
    return response.data.map((note: { createdAt: string }) => ({
      ...note,
      createdAt: DateTime.fromISO(note.createdAt),
    }));
  }

  public createNote(note: CreateResultNote): Promise<void> {
    return this.http.post(`/case/${note.caseReference}/notes`, {
      content: note.content,
    });
  }

  getResultForVisit(cohortId: string, visitType: VisitType): Promise<IResult> {
    return this.http
      .get<IResult>(`/result/${cohortId}`, {
        params: {
          visitType,
        },
      })
      .then((response) => response.data);
  }
}
