export enum ReportType {
  Appointments = "APPOINTMENTS",
  BloodDrawsPerDay = "BLOOD_DRAWS_PER_DAY",
  Y1LabForecast = "Y1_LAB_FORECAST",
  BloodDrawsPerUnitOrNurse = "BLOOD_DRAWS_PER_UNIT_OR_NURSE",
  FalsePositives = "FALSE_POSITIVES",
  CumulativeRetention = "CUMULATIVE_RETENTION",
  Y2CumulativeRetention = "Y2_CUMULATIVE_RETENTION",
  MobileClinicsDailyTrends = "MOBILE_CLINICS_DAILY_TRENDS",
  RetentionByLocationAndEthnicity = "RETENTION_BY_LOCATION_AND_ETHNICITY",
  RetentionByLocationAndIMD = "RETENTION_BY_LOCATION_AND_IMD",
  RetentionByLocationAndAgeAndGender = "RETENTION_BY_LOCATION_AND_AGE_AND_GENDER",
  AppointmentNotBooked = "APPOINTMENT_NOT_BOOKED",
  Y2MobileClinicsDailyTrends = "Y2_MOBILE_CLINICS_DAILY_TRENDS",
  ParticipantReportedStatus = "PARTICIPANT_REPORTED_STATUS",
}
