import "./AuditEvents.scss";
import React, { useState } from "react";
import {
  AuditActionEvent,
  AuditCreateEvent,
  AuditDeleteEvent,
  AuditUpdateEvent,
  IAuditEvent,
  UpdateDetail,
} from "interfaces/IAuditEvent";
import { Details, Table } from "nhsuk-react-components";
import { T } from "i18n";
import { Trans } from "react-i18next";
import { SortArrows } from "components/SortArrows";
import { FieldSortFunc, IFieldSorter } from "interfaces";

interface ExpandableEventProps {
  event: IAuditEvent;
  summaryKey: string;
}

const ExpandableEvent = ({
  event,
  summaryKey,
  children,
}: React.PropsWithChildren<ExpandableEventProps>): JSX.Element => {
  return (
    <Details>
      <Details.Summary>
        <Trans
          i18nKey={`containers.auditEvents.${summaryKey}`}
          values={{ subject: event.subject, entity: event.entity }}
        />
      </Details.Summary>
      <Details.Text>
        <p>
          <Trans
            i18nKey="containers.auditEvents.by"
            values={{ initiator: event.initiator }}
          />
        </p>
        {children}
      </Details.Text>
    </Details>
  );
};

const CreateEvent = ({ event }: { event: AuditCreateEvent }): JSX.Element => {
  return (
    <ExpandableEvent event={event} summaryKey="createEvent">
      <ul>
        {event.details
          .filter((detail) => detail.value !== "")
          .map((detail) => (
            <li key={detail.field}>
              {T("containers.auditEvents.createField", {
                field: detail.field.toLowerCase(),
                value: detail.value,
              })}
            </li>
          ))}
      </ul>
    </ExpandableEvent>
  );
};

const updateKey = (detail: UpdateDetail): string => {
  const baseKey = "containers.auditEvents.";
  if (detail.old === "") {
    return baseKey + "updateAddingField";
  }
  if (detail.new === "") {
    return baseKey + "updateRemovingField";
  }
  return baseKey + "updateField";
};

const UpdateEvent = ({ event }: { event: AuditUpdateEvent }): JSX.Element => {
  return (
    <ExpandableEvent event={event} summaryKey="updateEvent">
      <ul>
        {event.details.map((detail) => (
          <li key={detail.field}>{T(updateKey(detail), detail)}</li>
        ))}
      </ul>
    </ExpandableEvent>
  );
};

const DeleteEvent = ({ event }: { event: AuditDeleteEvent }): JSX.Element => {
  return (
    <ExpandableEvent event={event} summaryKey={"deleteEvent"}>
      <ul>
        {event.details.map((detail) => (
          <li key={detail.field}>
            {T("containers.auditEvents.deleteField", detail)}
          </li>
        ))}
      </ul>
    </ExpandableEvent>
  );
};

const ActionEvent = ({ event }: { event: AuditActionEvent }): JSX.Element => {
  return (
    <p className="summary-text">
      <Trans
        i18nKey={`containers.auditEvents.actions.${
          event.details.type
        }.${event.details.result.toLowerCase()}`}
        values={{ subject: event.subject }}
      />
    </p>
  );
};

interface AuditEventsProps {
  events: IAuditEvent[];
}

export const AuditEvents = ({ events }: AuditEventsProps): JSX.Element => {
  const [currentSorter, setCurrentSorter] = useState<IFieldSorter<IAuditEvent>>(
    {
      field: "timestamp",
      isDescending: true,
    }
  );
  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.Cell>{T("containers.auditEvents.eventHeader")}</Table.Cell>
          <Table.Cell>
            <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
              <div>{T("containers.auditEvents.timestampHeader")}</div>
              <SortArrows
                field="timestamp"
                currentSorter={currentSorter}
                setCurrentSorter={setCurrentSorter}
                className=""
              />
            </div>
          </Table.Cell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {events.sort(FieldSortFunc<IAuditEvent>(currentSorter)).map((event) => {
          let component = null;
          switch (event.type) {
            case "CREATE":
              component = <CreateEvent event={event} />;
              break;
            case "UPDATE":
              component = <UpdateEvent event={event} />;
              break;
            case "DELETE":
              component = <DeleteEvent event={event} />;
              break;
            case "ACTION":
              component = <ActionEvent event={event} />;
              break;
          }
          return (
            <Table.Row
              key={`${event.subject}-${event.entity}-${event.timestamp}`}
            >
              <Table.Cell>{component}</Table.Cell>
              <Table.Cell>
                {event.timestamp.toFormat("dd MMM yyyy") +
                  " at " +
                  event.timestamp.toFormat("T ZZZZ")}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};
