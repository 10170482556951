export enum AppointmentRegion {
  NHS_01 = "NHS_01",
  NHS_02 = "NHS_02",
  NHS_03 = "NHS_03",
  NHS_04 = "NHS_04",
  NHS_05 = "NHS_05",
  NHS_06 = "NHS_06",
  NHS_07 = "NHS_07",
  UNKNOWN = "UNKNOWN",
}
