import React from "react";
import {
  IAccessibilityInput,
  IAccessibilityInputOptions,
  JumpTo,
} from "../../components";
import { SummaryList } from "nhsuk-react-components";
import { T } from "i18n";
import { getInterpreterLanguageName } from "../../helpers";

export function summariseAccessibility(
  accessibility: IAccessibilityInput
): [string, string][] {
  return Object.entries(accessibility.options)
    .filter(([, selected]) => selected)
    .map(([option]) => option as keyof IAccessibilityInputOptions)
    .map((option) => {
      switch (option) {
        case "none":
          return [
            option,
            T("containers.booking.accessibilitySummary.options.none"),
          ];
        case "interpreter": {
          const summary = `${T(
            `containers.booking.fields.search.accessibility.inputs.interpreter.label`
          )} - ${getInterpreterLanguageName(
            accessibility.interpreterLanguage
          )}`;

          return [option, summary];
        }
        default:
          return [
            option,
            T(
              `containers.booking.fields.search.accessibility.inputs.${option}.label`
            ),
          ];
      }
    });
}

export const AccessibilitySummary = ({
  accessibility,
  accessibilityWizardPageID,
}: {
  accessibility: IAccessibilityInput;
  accessibilityWizardPageID?: string;
}): JSX.Element => {
  return (
    <SummaryList>
      <SummaryList.Row>
        <SummaryList.Key>
          {T("containers.booking.accessibilitySummary.label")}
        </SummaryList.Key>
        <SummaryList.Value data-testid="accessibility-summary-values">
          {summariseAccessibility(accessibility).map(([option, summary]) => {
            return <p key={option}>{summary}</p>;
          })}
        </SummaryList.Value>
        {accessibilityWizardPageID && (
          <SummaryList.Actions>
            <JumpTo
              to={accessibilityWizardPageID}
              testid="accessibility-summary-change"
              text={T(
                "containers.booking.accessibilitySummary.actions.change.label"
              )}
            />
          </SummaryList.Actions>
        )}
      </SummaryList.Row>
    </SummaryList>
  );
};
