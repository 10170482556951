import "./DataSharingConsent.scss";
import React, { useEffect, useState } from "react";
import { BackLink, Button } from "nhsuk-react-components";
import { ErrorPanel, Loader } from "components";
import { IDataSharingAgreement } from "interfaces";
import { Link } from "react-router-dom";
import { T } from "i18n";
import { useAPI } from "api";

type DataSharingConsentPropType = {
  agreementVersion?: string | null;
  onAgreementVersionChanged: (agreementVersion?: string | null) => void;
};

export const DataSharingConsent = ({
  agreementVersion,
  onAgreementVersionChanged,
}: DataSharingConsentPropType): JSX.Element => {
  const documentAPI = useAPI("document");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [agreement, setAgreement] = useState<IDataSharingAgreement>();

  useEffect(() => {
    if (agreement !== undefined) {
      return;
    }
    setLoading(true);
    documentAPI
      .getDataSharingAgreement()
      .then((agreement) => setAgreement(agreement))
      .catch(({ message }) => setError(message))
      .finally(() => setLoading(false));
  }, [agreement, documentAPI]);

  if (error) {
    return (
      <ErrorPanel
        title={T("containers.dataSharingConsent.errorTitle")}
        label={T("containers.dataSharingConsent.errorTitle")}
      >
        <p data-testid="data-sharing-consent-error">
          {T("containers.dataSharingConsent.error", { error })}
        </p>
      </ErrorPanel>
    );
  }

  if (loading || agreement === undefined) {
    return <Loader />;
  }

  if (agreementVersion === null) {
    return (
      <>
        <h1>{T("containers.dataSharingConsent.rejectedTitle")}</h1>
        <pre
          className="data-sharing-agreement"
          data-testid="data-sharing-consent-agreement-rejected"
        >
          {T("containers.dataSharingConsent.rejected")}
        </pre>
      </>
    );
  }

  const { content, version } = agreement;

  return (
    <>
      <BackLink asElement={Link} to="/welcome">
        {T("containers.dataSharingConsent.actions.back")}
      </BackLink>
      <h1>{T("containers.dataSharingConsent.title")}</h1>
      <pre
        className="data-sharing-agreement"
        data-testid="data-sharing-consent-agreement"
      >
        {content}
      </pre>
      <Button
        data-testid="data-sharing-consent-button"
        className="nhsuk-button-margin-right"
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          onAgreementVersionChanged(version);
        }}
      >
        {T("containers.dataSharingConsent.actions.accept")}
      </Button>
      &nbsp;
      <Button
        data-testid="data-sharing-reject-button"
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          onAgreementVersionChanged(null);
        }}
      >
        {T("containers.dataSharingConsent.actions.reject")}
      </Button>
    </>
  );
};
