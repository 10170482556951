import { AxiosInstance } from "axios";
import { IVoucher } from "interfaces";
import { T } from "i18n";

export interface IVoucherClient {
  createVoucher: (
    cohortId: string,
    voucherPayload: IVoucher
  ) => Promise<unknown>;
}

export class VoucherClient implements IVoucherClient {
  public constructor(protected readonly http: AxiosInstance) {}

  public async createVoucher(
    cohortId: string,
    voucherPayload: IVoucher
  ): Promise<unknown> {
    return this.http
      .post(`/people/${cohortId}/vouchers`, voucherPayload)
      .catch((err) => {
        if (err.response?.status === 409) {
          return Promise.reject(
            new Error(T("containers.vouchers.errors.alreadyClaimed"))
          );
        }
        return Promise.reject(err);
      });
  }
}
