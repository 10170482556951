import { AxiosInstance } from "axios";

export interface IParticipantClient {
  createParticipant(cohortId: string, unitCode: string): Promise<void>;
}

export class ParticipantClient implements IParticipantClient {
  public constructor(protected readonly http: AxiosInstance) {}

  public createParticipant(cohortId: string, unitCode: string): Promise<void> {
    return this.http.post("/participants", {
      cohortId,
      unitCode,
    });
  }
}
