/* tslint:disable */

import {
  IDateError,
  IDateInputValue,
  IDateSetter,
  IDateValidator,
} from "../interfaces";
import { T } from "i18n";
import { isValidDate, isValidDateBoolean } from "../validations";
import { useMemo, useState } from "react";

export const useDate = (): readonly [
  IDateInputValue,
  IDateError,
  IDateSetter,
  IDateValidator,
  boolean
] => {
  const [date, setDate] = useState<IDateInputValue>({
    day: "",
    month: "",
    year: "",
  });
  const [dateError, setDateError] = useState("");

  const validate = (): boolean => {
    const errMessage = isValidDate(
      date,
      T("components.dateInput.errors.invalid")
    );
    setDateError(errMessage);
    return errMessage === "";
  };

  const isValid = useMemo(() => {
    return isValidDateBoolean(date);
  }, [date]);

  return [date, dateError, setDate, validate, isValid] as const;
};
