import "./Warning.scss";
import React from "react";
import { T } from "i18n";

interface WarningProps {
  disallow: string[];
  location: Location;
}

export const Warning = ({
  disallow,
  location: { host },
}: WarningProps): JSX.Element | null => {
  const disallowed = disallow.find((domain) => host.indexOf(domain) !== -1);
  if (!disallowed) {
    return null;
  }
  return (
    <div data-testid="environment-warning" className="warning">
      {T("warning", { disallowed })}
    </div>
  );
};
