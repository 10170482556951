import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { T } from "i18n";
import { WarningCallout } from "nhsuk-react-components";
import { useAPI } from "api";
import { useEventType } from "hooks";

const bloodDrawOffset = {
  D1: 0,
  Y1: 313,
  Y2: 678,
};

export const YxBookingNote = ({
  cohortId,
}: {
  cohortId: string;
}): JSX.Element => {
  const [bloodDrawDate, setBloodDrawDate] = useState<DateTime>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error>();
  const eventType = useEventType();

  const studyEventsAPI = useAPI("studyEvents");

  useEffect(() => {
    setLoading(true);
    studyEventsAPI
      .getStudyEvents(cohortId)
      .then((studyEvents) => {
        for (const studyEvent of studyEvents) {
          if (studyEvent.eventType === eventType) {
            setBloodDrawDate(
              studyEvent.predictedActionDate.minus({
                days: bloodDrawOffset[eventType],
              })
            );
          }
        }
      })
      .catch(setError)
      .finally(() => setLoading(false));
  }, [setLoading, setBloodDrawDate, cohortId, studyEventsAPI, eventType]);

  if (loading) {
    return <></>;
  }
  // Error behaviour was undefined in the ticket but given this is an instruction this will just not show in both error cases
  if (error !== undefined || bloodDrawDate === undefined) {
    return <></>;
  }

  return (
    <WarningCallout label={T("containers.booking.fields.slot.yxNote.title")}>
      <WarningCallout.Label>
        {T("containers.booking.fields.slot.yxNote.title")}
      </WarningCallout.Label>
      <p>
        {T("containers.booking.fields.slot.yxNote.firstAppointmentNote", {
          date: bloodDrawDate.toFormat("d LLLL yyyy"),
        })}
      </p>
      <p>
        {T("containers.booking.fields.slot.yxNote.instruction", {
          context: eventType,
        })}
      </p>
    </WarningCallout>
  );
};
