import { IUser } from "./IUser";
import { createContext } from "react";

const errorUseOutsideProvider = (): never => {
  throw new Error("useAuth must be used within an AuthProvider");
};

export type MFA = "SMS_MFA" | "SOFTWARE_TOKEN_MFA";

export type UserAuthState = "NEW_PASSWORD_REQUIRED" | MFA | "REGISTER_TOTP";

export interface IAuthContext {
  user?: IUser;
  isLoading: boolean;
  isInitialized: boolean;
  deviceRegistrationCode?: string;
  userAuthState?: UserAuthState;
  error?: any;
  signIn: (username: string, password: string) => Promise<any>;
  signOut: () => Promise<any>;
  completeNewPassword: (newPassword: string) => Promise<void>;
  confirmSignIn: (code: string, mfaType: MFA) => Promise<void>;
  confirmDevice: (code: string) => Promise<void>;
  refresh: () => Promise<void>;
}

const initialContext: IAuthContext = {
  isLoading: false,
  isInitialized: false,
  signIn: errorUseOutsideProvider,
  signOut: errorUseOutsideProvider,
  completeNewPassword: errorUseOutsideProvider,
  confirmSignIn: errorUseOutsideProvider,
  confirmDevice: errorUseOutsideProvider,
  refresh: errorUseOutsideProvider,
};

export const AuthContext = createContext<IAuthContext>(initialContext);
