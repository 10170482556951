import { AxiosInstance } from "axios";

import { EventType, IQuestionnaire } from "../../interfaces";

export interface IQuestionnaireClient {
  getQuestionnaire(
    cohortId: string,
    eventType?: EventType
  ): Promise<IQuestionnaire>;
}

export class QuestionnaireClient implements IQuestionnaireClient {
  public constructor(protected readonly http: AxiosInstance) {}

  public getQuestionnaire(
    cohortID: string,
    eventType: EventType = "D1"
  ): Promise<IQuestionnaire> {
    return this.http
      .get<IQuestionnaire>(
        `/person/${cohortID}/questionnaire?eventType=${eventType}`
      )
      .then((response) => response.data);
  }
}
