import React from "react";
import { ActionLink } from "nhsuk-react-components";
import { Link } from "react-router-dom";
import { T } from "i18n";
import { VisitStageProps } from "../Visit";

export const Checkout = ({
  cohortId,
  visit,
}: VisitStageProps<"CHECKOUT">): JSX.Element => (
  <>
    <h3 data-testid="visit-complete">
      {T("containers.visit.completed", { cohortId })}
    </h3>
    <ActionLink
      data-testid="go-to-profile"
      asElement={Link}
      to={`/participant/${cohortId}`}
    >
      {T("containers.visit.links.goToProfile", { cohortId })}
    </ActionLink>
    <ActionLink
      asElement={Link}
      to={`/check-in/${visit.unitCode}/schedule`}
      data-testid="return-to-schedule"
    >
      {T("containers.visit.links.returnToUnitSchedule")}
    </ActionLink>
  </>
);
