import React from "react";
import { Col, DateInput, Row } from "nhsuk-react-components";
import { ErrorPanel } from "./ErrorPanel";
import {
  IDateTimeError,
  IDateTimeInputValue,
  IDateTimeSetter,
} from "interfaces";
import { T } from "i18n";
import { TimeInput } from "./TimeInput";

interface IDateTimeInputProps extends IDateTimeInputValue, IDateTimeSetter {
  ariaLabel: string;
  dataTestID?: string;
  error: IDateTimeError;
  disabled?: boolean;
}

export const DateTimeInput = (props: IDateTimeInputProps): JSX.Element => {
  const {
    ariaLabel,
    dataTestID,
    date,
    time,

    error,

    setDate,
    setTime,
    disabled = false,
  } = props;

  return (
    <>
      <Row>
        <Col width="one-half">
          <DateInput
            aria-label={`${ariaLabel} Date`}
            data-testid={`${dataTestID}-date`}
            value={date}
            disabled={disabled}
            onChange={(e) => setDate(e.currentTarget.value)}
          />
        </Col>
        <Col width="one-half">
          <TimeInput
            {...time}
            disabled={disabled}
            setTime={setTime}
            ariaLabel={`${ariaLabel} Time`}
            data-testid={`${dataTestID}-time`}
          />
        </Col>
      </Row>
      {error !== "" && (
        <ErrorPanel
          label="submission-error"
          title={
            <span id="error-summary-title">
              {T("components.dateTimeInput.error.title")}
            </span>
          }
        >
          <p>{error}</p>
        </ErrorPanel>
      )}
    </>
  );
};
