import { AxiosInstance } from "axios";
import { ConsentDocVersion } from "../../enums/ConsentDocVersion";
import { signedAtInFuture, witnessedAtInFuture } from "errors/consent";

export interface IndividualConsent {
  cohortId: string;
  version: ConsentDocVersion;
  signedAt: string;
  researchOptIn: boolean;
  firstName: string;
  lastName: string;
  witnessedAt: string;
  expired?: boolean;
}

export interface ConsentClient {
  submit(consent: IndividualConsent): Promise<void>;
  find(cohortId: string): Promise<IndividualConsent | null>;
}

export class HTTPConsentClient implements ConsentClient {
  public constructor(protected readonly http: AxiosInstance) {}

  async submit({ cohortId, ...rest }: IndividualConsent): Promise<void> {
    try {
      await this.http.post(`/person/${cohortId}/consent`, rest);
    } catch (err: any) {
      const { status } = err.response;
      const { error } = err.response.data;
      switch (status) {
        case 400:
          switch (error) {
            case "ErrSignedAtInFuture":
              throw new Error(signedAtInFuture);
            case "ErrWitnessedAtInFuture":
              throw new Error(witnessedAtInFuture);
            default:
              throw err;
          }
        default:
          throw err;
      }
    }
  }

  async find(cohortId: string): Promise<IndividualConsent | null> {
    try {
      const { data } = await this.http.get(`/person/${cohortId}/consent`);
      return data;
    } catch (ex: any) {
      if (ex.response?.status === 404) {
        return null;
      }
      throw ex;
    }
  }
}
