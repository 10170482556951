import React, { useEffect } from "react";
import { EligibilityRecheckActions } from "./EligibilityRecheckActions";
import { VisitStageProps } from "../../Visit";
import { useHistory } from "react-router-dom";

export function EligibilityRecheck({
  cohortId,
  onComplete,
  isComplete,
}: VisitStageProps<"D1_ELIGIBILITY_RECHECK">): JSX.Element {
  const { push } = useHistory();

  useEffect(() => {
    if (isComplete) {
      push(`/visit/${cohortId}`);
    }
  }, [isComplete, push, cohortId]);

  if (isComplete) {
    return <>Completed</>;
  }

  return (
    <EligibilityRecheckActions
      cohortId={cohortId}
      onAmend={() => push(`/visit/${cohortId}?stage=D1_ELIGIBILITY`)}
      onConfirm={onComplete}
    />
  );
}
