import React, { useEffect } from "react";
import {
  Abort,
  Acknowledgement,
  AddUnit,
  AddUnitCapacity,
  AddUnitLocation,
  ArchiveUser,
  BloodDraw,
  Booking,
  BookingConfirmation,
  CheckIn,
  Checkout,
  ConfirmDetails,
  Consent,
  CreateUser,
  Eligibility,
  EligibilityRecheck,
  Login,
  MarkOutsideEngland,
  Participate,
  Person,
  Questionnaire,
  Registration,
  RemoveOutsideEngland,
  ResetPasswordConfirmation,
  Result,
  Results,
  Search,
  TRFClinicalData,
  UnitCapacity,
  UnitLocation,
  UnitSchedule,
  Units,
  UpdateProfile,
  User,
  Users,
  Video,
  Visit,
  Voucher,
  Welcome,
  Withdrawal,
  ParticipantVerification,
  AuditLog,
  AuditLogV2,
} from "containers";
import { AuthAction, Restricted, RestrictedRoute } from "auth/components";
import {
  AuthenticatedUser,
  Feature,
  FeatureFlag,
  SupportedBrowser,
} from "components";
import { CancelRebook } from "containers/Person/CancelAppointment";
import { ContactManagement } from "containers/Person/ContactManagement";
import { Container, Footer, Header } from "nhsuk-react-components";
import { DateTime } from "luxon";
import { DeleteData } from "./containers/Data/DeleteData";
import { IUser, useAuth } from "auth";
import {
  Link,
  LinkProps,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { Report } from "containers/Reports/Reports";
import { SetPassword } from "./containers/User/SetPassword";
import { T } from "i18n";
import {
  MarkNotParticipating,
  RemoveNotParticipating,
} from "containers/NotParticipating";
import { isFeatureFlagEnabled } from "./features";

function WithoutBrowserHistory<T>({
  children,
}: React.PropsWithChildren<T>): JSX.Element {
  const history = useHistory();
  useEffect(
    () =>
      history.listen((location, e) => {
        switch (e) {
          case "PUSH": {
            history.replace(location);
            break;
          }
          case "POP": {
            history.go(1);
            break;
          }
        }
      }),
    [history]
  );
  return <>{children}</>;
}

const NavLink = (props: LinkProps): JSX.Element => (
  <li className="nhsuk-header__navigation-item">
    <Link {...{ ...props, className: "nhsuk-header__navigation-link" }} />
  </li>
);

export const App = (): JSX.Element => {
  const { isInitialized, user } = useAuth();

  // prevent rendering until the authentication hook is initialised.
  if (!isInitialized) {
    return <></>;
  }

  let extraHeaderFooterClassNames = "";
  if (user !== undefined) {
    extraHeaderFooterClassNames += "logged-in";
  }

  const shouldUserUpdateProfile = (user: IUser): boolean => {
    return (
      user.roles.includes("EMS Unit Staff") && !user.firstName && !user.lastName
    );
  };

  let defaultRouteRedirect: JSX.Element;
  if (user && shouldUserUpdateProfile(user)) {
    defaultRouteRedirect = <Redirect to={`/user/${user.id}/update-profile`} />;
  } else if (window.location.hostname.startsWith("book")) {
    defaultRouteRedirect = <Redirect to="/book" />;
  } else {
    defaultRouteRedirect = <Redirect to="/welcome" />;
  }

  return (
    <WithoutBrowserHistory>
      <div className="content">
        <SupportedBrowser>
          <Header transactional white>
            <Header.Container>
              <AuthenticatedUser />
              <div className="nhsuk-header__logos">
                <img
                  src={`${process.env.PUBLIC_URL}/images/nhs-galleri-logo.svg`}
                  height={70}
                  alt={T("branding.logos.nhs")}
                />
                <img
                  src={`${process.env.PUBLIC_URL}/images/grail-logo.svg`}
                  height={23}
                  alt={T("branding.logos.grail")}
                />
                <img
                  src={`${process.env.PUBLIC_URL}/images/kcl-cptu-logo.png`}
                  height={46}
                  alt={T("branding.logos.cptu")}
                />
              </div>
              <Restricted mode="authenticated">
                <Header.Content>
                  <Header.MenuToggle />
                </Header.Content>
              </Restricted>
            </Header.Container>
            <Header.Nav
              data-testid="navigation-header"
              className={extraHeaderFooterClassNames}
            >
              <Restricted
                allowedRoles={[
                  "KCL Admin",
                  "Call Centre Agent",
                  "EMS Central Team",
                  "EMS Unit Staff",
                  "KCL Research Nurse",
                  "KCL Admin (Unblinded)",
                ]}
                mode="allowRoles"
              >
                <NavLink aria-label="search" to="/search">
                  {T("navigation.search")}
                </NavLink>
              </Restricted>
              <Restricted
                allowedRoles={[
                  "KCL Admin",
                  "Call Centre Agent",
                  "EMS Central Team",
                  "EMS Unit Staff",
                  "KCL Research Nurse",
                ]}
                mode="allowRoles"
              >
                <NavLink aria-label="register" to="/registration">
                  {T("navigation.register")}
                </NavLink>
              </Restricted>
              <Feature version=">= 1.1.0">
                <Restricted allowedRoles={["EMS Unit Staff"]} mode="allowRoles">
                  <NavLink aria-label="check-in" to="/check-in">
                    {T("navigation.checkIn")}
                  </NavLink>
                </Restricted>
              </Feature>
              <Restricted
                allowedRoles={[
                  "KCL Admin",
                  "KCL Scheduling Support",
                  "EMS Central Team",
                ]}
                mode="allowRoles"
              >
                <NavLink aria-label="Manage Units" to="/unit">
                  {T("navigation.units")}
                </NavLink>
              </Restricted>
              <Feature version=">= 1.1.0">
                <Restricted
                  allowedRoles={["KCL Research Nurse"]}
                  mode="allowRoles"
                >
                  <NavLink aria-label="View Results" to="/results">
                    {T("navigation.results")}
                  </NavLink>
                </Restricted>
              </Feature>
              <Restricted allowedRoles={["KCL Admin"]} mode="allowRoles">
                <NavLink aria-label="user" to="/user">
                  {T("navigation.user")}
                </NavLink>
              </Restricted>
              <Restricted
                allowedRoles={[
                  "KCL Admin",
                  "KCL Scheduling Support",
                  "GRAIL User",
                  "GRAIL User (Unblinded)",
                  "KCL Research Nurse",
                  "EMS Unit Staff",
                  "EMS Central Team",
                ]}
                mode="allowRoles"
              >
                <NavLink aria-label="reports" to="/report">
                  {T("navigation.report")}
                </NavLink>
              </Restricted>
              <FeatureFlag flag="Y1_ENABLED">
                <Restricted
                  allowedRoles={["KCL Admin", "Call Centre Agent"]}
                  mode="allowRoles"
                >
                  <NavLink aria-label="Y2 Booking" to="/book">
                    {T("navigation.y2Booking")}
                  </NavLink>
                </Restricted>
              </FeatureFlag>
              <Restricted allowedRoles={["KCL Admin"]} mode="allowRoles">
                <NavLink aria-label="User Events" to="/user-events">
                  {T("navigation.userEvents")}
                </NavLink>
              </Restricted>
              <FeatureFlag flag="AUDIT_V2_ENABLED">
                <Restricted allowedRoles={["KCL Monitor"]} mode="allowRoles">
                  <NavLink aria-label="Audit Log" to="/audit">
                    {T("navigation.audit")}
                  </NavLink>
                </Restricted>
              </FeatureFlag>
            </Header.Nav>
          </Header>
          <Container className="main-container">
            <Switch>
              <Route exact path="/registration">
                <Registration />
              </Route>
              <Route exact path="/appointment/:appointmentId/confirmation">
                <BookingConfirmation />
              </Route>
              <Route exact path="/registration/:cohortId/appointment">
                <Booking />
              </Route>
              <RestrictedRoute
                roles={[
                  "KCL Admin",
                  "Call Centre Agent",
                  "EMS Central Team",
                  "EMS Unit Staff",
                  "KCL Research Nurse",
                  "KCL Admin (Unblinded)",
                ]}
                exact
                path="/search"
                redirect="/"
              >
                <Search />
              </RestrictedRoute>
              <RestrictedRoute
                roles={[
                  "KCL Admin",
                  "Call Centre Agent",
                  "EMS Central Team",
                  "EMS Unit Staff",
                  "KCL Research Nurse",
                  "KCL Admin (Unblinded)",
                ]}
                exact
                path="/participant/:cohortId"
                redirect="/"
              >
                <Person />
              </RestrictedRoute>
              <RestrictedRoute
                roles={["Call Centre Agent"]}
                exact
                path="/participant/:cohortId/notifications"
                redirect="/"
              >
                <ContactManagement />
              </RestrictedRoute>
              <RestrictedRoute
                roles={[
                  "KCL Admin",
                  "Call Centre Agent",
                  "EMS Central Team",
                  "EMS Unit Staff",
                  "KCL Research Nurse",
                ]}
                exact
                path="/appointment/:cohortId/cancel"
                redirect="/"
              >
                <CancelRebook />
              </RestrictedRoute>
              <RestrictedRoute
                roles={["EMS Unit Staff", "KCL Research Nurse"]}
                exact
                path="/participant/:cohortId/withdraw"
                redirect="/"
              >
                <Withdrawal />
              </RestrictedRoute>
              <RestrictedRoute
                roles={["KCL Admin"]}
                exact
                path="/data/:cohortId/delete"
                redirect="/"
              >
                <DeleteData />
              </RestrictedRoute>
              <RestrictedRoute
                roles={["Call Centre Agent"]}
                exact
                path="/participant/:cohortId/mark-outside-england"
                redirect="/"
              >
                <MarkOutsideEngland />
              </RestrictedRoute>
              <RestrictedRoute
                roles={["Call Centre Agent"]}
                exact
                path="/participant/:cohortId/remove-outside-england"
                redirect="/"
              >
                <RemoveOutsideEngland />
              </RestrictedRoute>
              {process.env.REACT_APP_NO_LONGER_PARTICIPATING === "true" && (
                <RestrictedRoute
                  roles={["KCL Research Nurse"]}
                  exact
                  path="/participant/:cohortId/mark-not-participating"
                  redirect="/"
                >
                  <MarkNotParticipating />
                </RestrictedRoute>
              )}
              {process.env.REACT_APP_NO_LONGER_PARTICIPATING === "true" && (
                <RestrictedRoute
                  roles={["KCL Research Nurse"]}
                  exact
                  path="/participant/:cohortId/remove-not-participating"
                  redirect="/"
                >
                  <RemoveNotParticipating />
                </RestrictedRoute>
              )}
              <RestrictedRoute
                roles={[
                  "KCL Admin",
                  "KCL Scheduling Support",
                  "EMS Central Team",
                ]}
                redirect="/"
                exact
                path="/unit"
              >
                <Units />
              </RestrictedRoute>
              <RestrictedRoute
                roles={[
                  "KCL Admin",
                  "KCL Scheduling Support",
                  "EMS Central Team",
                ]}
                redirect="/"
                exact
                path="/unit/:unitCode/capacity"
              >
                <UnitCapacity />
              </RestrictedRoute>
              <RestrictedRoute
                roles={[
                  "KCL Admin",
                  "KCL Scheduling Support",
                  "EMS Central Team",
                ]}
                redirect="/"
                exact
                path="/unit/add"
              >
                <AddUnit />
              </RestrictedRoute>
              <RestrictedRoute
                roles={[
                  "KCL Admin",
                  "KCL Scheduling Support",
                  "EMS Central Team",
                ]}
                redirect="/"
                exact
                path="/unit/:unitCode/location"
              >
                <UnitLocation />
              </RestrictedRoute>
              <RestrictedRoute
                roles={[
                  "KCL Admin",
                  "KCL Scheduling Support",
                  "EMS Central Team",
                ]}
                redirect="/"
                exact
                path="/unit/:unitCode/location/add"
              >
                <AddUnitLocation />
              </RestrictedRoute>
              <RestrictedRoute
                roles={[
                  "KCL Admin",
                  "KCL Scheduling Support",
                  "EMS Central Team",
                ]}
                redirect="/"
                exact
                path="/unit/:unitCode/capacity/add"
              >
                <AddUnitCapacity />
              </RestrictedRoute>
              <Route exact path="/user/reset-password">
                <SetPassword />
              </Route>
              <RestrictedRoute
                roles={["KCL Admin"]}
                exact
                path="/user"
                redirect="/"
              >
                <Users />
              </RestrictedRoute>
              <RestrictedRoute
                roles={["KCL Admin"]}
                exact
                path="/user/new"
                redirect="/"
              >
                <CreateUser />
              </RestrictedRoute>
              <RestrictedRoute
                roles={["KCL Admin"]}
                exact
                path="/user/:id"
                redirect="/"
              >
                <User />
              </RestrictedRoute>
              <RestrictedRoute
                roles={["EMS Unit Staff"]}
                exact
                path="/user/:id/update-profile"
                redirect="/"
              >
                <UpdateProfile />
              </RestrictedRoute>
              <RestrictedRoute
                roles={["KCL Admin"]}
                exact
                path="/user/:id/reset-password"
                redirect="/"
              >
                <ResetPasswordConfirmation />
              </RestrictedRoute>
              <RestrictedRoute
                roles={["KCL Admin"]}
                exact
                path="/user/:id/archive"
                redirect="/"
              >
                <ArchiveUser />
              </RestrictedRoute>
              <RestrictedRoute
                roles={["EMS Unit Staff"]}
                exact
                path="/check-in"
                redirect="/"
              >
                <Feature version=">= 1.1.0">
                  <CheckIn />
                </Feature>
              </RestrictedRoute>
              <RestrictedRoute
                roles={["EMS Unit Staff"]}
                exact
                path="/check-in/:unitCode/schedule"
                redirect="/"
              >
                <Feature version=">= 1.1.0">
                  <UnitSchedule />
                </Feature>
              </RestrictedRoute>
              <RestrictedRoute
                roles={["EMS Unit Staff"]}
                exact
                path="/check-in/:unitCode/confirm/:cohortId"
                redirect="/"
              >
                <Feature version=">= 1.1.0">
                  <ConfirmDetails />
                </Feature>
              </RestrictedRoute>
              <RestrictedRoute
                roles={["EMS Unit Staff"]}
                exact
                redirect="/"
                path="/visit/:cohortId"
              >
                <Feature version=">= 1.1.0">
                  <Visit
                    stages={{
                      D1_VIDEO: Video,
                      D1_CONSENT: Consent,
                      VOUCHER: Voucher,
                      D1_ELIGIBILITY: Eligibility,
                      D1_ELIGIBILITY_RECHECK: EligibilityRecheck,
                      D1_QUESTIONNAIRE: Questionnaire,
                      Y1_QUESTIONNAIRE: Questionnaire,
                      Y2_QUESTIONNAIRE: Questionnaire,
                      CREATE_PARTICIPANT: Participate,
                      D1_BLOOD_DRAW: BloodDraw,
                      Y1_BLOOD_DRAW: BloodDraw,
                      Y2_BLOOD_DRAW: BloodDraw,
                      D1_TRF_CLINICAL_DATA: TRFClinicalData,
                      Y1_TRF_CLINICAL_DATA: TRFClinicalData,
                      Y2_TRF_CLINICAL_DATA: TRFClinicalData,
                      CHECKOUT: Checkout,
                      ABORT_APPOINTMENT: Abort,
                    }}
                  />
                </Feature>
              </RestrictedRoute>
              <RestrictedRoute
                roles={["KCL Research Nurse"]}
                redirect="/"
                exact
                path="/results/:caseReference"
              >
                <Feature version=">= 1.1.0">
                  <Result />
                </Feature>
              </RestrictedRoute>
              <RestrictedRoute
                roles={["KCL Research Nurse"]}
                redirect="/"
                exact
                path="/results"
              >
                <Feature version=">= 1.1.0">
                  <Results />
                </Feature>
              </RestrictedRoute>
              <RestrictedRoute
                roles={[
                  "KCL Admin",
                  "KCL Scheduling Support",
                  "GRAIL User",
                  "GRAIL User (Unblinded)",
                  "KCL Research Nurse",
                  "EMS Unit Staff",
                  "EMS Central Team",
                ]}
                exact
                path="/report"
                redirect="/"
              >
                <Report />
              </RestrictedRoute>
              <RestrictedRoute
                exact
                path="/user-events"
                redirect="/"
                roles={["KCL Admin"]}
              >
                <AuditLog />
              </RestrictedRoute>
              <RestrictedRoute
                exact
                path="/audit"
                redirect="/"
                roles={["KCL Monitor"]}
              >
                {isFeatureFlagEnabled("AUDIT_V2_ENABLED") && <AuditLogV2 />}
              </RestrictedRoute>
              <Route exact path="/welcome">
                <Welcome />
              </Route>
              <Route exact path="/book">
                <FeatureFlag flag="Y1_ENABLED">
                  <ParticipantVerification />
                </FeatureFlag>
              </Route>
              <Route exact path="/acknowledgement">
                <Acknowledgement />
              </Route>
              <Route exact path="/sign-in">
                {user ? <Redirect to="/" /> : <Login />}
              </Route>
              <Route exact path="/sign-out">
                {user ? <AuthAction type="signOut" /> : <Redirect to="/" />}
              </Route>
              <Route>{defaultRouteRedirect}</Route>
            </Switch>
          </Container>
        </SupportedBrowser>
      </div>
      <Footer data-testid="footer" className={extraHeaderFooterClassNames}>
        <Footer.List>
          <Footer.ListItem href="https://www.nhs-galleri.org/contact">
            {T("footer.contactUs")}
          </Footer.ListItem>
          <Footer.ListItem data-testid="version">
            {T("version", { version: process.env.REACT_APP_VERSION })}
          </Footer.ListItem>
        </Footer.List>
        <Footer.Copyright data-testid="copyright">
          &copy;
          {T("footer.copyright", { year: DateTime.now().year })}
        </Footer.Copyright>
      </Footer>
    </WithoutBrowserHistory>
  );
};
