import React from "react";

import { AccessibilityIconProps } from "./types";

export function VisualIcon({
  title,
  stroke = 4,
  color = "#005EB8",
  size = 16,
}: React.PropsWithChildren<AccessibilityIconProps>): JSX.Element {
  return (
    <svg width={size} height={size} viewBox="0 0 240 240" aria-label={title}>
      <g id="Conversation">
        <path
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M223,119.5s-2.9,3.6-8.1,9a208.18,208.18,0,0,1-22.1,19.8,157.88,157.88,0,0,1-32.7,19.8c-12.3,5.4-25.9,9-40.1,9s-27.8-3.6-40.1-9a157.88,157.88,0,0,1-32.7-19.8,208.18,208.18,0,0,1-22.1-19.8c-5.2-5.4-8.1-9-8.1-9s2.9-3.6,8.1-9A208.18,208.18,0,0,1,47.2,90.7,157.64,157.64,0,0,1,79.9,71c12.3-5.4,25.9-9,40.1-9s27.8,3.6,40.1,9a157.88,157.88,0,0,1,32.7,19.8,208.18,208.18,0,0,1,22.1,19.8C220.1,116,223,119.5,223,119.5Z"
        />
        <path
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M172,78A57.5,57.5,0,1,1,66,78"
        />
        <ellipse
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          cx="120"
          cy="105"
          rx="19.6"
          ry="18.2"
        />
      </g>
    </svg>
  );
}
