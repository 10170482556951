import React, { useState } from "react";
import { AppointmentRegion } from "../../enums";
import { Button, Fieldset, Radios } from "nhsuk-react-components";
import { T } from "../../i18n";

interface RegionSelectorProps {
  setRegion: (value: AppointmentRegion) => void;
}

export const RegionSelector = ({
  setRegion,
}: RegionSelectorProps): JSX.Element => {
  const [selectedRegion, setSelectedRegion] = useState<AppointmentRegion>();
  const [error, setError] = useState<string>();

  const regions = Object.keys(AppointmentRegion).filter(
    (key) => key !== AppointmentRegion.UNKNOWN
  );

  const confirmRegion = (): void => {
    if (selectedRegion !== undefined) {
      setRegion(selectedRegion);
    } else {
      setError(T("containers.booking.fields.region.actions.confirm.error"));
    }
  };

  return (
    <>
      <Fieldset data-testid="regions">
        <Fieldset.Legend>
          {T("containers.booking.fields.region.header")}
        </Fieldset.Legend>
        <Radios value={selectedRegion} error={error}>
          {regions.map((region) => (
            <Radios.Radio
              key={region}
              data-testid={`region-${region}`}
              value={region}
              onChange={(e) =>
                setSelectedRegion(e.currentTarget.value as AppointmentRegion)
              }
            >
              {T(`containers.booking.fields.region.regions.${region}`)}
            </Radios.Radio>
          ))}
        </Radios>
      </Fieldset>
      <Button data-testid="confirm-region" onClick={confirmRegion}>
        {T("containers.booking.fields.region.actions.confirm.label")}
      </Button>
    </>
  );
};
