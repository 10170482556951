import React from "react";

import { AccessibilityIconProps } from "./types";

export function HearingIcon({
  title,
  stroke = 4,
  color = "#005EB8",
  size = 16,
}: React.PropsWithChildren<AccessibilityIconProps>): JSX.Element {
  return (
    <svg width={size} height={size} viewBox="0 0 240 240" aria-label={title}>
      <g id="Hearing">
        <g id="ear">
          <path
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={stroke}
            d="M47.56,56.2a59.58,59.58,0,0,1,104.33,57.57c-3.53,6.35-8.72,12.23-13.67,17.06s-9.67,8.62-12.23,10.8a42.17,42.17,0,0,0-7.41,9.12,62.34,62.34,0,0,0-4.84,9c-.84,2.09-3.42,7.2-6,12.25s-5.21,10-6.14,11.9a29.82,29.82,0,0,1-3.17,5.57,35.12,35.12,0,0,1-4.65,5.2,30.75,30.75,0,0,1-3.85,2.91,36.14,36.14,0,0,1-10.13,4.6,32,32,0,0,1-15,.52c-5.56-1.18-11.47-4.15-17-10.14s-8-12-8.64-17.48A32.07,32.07,0,0,1,41,160.44"
          />
          <path
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={stroke}
            d="M87.32,138.74c-1.14,2.15-2.63,5.69-5.1,7.67-1.49,1.2-9.12,7.41-16.21,2.78-2.61-1.71-4.7-3.81-5.5-6-5.08-14.23,8.68-14.45,11.57-26.89s-9.79-18.09-9.56-26.77a41.41,41.41,0,0,1,1.89-11.93A35.14,35.14,0,0,1,107,55.35a31.14,31.14,0,0,1,21.27,40.42,34,34,0,0,1-5.69,10.34"
          />
        </g>
        <g>
          <path
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={stroke}
            d="M202.92,68.51c9,14.8,12,32.5,7,49.58S192.75,148.4,177.2,156"
          />
          <path
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={stroke}
            d="M178.62,80.38a36.37,36.37,0,0,1-15.44,52.49"
          />
        </g>
      </g>
    </svg>
  );
}
