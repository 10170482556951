import { DateTime } from "luxon";
import { IDateInputValue } from "../interfaces";

export const dateInputToString = ({
  year,
  month,
  day,
}: IDateInputValue): string =>
  [
    String(year).padStart(4, "0"),
    String(month).padStart(2, "0"),
    String(day).padStart(2, "0"),
  ].join("-");

export const stringToDateInput = (date: string): IDateInputValue => {
  const split = date.split("-");
  if (split.length !== 3) return { year: "", month: "", day: "" };
  return {
    year: split[0],
    month: split[1],
    day: split[2],
  };
};

export const isoDateFormat = "yyyy-MM-dd";

export const dateFromISOString = (input: string): DateTime =>
  DateTime.fromFormat(input, isoDateFormat);
