import React, { useEffect, useState } from "react";
import {
  Address,
  AddressInput,
  ErrorMessage,
  FormValidity,
  Loader,
} from "components";
import {
  Button,
  Details,
  Fieldset,
  Input,
  Label,
  Radios,
} from "nhsuk-react-components";
import { IAddress, IAddressFieldIDs, IAddressSetter, IGp } from "interfaces";
import { T } from "../../i18n";
import { useAPI } from "api";

interface IGpFinderProps {
  gpName: string;
  gpNameSetter: (value: string) => void;
  gpOrgId: string | null;
  gpOrgIdSetter: (value: string | null) => void;
  gpOrgIdError?: string;
  gpOrgIdErrorSetter?: (value: string) => void;
  gpPracticeName: string;
  gpPracticeNameSetter: (value: string) => void;
  gpPracticeNameError?: string;
  gpAddress: IAddress;
  gpFieldIDs: IAddressFieldIDs;
  gpAddressSetter: IAddressSetter;
  componentName: string;
  gpValidity: FormValidity;
  allowOtherGp?: boolean;
}

export const GpFinder = (props: IGpFinderProps): JSX.Element => {
  const {
    gpName,
    gpNameSetter,
    gpOrgId,
    gpOrgIdSetter,
    gpOrgIdError,
    gpOrgIdErrorSetter,
    gpPracticeName,
    gpPracticeNameError,
    gpPracticeNameSetter,
    gpAddress,
    gpFieldIDs,
    gpAddressSetter,
    componentName = "gpAddressInput",
    gpValidity,
    allowOtherGp = false,
  } = props;

  const gpAPI = useAPI("gp");
  const [searchError, setSearchError] = useState<Error>();
  const [other, setRadioOther] = useState<boolean>(false);
  const [searchPostcode, setSearchPostcode] = useState<string>("");
  const [searchClicked, setSearchClicked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const setGpAddress = (gp: IGp): void => {
    setRadioOther(false);
    gpPracticeNameSetter(gp.name);
    gpOrgIdSetter(gp.orgId);
    gpAddressSetter.setAddress1(gp.address.address1);
    gpAddressSetter.setAddress2(gp.address.address2);
    gpAddressSetter.setCounty(gp.address.county);
    gpAddressSetter.setPostcode(gp.address.postcode);
    gpAddressSetter.setTownOrCity(gp.address.townOrCity);
  };

  const resetGPAddress = (): void => {
    gpPracticeNameSetter("");
    gpOrgIdSetter(null);
    gpOrgIdErrorSetter && gpOrgIdErrorSetter("");
    gpAddressSetter.setAddress1("");
    gpAddressSetter.setAddress2("");
    gpAddressSetter.setCounty("");
    gpAddressSetter.setPostcode("");
    gpAddressSetter.setTownOrCity("");
  };

  const setOther = (): void => {
    setRadioOther(true);
    resetGPAddress();
  };

  useEffect(() => {
    if (gpAddress.postcode !== "") {
      setLoading(true);
      setSearchPostcode(gpAddress.postcode);
      gpAPI
        .findGps(gpAddress.postcode)
        .then(setFoundGps)
        .catch(setSearchError)
        .finally(() => {
          setLoading(false);
          setSearchClicked(true);
          if (gpOrgId !== null) {
            const gp = foundGps.find((x) => x.orgId === gpOrgId);
            if (gp !== undefined) {
              setGpAddress(gp);
            }
          } else {
            setRadioOther(true);
          }
        });
    }
    // eslint-disable-next-line
  }, []);

  const emptyGPError = "No GP found";

  const find = (): void => {
    setSearchClicked(true);
    setSearchError(undefined);
    resetGPAddress();

    if (allowOtherGp && searchPostcode === "") {
      setFoundGps([]);
    } else {
      setLoading(true);
      gpAPI
        .findGps(searchPostcode)
        .then((gps) => {
          setFoundGps(gps);
          if (gps.length === 0) {
            throw new Error(emptyGPError);
          }
        })
        .catch(setSearchError)
        .finally(() => setLoading(false));
    }
  };

  const getErrorMessage = ({ message }: Error): string => {
    if (message === emptyGPError) {
      if (allowOtherGp) {
        return T("components.gp.errors.emptyOtherAllowed");
      }
      return T("components.gp.errors.empty");
    }
    if (allowOtherGp) {
      return T("components.gp.errors.other");
    }
    return T("components.gp.errors.findingGPs");
  };

  const [foundGps, setFoundGps] = useState<IGp[]>([]);

  return (
    <>
      <>
        <Input
          type="text"
          label={`${T(`components.${componentName}.fields.postcode.label`)}`}
          hint={T(`components.${componentName}.fields.postcode.hint`)}
          aria-label={`GP ${T(
            `components.${componentName}.fields.postcode.label`
          )}`}
          value={searchPostcode}
          data-testid="gp-search-postcode"
          onChange={(e) => setSearchPostcode(e.currentTarget.value)}
          width="20"
        />

        <Details data-testid="find-gp-postcode-accordion">
          <Details.Summary>
            {T(`components.${componentName}.accordion.title`)}
          </Details.Summary>
          <Details.Text>
            <p>{T(`components.${componentName}.accordion.body.text`)}</p>
            <p>
              {T(`components.${componentName}.accordion.body.link.text`)}
              &nbsp;
              <a
                href="https://www.nhs.uk/service-search/find-a-gp"
                target="_blank"
                rel="noreferrer"
              >
                {T(`components.${componentName}.accordion.body.link.action`)}
              </a>
            </p>
          </Details.Text>
        </Details>

        <Button
          type="button"
          aria-label={T("components.gp.find")}
          data-testid="find-gps"
          disabled={loading}
          onClick={(): void => find()}
        >
          {T("components.gp.find")}
        </Button>
        {!searchClicked && gpOrgIdError && (
          <ErrorMessage
            label="search-gp-error"
            title={
              <span id="error-summary-title">
                {T("components.gp.errors.title")}
              </span>
            }
          >
            {gpOrgIdError}
          </ErrorMessage>
        )}
        {searchError !== undefined && (
          <ErrorMessage
            label="search-gp-error"
            title={
              <span id="error-summary-title">
                {T("components.gp.errors.title")}
              </span>
            }
          >
            {getErrorMessage(searchError)}
          </ErrorMessage>
        )}
      </>
      {loading && <Loader />}
      {searchClicked && !loading && (
        <>
          <Fieldset>
            <Fieldset.Legend>Please select your GP</Fieldset.Legend>
            <Radios error={gpOrgIdError} name="GP List" aria-label="GP List">
              {foundGps.map((gp) => (
                <React.Fragment key={gp.orgId}>
                  <Radios.Radio
                    defaultChecked={gp.orgId === gpOrgId}
                    value={gp.orgId ? gp.orgId : ""}
                    key={gp.orgId}
                    onChange={(e) => setGpAddress(gp)}
                    data-testid={`radio-${gp.orgId}`}
                  >
                    <Label size="m">{gp.name}</Label>
                    <Address address={gp.address} />
                  </Radios.Radio>
                </React.Fragment>
              ))}
              {allowOtherGp && (
                <React.Fragment key="other">
                  <Radios.Radio
                    defaultChecked={
                      gpAddress.postcode !== "" && gpOrgId === null
                    }
                    value="other"
                    key="other"
                    onClick={() => setOther()}
                    data-testid="gp-radio-other"
                  >
                    <Label size="m">{T("components.gp.other.label")}</Label>
                  </Radios.Radio>
                  {!other && <>{T("components.gp.other.hint")}</>}
                  {other && (
                    <>
                      <Input
                        type="text"
                        name="practiceName"
                        label={T(
                          "containers.registration.fields.gpPracticeName.label"
                        )}
                        hint={T(
                          "containers.registration.fields.gpPracticeName.hint"
                        )}
                        aria-label={T(
                          "containers.registration.fields.gpPracticeName.label"
                        )}
                        value={gpPracticeName}
                        data-testid="GP Practice"
                        onChange={(e) =>
                          gpPracticeNameSetter(e.currentTarget.value)
                        }
                        error={gpPracticeNameError}
                        width="20"
                      />
                      <AddressInput
                        {...gpAddress}
                        {...gpFieldIDs}
                        {...gpAddressSetter}
                        componentName={componentName}
                        addressValidity={gpValidity}
                      />
                    </>
                  )}
                </React.Fragment>
              )}
            </Radios>
          </Fieldset>
        </>
      )}
      <Input
        type="text"
        data-testid="gpName"
        label={T("containers.registration.fields.gpName.label")}
        hint={T("containers.registration.fields.gpName.hint")}
        aria-label={T("containers.registration.fields.gpName.label")}
        value={gpName}
        onChange={(e) => gpNameSetter(e.currentTarget.value)}
        width="20"
      />
    </>
  );
};
