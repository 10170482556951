import React from "react";
import { ISearchResult } from "interfaces";
import { Card, Label, SummaryList } from "nhsuk-react-components";
import { Link } from "react-router-dom";
import { T } from "i18n";
import { formatAddress, formatDateOfBirth } from "formatters";

interface SearchResultProps {
  person: ISearchResult;
}

export const SearchResult = ({ person }: SearchResultProps): JSX.Element => {
  return (
    <Card>
      <Card.Content>
        <Link to={`/participant/${person.cohortId}`}>
          <Label size="m">
            {`${person.lastName.toUpperCase()}, ${person.firstName}`}
          </Label>
        </Link>
        <SummaryList>
          <SummaryList.Row>
            <SummaryList.Key>
              {T("containers.search.headings.address")}
            </SummaryList.Key>
            <SummaryList.Value
              aria-label={T("containers.search.headings.address")}
            >
              {formatAddress(person.mailingAddress)}
            </SummaryList.Value>
          </SummaryList.Row>

          <SummaryList.Row>
            <SummaryList.Key>
              {T("containers.search.headings.dateOfBirth")}
            </SummaryList.Key>
            <SummaryList.Value
              aria-label={T("containers.search.headings.dateOfBirth")}
            >
              {formatDateOfBirth(person.dateOfBirth)}
            </SummaryList.Value>
          </SummaryList.Row>

          <SummaryList.Row>
            <SummaryList.Key>
              {T("containers.search.headings.cohortId")}
            </SummaryList.Key>
            <SummaryList.Value
              aria-label={T("containers.search.headings.cohortId")}
            >
              {person.cohortId}
            </SummaryList.Value>
          </SummaryList.Row>

          {person.studyId && (
            <SummaryList.Row>
              <SummaryList.Key>
                {T("containers.search.headings.participantId")}
              </SummaryList.Key>
              <SummaryList.Value
                aria-label={T("containers.search.headings.participantId")}
              >
                {person.studyId}
              </SummaryList.Value>
            </SummaryList.Row>
          )}
        </SummaryList>
      </Card.Content>
    </Card>
  );
};
