import { Table } from "nhsuk-react-components";
import { T } from "../../i18n";
import { AuditEvents, Event } from "../../interfaces/IAuditEventV2";
import { PaginationWithNumbers } from "components/PaginationWithNumbers";
import { DateTime } from "luxon";
import "./AuditEventsV2.scss";
import classNames from "classnames";
import { AuditDetailsContainer } from "./AuditDetailsContainer/AuditDetailsContainer";

interface AuditEventsV2Props {
  loading: boolean;
  auditEvents: AuditEvents;
  currentPage: number;
  sortDirection: string;
  changeSortFn: () => void;
  changePageFn: (newNumber: number) => void;
}
export const AuditEventsV2 = ({
  loading,
  auditEvents,
  currentPage,
  sortDirection,
  changeSortFn,
  changePageFn,
}: AuditEventsV2Props): JSX.Element => {
  const totalPages = auditEvents.totalPages;
  return (
    <>
      <h3>
        {auditEvents.empty
          ? T("containers.auditv2.noResultsHeading")
          : T("containers.auditv2.resultsHeading", {
              currentPage: currentPage,
              totalPages: totalPages,
            })}
      </h3>
      <Table className="audit-v2-table">
        <Table.Head>
          <Table.Row>
            <Table.Cell>
              <div
                style={{ display: "flex", gap: "12px", alignItems: "center" }}
              >
                <div>{T("containers.auditv2.timestampHeader")}</div>
                <div
                  data-testid={"sortArrows"}
                  className={classNames({
                    "sort-arrow-asc": sortDirection === "DESC",
                    "sort-arrow-desc": sortDirection === "ASC",
                  })}
                  onClick={() => changeSortFn()}
                ></div>
              </div>
            </Table.Cell>
            <Table.Cell className="event-data-col">
              {T("containers.auditv2.eventHeader")}
            </Table.Cell>
            <Table.Cell>{T("containers.auditv2.userHeader")}</Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {auditEvents.content.map((event: Event) => {
            return (
              <Table.Row key={`${event.summary}-${event.timestamp}`}>
                <Table.Cell>
                  <div className={classNames({ loading: loading })}>
                    {DateTime.fromISO(event.timestamp, {
                      zone: "UTC",
                    }).toFormat("dd MMM yyyy HH:mm 'UTC'")}
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <div className={classNames({ loading: loading })}>
                    {!event.resource.version && event.summary}
                    {event.resource.version && (
                      <AuditDetailsContainer
                        parentLoading={loading}
                        summary={event.summary}
                        resourceType={event.resource.type}
                        actionType={event.action}
                        version={event.resource.version}
                      />
                    )}
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <div className={classNames({ loading: loading })}>
                    {event.user.email}
                  </div>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <PaginationWithNumbers>
        <PaginationWithNumbers.Link
          onClick={() => {
            if (!auditEvents?.first) {
              changePageFn(currentPage - 1);
            }
          }}
          previous={!auditEvents?.first}
        ></PaginationWithNumbers.Link>
        <PaginationWithNumbers.Numbers
          totalPages={totalPages}
          currentPage={currentPage}
          onClickFn={changePageFn}
        ></PaginationWithNumbers.Numbers>
        <PaginationWithNumbers.Link
          onClick={() => {
            if (!auditEvents?.last) {
              changePageFn(currentPage + 1);
            }
          }}
          next={!auditEvents?.last}
        ></PaginationWithNumbers.Link>
      </PaginationWithNumbers>
    </>
  );
};
