import React from "react";
import { FormFieldProps } from "@grailbio/components-ecrf";
import { ReadOnlyFormField } from "./ReadOnlyFormField";
import { Select } from "nhsuk-react-components";
import { T } from "i18n";

export function LongEnumInput(props: FormFieldProps): JSX.Element {
  const { definition, value, setValue, readOnly, disabled } = props;
  const { enumOptions, label } = definition;
  if (readOnly) {
    return (
      <ReadOnlyFormField
        label={label}
        value={enumOptions?.find((option) => option.value === value)?.label}
      />
    );
  }
  return (
    <Select
      value={value}
      label={label}
      onChange={(e) => {
        setValue(e.currentTarget.value);
      }}
      disabled={definition.systemPopulated || disabled}
    >
      <Select.Option key="-1" value="" disabled>
        {T("components.ecrf.placeholders.selectAnAnswer")}
      </Select.Option>
      {enumOptions &&
        enumOptions.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
    </Select>
  );
}
