import React, { useEffect, useRef } from "react";

function useListener(
  ref: React.MutableRefObject<HTMLElement | null>,
  eventName: string,
  handler: (event: any) => void
) {
  useEffect(() => {
    if (ref.current) {
      const element = ref.current;
      element.addEventListener(eventName, handler);
      return () => element.removeEventListener(eventName, handler);
    }
  }, [eventName, handler, ref]);
}

interface CalendarDatePickerProps {
  value: string;
  onChange: (e: any) => void;
  identifier: string;
}

export function CalendarDatePicker({
  onChange,
  ...props
}: CalendarDatePickerProps) {
  const ref = useRef(null);

  useListener(ref, "duetChange", onChange);

  return <duet-date-picker ref={ref} {...props}></duet-date-picker>;
}
