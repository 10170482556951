import { DateTime } from "luxon";

export const birthDateToAge = (
  birthDate: DateTime,
  baseline?: DateTime
): number => {
  let today = DateTime.now();
  if (baseline) {
    today = baseline;
  }
  const age = today.diff(birthDate, "years");
  return Math.trunc(age.years);
};
