import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AppointmentList, AppointmentListEntry } from "./AppointmentList";
import { BackLink } from "nhsuk-react-components";
import { CompletedVisits, VisitWithPerson } from "./CompletedVisits";
import { DateTime } from "luxon";
import { ErrorPanel, Loader } from "components";
import { IUnitSchedule } from "interfaces";
import { T } from "i18n";
import { VisitsInProgress } from "./VisitsInProgress";
import { useAPI } from "api";
import { useHistory, useLocation, useParams } from "react-router-dom";

export interface UnitAddressLocationState {
  unitAddressLine1?: string;
}

export function UnitSchedule(): JSX.Element {
  // FIXME(ctang): The underlying call to unitApi.getSchedule using UTC now potentially incorrectly filters out results
  //  eg. when it is 1/7 00:00 BST, it will be 30/6 23:00 UTC. This will be 30/6 and we want to include results
  //  from 1/7 as well
  const now = useMemo(() => DateTime.now(), []);
  const { unitCode } = useParams<{ unitCode: string }>();
  const location = useLocation<UnitAddressLocationState>();

  const unitApi = useAPI("unit");

  const [unitSchedule, setUnitSchedule] = useState<IUnitSchedule>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();

  const fetchUnitSchedule = useCallback((): Promise<void> => {
    setLoading(true);
    return unitApi
      .getSchedule(unitCode, now)
      .then(setUnitSchedule)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [unitCode, now, unitApi]);

  useEffect((): void => {
    fetchUnitSchedule();
  }, [fetchUnitSchedule]);

  const { push } = useHistory();

  const appointments = useMemo<AppointmentListEntry[]>(() => {
    if (!unitSchedule) return [];
    return unitSchedule.schedule
      .flatMap(({ person, appointments, blood }) =>
        appointments.map((appointment) => ({ appointment, person, blood }))
      )
      .sort(
        (a, b) =>
          a.appointment.appointmentStartTime.toMillis() -
          b.appointment.appointmentStartTime.toMillis()
      );
  }, [unitSchedule]);

  const visits = useMemo<VisitWithPerson[]>(() => {
    if (!unitSchedule) return [];
    return unitSchedule.schedule.flatMap(({ person, visits }) =>
      visits.map((visit) => ({ visit, person }))
    );
  }, [unitSchedule]);

  return (
    <div className="nhsuk-main-wrapper">
      <BackLink
        onClick={() => push("/check-in")}
        data-testid="check-in-unit-back-link"
        aria-label={T("containers.wizard.previous")}
      >
        {T("containers.wizard.previous")}
      </BackLink>
      {location.state && <h1>{location.state.unitAddressLine1}</h1>}
      <h2>{now.toFormat("cccc d LLLL yyyy")}</h2>

      {error && (
        <ErrorPanel
          title={T("containers.checkIn.fields.appointments.errors.title")}
          label={T("containers.checkIn.fields.appointments.errors.title")}
        >
          {error.message}
        </ErrorPanel>
      )}
      <>
        {loading && <Loader />}
        {!loading && (
          <>
            <h3>
              {T("containers.checkIn.fields.appointments.awaitingCheckIn")}
            </h3>
            <AppointmentList
              unitCode={unitCode}
              unitScheduleEntries={appointments}
            />
            <h3>{T("containers.checkIn.fields.appointments.inProgress")}</h3>
            <VisitsInProgress unitScheduleEntries={visits} />

            <h3>{T("containers.checkIn.fields.appointments.completed")}</h3>
            <CompletedVisits unitScheduleEntries={visits} />
          </>
        )}
      </>
    </div>
  );
}
