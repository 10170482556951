import React, { useEffect } from "react";
import isEqual from "lodash/isEqual";
import {
  EmailInput,
  FormValidity,
  PersonalPhoneNumberInput,
  PostalAddressInput,
  ValiditySummary,
} from "../../components";
import { IAddress } from "../../interfaces";
import { T } from "i18n";
import {
  isValidEmail,
  isValidUKMobilePhoneNumber,
  isValidUKPhoneNumber,
} from "../../validations";
import { useAddress, usePrevious } from "../../hooks";

interface ContactFormProps {
  phoneNumber?: string;
  phoneNumberChanged?: (newVal: string) => void;
  mobilePhoneNumber?: string;
  mobilePhoneNumberChanged?: (newVal: string) => void;
  email?: string;
  emailChanged?: (newVal: string) => void;
  postalAddress?: IAddress;
  postalAddressChanged?: (address: IAddress) => void;
  validity?: FormValidity;
  validityUpdated?: (validity: FormValidity) => void;
}

const ids = {
  phoneNumber: "phone-number-input",
  mobilePhoneNumber: "mobile-phone-number-input",
  email: "email-input",
};

export const ContactForm = ({
  phoneNumber = "",
  phoneNumberChanged,
  mobilePhoneNumber = "",
  mobilePhoneNumberChanged,
  email = "",
  emailChanged,
  postalAddress: postalAddressProp,
  postalAddressChanged,
  validity = new FormValidity(),
  validityUpdated,
}: ContactFormProps): JSX.Element => {
  const [
    postalAddress,
    postalAddressSetter,
    postalAddressFieldIDs,
    postalValidateAddress,
  ] = useAddress(postalAddressProp);
  const previousPostalAddress = usePrevious(postalAddress);

  useEffect(() => {
    if (postalAddressChanged) {
      const isPostalAddressChanged = !isEqual(
        previousPostalAddress,
        postalAddress
      );
      if (isPostalAddressChanged) {
        postalAddressChanged(postalAddress);
      }
    }
  }, [postalAddress, postalAddressChanged, previousPostalAddress]);

  useEffect(() => {
    const currentValidity = new FormValidity();

    const emailError = isValidEmail(
      email,
      T("containers.registration.fields.email.errors.invalid")
    );
    currentValidity.addError(emailError, ids.email);

    const phoneNumberError = isValidUKPhoneNumber(
      phoneNumber,
      T("containers.registration.fields.phoneNumber.errors.invalid")
    );
    currentValidity.addError(phoneNumberError, ids.phoneNumber);

    const mobilePhoneNumberError = isValidUKMobilePhoneNumber(
      mobilePhoneNumber,
      T("containers.registration.fields.mobilePhoneNumber.errors.invalid")
    );
    currentValidity.addError(mobilePhoneNumberError, ids.mobilePhoneNumber);

    postalValidateAddress(currentValidity);

    validityUpdated && validityUpdated(currentValidity);
  }, [
    phoneNumber,
    mobilePhoneNumber,
    email,
    validityUpdated,
    postalValidateAddress,
  ]);

  return (
    <>
      <ValiditySummary validity={validity} />
      <PersonalPhoneNumberInput
        phoneNumber={phoneNumber}
        phoneNumberChanged={phoneNumberChanged}
        phoneNumberError={validity.getFirstErrorForId(ids.phoneNumber)?.message}
        elementIDs={{ phoneNumber: ids.phoneNumber }}
        label={T("components.personalPhoneNumberInput.fields.phone.label")}
        hint={T("components.personalPhoneNumberInput.fields.phone.hint")}
        dataTestId="main-phone-number"
      />
      <PersonalPhoneNumberInput
        phoneNumber={mobilePhoneNumber}
        phoneNumberChanged={mobilePhoneNumberChanged}
        phoneNumberError={
          validity.getFirstErrorForId(ids.mobilePhoneNumber)?.message
        }
        elementIDs={{ phoneNumber: ids.mobilePhoneNumber }}
        label={T(
          "components.personalPhoneNumberInput.fields.mobilePhone.label"
        )}
        hint={T("components.personalPhoneNumberInput.fields.mobilePhone.hint")}
        dataTestId="mobile-phone-number"
      />
      <EmailInput
        email={email}
        emailChanged={emailChanged}
        emailError={validity.getFirstErrorForId(ids.email)?.message}
        elementIDs={{ email: ids.email }}
      />
      <PostalAddressInput
        postalAddress={postalAddress}
        postalAddressChanged={postalAddressSetter}
        elementIDs={postalAddressFieldIDs}
        validity={validity}
      />
    </>
  );
};
