/* tslint:disable */

import { IAddress, IAddressFieldIDs, IAddressSetter } from "../interfaces";
import { IAddressValidator, useAddress } from "./useAddress";

const emptyAddress = {
  address1: "",
  address2: "",
  county: "",
  locality: "",
  postcode: "",
  townOrCity: "",
};

export const useGPAddress = (
  initialValues: IAddress = emptyAddress
): readonly [
  IAddress,
  IAddressSetter,
  IAddressFieldIDs,
  IAddressValidator,
  boolean
] => {
  const allowPartialAddress = true;
  return useAddress(initialValues, allowPartialAddress);
};
