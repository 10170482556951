import React, { useEffect, useState } from "react";
import { Address, ErrorPanel, Loader } from "components";
import { BodyText, Fieldset, SummaryList } from "nhsuk-react-components";
import { DateTime } from "luxon";
import { EditPersonForm } from "./EditPersonForm";
import { IPerson } from "interfaces";
import { T } from "i18n";
import { Tag } from "nhsuk-react-components-extensions";
import { UpdatePayload } from "api/people";
import { birthDateToAge } from "helpers";
import { formatDateOfBirth } from "formatters";
import { useAPI } from "api";

export type PersonFields =
  | keyof IPerson
  | "name"
  | "studyStatus"
  | "eligibilityStatus"
  | "age"
  | "y1Anniversary"
  | "y2Anniversary";

interface PersonDetailsProps {
  person: IPerson;
  setPerson: (p: IPerson) => void;
  displayFields: Set<PersonFields>;
  isEligible?: boolean | undefined;
}

function displayIsEligible(isEligible: boolean | undefined): string {
  switch (isEligible) {
    case true:
      return "Yes";
    case false:
      return "No";
    default:
      return "-";
  }
}

function display(field: string | undefined): string {
  switch (field) {
    case undefined:
    case "":
    case null:
      return "-";
    default:
      return field;
  }
}

function ChangeButton({
  children,
  ...rest
}: React.PropsWithChildren<
  React.DOMAttributes<HTMLButtonElement>
>): JSX.Element {
  return (
    <button
      type="button"
      data-testid="change-details"
      style={{
        background: "none",
        border: "none",
        outline: "none",
        cursor: "pointer",
        fontSize: "1.1rem",
        textDecoration: "underline",
        marginBottom: "1.5rem",
        paddingLeft: 0,
        color: "#005eb8",
      }}
      {...rest}
    >
      {children}
    </button>
  );
}

export const PersonDetails = ({
  person,
  setPerson,
  displayFields,
  isEligible,
}: PersonDetailsProps): JSX.Element => {
  const personAPI = useAPI("people");
  const [stale, setStale] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [isEditing, setIsEditing] = useState(false);
  const [editingMessage, setEditingMessage] = useState("");

  const studyEventsAPI = useAPI("studyEvents");
  const [y1Anniversary, setY1Anniversary] = useState<DateTime>();
  const [y2Anniversary, setY2Anniversary] = useState<DateTime>();

  const { cohortId } = person;
  const edit = (): void => {
    setEditingMessage("");
    setIsEditing(true);
  };

  useEffect(() => {
    if (stale) {
      setLoading(true);
      personAPI
        .getPerson(cohortId)
        .then((person: IPerson) => {
          setPerson(person);
        })
        .catch(({ message }) => setError(message))
        .finally(() => {
          setLoading(false);
          setStale(false);
        });
    }
  }, [cohortId, personAPI, stale, setPerson]);

  useEffect(() => {
    if (
      !displayFields.has("y1Anniversary") &&
      !displayFields.has("y2Anniversary")
    ) {
      return;
    }
    studyEventsAPI
      .getStudyEvents(cohortId)
      .then((studyEvents) => {
        for (const studyEvent of studyEvents) {
          if (studyEvent.eventType === "Y1") {
            setY1Anniversary(studyEvent.predictedActionDate.plus({ days: 52 }));
          } else if (studyEvent.eventType === "Y2") {
            setY2Anniversary(studyEvent.predictedActionDate.plus({ days: 52 }));
          }
        }
      })
      .catch(setError)
      .finally(() => setLoading(false));
  }, [studyEventsAPI, displayFields, cohortId]);

  if (!loading && error && !isEditing) {
    return (
      <ErrorPanel title={error} label={error}>
        {error}
      </ErrorPanel>
    );
  }

  if (loading || !person) {
    return <Loader />;
  }

  if (isEditing) {
    return (
      <>
        {error && (
          <ErrorPanel title={error} label={error}>
            {error}
          </ErrorPanel>
        )}
        <EditPersonForm
          initialValues={person}
          onCancel={() => setIsEditing(false)}
          onSubmit={async (details: UpdatePayload): Promise<void> => {
            setError("");
            setLoading(true);

            try {
              await personAPI.updatePerson(cohortId, details);
              setIsEditing(false);
              setEditingMessage(T("components.person.edit.success"));
            } catch ({ message }) {
              setError(message);
            } finally {
              setStale(true);
              setLoading(false);
            }
          }}
        />
      </>
    );
  }

  return (
    <>
      <Fieldset>
        <Fieldset.Legend size="m">
          <span data-testid="changes-saved" style={{ color: "#00703c" }}>
            {editingMessage}
          </span>
        </Fieldset.Legend>
      </Fieldset>
      <ChangeButton onClick={edit}>
        {T("components.person.labels.change")}
      </ChangeButton>
      <SummaryList label="person" data-testid="person-details">
        {displayFields.has("name") && (
          <SummaryList.Row>
            <SummaryList.Key>
              {T("components.person.labels.name")}
            </SummaryList.Key>
            <SummaryList.Value aria-label={T("components.person.labels.name")}>
              {person.firstName}
              &nbsp;
              {person.lastName}
            </SummaryList.Value>
            <SummaryList.Actions />
          </SummaryList.Row>
        )}
        {displayFields.has("nhsNumber") && (
          <SummaryList.Row>
            <SummaryList.Key>
              {T("components.person.labels.nhsNumber")}
            </SummaryList.Key>
            <SummaryList.Value
              aria-label={T("components.person.labels.nhsNumber")}
            >
              {person.nhsNumber}
            </SummaryList.Value>
            <SummaryList.Value />
          </SummaryList.Row>
        )}
        {displayFields.has("dateOfBirth") && (
          <SummaryList.Row>
            <SummaryList.Key>
              {T("components.person.labels.dateOfBirth")}
            </SummaryList.Key>
            <SummaryList.Value
              aria-label={T("components.person.labels.dateOfBirth")}
            >
              {formatDateOfBirth(person.dateOfBirth)}
            </SummaryList.Value>
            <SummaryList.Value />
          </SummaryList.Row>
        )}
        {displayFields.has("age") && (
          <SummaryList.Row>
            <SummaryList.Key>
              {T("components.person.labels.age")}
            </SummaryList.Key>
            <SummaryList.Value aria-label={T("components.person.labels.age")}>
              {birthDateToAge(person.dateOfBirth)}
            </SummaryList.Value>
            <SummaryList.Value />
          </SummaryList.Row>
        )}
        {(displayFields.has("phoneNumber") ||
          displayFields.has("mobilePhoneNumber") ||
          displayFields.has("email")) && (
          <SummaryList.Row>
            <SummaryList.Key>
              {T("components.person.labels.contactDetails.label")}
            </SummaryList.Key>
            <SummaryList.Value
              aria-label={T("components.person.labels.contactDetails.label")}
            >
              <BodyText
                aria-label={T(
                  "components.person.labels.contactDetails.fields.phoneNumber"
                )}
              >
                {person.phoneNumber}
              </BodyText>
              <BodyText
                aria-label={T(
                  "components.person.labels.contactDetails.fields.mobilePhoneNumber"
                )}
              >
                {person.mobilePhoneNumber}
              </BodyText>
              <BodyText
                aria-label={T(
                  "components.person.labels.contactDetails.fields.email"
                )}
              >
                {person.email}
              </BodyText>
            </SummaryList.Value>
            <SummaryList.Value />
          </SummaryList.Row>
        )}
        {displayFields.has("mailingAddress") && (
          <SummaryList.Row>
            <SummaryList.Key>
              {T("components.person.labels.address")}
            </SummaryList.Key>
            <SummaryList.Value
              aria-label={T("components.person.labels.address")}
            >
              <Address address={person.mailingAddress} properCase />
            </SummaryList.Value>
            <SummaryList.Value>
              {person.outsideOfEngland && (
                <Tag>{T("components.person.labels.outsideOfEngland")}</Tag>
              )}
            </SummaryList.Value>
          </SummaryList.Row>
        )}
        {displayFields.has("gpPractice") && (
          <SummaryList.Row>
            <SummaryList.Key>
              {T("components.person.labels.gpAddress")}
            </SummaryList.Key>
            <SummaryList.Value
              aria-label={T("components.person.labels.gpAddress")}
            >
              {displayFields.has("gpName") && person.gpName && (
                <BodyText aria-label={T("components.person.labels.gpName")}>
                  {person.gpName}
                </BodyText>
              )}
              <BodyText
                aria-label={T("components.person.labels.gpPracticeName")}
              >
                {person.gpPractice.name}
              </BodyText>
              <Address address={person.gpPractice.address} properCase />
            </SummaryList.Value>
            <SummaryList.Value />
          </SummaryList.Row>
        )}
        {displayFields.has("dataSharingAgreementVersion") && (
          <SummaryList.Row>
            <SummaryList.Key>
              {T("components.person.labels.dataSharingAgreementVersion")}
            </SummaryList.Key>
            <SummaryList.Value
              aria-label={T(
                "components.person.labels.dataSharingAgreementVersion"
              )}
            >
              {display(person.dataSharingAgreementVersion)}
            </SummaryList.Value>
            <SummaryList.Value />
          </SummaryList.Row>
        )}
        {displayFields.has("eligibilityStatus") && (
          <SummaryList.Row>
            <SummaryList.Key>
              {T("components.person.labels.eligibilityStatus")}
            </SummaryList.Key>
            <SummaryList.Value
              aria-label={T("components.person.labels.eligibilityStatus")}
            >
              {displayIsEligible(isEligible)}
            </SummaryList.Value>
            <SummaryList.Value />
          </SummaryList.Row>
        )}
        {displayFields.has("studyStatus") && (
          <SummaryList.Row>
            <SummaryList.Key>
              {T("components.person.labels.studyStatus.label")}
            </SummaryList.Key>
            <SummaryList.Value
              aria-label={T("components.person.labels.studyStatus.label")}
            >
              <BodyText>
                {person.withdrawn
                  ? T("components.person.labels.studyStatus.withdrawn")
                  : T("components.person.labels.studyStatus.active")}
              </BodyText>
            </SummaryList.Value>
            <SummaryList.Value />
          </SummaryList.Row>
        )}
        {displayFields.has("y1Anniversary") && (
          <SummaryList.Row>
            <SummaryList.Key>
              {T("components.person.labels.y1Anniversary")}
            </SummaryList.Key>
            <SummaryList.Value
              aria-label={T("components.person.labels.y1Anniversary")}
            >
              {display(y1Anniversary?.toFormat("cccc d LLLL yyyy"))}
            </SummaryList.Value>
            <SummaryList.Value />
          </SummaryList.Row>
        )}
        {displayFields.has("y2Anniversary") && (
          <SummaryList.Row>
            <SummaryList.Key>
              {T("components.person.labels.y2Anniversary")}
            </SummaryList.Key>
            <SummaryList.Value
              aria-label={T("components.person.labels.y2Anniversary")}
            >
              {display(y2Anniversary?.toFormat("cccc d LLLL yyyy"))}
            </SummaryList.Value>
            <SummaryList.Value />
          </SummaryList.Row>
        )}
        {displayFields.has("cohortId") && (
          <SummaryList.Row>
            <SummaryList.Key>
              {T("components.person.labels.cohortId")}
            </SummaryList.Key>
            <SummaryList.Value
              aria-label={T("components.person.labels.cohortId")}
            >
              {person.cohortId}
            </SummaryList.Value>
            <SummaryList.Value />
          </SummaryList.Row>
        )}
        {displayFields.has("studyId") && (
          <SummaryList.Row>
            <SummaryList.Key>
              {T("components.person.labels.studyId")}
            </SummaryList.Key>
            <SummaryList.Value
              aria-label={T("components.person.labels.studyId")}
            >
              {display(person.studyId)}
            </SummaryList.Value>
            <SummaryList.Value />
          </SummaryList.Row>
        )}
      </SummaryList>
    </>
  );
};
