import { DateTime } from "luxon";
import { IDateInputValue } from "../interfaces";
import { ValidationFunction } from "./ValidationFunction";

export const isValidDateBoolean = ({
  day,
  month,
  year,
}: IDateInputValue): boolean =>
  DateTime.local(parseInt(year, 10), parseInt(month, 10), parseInt(day, 10))
    .isValid;

const isValidBirthDateBoolean = ({
  day,
  month,
  year,
}: IDateInputValue): boolean =>
  isValidDateBoolean({ day, month, year }) && isValidBirthDateYearBoolean(year);

const isValidBirthDateYearBoolean = (year: string): boolean =>
  parseInt(year, 10) > 1899 && parseInt(year, 10) < 2100;

const isValidStudyRangeYearBoolean = (year: string): boolean =>
  parseInt(year, 10) >= 1943 && parseInt(year, 10) <= 1974;

const isValidFourDigitYearBoolean = (year: string): boolean =>
  parseInt(year, 10) > 999 && parseInt(year, 10) < 10000;

export const isValidFourDigitYear = (year: string, msg: string): string =>
  isValidFourDigitYearBoolean(year) ? "" : msg;

export const isValidDateDay = (day: string, msg: string): string =>
  parseInt(day, 10) >= 1 && parseInt(day, 10) <= 31 ? "" : msg;

export const isValidDateMonth = (month: string, msg: string): string =>
  parseInt(month, 10) >= 1 && parseInt(month, 10) <= 12 ? "" : msg;

export const isValidDateYear = (
  year: string,
  invalidFormatMsg: string,
  invalidYearMsg: string
): string => {
  if (!isValidFourDigitYearBoolean(year)) {
    return invalidFormatMsg;
  }

  if (!isValidStudyRangeYearBoolean(year)) {
    return invalidYearMsg;
  }

  return "";
};

const isDateInThePastBoolean = ({
  day,
  month,
  year,
}: IDateInputValue): boolean => {
  const date = DateTime.local(
    parseInt(year, 10),
    parseInt(month, 10),
    parseInt(day, 10)
  );
  const today = DateTime.now().startOf("day");
  return date <= today;
};

export const isValidBirthDate: ValidationFunction<IDateInputValue> = (
  input: IDateInputValue,
  message: string
): string => (isValidBirthDateBoolean(input) ? "" : message);

export const isValidDate: ValidationFunction<IDateInputValue> = (
  input: IDateInputValue,
  message: string
): string => (isValidDateBoolean(input) ? "" : message);

export const isValidDateInThePast: ValidationFunction<IDateInputValue> = (
  input: IDateInputValue,
  message: string
): string =>
  isValidDateBoolean(input) && isDateInThePastBoolean(input) ? "" : message;
