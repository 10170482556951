import { IUnitCapacityPerDay, IUnitCapacityUpdateInput } from "interfaces";

export type CapacityUpdateDiff = Omit<IUnitCapacityUpdateInput, "unitCode">;

export const capacityUpdateKey = (capacity: IUnitCapacityPerDay) =>
  `${capacity.activeFrom.toFormat("HHmm")}-${capacity.activeTo.toFormat(
    "HHmm"
  )}`;

function matches(
  diff: CapacityUpdateDiff,
  capacity: IUnitCapacityPerDay
): boolean {
  const matchingStatus =
    diff.newStatus === undefined || diff.newStatus === capacity.status;
  const matchingCapacity =
    !diff.newCapacity === undefined || diff.newCapacity === capacity.capacity;
  return matchingStatus && matchingCapacity;
}

export function updateCapacityDiffs(
  currentDiffs: Record<string, CapacityUpdateDiff>,
  capacity: IUnitCapacityPerDay,
  updateField: "newCapacity" | "newStatus",
  updateValue: any
): Record<string, Omit<IUnitCapacityUpdateInput, "unitCode">> {
  const key = capacityUpdateKey(capacity);
  if (!currentDiffs[key]) {
    // no existing diff - add one
    return {
      ...currentDiffs,
      [key]: {
        activeFrom: capacity.activeFrom,
        activeTo: capacity.activeTo,
        [updateField]: updateValue,
      },
    };
  }
  const currentDiff = currentDiffs[key];
  const updatedDiff = { ...currentDiff, [updateField]: updateValue };
  if (matches(updatedDiff, capacity)) {
    // update takes us back to original value - remove diff
    const updatedDiffs = { ...currentDiffs };
    delete updatedDiffs[key];
    return updatedDiffs;
  }
  const capacityValue =
    updateField === "newCapacity" ? capacity.capacity : capacity.status;
  if (updateValue === capacityValue) {
    // update takes us back to original value of field - remove field
    delete updatedDiff[updateField];
  }
  // update diff with new value
  return {
    ...currentDiffs,
    [key]: updatedDiff,
  };
}
