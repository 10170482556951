import React, { FormEvent, SyntheticEvent, useState } from "react";
import {
  Button,
  Checkboxes,
  Fieldset,
  Form,
  Input,
  Select,
} from "nhsuk-react-components";
import { EDCSiteId } from "enums";
import { T } from "i18n";
import { isValidUnitCode } from "../../validations/isValidUnitCode";
import { unitCodeExists } from "errors/unitManagement";
import { useAPI } from "api";
import { useHistory } from "react-router-dom";

export function AddUnit(): JSX.Element {
  const history = useHistory();
  const [requestError, setRequestError] = useState("");
  const [validationError, setValidationError] = useState("");
  const [unitCode, setUnitCode] = useState("");
  const [edcSiteId, setEdcSiteId] = useState<EDCSiteId>(EDCSiteId.NHS_01);
  const [unitAccessible, setUnitAccessible] = useState(false);

  const onCancelClick = (): void => {
    const path = `/unit`;
    history.push(path);
  };

  const unitAPI = useAPI("unit");

  const onSubmitForm = async (
    e: SyntheticEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    await unitAPI
      .createUnit(unitCode, edcSiteId, unitAccessible)
      .then(() => {
        history.push(`/unit`);
      })
      .catch(({ message }) => {
        if (message === unitCodeExists) {
          setRequestError(T("containers.unit.add.errors.unitCodeExists"));
        } else {
          setRequestError(T("errors.unknown"));
        }
      });
  };

  const edcSiteIds = Object.keys(EDCSiteId).map((edcSiteId) => (
    <Select.Option
      key={edcSiteId}
      data-testid={`status-option-${edcSiteId}`}
      value={edcSiteId}
    >
      {edcSiteId}
    </Select.Option>
  ));

  return (
    <main className="nhsuk-main-wrapper">
      <Form onSubmit={onSubmitForm}>
        <Fieldset>
          <Fieldset.Legend isPageHeading>
            {T("containers.unit.add.title")}
          </Fieldset.Legend>
        </Fieldset>

        <Input
          type="text"
          label={T("containers.unit.add.fields.unitCode.label")}
          aria-label={T("containers.unit.add.fields.unitCode.label")}
          value={unitCode}
          onChange={(e) => {
            setRequestError("");
            const { value } = e.currentTarget;
            const validationError = isValidUnitCode(
              value,
              T("containers.unit.add.errors.invalidUnitCode")
            );
            setValidationError(validationError);
            setUnitCode(value);
          }}
          error={validationError || requestError}
          width="10"
        />
        <Select
          data-testid="edc-site-select"
          value={edcSiteId}
          onChange={(e) => setEdcSiteId(e.currentTarget.value as EDCSiteId)}
        >
          {edcSiteIds}
        </Select>

        <Checkboxes>
          <Checkboxes.Box
            value="accessible"
            checked={unitAccessible}
            onChange={(e: FormEvent<HTMLInputElement>): void => {
              setUnitAccessible(e.currentTarget.checked);
            }}
            aria-label={T("containers.unit.add.fields.unitAccessible.label")}
          >
            {T("containers.unit.add.fields.unitAccessible.label")}
          </Checkboxes.Box>
        </Checkboxes>

        <Button
          type="button"
          secondary
          aria-label="Cancel"
          onClick={onCancelClick}
        >
          {T("containers.unit.add.actions.cancel")}
        </Button>
        <Button
          type="submit"
          disabled={unitCode === "" || validationError !== ""}
          aria-label="Save"
        >
          {T("containers.unit.add.actions.save")}
        </Button>
      </Form>
    </main>
  );
}
