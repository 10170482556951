import React from "react";
import { API, APIContext } from "./api";
import { AuthClient, AuthProvider } from "auth";
import { History } from "history";
import { Router } from "react-router";

interface ShellProps<T> {
  history: History;
  auth: AuthClient<T>;
}

export function Shell<T>({
  auth,
  children,
  history,
}: React.PropsWithChildren<ShellProps<T>>): JSX.Element {
  return (
    <APIContext.Provider value={API}>
      <AuthProvider client={auth}>
        <Router history={history}>{children}</Router>
      </AuthProvider>
    </APIContext.Provider>
  );
}
