import React, { useState } from "react";
import { Button, Input, Label } from "nhsuk-react-components";
import { Code } from "./Code";
import { ErrorMessage } from "components";
import { RegisterMFA } from "./RegisterMFA";
import { T } from "i18n";
import { UpdatePassword } from "./UpdatePassword";
import { useAuth } from "auth/useAuth";

export const Login = (): JSX.Element => {
  const { signIn, isLoading, error, userAuthState } = useAuth();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  switch (userAuthState) {
    case "NEW_PASSWORD_REQUIRED":
      return <UpdatePassword />;
    case "SOFTWARE_TOKEN_MFA":
    case "SMS_MFA":
      return <Code mfaType={userAuthState} />;
    case "REGISTER_TOTP":
      return <RegisterMFA />;
  }

  const stringError = (): string => {
    switch (error.code) {
      case "NotAuthorizedException":
        return T("containers.login.errors.incorrectUsernameOrPassword");
      default:
        return T("containers.login.errors.unknown");
    }
  };

  return (
    <>
      <Label className="nhsuk-u-margin-top-5" size="m">
        {T(`containers.login.title`)}
      </Label>
      <Input
        type="username"
        label={`${T(`containers.login.fields.username.label`)}`}
        aria-label={`${T(`containers.login.fields.username.label`)}`}
        value={username}
        data-testid="username"
        onChange={(e) => setUsername(e.currentTarget.value)}
        width="20"
      />
      <Input
        type="password"
        label={`${T(`containers.login.fields.password.label`)}`}
        aria-label={`${T(`containers.login.fields.password.label`)}`}
        value={password}
        data-testid="password"
        onChange={(e) => setPassword(e.currentTarget.value)}
        width="20"
      />
      <Button
        disabled={isLoading || username === "" || password === ""}
        onClick={() => signIn(username, password)}
        data-testid="signIn"
      >
        {isLoading
          ? T(`containers.login.signIn.loading`)
          : T(`containers.login.signIn.label`)}
      </Button>
      {error ? (
        <ErrorMessage
          label="submission-error"
          title={
            <span id="error-summary-title">
              {T("containers.login.errors.title")}
            </span>
          }
        >
          {stringError()}
        </ErrorMessage>
      ) : (
        <></>
      )}
    </>
  );
};
