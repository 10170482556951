import React, { useState } from "react";
import { Card, DateInput, Radios } from "nhsuk-react-components";
import { IDateInputValue } from "interfaces";
import { T } from "i18n";

const acceptedOption = "ACCEPTED";
const rejectedOption = "REJECTED";
type RadioOption = "" | "ACCEPTED" | "REJECTED";

const initialRadioSelected = (dates: {
  accepted: IDateInputValue;
  rejected: IDateInputValue;
}): RadioOption => {
  if (dates.accepted.year !== "") {
    return acceptedOption;
  }
  if (dates.rejected.year !== "") {
    return rejectedOption;
  }
  return "";
};

const resetDateInput: IDateInputValue = {
  year: "",
  month: "",
  day: "",
};

export const ParticipantReferralLog = ({
  dates,
  errors,
  onAcceptedDateChange,
  onRejectedDateChange,
}: {
  dates: { accepted: IDateInputValue; rejected: IDateInputValue };
  errors: { acceptedError: string; rejectedError: string };
  onAcceptedDateChange: (value: IDateInputValue) => void;
  onRejectedDateChange: (value: IDateInputValue) => void;
}): JSX.Element => {
  const [selectedRadio, setSelectedRadio] = useState<RadioOption>(
    initialRadioSelected(dates)
  );
  const handleSelectAccepted = (): void => {
    onRejectedDateChange(resetDateInput);
    setSelectedRadio(acceptedOption);
  };
  const handleSelectRejected = (): void => {
    onAcceptedDateChange(resetDateInput);
    setSelectedRadio(rejectedOption);
  };

  return (
    <Card feature>
      <Card.Content>
        <Card.Heading>
          {T("containers.resultsCase.referralLogCard.title")}
        </Card.Heading>
        <Radios
          name="result"
          label={T("containers.resultsCase.referralLogCard.label")}
          aria-label={T("containers.resultsCase.referralLogCard.label")}
          value={selectedRadio}
        >
          <Radios.Radio
            data-testid="referralAccepted"
            onChange={handleSelectAccepted}
            aria-label={T("containers.resultsCase.referralLogCard.accepted")}
            value={acceptedOption}
            checked={selectedRadio === acceptedOption}
          >
            {T("containers.resultsCase.referralLogCard.accepted")}
          </Radios.Radio>
          {selectedRadio === acceptedOption && (
            <DateInput
              aria-label={T("containers.resultsCase.referralLogCard.accepted")}
              data-testid="referralAcceptedDate"
              value={dates.accepted}
              error={errors.acceptedError}
              onChange={(e) => onAcceptedDateChange(e.target.value)}
            />
          )}
          <Radios.Radio
            data-testid="referralRejected"
            onChange={handleSelectRejected}
            aria-label={T("containers.resultsCase.referralLogCard.rejected")}
            value={rejectedOption}
            checked={selectedRadio === rejectedOption}
          >
            {T("containers.resultsCase.referralLogCard.rejected")}
          </Radios.Radio>
          {selectedRadio === rejectedOption && (
            <DateInput
              aria-label={T("containers.resultsCase.referralLogCard.rejected")}
              data-testid="referralRejectedDate"
              value={dates.rejected}
              error={errors.rejectedError}
              onChange={(e) => onRejectedDateChange(e.target.value)}
            />
          )}
        </Radios>
      </Card.Content>
    </Card>
  );
};
