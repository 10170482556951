import React from "react";
import { T } from "i18n";

export const RegistrationClosed = (): JSX.Element => {
  return (
    <>
      <p>{T("components.registrationClosed.paragraph1")}</p>
      <p>{T("components.registrationClosed.paragraph2")}</p>
    </>
  );
};
