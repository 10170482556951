import React from "react";
import { BackToTop } from "./BackToTop";
import { Card, Label, Radios } from "nhsuk-react-components";
import { ReportPDF } from "./ReportPDF";
import { T } from "i18n";
import { Trans } from "react-i18next";

export const BeforeYouBegin = ({
  answer,
  setAnswer,
  caseReference,
  reportISODate,
  studyID,
}: {
  answer: string | undefined;
  setAnswer: (input: string) => void;
  caseReference: string;
  reportISODate?: string;
  studyID: string;
}): JSX.Element => {
  let error: string | undefined;
  if (answer === "NO") {
    error = "WARNING: Do not proceeed if the IDs do not match!";
  }

  return (
    <>
      <Card feature>
        <Card.Content>
          <Card.Heading>
            {T("containers.resultsCase.beforeYouBegin.title")}
          </Card.Heading>
          <Label data-testid="beforeYouBeginText">
            <Trans
              i18nKey="containers.resultsCase.beforeYouBegin.answer.label"
              values={{ studyID }}
            />
          </Label>
          <Radios name="confirm" inline value={answer} error={error}>
            <Radios.Radio
              data-testid="beforeYouBeginYes"
              onChange={(e) => setAnswer(e.currentTarget.value)}
              aria-label={T("containers.resultsCase.beforeYouBegin.answer.yes")}
              value="YES"
              checked={answer === "YES"}
            >
              {T("containers.resultsCase.beforeYouBegin.answer.yes")}
            </Radios.Radio>
            <Radios.Radio
              data-testid="beforeYouBeginNo"
              onChange={(e) => setAnswer(e.currentTarget.value)}
              aria-label={T("containers.resultsCase.beforeYouBegin.answer.no")}
              value="NO"
              checked={answer === "NO"}
            >
              {T("containers.resultsCase.beforeYouBegin.answer.no")}
            </Radios.Radio>
          </Radios>
          <div style={{ textAlign: "center" }}>
            <ReportPDF
              caseReference={caseReference}
              studyID={studyID}
              reportISODate={reportISODate}
            />
          </div>
        </Card.Content>
      </Card>
      <BackToTop />
    </>
  );
};
