import { AxiosInstance } from "axios";
import { IDataSharingAgreement } from "interfaces";

export interface IDocumentClient {
  getDataSharingAgreement: () => Promise<IDataSharingAgreement>;
}

export class DocumentClient implements IDocumentClient {
  public constructor(protected readonly http: AxiosInstance) {}

  public async getDataSharingAgreement(): Promise<IDataSharingAgreement> {
    const response = await this.http.get<IDataSharingAgreement>(
      "/data-sharing-agreement"
    );
    return response.data;
  }
}
