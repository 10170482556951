import { CaseStatus } from "enums";
import { DateTime } from "luxon";

export type ReportResult = "UNKNOWN" | "ABSENT" | "PRESENT" | "CANCELLED";

export type ReportType = "ORIGINAL" | "CORRECTED" | "AMENDED";

export interface ICase {
  studyId: string;
  cohortId: string;
  fullName: string;
  reportDate: DateTime;
  bloodCollectionTime: DateTime | undefined;
  reportResult: ReportResult;
  progress: number;
  progressTotal: number;
  reportType: ReportType;
  reportFilePath: string | null;
  caseReference: string;
  status: CaseStatus;
  lastUpdatedBy: string | null;
  lastUpdatedTime: DateTime | undefined;
}

export interface ICaseList {
  cases: ICase[];
}

export enum ECaseField {
  IDConfirmation = "ID_CONFIRMATION",
  ParticipantDocsMailed = "PARTICIPANT_DOCS_MAILED",
  ReferralSubmitted = "REFERRAL_SUBMITTED",
  ReferralRejected = "REFERRAL_REJECTED",
  ReferralAccepted = "REFERRAL_ACCEPTED",
  GPDocsMailed = "GP_DOCS_MAILED",
  ECRFFormSubmitted = "ECRF_FORM_SUBMITTED",
  CloseNote = "CLOSE_NOTE",
}

export interface ICaseState {
  cohortId: string;
  status: CaseStatus;
  state: CaseFieldState;
  contactHistory: CaseContact[];
  lastUpdatedBy: string | null;
  lastUpdatedTime: DateTime | null;
}

export type CaseFieldState = {
  [key in ECaseField]: string;
};

export enum ECaseContactType {
  CaseContactCallSuccessful = "CALL_SUCCESSFUL",
  CaseContactCallNoResponse = "CALL_NO_RESPONSE",
  CaseContactNote = "NOTE",
  CaseContactLetterSent = "LETTER_SENT",
}

export type CaseContact = {
  type: ECaseContactType;
  date: DateTime;
  note: string;
};
