import { AxiosInstance, AxiosResponse } from "axios";
import { IBloodDrawStatus } from "interfaces";
import {
  bloodFormNotComplete,
  notCompletedAppointment,
  reAttemptNotNeeded,
} from "errors/blood";

export interface BloodClient {
  getStatus(cohortId: string): Promise<IBloodDrawStatus>;
  markReAttemptNeeded(cohortId: string): Promise<AxiosResponse>;
  removeReAttempt(cohortId: string): Promise<void>;
  markReDrawNeeded(cohortId: string): Promise<AxiosResponse>;
  removeReDraw(cohortId: string): Promise<AxiosResponse>;
}

export class HTTPBloodClient implements BloodClient {
  public constructor(protected readonly http: AxiosInstance) {}

  async getStatus(cohortId: string): Promise<IBloodDrawStatus> {
    return (await this.http.get(`/blood/${cohortId}`)).data;
  }

  async markReAttemptNeeded(cohortId: string): Promise<AxiosResponse> {
    return this.http.post(`/blood/${cohortId}/reattempt`).catch((error) => {
      const { response } = error;
      switch (response.data.error) {
        case "ErrBloodDrawFormNotComplete":
          throw new Error(bloodFormNotComplete);
        case "ErrReAttemptNotNeeded":
          throw new Error(reAttemptNotNeeded);
        default:
          throw error;
      }
    });
  }

  async removeReAttempt(cohortId: string): Promise<void> {
    return this.http.delete(`/blood/${cohortId}/reattempt`);
  }

  async markReDrawNeeded(cohortId: string): Promise<AxiosResponse> {
    return this.http.post(`/blood/${cohortId}/redraw`).catch((error) => {
      const { response } = error;
      switch (response.data.error) {
        case "ErrAppointmentNotCompleted":
          throw new Error(notCompletedAppointment);
        default:
          throw error;
      }
    });
  }

  async removeReDraw(cohortId: string): Promise<AxiosResponse> {
    return this.http.delete(`/blood/${cohortId}/redraw`).catch((error) => {
      const { response } = error;
      switch (response.data.error) {
        case "ErrAppointmentNotCompleted":
          throw new Error(notCompletedAppointment);
        default:
          throw error;
      }
    });
  }
}
