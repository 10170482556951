import { ITimeInputValue } from "../interfaces";
import { ValidationFunction } from "./ValidationFunction";

export const isValidTimeBoolean = ({
  hour,
  minute,
}: ITimeInputValue): boolean =>
  parseInt(hour, 10) >= 0 &&
  parseInt(hour, 10) < 24 &&
  parseInt(minute, 10) >= 0 &&
  parseInt(minute, 10) < 60;

export const isValidTime: ValidationFunction<ITimeInputValue> = (
  input: ITimeInputValue,
  message: string
): string => (isValidTimeBoolean(input) ? "" : message);
