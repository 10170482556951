import React, { useEffect, useState } from "react";
import { Button } from "nhsuk-react-components";
import { ErrorMessage, Loader } from "components";
import { FormSummary } from "../../../../components/ecrf/FormSummary";
import { IForm } from "interfaces";
import { T } from "i18n";
import { useAPI } from "api";

const FORM_TYPE = "PARTICIPANT_ELIGIBILITY";

export function EligibilityRecheckActions({
  cohortId,
  onAmend,
  onConfirm,
}: {
  cohortId: string;
  onAmend: () => void;
  onConfirm: () => void;
}): JSX.Element {
  const eligibilityAPI = useAPI("eligibility");
  const formAPI = useAPI("forms");

  const [form, setForm] = useState<IForm>();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<Error>();
  const [loadError, setLoadError] = useState<Error>();

  const onSubmit = async (): Promise<void> => {
    if (!form) {
      return;
    }
    setSubmitting(true);
    return formAPI
      .saveForm(cohortId, form)
      .then(() => {
        setSubmitting(false);
        onConfirm();
      })
      .catch((err) => {
        setError(err);
        setSubmitting(false);
      });
  };

  const onReset = (): void => {
    setSubmitting(true);
    eligibilityAPI
      .resetEligibility(cohortId)
      .then(() => {
        setSubmitting(false);
        onAmend();
      })
      .catch((err) => {
        setSubmitting(false);
        setError(err);
      });
  };

  useEffect(() => {
    setLoading(true);
    formAPI
      .getForm(cohortId, FORM_TYPE, "D1")
      .then((newForm) => {
        setForm(newForm);
        setLoading(false);
      })
      .catch((err) => {
        setLoadError(err);
        setLoading(false);
      });
  }, [formAPI, cohortId]);

  if (loadError) {
    return (
      <ErrorMessage title={T("containers.eligibilityCheck.errors.loadError")}>
        {loadError.message}
      </ErrorMessage>
    );
  }

  if (!form || !form.answers || loading) {
    return <Loader />;
  }

  return (
    <>
      <FormSummary
        definition={form?.definition}
        answers={form?.answers}
        disabled={submitting}
        onSubmit={onSubmit}
        title={T("containers.eligibilityCheck.title")}
        instruction={T("containers.eligibilityCheck.instruction")}
        submitLabel={T("containers.eligibilityCheck.actions.continue")}
      />
      {error && (
        <ErrorMessage
          title={T("containers.eligibilityCheck.errors.actionError")}
        >
          {error.message}
        </ErrorMessage>
      )}
      <Button
        data-testid="amend"
        secondary
        type="reset"
        onClick={onReset}
        disabled={submitting}
      >
        {T("containers.eligibilityCheck.actions.amend")}
      </Button>
    </>
  );
}
