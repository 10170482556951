import React from "react";

import { AccessibilityIconProps } from "./types";

export function InterpreterIcon({
  title,
  stroke = 4,
  color = "#005EB8",
  size = 16,
}: React.PropsWithChildren<AccessibilityIconProps>): JSX.Element {
  return (
    <svg width={size} height={size} viewBox="0 0 240 240" aria-label={title}>
      <g id="Conversation">
        <path
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M167,88h14a45.5,45.5,0,0,1,45.4,45.7v8.7a45.56,45.56,0,0,1-16.5,35.2c5.8,10.8,12.8,21.5,18.2,23,0,0-22.5-3.7-37.5-13.6a43.47,43.47,0,0,1-9.6,1.1H125.8c-25.1,0-45.4-21.64-45.4-42.7"
        />
        <path
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M121.6,45.1H66.5A45.5,45.5,0,0,0,21.1,90.8v8.7a45.56,45.56,0,0,0,16.5,35.2c-5.8,10.8-12.8,21.5-18.2,23,0,0,22.5-3.7,37.5-13.6a43.47,43.47,0,0,0,9.6,1.1h55.2a45.5,45.5,0,0,0,45.4-45.7V90.8A45.64,45.64,0,0,0,121.6,45.1Z"
        />
        <line
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          x1="55.2"
          y1="74.9"
          x2="136.5"
          y2="74.9"
        />
        <line
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          x1="55.2"
          y1="94.9"
          x2="136.5"
          y2="94.9"
        />
        <line
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          x1="55.2"
          y1="114.9"
          x2="100.3"
          y2="114.9"
        />
      </g>
    </svg>
  );
}
