import QRCode from "react-qr-code";
import React, { useState } from "react";
import { Button, Input, Label } from "nhsuk-react-components";
import { T } from "i18n";
import { useAuth } from "auth";

export const RegisterMFA = (): JSX.Element => {
  const { deviceRegistrationCode, error, isLoading, confirmDevice } = useAuth();
  const [code, setCode] = useState("");

  const stringError = (): string => {
    if (!error) {
      return "";
    }
    switch (error.code) {
      case "EnableSoftwareTokenMFAException":
        return T("containers.login.registerMFA.errors.invalidCode");
      default:
        return T("containers.login.registerMFA.errors.unknown");
    }
  };

  if (!deviceRegistrationCode) {
    return <></>;
  }

  return (
    <>
      <Label size="m" className="nhsuk-u-margin-top-5">
        {T("containers.login.registerMFA.title")}
      </Label>
      <Label>{T("containers.login.registerMFA.description")}</Label>
      <QRCode data-testid="qr-code" value={deviceRegistrationCode} />
      <Input
        type="text"
        data-testid="code"
        label={T("containers.login.registerMFA.label")}
        aria-label={T("containers.login.registerMFA.label")}
        value={code}
        onChange={(e) => setCode(e.currentTarget.value)}
        error={stringError()}
        width="20"
      />
      <Button
        disabled={isLoading || code === ""}
        data-testid="confirmDevice"
        onClick={() => confirmDevice(code)}
      >
        {isLoading
          ? T(`containers.login.registerMFA.button.loading`)
          : T(`containers.login.registerMFA.button.label`)}
      </Button>
    </>
  );
};
