import { AxiosInstance } from "axios";
import { IAddress, IRegistration } from "interfaces";
import { T } from "i18n";
import { formatAPIDate } from "formatters";

export interface IRegistrationClient {
  register: (input: IRegistration) => Promise<string>;
}

export interface APIPractice {
  practiceId: string;
  address: IAddress;
  name: string;
}

export class RegistrationClient implements IRegistrationClient {
  public constructor(protected readonly http: AxiosInstance) {}

  public async register(input: IRegistration): Promise<string> {
    interface apiRegistration
      extends Omit<IRegistration, "dateOfBirth" | "biologicalSex"> {
      dateOfBirth: string;
      biologicalSex: string;
    }

    const requestBody: apiRegistration = {
      ...input,
      dateOfBirth: formatAPIDate(input.dateOfBirth),
      biologicalSex: input.biologicalSex as string,
    };

    return this.http
      .post("/people", requestBody)
      .then((response) => {
        return response.data.cohortId;
      })
      .catch((error) => {
        const statusCode = error.response?.status;
        switch (statusCode) {
          case 403:
            throw new Error(T("errors.registration.unauthorised"));
          case 409:
            throw new Error(T("errors.registration.alreadyRegistered"));
          default:
            throw error;
        }
      });
  }
}
