import React from "react";
import { Button } from "nhsuk-react-components";
import { IPerson } from "interfaces";
import { T } from "i18n";
import { useHistory } from "react-router-dom";

interface StudyEndpointProps {
  person: IPerson;
}

export const StudyEndpointOutsideEngland = ({
  person,
}: StudyEndpointProps): JSX.Element => {
  const history = useHistory();

  return (
    <>
      <Button
        onClick={() => {
          history.push(
            `/participant/${person.cohortId}/${
              person.outsideOfEngland
                ? "remove-outside-england"
                : "mark-outside-england"
            }`
          );
        }}
        data-testid="outside-of-england-button"
      >
        {T(
          person.outsideOfEngland
            ? "components.person.studyEndpoint.removeOutsideOfEngland"
            : "components.person.studyEndpoint.markOutsideOfEngland"
        )}
      </Button>
    </>
  );
};
