import React, { useEffect, useState } from "react";
import { FormFieldProps } from "@grailbio/components-ecrf";
import { Input } from "nhsuk-react-components";
import { ReadOnlyFormField } from "./ReadOnlyFormField";
import { T } from "i18n";

export function TextInput(props: FormFieldProps): JSX.Element {
  const { definition, value, setValue, readOnly, disabled } = props;
  const { label } = definition;

  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    if (value !== inputValue) {
      setValue(inputValue);
    }
    if (definition.systemPopulated) {
      if (definition.values) {
        setInputValue(definition.values[0]);
      } else {
        setInputValue(T("components.ecrf.placeholders.systemPopulated"));
      }
    }
  }, [
    value,
    setValue,
    inputValue,
    definition.values,
    definition.systemPopulated,
  ]);

  if (readOnly) {
    return <ReadOnlyFormField label={label} value={value} />;
  }

  return (
    <Input
      type="text"
      disabled={definition.systemPopulated || disabled}
      label={label}
      aria-label={label}
      value={inputValue}
      onChange={(e) => setInputValue(e.currentTarget.value)}
      width="20"
    />
  );
}
