import React, { useState } from "react";
import { Button, Checkboxes } from "nhsuk-react-components";
import { IPerson } from "interfaces/IPerson";
import { IneligibilityReason } from "../../../../enums/IneligibilityReason";
import { T } from "i18n";
import { VisitPerson } from "containers/Visit/VisitPerson";

interface EligibilityResultProps {
  eligible: boolean;
  ineligibilityReasons?: IneligibilityReason[];
  onReset: () => void;
  person?: IPerson;
  heading?: string;
}

export const EligibilityResult = ({
  eligible,
  ineligibilityReasons,
  onReset,
  person,
  heading,
}: EligibilityResultProps): JSX.Element => {
  const [resetClicked, setResetClicked] = useState<boolean>(false);
  const [resetApproved, setResetApproved] = useState<boolean>(false);

  const resetEligbility = (): void => {
    onReset();
  };

  return (
    <>
      <h1>{heading}</h1>
      {person && <VisitPerson person={person} />}
      <p data-testid="eligibility-result">
        {eligible
          ? T("containers.eligibility.eligible.description")
          : T("containers.eligibility.ineligible.description")}
      </p>
      <ul>
        {ineligibilityReasons &&
          Array.from(new Set(ineligibilityReasons)).map((reason) => {
            switch (reason) {
              case "PARTICIPATED_IN_OTHER_GRAIL_STUDY":
                return (
                  <li key={reason}>
                    {T(
                      "containers.eligibility.ineligible.reasons.participatedInOtherGrailStudy"
                    )}
                  </li>
                );
              case "CANCER_HISTORY":
                return (
                  <li key={reason}>
                    {T(
                      "containers.eligibility.ineligible.reasons.cancerHistory"
                    )}
                  </li>
                );
              case "CYTOTOXIC_DEMETHYLATING_AGENTS":
                return (
                  <li key={reason}>
                    {T(
                      "containers.eligibility.ineligible.reasons.cytotoxicDemethylatingAgents"
                    )}
                  </li>
                );
              case "ONGOING_CANCER_INVESTIGATIONS":
                return (
                  <li key={reason}>
                    {T(
                      "containers.eligibility.ineligible.reasons.ongoingCancerInvestigations"
                    )}
                  </li>
                );
              case "PALLIATIVE_CARE":
                return (
                  <li key={reason}>
                    {T(
                      "containers.eligibility.ineligible.reasons.palliativeCare"
                    )}
                  </li>
                );
              default:
                throw new Error("Unsupported ineligibility reason");
            }
          })}
      </ul>
      {!resetClicked && !eligible && (
        <>
          <h3>{T("containers.eligibility.amend.title")}</h3>
          <p>{T("containers.eligibility.amend.description")}</p>
          <Button
            type="button"
            data-testid="amend"
            onClick={() => setResetClicked(true)}
          >
            {T("containers.eligibility.amend.button")}
          </Button>
        </>
      )}
      {resetClicked && !eligible && (
        <>
          <Checkboxes name="confirm" id="confirm">
            <Checkboxes.Box
              value="accept"
              checked={resetApproved}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                setResetApproved(event.currentTarget.checked)
              }
              data-testid="reset-confirm"
            >
              {T("containers.eligibility.amend.checkbox")}
            </Checkboxes.Box>
          </Checkboxes>
          <Button
            data-testid="continue"
            disabled={!resetApproved}
            onClick={resetEligbility}
          >
            {T("containers.eligibility.amend.confirm")}
          </Button>
        </>
      )}
    </>
  );
};
