import { DateInput, Input } from "nhsuk-react-components";
import { T } from "../../i18n";
import { FormValidity, ValiditySummary } from "../../components";
import { MaskedInput } from "nhsuk-react-components-extensions";
import React, { FormEvent, useEffect, useMemo } from "react";
import { nhsNumberInputToString } from "../../helpers";
import { IDateInputValue } from "../../interfaces";
import {
  hasWords,
  isValidBirthDate,
  isValidDateDay,
  isValidDateMonth,
  isValidFourDigitYear,
  isValidNHSNumber,
} from "../../validations";
import { FindMyNHSNumber } from "../Registration/RegistrationForm";

const ids = {
  nhsNumber: "nhs-number-input",
  lastName: "last-name-input",
  dateOfBirth: {
    combined: "dob-input",
    day: "dob-input-day",
    month: "dob-input-month",
    year: "dob-input-year",
  },
};

interface FindDetailsProps {
  nhsNumber: string;
  nhsNumberChanged: (newVal: string) => void;
  lastName: string;
  lastNameChanged: (newVal: string) => void;
  dateOfBirth: IDateInputValue;
  dateOfBirthChanged: (newVal: IDateInputValue) => void;
  validity: FormValidity;
  validityUpdated: (validity: FormValidity) => void;
}

export const IdentityVerifyForm = ({
  nhsNumber,
  nhsNumberChanged,
  lastName,
  lastNameChanged,
  dateOfBirth,
  dateOfBirthChanged,
  validity = new FormValidity(),
  validityUpdated,
}: FindDetailsProps): JSX.Element => {
  useEffect(() => {
    const validity = new FormValidity();

    const nhsNumberError = isValidNHSNumber(
      nhsNumber,
      T("containers.participantDetailsManager.fields.nhsNumber.errors.invalid")
    );
    validity.addError(nhsNumberError, ids.nhsNumber);

    const lastNameError = hasWords(
      lastName,
      T("containers.participantDetailsManager.fields.lastName.errors.empty")
    );
    validity.addError(lastNameError, ids.lastName);

    const dateOfBirthDayError = isValidDateDay(
      dateOfBirth.day,
      T(
        "containers.participantDetailsManager.fields.dateOfBirth.errors.invalidDay"
      )
    );
    validity.addError(dateOfBirthDayError, ids.dateOfBirth.day);

    const dateOfBirthMonthError = isValidDateMonth(
      dateOfBirth.month,
      T(
        "containers.participantDetailsManager.fields.dateOfBirth.errors.invalidMonth"
      )
    );
    validity.addError(dateOfBirthMonthError, ids.dateOfBirth.month);

    const dateOfBirthYearError = isValidFourDigitYear(
      dateOfBirth.year,
      T(
        "containers.participantDetailsManager.fields.dateOfBirth.errors.invalidYearFormat"
      )
    );
    validity.addError(dateOfBirthYearError, ids.dateOfBirth.year);

    if (
      dateOfBirthDayError === "" &&
      dateOfBirthMonthError === "" &&
      dateOfBirthYearError === ""
    ) {
      const dateOfBirthError = isValidBirthDate(
        dateOfBirth,
        T(
          "containers.participantDetailsManager.fields.dateOfBirth.errors.invalid"
        )
      );
      validity.addError(dateOfBirthError, ids.dateOfBirth.combined);
    }

    validityUpdated && validityUpdated(validity);
  }, [nhsNumber, lastName, dateOfBirth, validityUpdated]);

  const dateOfBirthErrors = useMemo(
    () => ({
      combined: validity.getFirstErrorForId(ids.dateOfBirth.combined)?.message,
      day: validity.getFirstErrorForId(ids.dateOfBirth.day)?.message,
      month: validity.getFirstErrorForId(ids.dateOfBirth.month)?.message,
      year: validity.getFirstErrorForId(ids.dateOfBirth.year)?.message,
    }),
    [validity]
  );

  return (
    <>
      <ValiditySummary validity={validity} />
      <MaskedInput
        type="text"
        data-testid="nhsNumber"
        id={ids.nhsNumber}
        label={T("containers.participantDetailsManager.fields.nhsNumber.label")}
        hint={T("containers.participantDetailsManager.fields.nhsNumber.hint")}
        aria-label={T(
          "containers.participantDetailsManager.fields.nhsNumber.label"
        )}
        value={nhsNumber}
        onChange={(e: FormEvent<HTMLInputElement>) =>
          nhsNumberChanged &&
          nhsNumberChanged(nhsNumberInputToString(e.currentTarget.value))
        }
        error={validity.getFirstErrorForId(ids.nhsNumber)?.message}
        mask="999 999 9999"
        maskChar=""
        width="10"
      />
      <FindMyNHSNumber />
      <DateInput
        label={T(
          "containers.participantDetailsManager.fields.dateOfBirth.label"
        )}
        id={ids.dateOfBirth.combined}
        aria-label={T(
          "containers.participantDetailsManager.fields.dateOfBirth.label"
        )}
        hint={T("containers.participantDetailsManager.fields.dateOfBirth.hint")}
        value={dateOfBirth}
        error={`${Object.values(dateOfBirthErrors)
          .filter((s) => s !== undefined)
          .join("\n")}`}
        onChange={(e) =>
          dateOfBirthChanged && dateOfBirthChanged(e.currentTarget.value)
        }
      >
        <DateInput.Day error={!!dateOfBirthErrors.day} />
        <DateInput.Month error={!!dateOfBirthErrors.month} />
        <DateInput.Year error={!!dateOfBirthErrors.year} />
      </DateInput>
      <Input
        type="text"
        data-testid="lastName"
        id={ids.lastName}
        label={T("containers.participantDetailsManager.fields.lastName.label")}
        hint={T("containers.participantDetailsManager.fields.lastName.hint")}
        aria-label={T(
          "containers.participantDetailsManager.fields.lastName.label"
        )}
        value={lastName}
        onChange={(e) =>
          lastNameChanged && lastNameChanged(e.currentTarget.value)
        }
        error={validity.getFirstErrorForId(ids.lastName)?.message}
        width="20"
      />
    </>
  );
};
