import { ValidationFunction } from "./ValidationFunction";

const nhsNumberRegex = /^[0-9]{10}$/;

export const isValidNHSNumber: ValidationFunction<string> = (
  value: string,
  message: string
): string => {
  if (!nhsNumberRegex.test(value)) {
    return message;
  }

  // See https://www.datadictionary.nhs.uk/data_dictionary/attributes/n/nhs/nhs_number_de.asp
  // for more information on validating an NHS number.
  const digits = value
    .replace(/\s+/g, "")
    .split("")
    .map((x) => parseInt(x, 10));

  const weights = [10, 9, 8, 7, 6, 5, 4, 3, 2];
  const expectedCheckDigit = digits.pop();
  const total = digits.map((x, i) => weights[i] * x).reduce((x, y) => x + y, 0);

  const remainder = total % 11;
  let checkDigit = 11 - remainder;
  if (checkDigit === 11) {
    checkDigit = 0;
  }

  if (checkDigit === 10) {
    return message;
  }

  if (expectedCheckDigit !== checkDigit) {
    return message;
  }

  return "";
};
