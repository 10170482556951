import React, { Ref, forwardRef, useImperativeHandle, useState } from "react";
import { FormValidity, ValiditySummary } from "../../components";
import { Label, WarningCallout } from "nhsuk-react-components";
import { SurveyAnswers, SurveyQuestions } from "../../api";
import { SurveyQuestion } from "./SurveyQuestion";
import { T } from "i18n";
import { ValidateFormHandle } from "../../validations/ValidateFormHandle";
import { hasWords } from "../../validations";

interface TakingPartFormProps {
  survey: SurveyQuestions;
  answers: SurveyAnswers;
  onAnswerChanged: (answers: { [key: string]: string }) => void;
}

export const TakingPartForm = forwardRef(
  (
    { survey, answers, onAnswerChanged }: TakingPartFormProps,
    ref: Ref<ValidateFormHandle>
  ) => {
    const { questions } = survey;
    const [takingPartValidity, setTakingPartValidity] = useState<FormValidity>(
      new FormValidity()
    );

    const ids = {
      question1: "question1",
      question2: "question2",
      question3: "question3",
    };

    useImperativeHandle(ref, () => ({
      validate() {
        const validity: FormValidity = new FormValidity();

        const question1Error = hasWords(
          answers.question1,
          T("containers.survey.questionUnansweredError")
        );
        validity.addError(question1Error, ids.question1);

        const question2Error = hasWords(
          answers.question2,
          T("containers.survey.questionUnansweredError")
        );
        validity.addError(question2Error, ids.question2);

        const question3Error = hasWords(
          answers.question3,
          T("containers.survey.questionUnansweredError")
        );
        validity.addError(question3Error, ids.question3);

        setTakingPartValidity(validity);

        return validity.valid;
      },
    }));

    return (
      <>
        <h1 data-testid="survey-taking-part-questions-title">
          {T("containers.survey.takingPart.title")}
        </h1>
        <ValiditySummary validity={takingPartValidity} />
        <p data-testid="survey-taking-part-questions-instructions">
          {T("containers.survey.takingPart.description")}
        </p>

        <SurveyQuestion
          id={ids.question1}
          text={questions.question1}
          error={takingPartValidity.getFirstErrorForId(ids.question1)?.message}
          onChange={(value) => {
            onAnswerChanged({ question1: value });
          }}
          value={answers.question1}
        />
        <SurveyQuestion
          id={ids.question2}
          text={questions.question2}
          error={takingPartValidity.getFirstErrorForId(ids.question2)?.message}
          onChange={(value) => {
            onAnswerChanged({ question2: value });
          }}
          value={answers.question2}
        />
        <SurveyQuestion
          id={ids.question3}
          text={questions.question3}
          error={takingPartValidity.getFirstErrorForId(ids.question3)?.message}
          onChange={(value) => {
            onAnswerChanged({ question3: value });
          }}
          value={answers.question3}
        />
        <WarningCallout label={T("containers.survey.takingPart.warning.title")}>
          <WarningCallout.Label>
            {T("containers.survey.takingPart.warning.title")}
          </WarningCallout.Label>
          <p>{T("containers.survey.takingPart.warning.body")}</p>
        </WarningCallout>
        <Label size="l">
          {T("containers.survey.takingPart.medications.title")}
        </Label>
        <p>{T("containers.survey.takingPart.medications.paragraph1")}</p>
        <p>{T("containers.survey.takingPart.medications.paragraph2")}</p>
        <p>{T("containers.survey.takingPart.medications.paragraph3")}</p>
      </>
    );
  }
);
