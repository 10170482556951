import { AppointmentRegion } from "enums";
import { DateTime } from "luxon";
import { Duration } from "api/appointment";
import { IAccessibilityInput } from "components";
import { IAppointmentSlot, IAvailabilityLocation } from "interfaces";

interface BookingState {
  region?: AppointmentRegion;
  accessibility: IAccessibilityInput;
  location?: IAvailabilityLocation;
  postcode?: string;
  date?: DateTime;
  slot?: IAppointmentSlot;
  duration: Duration;
}

type BookingAction =
  | { type: "SET_REGION"; region: AppointmentRegion }
  | { type: "SET_ACCESSIBILITY"; accessibility: IAccessibilityInput }
  | { type: "INIT_LOCATION"; location?: IAvailabilityLocation }
  | { type: "UPDATE_LOCATION"; location: IAvailabilityLocation }
  | { type: "SET_POSTCODE"; postcode?: string }
  | { type: "RESET_LOCATION" }
  | { type: "SET_SLOT"; slot?: IAppointmentSlot }
  | { type: "SET_DATE"; date?: DateTime }
  | { type: "SET_DURATION"; duration: Duration }
  | { type: "RESET_DATE_SLOT" };

export const setRegion = (region: AppointmentRegion): BookingAction => ({
  type: "SET_REGION",
  region,
});

export const setAccessibility = (
  accessibility: IAccessibilityInput
): BookingAction => ({
  type: "SET_ACCESSIBILITY",
  accessibility,
});

export const resetLocation = (): BookingAction => ({ type: "RESET_LOCATION" });

export const initLocation = (
  location?: IAvailabilityLocation
): BookingAction => ({
  type: "INIT_LOCATION",
  location,
});
export const updateLocation = (
  location: IAvailabilityLocation
): BookingAction => ({
  type: "UPDATE_LOCATION",
  location,
});

export const setPostcode = (postcode?: string): BookingAction => ({
  type: "SET_POSTCODE",
  postcode,
});

export const setSlot = (slot?: IAppointmentSlot): BookingAction => ({
  type: "SET_SLOT",
  slot,
});

export const setDate = (date?: DateTime): BookingAction => ({
  type: "SET_DATE",
  date,
});

export const setDuration = (duration: Duration): BookingAction => ({
  type: "SET_DURATION",
  duration,
});

export const resetDateSlot = (): BookingAction => ({ type: "RESET_DATE_SLOT" });

export const defaultAccessibility: IAccessibilityInput = {
  options: {
    wheelchair: false,
    parking: false,
    stepFree: false,
    interpreter: false,
    visual: false,
    hearing: false,
    signLanguage: false,
    none: false,
  },
  interpreterLanguage: "NOT_APPLICABLE",
};

export const defaultDuration = (): Duration => {
  return parseInt(
    DateTime.now() <
      DateTime.fromISO(
        process.env.REACT_APP_Y1_START_DATE || "2022-08-31T00:00:00+01:00"
      )
      ? process.env.REACT_APP_BOOK_APPOINTMENT_DEFAULT_DURATION_D1 || "30"
      : process.env.REACT_APP_BOOK_APPOINTMENT_DEFAULT_DURATION_Y1 || "15",
    10
  ) as Duration;
};

export const initialBookingState = (): BookingState => {
  return {
    accessibility: defaultAccessibility,
    duration: defaultDuration(),
  };
};

export function bookingReducer(
  state: BookingState,
  action: BookingAction
): BookingState {
  switch (action.type) {
    case "SET_REGION":
      return { ...state, region: action.region };
    case "SET_ACCESSIBILITY":
      return { ...state, accessibility: action.accessibility };
    case "RESET_LOCATION":
      return {
        ...state,
        date: undefined,
        slot: undefined,
        location: undefined,
        duration: defaultDuration(),
      };
    case "INIT_LOCATION":
      return {
        ...state,
        slot: undefined,
        location: action.location,
      };
    case "UPDATE_LOCATION":
      return {
        ...state,
        date: undefined,
        slot: undefined,
        location: action.location,
      };
    case "SET_POSTCODE":
      return {
        ...state,
        postcode: action.postcode,
      };
    case "SET_SLOT":
      return {
        ...state,
        slot: action.slot,
      };
    case "SET_DATE":
      return {
        ...state,
        date: action.date,
      };
    case "SET_DURATION":
      return {
        ...state,
        duration: action.duration,
      };
    case "RESET_DATE_SLOT":
      return {
        ...state,
        date: undefined,
        slot: undefined,
      };
  }
}
