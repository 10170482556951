import React from "react";
import { BiologicalSex } from "../../enums";
import { Radios } from "nhsuk-react-components";
import { T } from "i18n";

interface SexElementIDs {
  biologicalSex?: string;
}

interface SexInputProps {
  biologicalSex?: BiologicalSex;
  biologicalSexChanged?: (newVal: BiologicalSex) => void;
  biologicalSexError?: string;
  elementIDs?: SexElementIDs;
}

export const BiologicalSexInput = ({
  biologicalSex,
  biologicalSexChanged,
  biologicalSexError,
  elementIDs,
}: SexInputProps): JSX.Element => {
  return (
    <Radios
      data-testid="biological-sex-radios"
      name="biologicalSex"
      id={elementIDs?.biologicalSex}
      label={T("components.biologicalSexInput.fields.biologicalSex.label")}
      aria-label={T("components.biologicalSexInput.fields.biologicalSex.label")}
      hint={T("components.biologicalSexInput.fields.biologicalSex.hint")}
      value={biologicalSex}
      error={biologicalSexError}
    >
      <Radios.Radio
        data-testid="male"
        onChange={(e) =>
          biologicalSexChanged &&
          biologicalSexChanged(e.currentTarget.value as BiologicalSex)
        }
        checked={biologicalSex === BiologicalSex.MALE}
        aria-label={T(
          "components.biologicalSexInput.fields.biologicalSex.options.male"
        )}
        value={BiologicalSex.MALE}
      >
        {T("components.biologicalSexInput.fields.biologicalSex.options.male")}
      </Radios.Radio>
      <Radios.Radio
        data-testid="female"
        onChange={(e) =>
          biologicalSexChanged &&
          biologicalSexChanged(e.currentTarget.value as BiologicalSex)
        }
        checked={biologicalSex === BiologicalSex.FEMALE}
        aria-label={T(
          "components.biologicalSexInput.fields.biologicalSex.options.female"
        )}
        value={BiologicalSex.FEMALE}
      >
        {T("components.biologicalSexInput.fields.biologicalSex.options.female")}
      </Radios.Radio>
    </Radios>
  );
};
