import React from "react";
import { Button } from "nhsuk-react-components";
import { T } from "i18n";

interface SurveyResultsEligibleProps {
  onContinue(): void;
}

export const SurveyResultsEligible = ({
  onContinue,
}: SurveyResultsEligibleProps): JSX.Element => (
  <>
    <h1 data-testid="survey-eligible-title">
      {T("containers.survey.eligible.title")}
    </h1>
    <p>{T("containers.survey.eligible.description")}</p>
    <Button
      className="nhsuk-button"
      onClick={(
        e:
          | React.MouseEvent<HTMLAnchorElement>
          | React.MouseEvent<HTMLButtonElement>
      ) => {
        e.preventDefault();
        onContinue();
      }}
      data-testid="survey-eligible-continue"
      aria-label={T("containers.survey.eligible.actions.continue.label")}
    >
      {T("containers.survey.eligible.actions.continue.label")}
    </Button>
  </>
);
