import React, { useCallback, useState } from "react";
import {
  BackLink,
  Button,
  Input,
  InsetText,
  WarningCallout,
} from "nhsuk-react-components";
import { ErrorPanel, Loader } from "../../components";
import { T } from "../../i18n";
import { useAPI } from "../../api";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";

export const DeleteData = (): JSX.Element => {
  const { push } = useHistory();
  const { cohortId } = useParams<{ cohortId: string }>();
  const [confirmDeleteText, setConfirmDeleteText] = useState<string>("");
  const [deletionInProgress, setDeletionInProgress] = useState<boolean>(false);
  const [dataDeleted, setDataDeleted] = useState<boolean>(false);
  const [dataDeleteError, setDataDeleteError] = useState<Error>();

  const dataAPI = useAPI("data");
  const deleteData = useCallback((): void => {
    setDeletionInProgress(true);
    dataAPI
      .delete(cohortId)
      .then(() => setDataDeleted(true))
      .catch(setDataDeleteError)
      .finally(() => setDeletionInProgress(false));
  }, [cohortId, dataAPI]);

  return (
    <main className="nhsuk-main-wrapper">
      <BackLink
        onClick={() => push(`/participant/${cohortId}`)}
        data-testid="delete-back-link"
        aria-label={T("containers.wizard.previous")}
      >
        {T("containers.wizard.previous")}
      </BackLink>

      <h3>{T("containers.dataDeletion.title")}</h3>

      <WarningCallout
        label={T("containers.dataDeletion.warning.label")}
        data-testid="warning-text"
      >
        <WarningCallout.Label>
          {T("containers.dataDeletion.warning.label")}
        </WarningCallout.Label>
        <p>{T("containers.dataDeletion.warning.text")}</p>
      </WarningCallout>

      <Input
        disabled={dataDeleted}
        type="text"
        data-testid="confirm-delete-data-input"
        value={confirmDeleteText}
        onChange={(e) => setConfirmDeleteText(e.currentTarget.value)}
        width="20"
      />

      <Button
        disabled={
          confirmDeleteText !== "delete" || deletionInProgress || dataDeleted
        }
        data-testid="confirm-delete-data-button"
        style={{ background: "#d5281b" }}
        onClick={() => {
          deleteData();
        }}
      >
        {T("containers.dataDeletion.button")}
      </Button>
      {deletionInProgress && <Loader />}

      {dataDeleteError && (
        <ErrorPanel
          title={T("containers.dataDeletion.error")}
          label={T("containers.dataDeletion.error")}
        >
          {dataDeleteError.message}
        </ErrorPanel>
      )}

      {dataDeleted && (
        <InsetText data-testid="data-deleted-confirmation">
          {T("containers.dataDeletion.confirmation")}
        </InsetText>
      )}
    </main>
  );
};
