import React, { useCallback, useMemo } from "react";
import {
  Button,
  Card,
  Checkboxes,
  Fieldset,
  InsetText,
  Radios,
} from "nhsuk-react-components";
import { CaseContact, ECaseContactType, IAddress } from "interfaces";
import { T } from "i18n";
import { ResultNotes } from "./ResultNotes";
import { DateTime } from "luxon";
import { Address } from "components";

const attemptLabel = (attemptNumber: number): string => {
  return T("containers.resultsCase.participantCard.attempt", { attemptNumber });
};
const participantNotifiedLabel = T(
  "containers.resultsCase.participantCard.participantNotified"
);
const callUnsuccessfulLabel = T(
  "containers.resultsCase.participantCard.callUnsuccessful"
);

type IndexedCaseContact = CaseContact & { index: number };

const participantNotifiedIn = (history: CaseContact[]) => {
  return (
    history.length > 0 &&
    history[history.length - 1].type ===
      ECaseContactType.CaseContactCallSuccessful
  );
};

const orderedCalls = (cases: CaseContact[]): IndexedCaseContact[] => {
  return cases
    .map((caseContact, i) => ({ index: i, ...caseContact }))
    .filter((caseContact) =>
      [
        ECaseContactType.CaseContactCallSuccessful,
        ECaseContactType.CaseContactCallNoResponse,
      ].includes(caseContact.type)
    )
    .sort((a, b) => a.date.toSeconds() - b.date.toSeconds());
};

const findLetter = (cases: CaseContact[]): CaseContact | undefined =>
  cases.find(
    (caseContact) => caseContact.type === ECaseContactType.CaseContactLetterSent
  );

const ExistingAttempts = ({
  existingCallHistory,
}: {
  existingCallHistory: CaseContact[];
}): JSX.Element => {
  return (
    <>
      {existingCallHistory.map((caseContact, i) => (
        <Fieldset key={caseContact.type + caseContact.date.toString()}>
          <Radios disabled inline label={attemptLabel(i + 1)}>
            <Radios.Radio
              checked={
                caseContact.type === ECaseContactType.CaseContactCallSuccessful
              }
            >
              {participantNotifiedLabel}
            </Radios.Radio>
            <Radios.Radio
              checked={
                caseContact.type === ECaseContactType.CaseContactCallNoResponse
              }
              data-testid={`participant-call-unsuccessful-${i + 1}`}
            >
              {callUnsuccessfulLabel}
            </Radios.Radio>
          </Radios>
          <p data-testid="case-contact-timestamp">
            {caseContact.date.toFormat("dd MMM yyyy hh:mm a")}
          </p>
        </Fieldset>
      ))}
    </>
  );
};

const NewAttempts = ({
  newCallHistory,
  previousAttempts,
  updateCaseContact,
}: {
  newCallHistory: IndexedCaseContact[];
  previousAttempts: number;
  updateCaseContact: (index: number, type: ECaseContactType) => void;
}): JSX.Element => {
  return (
    <>
      {newCallHistory.map((caseContact, i) => (
        <Radios
          inline
          label={attemptLabel(previousAttempts + i + 1)}
          key={caseContact.type + caseContact.date.toString()}
        >
          <Radios.Radio
            checked={
              caseContact.type === ECaseContactType.CaseContactCallSuccessful
            }
            onChange={() =>
              updateCaseContact(
                caseContact.index,
                ECaseContactType.CaseContactCallSuccessful
              )
            }
          >
            {participantNotifiedLabel}
          </Radios.Radio>
          <Radios.Radio
            checked={
              caseContact.type === ECaseContactType.CaseContactCallNoResponse
            }
            onChange={() =>
              updateCaseContact(
                caseContact.index,
                ECaseContactType.CaseContactCallNoResponse
              )
            }
            data-testid={`participant-call-unsuccessful-${
              previousAttempts + i + 1
            }`}
          >
            {callUnsuccessfulLabel}
          </Radios.Radio>
        </Radios>
      ))}
    </>
  );
};

const NextAttempts = ({
  currentAttempts,
  addCaseContact,
}: {
  currentAttempts: number;
  addCaseContact: (type: ECaseContactType) => void;
}): JSX.Element => {
  return (
    <>
      <Radios inline label={attemptLabel(currentAttempts + 1)}>
        <Radios.Radio
          checked={false}
          onChange={() =>
            addCaseContact(ECaseContactType.CaseContactCallSuccessful)
          }
        >
          {participantNotifiedLabel}
        </Radios.Radio>
        <Radios.Radio
          checked={false}
          data-testid={`participant-call-unsuccessful-${currentAttempts + 1}`}
          onChange={() =>
            addCaseContact(ECaseContactType.CaseContactCallNoResponse)
          }
        >
          {callUnsuccessfulLabel}
        </Radios.Radio>
      </Radios>
      <InactiveAttempt attemptNumber={currentAttempts + 2} />
    </>
  );
};

const InactiveAttempt = ({
  attemptNumber,
}: {
  attemptNumber: number;
}): JSX.Element => {
  return (
    <Radios
      inline
      label={attemptLabel(attemptNumber)}
      labelProps={{ style: { opacity: 0.5 } }}
    >
      <Radios.Radio disabled>{participantNotifiedLabel}</Radios.Radio>
      <Radios.Radio disabled>{callUnsuccessfulLabel}</Radios.Radio>
    </Radios>
  );
};

const LetterSuggestion = ({
  name,
  address,
  existingLetter,
  newLetter,
  addCaseContact,
}: {
  name: string;
  address: IAddress;
  existingLetter?: CaseContact;
  newLetter?: CaseContact;
  addCaseContact: (type: ECaseContactType) => void;
}) => {
  return (
    <InsetText>
      <p>{T("containers.resultsCase.participantCard.letterPrompt")}</p>
      {name}
      <br />
      <Address address={address} properCase />
      <br />
      <div style={{ marginTop: "32px" }}>
        <Button href="https://docmail.co.uk" target="_blank" secondary>
          {T("containers.resultsCase.document.openDocmail")}
        </Button>
        <Checkboxes>
          <Checkboxes.Box
            checked={!!existingLetter || !!newLetter}
            disabled={!!existingLetter || !!newLetter}
            onClick={() =>
              addCaseContact(ECaseContactType.CaseContactLetterSent)
            }
            data-testid="case-contact-letter-checkbox"
          >
            {T("containers.resultsCase.participantCard.letterSent")}
          </Checkboxes.Box>
        </Checkboxes>
        {existingLetter && (
          <p data-testid="case-contact-letter-timestamp">
            {existingLetter.date.toFormat("dd MMM yyyy hh:mm a")}
          </p>
        )}
      </div>
    </InsetText>
  );
};

export const CallParticipant = ({
  caseReference,
  existingContactHistory,
  newContactHistoryEntries,
  setNewContactHistoryEntries,
  participantName,
  participantPhoneNumber,
  participantMobilePhoneNumber,
  participantAddress,
}: {
  caseReference: string;
  existingContactHistory: CaseContact[];
  newContactHistoryEntries: CaseContact[];
  setNewContactHistoryEntries: (input: CaseContact[]) => void;
  participantName: string;
  participantPhoneNumber: string;
  participantMobilePhoneNumber: string | undefined;
  participantAddress: IAddress;
}): JSX.Element => {
  const [existingCallHistory, existingLetter] = useMemo(
    () => [
      orderedCalls(existingContactHistory),
      findLetter(existingContactHistory),
    ],
    [existingContactHistory]
  );
  const [newCallHistory, newLetter] = useMemo(
    () => [
      orderedCalls(newContactHistoryEntries),
      findLetter(newContactHistoryEntries),
    ],
    [newContactHistoryEntries]
  );
  const addCaseContact = useCallback(
    (type: ECaseContactType) => {
      setNewContactHistoryEntries([
        ...newContactHistoryEntries,
        { type, date: DateTime.now(), note: "" },
      ]);
    },
    [setNewContactHistoryEntries, newContactHistoryEntries]
  );
  const updateCaseContact = useCallback(
    (updateIndex: number, type: ECaseContactType) => {
      let updatedCases = newContactHistoryEntries.map((caseContact, index) => {
        if (index !== updateIndex) {
          return caseContact;
        }
        return { ...caseContact, type };
      });
      if (type === ECaseContactType.CaseContactCallSuccessful) {
        // call successful is terminal state so remove all calls after
        updatedCases = updatedCases.filter((caseContact, index) => {
          return (
            index <= updateIndex ||
            caseContact.type === ECaseContactType.CaseContactNote
          );
        });
      }
      setNewContactHistoryEntries(updatedCases);
    },
    [setNewContactHistoryEntries, newContactHistoryEntries]
  );

  return (
    <Card feature>
      <Card.Content>
        <Card.Heading>
          {T("containers.resultsCase.participantCard.title")}
        </Card.Heading>
        <h4>{T("containers.resultsCase.participantCard.contactDetails")}</h4>
        <p
          style={{ marginBottom: 0 }}
          data-testid="result-call-participant-participant-name"
        >
          {participantName}
        </p>
        <p
          style={{ marginBottom: 0 }}
          data-testid="result-call-participant-participant-phone-number"
        >
          {participantPhoneNumber}
        </p>
        {participantMobilePhoneNumber && (
          <>
            <p
              style={{ marginBottom: 0 }}
              data-testid="result-call-participant-participant-mobile-phone-number"
            >
              {participantMobilePhoneNumber}
            </p>
          </>
        )}
        <br />
        <h4>
          {T("containers.resultsCase.participantCard.attemptsSubheading")}
        </h4>
        <ExistingAttempts existingCallHistory={existingCallHistory} />
        {!participantNotifiedIn(existingCallHistory) && (
          <>
            <NewAttempts
              newCallHistory={newCallHistory}
              previousAttempts={existingCallHistory.length}
              updateCaseContact={updateCaseContact}
            />
            {!participantNotifiedIn(newCallHistory) && (
              <NextAttempts
                currentAttempts={
                  existingCallHistory.length + newCallHistory.length
                }
                addCaseContact={addCaseContact}
              />
            )}
          </>
        )}
        {existingCallHistory.length + newCallHistory.length >= 3 &&
          !participantNotifiedIn(existingCallHistory) &&
          !participantNotifiedIn(newCallHistory) && (
            <LetterSuggestion
              name={participantName}
              address={participantAddress}
              existingLetter={existingLetter}
              newLetter={newLetter}
              addCaseContact={addCaseContact}
            />
          )}
        <br />
        <ResultNotes caseReference={caseReference} />
      </Card.Content>
    </Card>
  );
};
