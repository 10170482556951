import { useCallback, useEffect, useReducer, useRef } from "react";

export enum AsyncStatus {
  IDLE,
  PENDING,
  SUCCESS,
  ERROR,
}

interface AsyncState<T, E> {
  status: AsyncStatus;
  value: T | null;
  error: E | null;
}

export interface AsyncResult<T, E> extends AsyncState<T, E> {
  execute: () => void;
}

const INITIAL_STATE = {
  status: AsyncStatus.IDLE,
  value: null,
  error: null,
};

const SET_VALUE = "SET_VALUE" as const;
const SET_ERROR = "SET_ERROR" as const;
const SET_STATUS = "SET_STATUS" as const;

type AsyncRegistrationEvent<T, E> =
  | { type: typeof SET_VALUE; payload: T | null }
  | { type: typeof SET_ERROR; payload: E | null }
  | { type: typeof SET_STATUS; payload: AsyncStatus };

function reducer<T, E>(
  state: AsyncState<T, E>,
  event: AsyncRegistrationEvent<T, E>
): AsyncState<T, E> {
  switch (event.type) {
    case SET_ERROR:
      return { ...state, error: event.payload };
    case SET_VALUE:
      return { ...state, value: event.payload };
    case SET_STATUS:
      return { ...state, status: event.payload };
    default:
      return state;
  }
}

export const useAsync = <T, E = string>(
  asyncFunction: () => Promise<T>,
  immediate = true
): AsyncResult<T, E> => {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  });

  const [{ value, error, status }, dispatch] = useReducer<
    (
      state: AsyncState<T, E>,
      event: AsyncRegistrationEvent<T, E>
    ) => AsyncState<T, E>
  >(reducer, INITIAL_STATE);

  const execute = useCallback(() => {
    dispatch({ type: SET_STATUS, payload: AsyncStatus.PENDING });
    dispatch({ type: SET_VALUE, payload: null });
    dispatch({ type: SET_ERROR, payload: null });

    return asyncFunction()
      .then((response: any) => {
        if (isMounted.current) {
          dispatch({ type: SET_STATUS, payload: AsyncStatus.SUCCESS });
          dispatch({ type: SET_VALUE, payload: response });
          dispatch({ type: SET_ERROR, payload: null });
        }
      })
      .catch((error) => {
        if (isMounted.current) {
          dispatch({ type: SET_STATUS, payload: AsyncStatus.ERROR });
          dispatch({ type: SET_VALUE, payload: null });
          dispatch({ type: SET_ERROR, payload: error });
        }
      });
  }, [asyncFunction, isMounted]);

  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);

  return { execute, status, value, error } as const;
};
