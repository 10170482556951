import { IUser, Role } from "./IUser";

export const hasRole = (user: IUser | undefined, roles: Role[]): boolean => {
  if (user === undefined || !Array.isArray(user.roles)) {
    return false;
  }

  return roles.some((role) => user.roles.includes(role));
};

export const isInvitee = (user: IUser | undefined): boolean => {
  return !hasRole(user, [
    "KCL Admin",
    "KCL Research Nurse",
    "Call Centre Agent",
    "EMS Central Team",
    "EMS Unit Staff",
    "GRAIL User",
    "GRAIL User (Unblinded)",
    "KCL Admin (Unblinded)",
    "KCL Monitor",
  ]);
};
