export const formatCapitalisation = (
  input: string,
  separator = " "
): string => {
  return input
    .split(separator)
    .filter((word) => word !== "")
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1).toLowerCase();
    })
    .join(" ");
};

export const capitaliseFirstLetter = (input: string): string => {
  return input[0].toUpperCase() + input.slice(1);
};
