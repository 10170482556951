import React from "react";
import { AsyncCall, CallState } from "helpers/AsyncCall";
import { Button, SummaryList } from "nhsuk-react-components";
import { ContactStatus } from "api/people";
import { ErrorPanel, Loader } from "components";
import { T } from "i18n";

interface NotificationsProps {
  contactStatus: AsyncCall<ContactStatus>;
  onEdit: () => void;
}

export function Notifications({
  contactStatus,
  onEdit,
}: NotificationsProps): JSX.Element {
  const contactStatusText = (status: boolean): string => {
    return status
      ? T("components.person.contactStatus.enabled")
      : T("components.person.contactStatus.optOut");
  };

  switch (contactStatus.state) {
    case CallState.Error:
      return (
        <ErrorPanel
          data-testid="notifications-error"
          title="Error"
          label="Notification Error"
        >
          {contactStatus.error.message}
        </ErrorPanel>
      );
    case CallState.Loading:
      return <Loader />;
    case CallState.NotCalled:
      return <></>;
    case CallState.Ok:
      return (
        <>
          <h4 data-testid="ContactTitle">
            {T("components.person.contactStatus.read.title")}
          </h4>
          <SummaryList>
            <SummaryList.Row>
              <SummaryList.Key>
                {T("components.person.contactStatus.email")}
              </SummaryList.Key>
              <SummaryList.Value>
                {contactStatusText(contactStatus.result.canEmail)}
              </SummaryList.Value>
            </SummaryList.Row>
            <SummaryList.Row>
              <SummaryList.Key>
                {T("components.person.contactStatus.sms")}
              </SummaryList.Key>
              <SummaryList.Value>
                {contactStatusText(contactStatus.result.canSMS)}
              </SummaryList.Value>
            </SummaryList.Row>
          </SummaryList>
          <Button onClick={onEdit} data-testid="EditNotification">
            {T("components.person.contactStatus.read.edit")}
          </Button>
        </>
      );
  }
}
