import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { ErrorPanel, Loader } from "components";
import { IUnitLocation } from "interfaces";
import { LocationsList } from "./LocationsList";
import { useAPI } from "api";

export interface UnitPickerProps {
  date: DateTime;
  onSelectUnit: (unit: IUnitLocation) => void;
}

export function UnitPicker({
  date,
  onSelectUnit,
}: UnitPickerProps): JSX.Element {
  const [unitLocations, setUnitLocations] = useState<IUnitLocation[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();

  const unitAPI = useAPI("unit");
  const showOnlyAccessibleLocations = false;

  useEffect(() => {
    setLoading(true);
    unitAPI
      .findUnitLocationsByDate(date, showOnlyAccessibleLocations)
      .then(setUnitLocations)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [date, showOnlyAccessibleLocations, unitAPI]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {error && <ErrorPanel>{error.message}</ErrorPanel>}
      {unitLocations && (
        <LocationsList
          locations={unitLocations}
          onSelect={(unit: IUnitLocation): void => {
            onSelectUnit(unit);
          }}
        />
      )}
    </>
  );
}
