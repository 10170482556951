import React from "react";
import { ErrorSummary } from "nhsuk-react-components";

interface ErrorMessageProps {
  title?: React.ReactNode;
  label?: string;
}

export const ErrorMessage = ({
  title,
  children,
  label = "error",
  ...rest
}: React.PropsWithChildren<ErrorMessageProps>): JSX.Element => (
  <ErrorSummary aria-labelledby={label} role="alert" tabIndex={-1} {...rest}>
    {title && <ErrorSummary.Title>{title}</ErrorSummary.Title>}
    <ErrorSummary.Body data-testid="error-body">{children}</ErrorSummary.Body>
  </ErrorSummary>
);
