import React from "react";
import {
  ErrorMessage,
  Hint,
  Input,
  Label,
  Select,
} from "nhsuk-react-components";
import { getCallingCodesSet, splitPhoneNumber } from "../helpers/phoneNumber";

interface PhoneNumberInputProps {
  phoneNumber: string;
  onChange: (phoneNumber: string) => void;
  error: string;
  defaultCallingCode: string;
  label: string;
  hint?: string;
  "data-testid": string;
  disabled?: boolean;
}

const callingCodes = Array.from(getCallingCodesSet()).sort();

export const PhoneNumberInput = ({
  phoneNumber,
  onChange,
  defaultCallingCode,
  error,
  label,
  hint,
  "data-testid": dataTestId,
  disabled = false,
}: PhoneNumberInputProps): JSX.Element => {
  const { callingCode, nationalNumber } = splitPhoneNumber(
    phoneNumber,
    defaultCallingCode
  );

  const setCallingCode = (newValue: string): void => {
    onChange(`${newValue} ${nationalNumber}`);
  };

  const setNationalNumber = (newValue: string): void => {
    onChange(`${callingCode} ${newValue}`);
  };

  return (
    <>
      {error && (
        <ErrorMessage data-testid={`${dataTestId}-error-message`}>
          {error}
        </ErrorMessage>
      )}
      <Label>{label}</Label>
      <Hint>{hint}</Hint>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
        }}
      >
        <Select
          data-testid={`${dataTestId}-calling-code`}
          onChange={({ currentTarget: { value } }) => setCallingCode(value)}
          value={callingCode}
          disabled={disabled}
          style={{
            marginRight: "1rem",
          }}
        >
          {callingCodes.map((code) => (
            <Select.Option value={code} key={code}>
              {code}
            </Select.Option>
          ))}
        </Select>
        <Input
          type="text"
          data-testid={`${dataTestId}-national`}
          name="phoneNumber"
          value={nationalNumber}
          disabled={disabled}
          aria-label={label}
          onChange={({ currentTarget: { value } }) => setNationalNumber(value)}
          error={!!error}
          width="20"
        />
      </div>
    </>
  );
};
