import React, { useEffect } from "react";
import { Details, Input, Label } from "nhsuk-react-components";
import { FormValidity, ValiditySummary } from "../FormValidity";
import { T } from "i18n";
import { hasWords } from "../../validations";
import { useEventType } from "../../hooks";

interface FindGPFormProps {
  findGPPostcode?: string;
  findGPPostcodeChanged?: (newVal: string) => void;
  validity?: FormValidity;
  validityUpdated?: (validity: FormValidity) => void;
}

const ids = {
  findGPPostcode: "gp-search-postcode",
};

export const FindGPForm = ({
  findGPPostcode,
  findGPPostcodeChanged,
  validity = new FormValidity(),
  validityUpdated,
}: FindGPFormProps): JSX.Element => {
  const eventType = useEventType();

  useEffect(() => {
    const currentValidity = new FormValidity();

    const noPostcodeError = hasWords(
      findGPPostcode || "",
      T("components.findGPForm.search.error")
    );
    currentValidity.addError(noPostcodeError, ids.findGPPostcode);

    validityUpdated && validityUpdated(currentValidity);
  }, [findGPPostcode, validityUpdated]);

  return (
    <>
      <ValiditySummary validity={validity} />
      <p>{T("components.findGPForm.text")}</p>
      <Input
        id={ids.findGPPostcode}
        data-testid="find-gps"
        type="text"
        label={T("components.findGPForm.search.label", { context: eventType })}
        hint={T("components.findGPForm.search.hint")}
        aria-label={T("components.findGPForm.search.label", {
          context: eventType,
        })}
        width="20"
        value={findGPPostcode}
        onChange={(e) =>
          findGPPostcodeChanged && findGPPostcodeChanged(e.currentTarget.value)
        }
        error={validity.getFirstErrorForId(ids.findGPPostcode)?.message}
      />
      <Details data-testid="find-gp-postcode-accordion">
        <Details.Summary>
          {T("components.findGPForm.accordion.title")}
        </Details.Summary>
        <Details.Text>
          <p>{T("components.findGPForm.accordion.body.text")}</p>
          <p>
            {T("components.findGPForm.accordion.body.link.text")}
            &nbsp;
            <a
              href="https://www.nhs.uk/service-search/find-a-gp"
              target="_blank"
              rel="noreferrer"
            >
              {T("components.findGPForm.accordion.body.link.action")}
            </a>
          </p>
        </Details.Text>
      </Details>
    </>
  );
};

export const NoGPView = (): JSX.Element => (
  <>
    <Label isPageHeading data-testid="noGP-heading">
      {T("components.noGPView.title")}
    </Label>
    <p>{T("components.noGPView.paragraph1")}</p>
    <p>{T("components.noGPView.paragraph2")}</p>
  </>
);

export const AmendDetailsNoGPView = (): JSX.Element => (
  <>
    <Label isPageHeading data-testid="amend-details-noGP-heading">
      {T("components.amendDetailsNoGPView.title")}
    </Label>
    <p>{T("components.amendDetailsNoGPView.paragraph1")}</p>
  </>
);
