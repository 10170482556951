/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Checkboxes } from "nhsuk-react-components";

interface SubBoxProps<ValueType> {
  value: ValueType;
  checked?: boolean;
  onChange?: (value: ValueType) => void;
}

export function SubBox<ValueType>({
  value,
  checked,
  onChange,
  children,
}: React.PropsWithChildren<SubBoxProps<ValueType>>): JSX.Element {
  if (checked === undefined || onChange === undefined) {
    return <></>;
  }
  return (
    <Checkboxes.Box onChange={() => onChange(value)} checked={checked}>
      {children}
    </Checkboxes.Box>
  );
}

interface SubCheckboxesProps<ValueType> {
  selectAllLabel: string;
  selectAllHint: string;
  selected: Set<ValueType>;
  setSelected: React.Dispatch<React.SetStateAction<Set<ValueType>>>;
}

export function SubCheckboxes<ValueType>({
  selectAllLabel,
  selectAllHint,
  selected,
  setSelected,
  children,
}: React.PropsWithChildren<SubCheckboxesProps<ValueType>>): JSX.Element {
  const [expanded, setExpanded] = useState(false);

  const allValues: ValueType[] = useMemo(() => {
    return React.Children.toArray(children).reduce(
      (acc: ValueType[], child) => {
        if (!React.isValidElement(child)) {
          return acc;
        }
        const { value } = child.props;
        return [...acc, value as ValueType];
      },
      []
    );
  }, [children]);

  const allSelected = allValues.every((val) => selected.has(val));

  const toggleAll = () => {
    const updatedSelected = new Set(selected);
    if (allSelected) {
      allValues.forEach((val) => updatedSelected.delete(val));
    } else {
      allValues.forEach((val) => updatedSelected.add(val));
    }
    setSelected(updatedSelected);
  };

  const toggle = (eventType: ValueType) => {
    const updatedSelected = new Set(selected);
    selected.has(eventType)
      ? updatedSelected.delete(eventType)
      : updatedSelected.add(eventType);
    setSelected(updatedSelected);
  };

  const inputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (!inputRef.current) return;
    const noneSelected = allValues.every((val) => !selected.has(val));
    if (allSelected || noneSelected) {
      inputRef.current.indeterminate = false;
    } else {
      const someSelected = allValues.some((val) => selected.has(val));
      if (someSelected) {
        inputRef.current.indeterminate = true;
      }
    }
  }, [allSelected, allValues, selected]);

  return (
    <div style={{ display: "flex" }}>
      <div
        className={expanded ? "expand-arrow-down" : "expand-arrow-up"}
        style={{ marginRight: "22px", marginTop: "13px" }}
        onClick={() => setExpanded(!expanded)}
        data-testid="expand-checkboxes"
      />
      <Checkboxes>
        <Checkboxes.Box
          hint={selectAllHint}
          onClick={toggleAll}
          checked={allSelected}
          /* see https://github.com/NHSDigital/nhsuk-react-components/issues/126 */
          /*
       // @ts-ignore */
          inputRef={inputRef}
          conditional={
            expanded ? (
              <Checkboxes>
                {React.Children.map(children, (child) => {
                  if (!React.isValidElement(child)) return;
                  const { value } = child.props;
                  return React.cloneElement(child, {
                    checked: selected.has(value),
                    onChange: () => toggle(value),
                  });
                })}
              </Checkboxes>
            ) : null
          }
          forceShowConditional={expanded}
        >
          {selectAllLabel}
        </Checkboxes.Box>
      </Checkboxes>
    </div>
  );
}
