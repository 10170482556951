import { UpdatePayload } from "api/people";

import React, { FormEvent, SyntheticEvent, useState } from "react";
import {
  AddressInput,
  ErrorPanel,
  FormValidity,
  PersonalPhoneNumberInput,
} from "components";
import { BiologicalSex } from "enums";
import {
  Button,
  DateInput,
  Fieldset,
  Form,
  Input,
  Radios,
} from "nhsuk-react-components";
import { GpFinder } from "containers/Registration/GpFinder";
import { IDateInputValue, IPerson } from "interfaces";
import { MaskedInput } from "nhsuk-react-components-extensions";
import { T } from "i18n";
import { dateInputToDateTime, nhsNumberInputToString } from "helpers";
import {
  hasWords,
  isValidBiologicalSex,
  isValidBirthDate,
  isValidEmail,
  isValidNHSNumber,
  isValidUKMobilePhoneNumber,
  isValidUKPhoneNumber,
} from "validations";
import { useAddress, useGPAddress } from "hooks";

interface EditPersonProps {
  disableErrorFromComponents?: boolean;
  initialValues: IPerson;
  onCancel?: () => void;
  onSubmit: (payload: UpdatePayload) => Promise<string | void>;
}

export const EditPersonForm = ({
  onCancel,
  onSubmit,
  initialValues,
  disableErrorFromComponents = false,
}: EditPersonProps): JSX.Element => {
  const [firstName, setFirstName] = useState(initialValues.firstName);
  const [firstNameError, setFirstNameError] = useState("");

  const [lastName, setLastName] = useState(initialValues.lastName);
  const [lastNameError, setLastNameError] = useState("");

  const { dateOfBirth } = initialValues;
  const [dateOfBirthInput, setDateOfBirthInput] = useState<IDateInputValue>({
    day: String(dateOfBirth.day),
    month: String(dateOfBirth.month),
    year: String(dateOfBirth.year),
  });
  const [dateOfBirthError, setDateOfBirthError] = useState("");

  const [biologicalSex, setBiologicalSex] = useState(
    initialValues.biologicalSex
  );
  const [biologicalSexError, setBiologicalSexError] = useState("");

  const [submissionError, setSubmissionError] = useState("");

  const [nhsNumber, setNhsNumber] = useState(initialValues.nhsNumber);
  const [nhsNumberError, setNhsNumberError] = useState("");
  const [gpName, setGpName] = useState(initialValues.gpName);

  const [gpPracticeName, setGpPracticeName] = useState(
    initialValues.gpPractice.name
  );
  const [gpPracticeNameError, setGpPracticeNameError] = useState("");
  const [gpOrgId, setGpOrgId] = useState<string | null>(
    initialValues.gpPractice.orgId
  );

  const [formValidity, setFormValidity] = useState<FormValidity>(
    new FormValidity()
  );
  const [gpAddress, gpAddressSetter, gpFieldIDs, validateGPAddress] =
    useGPAddress(initialValues.gpPractice.address);

  const [
    houseAddress,
    houseAddressSetter,
    houseAddressIDs,
    validateHouseAddress,
  ] = useAddress(initialValues.mailingAddress);

  const [email, setEmail] = useState(String(initialValues.email));
  const [emailError, setEmailError] = useState("");

  const [phoneNumber, setPhoneNumber] = useState(
    String(initialValues.phoneNumber)
  );
  const [phoneNumberError, setPhoneNumberError] = useState("");

  const [mobilePhoneNumber, setMobilePhoneNumber] = useState(
    String(initialValues.mobilePhoneNumber)
  );
  const [mobilePhoneNumberError, setMobilePhoneNumberError] = useState("");

  const formIsFilled = (): boolean => {
    const values = [
      firstName,
      lastName,
      dateOfBirthInput.day,
      dateOfBirthInput.month,
      dateOfBirthInput.year,
      nhsNumber,
      biologicalSex,
      phoneNumber,
      houseAddress.address1,
      houseAddress.postcode,
      houseAddress.townOrCity,
      gpAddress.address1,
      gpAddress.postcode,
      gpAddress.townOrCity,
      gpPracticeName,
    ];

    return !values.some((value) => value === "");
  };

  const updateEmail = (event: React.FormEvent<HTMLInputElement>): void => {
    const nextEmail = event.currentTarget.value;
    setEmail(nextEmail);
  };

  const onSubmitForm = async (
    e: SyntheticEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();

    setSubmissionError("");

    const firstNameError = hasWords(
      firstName,
      T("containers.registration.fields.firstName.errors.empty")
    );
    setFirstNameError(firstNameError);

    const lastNameError = hasWords(
      lastName,
      T("containers.registration.fields.lastName.errors.empty")
    );
    setLastNameError(lastNameError);

    const practiceNameError = hasWords(
      gpPracticeName,
      T("containers.registration.fields.gpPracticeName.errors.empty")
    );
    setGpPracticeNameError(practiceNameError);

    const dateOfBirthError = isValidBirthDate(
      dateOfBirthInput,
      T("containers.registration.fields.dateOfBirth.errors.invalid")
    );
    setDateOfBirthError(dateOfBirthError);

    const biologicalSexError = isValidBiologicalSex(
      biologicalSex,
      T("containers.registration.fields.biologicalSex.errors.invalid")
    );
    setBiologicalSexError(biologicalSexError);

    const emailError = isValidEmail(
      email,
      T("containers.registration.fields.email.errors.invalid")
    );
    setEmailError(emailError);

    const phoneNumberError = isValidUKPhoneNumber(
      phoneNumber,
      T("containers.registration.fields.phoneNumber.errors.invalid")
    );
    setPhoneNumberError(phoneNumberError);

    const mobilePhoneNumberError = isValidUKMobilePhoneNumber(
      mobilePhoneNumber,
      T("containers.registration.fields.mobilePhoneNumber.errors.invalid")
    );
    setMobilePhoneNumberError(mobilePhoneNumberError);

    const nhsNumberError = isValidNHSNumber(
      nhsNumber,
      T("containers.registration.fields.nhsNumber.errors.invalid")
    );
    setNhsNumberError(nhsNumberError);

    const validity = new FormValidity();
    if (gpOrgId === null) {
      validateGPAddress(validity);
    }
    validateHouseAddress(validity);

    setFormValidity(validity);

    const formHasErrors =
      !validity.valid ||
      [
        firstNameError,
        lastNameError,
        phoneNumberError,
        mobilePhoneNumberError,
        dateOfBirthError,
        biologicalSexError,
        emailError,
        nhsNumberError,
        gpPracticeNameError,
      ].some((value) => value !== "");

    if (formHasErrors) {
      return;
    }

    try {
      await onSubmit({
        biologicalSex:
          BiologicalSex[biologicalSex as keyof typeof BiologicalSex],
        dateOfBirth: dateInputToDateTime(dateOfBirthInput),
        firstName,
        lastName,
        mailingAddress: houseAddress,
        email,
        phoneNumber,
        mobilePhoneNumber,
        nhsNumber,
        gpName,
        gpPractice: {
          orgId: gpOrgId,
          address: gpAddress,
          name: gpPracticeName,
        },
      });
    } catch (e) {
      setSubmissionError(e.message);
    }
  };

  return (
    <>
      <Form
        onSubmit={onSubmitForm}
        disableErrorFromComponents={disableErrorFromComponents}
      >
        <Fieldset>
          <Fieldset.Legend isPageHeading>
            {T("components.person.edit.title", {
              name: `${firstName} ${lastName}`,
            })}
          </Fieldset.Legend>
        </Fieldset>

        <Input
          type="text"
          data-testid="firstName"
          label={T("containers.registration.fields.firstName.label")}
          aria-label={T("containers.registration.fields.firstName.label")}
          value={firstName}
          onChange={(e) => setFirstName(e.currentTarget.value)}
          error={firstNameError}
          width="20"
        />

        <Input
          type="text"
          label={T("containers.registration.fields.lastName.label")}
          aria-label={T("containers.registration.fields.lastName.label")}
          value={lastName}
          onChange={(e) => setLastName(e.currentTarget.value)}
          error={lastNameError}
          width="20"
        />

        <DateInput
          label={T("containers.registration.fields.dateOfBirth.label")}
          aria-label={T("containers.registration.fields.dateOfBirth.label")}
          value={dateOfBirthInput}
          error={dateOfBirthError}
          onChange={(e) => setDateOfBirthInput(e.currentTarget.value)}
        />

        <Radios
          name="sex"
          label={T("containers.registration.fields.biologicalSex.label")}
          aria-label={T("containers.registration.fields.biologicalSex.label")}
          inline
          value={biologicalSex}
          error={biologicalSexError}
        >
          <Radios.Radio
            checked={biologicalSex === BiologicalSex.MALE.toString()}
            onChange={(e) =>
              setBiologicalSex(e.currentTarget.value as BiologicalSex.MALE)
            }
            aria-label={T(
              "containers.registration.fields.biologicalSex.options.male"
            )}
            value={BiologicalSex.MALE.toString()}
          >
            {T("containers.registration.fields.biologicalSex.options.male")}
          </Radios.Radio>
          <Radios.Radio
            checked={biologicalSex === BiologicalSex.FEMALE.toString()}
            onChange={(e) =>
              setBiologicalSex(e.currentTarget.value as BiologicalSex.FEMALE)
            }
            aria-label={T(
              "containers.registration.fields.biologicalSex.options.female"
            )}
            value={BiologicalSex.FEMALE.toString()}
          >
            {T("containers.registration.fields.biologicalSex.options.female")}
          </Radios.Radio>
        </Radios>

        <MaskedInput
          type="text"
          label={T("containers.registration.fields.nhsNumber.label")}
          hint={T("containers.registration.fields.nhsNumber.hint")}
          aria-label={T("containers.registration.fields.nhsNumber.label")}
          value={nhsNumber}
          onChange={(e: FormEvent<HTMLInputElement>) =>
            setNhsNumber(nhsNumberInputToString(e.currentTarget.value))
          }
          error={nhsNumberError}
          mask="999 999 9999"
          maskChar=""
          width="10"
        />

        <Input
          type="text" // defer to isValidEmail and use name for auto-fill
          name="email"
          label={T("containers.registration.fields.email.label")}
          aria-label={T("containers.registration.fields.email.label")}
          value={email}
          onChange={updateEmail}
          error={emailError}
          width="20"
        />

        <PersonalPhoneNumberInput
          phoneNumber={phoneNumber}
          phoneNumberChanged={setPhoneNumber}
          phoneNumberError={phoneNumberError}
          label={T("containers.registration.fields.phoneNumber.label")}
          hint={T("containers.registration.fields.phoneNumber.hint")}
          dataTestId="main-phone-number"
        />

        <PersonalPhoneNumberInput
          phoneNumber={mobilePhoneNumber}
          phoneNumberChanged={setMobilePhoneNumber}
          phoneNumberError={mobilePhoneNumberError}
          label={T("containers.registration.fields.mobilePhoneNumber.label")}
          hint={T("containers.registration.fields.mobilePhoneNumber.hint")}
          dataTestId="mobile-phone-number"
        />

        <h5>{T("containers.registration.sections.postalAddress.title")}</h5>
        <div data-testid="house-address-input" aria-label="Home address">
          <AddressInput
            {...houseAddress}
            {...houseAddressSetter}
            {...houseAddressIDs}
            componentName="addressInput"
            addressValidity={formValidity}
          />
        </div>

        <h5>{T("containers.registration.sections.findGp.title")}</h5>
        <div data-testid="gp-address-input" aria-label="GP address">
          <GpFinder
            allowOtherGp
            {...{
              gpName,
              gpNameSetter: setGpName,
              gpOrgId,
              gpOrgIdSetter: setGpOrgId,
              gpPracticeName,
              gpPracticeNameError,
              gpPracticeNameSetter: setGpPracticeName,
              gpAddress,
              gpFieldIDs,
              gpAddressSetter,
              componentName: "gpAddressInput",
              gpValidity: formValidity,
            }}
          />
        </div>

        {submissionError !== "" && (
          <ErrorPanel
            label="submission-error"
            title={
              <span id="error-summary-title">
                {T("errors.submissionErrorTitle")}
              </span>
            }
          >
            <p>{submissionError}</p>
          </ErrorPanel>
        )}

        <div>
          <Button
            type="submit"
            disabled={!formIsFilled()}
            aria-label={T("components.person.edit.actions.submit")}
          >
            {T("components.person.edit.actions.submit")}
          </Button>
        </div>
        <div>
          <Button
            secondary
            type="cancel"
            onClick={onCancel}
            disabled={!formIsFilled()}
            aria-label={T("components.person.edit.actions.cancel")}
          >
            {T("components.person.edit.actions.cancel")}
          </Button>
        </div>
      </Form>
    </>
  );
};
