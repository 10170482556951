import React, { useCallback, useEffect, useState } from "react";
import {
  AsyncCall,
  CallState,
  err,
  loading,
  notCalled,
  ok,
} from "helpers/AsyncCall";
import { INote } from "interfaces";
import { useAPI } from "api";
import { T } from "i18n";
import { Notes } from "components/Notes";
import { ErrorPanel } from "components";

interface ResultNotesProps {
  caseReference: string;
}

export const ResultNotes = ({
  caseReference,
}: ResultNotesProps): JSX.Element => {
  const [notes, setNotes] = useState<AsyncCall<INote[]>>(notCalled);
  const resultAPI = useAPI("results");
  const getNotes = useCallback((): void => {
    resultAPI
      .getNotes(caseReference)
      .then((notes) => setNotes(ok(notes)))
      .catch(({ message }) => setNotes(err(message)));
  }, [caseReference, resultAPI]);

  useEffect(() => {
    setNotes(loading);
    getNotes();
  }, [getNotes]);

  const createNote = (content: string): Promise<any> => {
    return resultAPI
      .createNote({ caseReference, content })
      .then(() => getNotes());
  };

  const page = (content: React.ReactElement) => (
    <>
      <h4>{T("containers.resultsCase.participantCard.notesHeading")}</h4>
      {content}
    </>
  );
  switch (notes.state) {
    case CallState.NotCalled:
      return page(<></>);
    case CallState.Loading:
      return page(
        <p>{T("containers.resultsCase.participantCard.notesLoading")}</p>
      );
    case CallState.Error:
      return page(
        <ErrorPanel
          title={T("containers.resultsCase.participantCard.notesError")}
          label={T("containers.resultsCase.participantCard.notesError")}
        >
          {notes.error}
        </ErrorPanel>
      );
    case CallState.Ok:
      return page(<Notes notes={notes.result} createNote={createNote} />);
  }
};
