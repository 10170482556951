import React, { useEffect, useState } from "react";
import { BloodReAttemptStatus } from "./BloodReAttempt";
import { BloodReDrawStatus } from "./BloodReDraw";
import { ErrorPanel } from "components";
import { IBloodDrawStatus } from "interfaces";
import { T } from "i18n";
import { useAPI } from "api";

export const BloodStatus = ({
  cohortId,
  onChange,
}: {
  cohortId: string;
  onChange: () => void;
}): JSX.Element => {
  const [bloodStatusError, setBloodStatusError] = useState("");
  const [reAttemptError, setReAttemptError] = useState("");
  const [reDrawError, setReDrawError] = useState("");
  const bloodAPI = useAPI("blood");
  const [status, setStatus] = useState<IBloodDrawStatus>();

  useEffect((): void => {
    bloodAPI
      .getStatus(cohortId)
      .then(setStatus)
      .catch(({ message }) => setBloodStatusError(message));
  }, [cohortId, bloodAPI, setBloodStatusError]);

  const setReAttempt = (newReAttempt: boolean): void => {
    if (status) {
      setStatus({ ...status, reAttempt: newReAttempt });
      onChange();
    }
  };

  const setReDraw = (newReDraw: boolean): void => {
    if (status) {
      setStatus({ ...status, reDraw: newReDraw });
      onChange();
    }
  };

  const resetErrors = (): void => {
    setBloodStatusError("");
    setReAttemptError("");
    setReDrawError("");
  };

  let errorPanel: JSX.Element;

  if (bloodStatusError) {
    errorPanel = (
      <ErrorPanel title="Reattempt" label={bloodStatusError}>
        {bloodStatusError}
      </ErrorPanel>
    );
  } else if (reAttemptError) {
    errorPanel = (
      <ErrorPanel
        title={T("components.person.blood.reAttempt.error.title")}
        label={reAttemptError}
      >
        {reAttemptError}
      </ErrorPanel>
    );
  } else if (reDrawError) {
    errorPanel = (
      <ErrorPanel
        title={T("components.person.blood.reDraw.error.title")}
        label={reDrawError}
      >
        {reDrawError}
      </ErrorPanel>
    );
  } else {
    errorPanel = <></>;
  }

  return (
    <>
      <h4>{T("components.person.blood.title")}</h4>
      {status !== undefined && !status.reDraw ? (
        <>
          <BloodReAttemptStatus
            reAttempt={status?.reAttempt}
            setReAttempt={setReAttempt}
            cohortId={cohortId}
            setError={(error) => {
              resetErrors();
              setReAttemptError(error);
            }}
          />
        </>
      ) : (
        <></>
      )}
      {status !== undefined && !status.reAttempt ? (
        <>
          <BloodReDrawStatus
            reDraw={status?.reDraw}
            setReDraw={setReDraw}
            cohortId={cohortId}
            setError={(error) => {
              resetErrors();
              setReDrawError(error);
            }}
          />
        </>
      ) : (
        <></>
      )}
      {errorPanel}
    </>
  );
};
