import React from "react";
import { InsetText } from "nhsuk-react-components";
import { T } from "i18n";
import { useEventType } from "../hooks";

interface TestIDProps {
  "data-testid"?: string;
}

export const CallCentreNoteParagraph = ({
  "data-testid": dataTestId,
}: TestIDProps): JSX.Element => {
  const eventType = useEventType();

  return (
    <p
      data-testid={dataTestId}
      aria-label={T("components.callCentreNote.label")}
    >
      {T("components.callCentreNote.note", { context: eventType })}
    </p>
  );
};

export const CallCentreNote = ({
  "data-testid": dataTestId,
}: TestIDProps): JSX.Element => (
  <InsetText>
    <CallCentreNoteParagraph data-testid={dataTestId} />
  </InsetText>
);
