import React from "react";
import { AppointmentType } from "enums";
import { useSearchParams } from "../../hooks";
import { YxBookingFlow } from "./YxBookingFlow";
import { D1BookingFlow } from "./D1BookingFlow";
import { useQueryBookingClosed } from "../../hooks/useQueryBookingClosed";
import { ErrorPanel, Loader } from "../../components";
import { BookingClosed } from "../../components/BookingClosed";
import { ReadingWidth } from "nhsuk-react-components";
import { T } from "../../i18n";

export interface IBookingContext {
  isRebooking: boolean;
}

export const BookingContext = React.createContext<IBookingContext>({
  isRebooking: false,
});

const BookingFlow = ({
  appointmentType,
}: {
  appointmentType: AppointmentType;
}): JSX.Element => {
  const { bookingClosed, bookingClosedError } = useQueryBookingClosed();
  if (bookingClosedError !== null) {
    return (
      <main className="nhsuk-main-wrapper">
        <ReadingWidth>
          <ErrorPanel
            title={T("components.bookingClosed.error.title")}
            label="booking-closed-error"
          >
            {bookingClosedError}
          </ErrorPanel>
        </ReadingWidth>
      </main>
    );
  }
  if (bookingClosed === undefined) {
    return <Loader />;
  } else if (bookingClosed) {
    return <BookingClosed />;
  } else {
    switch (appointmentType) {
      case AppointmentType.Y1:
      case AppointmentType.Y2:
        return <YxBookingFlow appointmentType={appointmentType} />;
      default:
        return <D1BookingFlow />;
    }
  }
};

export const Booking = (): JSX.Element => {
  const { appointmentType = AppointmentType.D1, isRebooking = false } =
    useSearchParams<{
      appointmentType?: AppointmentType;
      from?: string;
      to?: string;
      isRebooking?: boolean;
    }>();

  return (
    <BookingContext.Provider value={{ isRebooking }}>
      <BookingFlow appointmentType={appointmentType} />
    </BookingContext.Provider>
  );
};
