import { AxiosInstance } from "axios";
import { IForm } from "interfaces";

export interface IFormClient {
  getForm(
    cohortId: string,
    formType: string,
    eventType: string
  ): Promise<IForm>;
  saveForm(cohortId: string, form: IForm): Promise<void>;
}

export class FormClient implements IFormClient {
  public constructor(protected readonly http: AxiosInstance) {}

  getForm(
    cohortId: string,
    formType: string,
    eventType: string
  ): Promise<IForm> {
    return this.http
      .get(`/people/${cohortId}/forms/${formType}`, {
        params: {
          eventType,
        },
      })
      .then((response) => response.data);
  }

  saveForm(
    cohortId: string,
    { type, eventType, version, definition, answers, instanceId }: IForm
  ): Promise<void> {
    return this.http.put<any, void>(
      `/people/${cohortId}/forms/${type}`,
      {
        definition,
        answers,
        version,
        instanceId,
      },
      {
        params: {
          eventType,
        },
      }
    );
  }
}
