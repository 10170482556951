import React, { useMemo, useState } from "react";
import {
  Button,
  DateInput,
  Label,
  Radios,
  Select,
} from "nhsuk-react-components";
import { DateTime } from "luxon";
import { ErrorPanel } from "components";
import { IDateInputValue } from "interfaces";
import { ReportType } from "enums";
import { Role, useAuth } from "../../auth";
import { T } from "i18n";
import { dateInputToDateTime } from "helpers";
import { hasRole } from "../../auth/hasRole";
import { isValidDate } from "validations";
import { macroCaseToCamelCase } from "../../helpers/letterCase";
import { useAPI } from "api";

const reportTypeUserRoleAccess: { [key in ReportType]: Role[] } = {
  [ReportType.Appointments]: ["KCL Admin", "KCL Scheduling Support"],
  [ReportType.BloodDrawsPerDay]: ["GRAIL User"],
  [ReportType.Y1LabForecast]: ["GRAIL User (Unblinded)"],
  [ReportType.BloodDrawsPerUnitOrNurse]: ["KCL Admin"],
  [ReportType.FalsePositives]: ["KCL Research Nurse"],
  [ReportType.CumulativeRetention]: ["GRAIL User"],
  [ReportType.Y2CumulativeRetention]: ["GRAIL User"],
  [ReportType.MobileClinicsDailyTrends]: ["GRAIL User"],
  [ReportType.RetentionByLocationAndEthnicity]: ["GRAIL User"],
  [ReportType.RetentionByLocationAndIMD]: ["GRAIL User"],
  [ReportType.RetentionByLocationAndAgeAndGender]: ["GRAIL User"],
  [ReportType.AppointmentNotBooked]: [
    "EMS Unit Staff",
    "EMS Central Team",
    "KCL Admin",
  ],
  [ReportType.Y2MobileClinicsDailyTrends]: ["GRAIL User"],
  [ReportType.ParticipantReportedStatus]: [
    "KCL Research Nurse",
    "KCL Admin (Unblinded)",
  ],
};

const noDateReportTypes = new Set([
  ReportType.CumulativeRetention,
  ReportType.Y2CumulativeRetention,
  ReportType.RetentionByLocationAndEthnicity,
  ReportType.RetentionByLocationAndIMD,
  ReportType.RetentionByLocationAndAgeAndGender,
  ReportType.AppointmentNotBooked,
  ReportType.ParticipantReportedStatus,
]);

type DatePickerRadioOptions = "TRIAL_START" | "OTHER";
const trialStart = {
  day: "1",
  month: "8",
  year: "2021",
};

export const Report = (): JSX.Element => {
  const [error, setError] = useState<Error>();
  const [fromError, setFromError] = useState<string>("");
  const [toError, setToError] = useState<string>("");

  const [fromDateOption, setFromDateOption] =
    useState<DatePickerRadioOptions>("TRIAL_START");
  const [from, setFrom] = useState<IDateInputValue>(trialStart);
  const setFromTrialStart = (): void => {
    setFromDateOption("TRIAL_START");
    setFrom(trialStart);
  };

  const [to, setTo] = useState<IDateInputValue>({
    day: "",
    month: "",
    year: "",
  });
  const { user } = useAuth();

  const userReportTypes = useMemo(
    () =>
      Object.entries(reportTypeUserRoleAccess).reduce(
        (results: ReportType[], [reportType, allowedRoles]) => {
          if (hasRole(user, allowedRoles)) {
            results.push(reportType as ReportType);
          }
          return results;
        },
        []
      ),
    [user]
  );

  const [reportType, setReportType] = useState<ReportType>(userReportTypes[0]);

  function checkDateError(field: IDateInputValue): string {
    if (!Object.values(field).every((value) => value === "")) {
      return isValidDate(field, T("containers.reports.dateError"));
    }
    return T("containers.reports.dateMissingError");
  }

  function downloadReport(): void {
    if (reportType) {
      setError(undefined);
      const requiresDateRange = !noDateReportTypes.has(reportType);
      if (requiresDateRange) {
        const fromErrorString = checkDateError(from);
        const toErrorString = checkDateError(to);
        setFromError(fromErrorString);
        setToError(toErrorString);
        if (fromErrorString !== "" || toErrorString !== "") {
          return;
        }
        if (dateInputToDateTime(to) < dateInputToDateTime(from)) {
          setToError(T("containers.reports.dateOrderError"));
          return;
        }
      }
      // FIXME(ctang): The use of UTC now here potentially incorrectly filters out results
      //  eg. when it is 1/7 00:00 BST, it will be 30/6 23:00 UTC. This will be 30/6 and we want to include results
      //  from 1/7 as well
      const dateToday = DateTime.utc().startOf("day");
      reportApi
        .getReport(
          reportType,
          requiresDateRange ? dateInputToDateTime(from) : dateToday,
          requiresDateRange ? dateInputToDateTime(to) : dateToday
        )
        .catch(setError);
    }
  }

  const reportApi = useAPI("report");

  return (
    <main className="nhsuk-main-wrapper">
      <Label isPageHeading>{T("containers.reports.title")}</Label>
      <Select
        label={T("containers.reports.reportTypeSelector")}
        aria-label={T("containers.reports.reportTypeSelector")}
        data-testid="Report-Type-Selector"
        value={reportType}
        onChange={(e) => setReportType(e.currentTarget.value as ReportType)}
      >
        {userReportTypes.map((type) => (
          <Select.Option data-testid={type} value={type} key={type}>
            {T(`containers.reports.select.${macroCaseToCamelCase(type)}`)}
          </Select.Option>
        ))}
      </Select>
      {!noDateReportTypes.has(reportType) && (
        <>
          <Radios
            label={T("containers.reports.fromDate")}
            value={fromDateOption}
          >
            <Radios.Radio
              checked={fromDateOption === "TRIAL_START"}
              onChange={setFromTrialStart}
            >
              {T("containers.reports.trialStart")}
            </Radios.Radio>
            <Radios.Radio
              checked={fromDateOption === "OTHER"}
              onChange={() => {
                setFromDateOption("OTHER");
                setFrom({
                  day: "",
                  month: "",
                  year: "",
                });
              }}
              conditional={
                <DateInput
                  aria-label={T("containers.reports.fromDate")}
                  data-testid="From-Date"
                  value={from}
                  onChange={(e) => setFrom(e.currentTarget.value)}
                  error={fromError}
                  onBlur={(e) => {
                    if (
                      !e.currentTarget.contains(e.relatedTarget as HTMLElement)
                    )
                      setFromError(checkDateError(from));
                  }}
                />
              }
            >
              {T("containers.reports.otherFrom")}
            </Radios.Radio>
          </Radios>
          <DateInput
            label={T("containers.reports.toDate")}
            aria-label={T("containers.reports.toDate")}
            data-testid="To-Date"
            value={to}
            onChange={(e) => setTo(e.currentTarget.value)}
            error={toError}
            onBlur={(e) => {
              if (!e.currentTarget.contains(e.relatedTarget as HTMLElement))
                setToError(checkDateError(from));
            }}
          />
        </>
      )}
      <Button
        data-testid="download"
        download
        disabled={!reportType}
        onClick={downloadReport}
        style={{ marginTop: "32px" }}
      >
        {T("containers.reports.download")}
      </Button>
      {error && (
        <ErrorPanel
          title={T("containers.reports.error")}
          label={T("containers.reports.error")}
        >
          {error.message}
        </ErrorPanel>
      )}
    </main>
  );
};
