import React, { useState } from "react";
import { BackLink, ReadingWidth } from "nhsuk-react-components";
import { DataSharingConsent } from "./DataSharingConsent";
import { IRegistration } from "interfaces";
import { Link, Redirect } from "react-router-dom";
import { Loader, RegistrationClosed } from "components";
import { RegistrationForm } from "./RegistrationForm";
import { Survey } from "../Survey";
import { T } from "i18n";
import { useAPI } from "api";
import { useAuth } from "auth";
import { useQueryRegistrationClosed } from "hooks";

export const Registration = (): JSX.Element => {
  const { user } = useAuth();
  const registrationClosed = useQueryRegistrationClosed();
  const registrationAPI = useAPI("registration");
  const [cohortId, setCohortId] = useState<string>("");
  const [surveyAnswered, setSurveyAnswered] = useState(false);
  const [agreementVersion, setAgreementVersion] = useState<string | null>();

  const register = async (details: IRegistration): Promise<string> => {
    const cohortId = await registrationAPI.register(details);
    setCohortId(cohortId);
    return cohortId;
  };

  if (cohortId !== "") {
    return <Redirect to={`/registration/${cohortId}/appointment`} />;
  }

  let content: JSX.Element;

  if (registrationClosed === undefined) {
    content = <Loader />;
  } else if (registrationClosed && !user) {
    content = <RegistrationClosed />;
  } else if (!agreementVersion) {
    content = (
      <>
        {agreementVersion === null && (
          <BackLink
            asElement={Link}
            to="#"
            onClick={() => {
              setAgreementVersion(undefined);
            }}
          >
            {T("containers.dataSharingConsent.actions.back")}
          </BackLink>
        )}
        <DataSharingConsent
          agreementVersion={agreementVersion}
          onAgreementVersionChanged={(newAgreementVersion) => {
            setAgreementVersion(newAgreementVersion);
          }}
        />
      </>
    );
  } else if (!surveyAnswered) {
    content = (
      <>
        <Survey onContinue={() => setSurveyAnswered(true)} />
      </>
    );
  } else {
    content = (
      <RegistrationForm
        onSubmit={register}
        dataSharingAgreementVersion={agreementVersion}
      />
    );
  }

  return (
    <main className="nhsuk-main-wrapper">
      <ReadingWidth>{content}</ReadingWidth>
    </main>
  );
};
