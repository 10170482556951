export enum AppointmentCancelReason {
  NO_REASON_PROVIDED = "NO_REASON_PROVIDED",
  LOCATION_UNSUITABLE = "LOCATION_UNSUITABLE",
  TIME_UNSUITABLE = "TIME_UNSUITABLE",
  MOBILITY_ISSUES = "MOBILITY_ISSUES",
  NOT_IN_COUNTRY = "NOT_IN_COUNTRY",
  WORK_OR_FAMILY_COMMITMENTS = "WORK_OR_FAMILY_COMMITMENTS",
  OUTSIDE_OF_ENGLAND = "OUTSIDE_OF_ENGLAND",
  OTHER = "OTHER",
}
