import React from "react";
import { Fieldset, Label, Radios } from "nhsuk-react-components";

type SurveyQuestionProps = {
  id: string;
  text: string;
  disabled?: boolean;
  error?: string;
  value: string;
  onChange?: (value: string) => void;
};

export function SurveyQuestion(props: SurveyQuestionProps): JSX.Element {
  const { id, text, onChange, disabled = false, error, value } = props;
  return (
    <Fieldset>
      <Fieldset.Legend aria-describedby={id}>
        <Label size="m">{text}</Label>
      </Fieldset.Legend>
      <Radios id={id} name={id} error={error}>
        <Radios.Radio
          aria-label={`${id}-yes`}
          data-testid={`${id}-yes`}
          disabled={disabled}
          value="YES"
          onChange={(e) => {
            if (onChange !== undefined) {
              onChange(e.currentTarget.value);
            }
          }}
          checked={value === "YES"}
        >
          Yes
        </Radios.Radio>
        <Radios.Radio
          aria-label={`${id}-no`}
          data-testid={`${id}-no`}
          disabled={disabled}
          onChange={(e) => {
            if (onChange !== undefined) {
              onChange(e.currentTarget.value);
            }
          }}
          value="NO"
          selected={value === "NO"}
          checked={value === "NO"}
        >
          No
        </Radios.Radio>
      </Radios>
    </Fieldset>
  );
}
