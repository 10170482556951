import React, { useEffect, useState } from "react";
import { ECRF, Loader } from "components";
import { ErrorMessage } from "nhsuk-react-components";
import { EventType, IForm } from "interfaces";
import { FormAnswer } from "@grailbio/components-ecrf";
import { T } from "i18n";
import { VisitStageProps } from "containers/Visit/Visit";
import { useAPI } from "api";
import { VisitPerson } from "containers/Visit/VisitPerson";

const FORM_TYPE = "PARTICIPANT_CLINICAL_DATA";

type TRFClinicalDataProps = VisitStageProps<`${EventType}_TRF_CLINICAL_DATA`>;

export function TRFClinicalData({
  stage,
  cohortId,
  onComplete,
  isComplete,
  person,
}: TRFClinicalDataProps): JSX.Element {
  const formAPI = useAPI("forms");

  const [form, setForm] = useState<IForm>();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<Error>();

  const eventType = stage.split("_TRF_CLINICAL_DATA")[0];

  useEffect(() => {
    setLoading(true);
    formAPI
      .getForm(cohortId, FORM_TYPE, eventType)
      .then(setForm)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [formAPI, cohortId, onComplete, eventType]);

  const onSubmit = (answers: FormAnswer[]): void => {
    if (!form) {
      return;
    }
    setError(undefined);
    setSubmitting(true);
    formAPI
      .saveForm(cohortId, {
        ...form,
        answers,
      })
      .then(onComplete)
      .catch(setError)
      .finally(() => setSubmitting(false));
  };

  if (isComplete) {
    return (
      <>
        {person && <VisitPerson person={person} />}
        <p data-testid="complete">
          {T("containers.trfClinicalData.completed")}
        </p>
      </>
    );
  }

  return (
    <>
      {loading && <Loader />}
      <>
        <h1>{T("containers.trfClinicalData.title")}</h1>
        {person && <VisitPerson person={person} />}
        {form && (
          <ECRF
            showTitle={false}
            showSectionNames={false}
            definition={form.definition}
            onSubmit={onSubmit}
            initialAnswers={form.answers || []}
            disabled={submitting}
          />
        )}
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
      </>
    </>
  );
}
