import React, {
  FormEvent,
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Checkboxes, Fieldset, Label, Select } from "nhsuk-react-components";
import { FormValidity, ValiditySummary } from "./FormValidity";
import {
  ISelectableInterpreterLanguage,
  selectableInterpreterLanguages,
  IInterpreterLanguage,
} from "interfaces";
import { T } from "i18n";
import { ValidateFormHandle } from "../validations/ValidateFormHandle";
import { getInterpreterLanguageName } from "../helpers";

type AccessibilityInputProps = {
  accessibility: IAccessibilityInput;
  setAccessibility: (accessibility: IAccessibilityInput) => void;
  descriptionText?: string;
};

export interface IAccessibilityInputOptions {
  wheelchair: boolean;
  stepFree: boolean;
  parking: boolean;
  visual: boolean;
  hearing: boolean;
  interpreter: boolean;
  signLanguage: boolean;
  none: boolean;
}

export interface IAccessibilityInput {
  options: IAccessibilityInputOptions;
  interpreterLanguage: ISelectableInterpreterLanguage;
}

const noneSelected: IAccessibilityInputOptions = {
  wheelchair: false,
  stepFree: false,
  parking: false,
  visual: false,
  hearing: false,
  interpreter: false,
  signLanguage: false,
  none: true,
};

export const AccessibilityInput = forwardRef(
  (
    {
      accessibility,
      setAccessibility,
      descriptionText,
    }: AccessibilityInputProps,
    ref: Ref<ValidateFormHandle>
  ): JSX.Element => {
    const [options, setOptions] = useState<IAccessibilityInputOptions>({
      wheelchair: accessibility.options.wheelchair,
      stepFree: accessibility.options.stepFree,
      parking: accessibility.options.parking,
      visual: accessibility.options.visual,
      hearing: accessibility.options.hearing,
      interpreter: accessibility.options.interpreter,
      signLanguage: accessibility.options.signLanguage,
      none: accessibility.options.none,
    });
    const [interpreterLanguage, setInterpreterLanguage] =
      useState<ISelectableInterpreterLanguage>(
        accessibility.interpreterLanguage
      );
    const [formValidity, setFormValidity] = useState<FormValidity>(
      new FormValidity()
    );

    useImperativeHandle(ref, () => ({
      validate() {
        const validity = new FormValidity();

        const noOptionSelected = Object.values(options).every((val) => !val);
        if (noOptionSelected) {
          validity.addError(
            T(
              "containers.booking.fields.search.errors.accessibilityNotSelected"
            ),
            ids.accessibilityCheckboxes
          );
        }

        if (options.interpreter && interpreterLanguage === "NOT_APPLICABLE") {
          validity.addError(
            T("containers.booking.fields.search.errors.languageNotSelected"),
            ids.interpreterLanguageSelect
          );
        }

        setFormValidity(validity);

        return validity.valid;
      },
    }));

    useEffect(() => {
      const accessibility: IAccessibilityInput = {
        options,
        interpreterLanguage,
      };
      setAccessibility(accessibility);
    }, [options, interpreterLanguage, setAccessibility]);

    const ids = {
      accessibilityCheckboxes: "accessibility",
      interpreterLanguageSelect: "language",
    };

    const selectOptions = selectableInterpreterLanguages.map((language) => (
      <Select.Option
        value={language}
        key={language}
        disabled={language === "NOT_APPLICABLE"}
      >
        {language === "NOT_APPLICABLE"
          ? T(
              "containers.booking.fields.search.accessibility.inputs.interpreter.placeholder"
            )
          : getInterpreterLanguageName(language)}
      </Select.Option>
    ));

    const languageInterpreterSelectionControl = (
      <>
        <Label>
          {T(
            "containers.booking.fields.search.accessibility.legends.interpreterLanguages"
          )}
        </Label>
        <Select
          id={ids.interpreterLanguageSelect}
          name="interpreterLanguageOptions"
          value={interpreterLanguage}
          error={
            formValidity.getFirstErrorForId(ids.interpreterLanguageSelect)
              ?.message
          }
          onChange={({ currentTarget: { value } }) => {
            const language: ISelectableInterpreterLanguage = options.interpreter
              ? (value as IInterpreterLanguage)
              : "NOT_APPLICABLE";
            setInterpreterLanguage(language);
          }}
          data-testid="interpreter-language-select"
        >
          {selectOptions}
        </Select>
      </>
    );

    const checkboxOnChange: (e: FormEvent<HTMLInputElement>) => void = (e) => {
      const optionSelected = e.currentTarget.checked;
      const option = e.currentTarget.value as keyof IAccessibilityInputOptions;

      if (option === "none" && optionSelected) {
        setInterpreterLanguage("NOT_APPLICABLE");
        setOptions(noneSelected);
        return;
      }

      if (option === "interpreter" && !optionSelected) {
        setInterpreterLanguage("NOT_APPLICABLE");
      }

      const newOptions = { ...options, none: false, [option]: optionSelected };
      setOptions(newOptions);
    };

    const checkboxes = Object.entries(options).map(([item, selected]) => {
      const checkboxHint =
        item !== "none"
          ? T(
              `containers.booking.fields.search.accessibility.inputs.${item}.legend`
            )
          : undefined;
      const checkbox = (
        <Checkboxes.Box
          key={item}
          data-testid={item}
          hint={checkboxHint}
          value={item}
          default={selected}
          checked={selected}
          onChange={checkboxOnChange}
        >
          {T(
            `containers.booking.fields.search.accessibility.inputs.${item}.label`
          )}
        </Checkboxes.Box>
      );

      switch (item) {
        case "interpreter": {
          return React.cloneElement(checkbox, {
            conditional: languageInterpreterSelectionControl,
          });
        }
        case "none":
          return (
            <React.Fragment key={item}>
              <div className="nhsuk-checkboxes__divider">
                {T(
                  "containers.booking.fields.search.accessibility.divider.label"
                )}
              </div>
              {checkbox}
            </React.Fragment>
          );
        default:
          return checkbox;
      }
    });

    return (
      <>
        <ValiditySummary validity={formValidity} />
        {descriptionText && <p>{descriptionText}</p>}
        <Label data-testid="accessibility-input-subheading" size="m">
          {T("containers.booking.fields.search.accessibility.subheading")}
        </Label>
        <Fieldset>
          <Checkboxes
            id={ids.accessibilityCheckboxes}
            data-testid="accessibility-option-checkboxes"
            name="accessibilityOptions"
            hint={T(
              "containers.booking.fields.search.accessibility.legends.options"
            )}
            error={
              formValidity.getFirstErrorForId(ids.accessibilityCheckboxes)
                ?.message
            }
          >
            {checkboxes}
          </Checkboxes>
        </Fieldset>
      </>
    );
  }
);
