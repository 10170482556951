import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import isEqual from "lodash/isEqual";
import {
  Address,
  EmailInput,
  ErrorPanel,
  FindGPForm,
  FormValidity,
  JumpTo,
  Loader,
  NameInput,
  PersonalPhoneNumberInput,
  PostalAddressInput,
  SelectGPForm,
  ValiditySummary,
  Wizard,
  WizardPage,
  AmendDetailsNoGPView,
} from "components";
import {
  Button,
  Form,
  InsetText,
  Label,
  SummaryList,
} from "nhsuk-react-components";
import { EventTypeContext, useAddress, usePrevious } from "../../hooks";
import {
  IAddress,
  IAddressSetter,
  IDateInputValue,
  IGp,
  IPerson,
} from "../../interfaces";
import { T } from "i18n";
import { UpdateAsParticipantPayload } from "../../api/people";
import { dateInputToDateTime } from "../../helpers";
import { formatCapitalisation } from "../../formatters";
import {
  hasWords,
  isValidEmail,
  isValidUKMobilePhoneNumber,
  isValidUKPhoneNumber,
} from "../../validations";
import { useAPI } from "../../api";
import { IdentityVerifyForm } from "./IdentityVerifyForm";

function needsLineBreak(
  existingPhoneNumber: string | undefined,
  amendedPhoneNumber: string | undefined
): boolean {
  if (amendedPhoneNumber !== undefined) {
    return amendedPhoneNumber !== "";
  }
  return !!existingPhoneNumber;
}

const ids = {
  nhsNumber: "nhs-number-input",
  lastName: "last-name-input",
  dateOfBirth: {
    combined: "dob-input",
    day: "dob-input-day",
    month: "dob-input-month",
    year: "dob-input-year",
  },
  amendingDetailForms: {
    firstName: "amending-first-name-input",
    lastName: "amending-last-name-input",
    phone: "amending-phone-input",
    mobilePhone: "amending-mobile-phone-input",
    email: "amending-email-input",
    address: "amending-address-input",
  },
};

export interface ParticipantDetailsManagerProps {
  onConfirm: (
    person: IPerson | undefined,
    updatePersonDetail?: UpdateAsParticipantPayload
  ) => Promise<string>;
}

export const ParticipantDetailsManager = (
  props: ParticipantDetailsManagerProps
): JSX.Element => {
  const { onConfirm } = props;

  const [submissionError, setSubmissionError] = useState<Error>(new Error(""));
  const [verifyParticipantFormValidity, setVerifyParticipantFormValidity] =
    useState(new FormValidity());
  const [findDetailsValidity, setFindDetailsValidity] = useState<FormValidity>(
    new FormValidity()
  );
  const [nhsNumber, setNHSNumber] = useState<string>("");

  const [lastName, setLastName] = useState<string>("");

  const [dateOfBirthInput, setDateOfBirthInput] = useState<IDateInputValue>({
    day: "",
    month: "",
    year: "",
  });

  const [foundParticipant, setFoundParticipant] = useState<IPerson>();

  const [loading, setLoading] = useState<boolean>(false);

  const api = useAPI("participantAuth");

  const validatePersonalInformation = async (): Promise<boolean> => {
    if (findDetailsValidity.valid) {
      await api
        .getToken(
          {
            nhsNumber,
            dateOfBirth: dateInputToDateTime(dateOfBirthInput),
            lastName,
          },
          false
        )
        .catch((e) => e);
    }

    setVerifyParticipantFormValidity(findDetailsValidity);
    return findDetailsValidity.valid;
  };

  const wizardPageIds = {
    findGP: "findGP",
    selectGP: "selectGP",
    noGP: "noGP",
    findParticipantDetails: "findParticipantDetails",
    confirmParticipantDetails: "confirmParticipantDetails",
    noResults: "noResults",
    nameChangingForm: "nameChangingForm",
    contactChangingForm: "contactChangingForm",
    addressChangingForm: "addressChangingForm",
  };

  const personAPI = useAPI("people");

  const updateFoundParticipant = async (
    nhsNumber: string,
    dateOfBirthInput: IDateInputValue,
    lastName: string
  ): Promise<IPerson | null> => {
    setLoading(true);
    try {
      const participant = await personAPI.getPersonForNextEvent(
        nhsNumber,
        dateInputToDateTime(dateOfBirthInput),
        lastName
      );
      setFoundParticipant(participant);
      return participant;
    } catch (e) {
      return null;
    } finally {
      setLoading(false);
    }
  };

  const updateFoundParticipantAndGetNextPageID = async (): Promise<string> => {
    const person = await updateFoundParticipant(
      nhsNumber,
      dateOfBirthInput,
      lastName
    );
    if (!person) {
      return wizardPageIds.noResults;
    }
    return wizardPageIds.confirmParticipantDetails;
  };

  const [amendedParticipant, setAmendedParticipant] =
    useState<UpdateAsParticipantPayload>({
      firstName: undefined,
      lastName: undefined,
      phoneNumber: undefined,
      mobilePhoneNumber: undefined,
      email: undefined,
      mailingAddress: undefined,
      gpPractice: undefined,
    });

  const [amendingDetailFormFirstName, setAmendingDetailFormFirstName] =
    useState<string>();
  const [amendingDetailFormLastName, setAmendingDetailFormLastName] =
    useState<string>();
  const [amendingDetailFormPhoneNumber, setAmendingDetailFormPhoneNumber] =
    useState<string>();
  const [
    amendingDetailFormMobilePhoneNumber,
    setAmendingDetailFormMobilePhoneNumber,
  ] = useState<string>();
  const [amendingDetailFormEmail, setAmendingDetailFormEmail] =
    useState<string>();
  const [
    amendingDetailFormPostalAddress,
    amendingDetailFormPostalAddressSetter,
    amendingDetailFormPostalAddressFieldIDs,
    amendingDetailFormPostalAddressValidateAddress,
  ] = useAddress();

  const setAddressBySetter = useCallback(
    (address: IAddress | undefined, addressSetter: IAddressSetter) => {
      // Verify if all setter functions
      const setter: IAddressSetter = {
        setAddress1: addressSetter.setAddress1,
        setAddress2: addressSetter.setAddress2,
        setTownOrCity: addressSetter.setTownOrCity,
        setCounty: addressSetter.setCounty,
        setPostcode: addressSetter.setPostcode,
      };

      setter.setAddress1(address?.address1 || "");
      setter.setAddress2(address?.address2 || "");
      setter.setTownOrCity(address?.townOrCity || "");
      setter.setCounty(address?.county || "");
      setter.setPostcode(address?.postcode || "");
    },
    []
  );

  useEffect(() => {
    if (foundParticipant) {
      setAmendingDetailFormFirstName(foundParticipant.firstName);
      setAmendingDetailFormLastName(foundParticipant.lastName);
      setAmendingDetailFormPhoneNumber(foundParticipant.phoneNumber);
      setAmendingDetailFormMobilePhoneNumber(
        foundParticipant.mobilePhoneNumber
      );
      setAmendingDetailFormEmail(foundParticipant.email);
      setAddressBySetter(
        foundParticipant.mailingAddress,
        amendingDetailFormPostalAddressSetter
      );
    }
  }, [
    amendingDetailFormPostalAddressSetter,
    foundParticipant,
    setAddressBySetter,
  ]);

  const [findGPPostcode, setFindGPPostcode] = useState("");
  const previousFindGPPostcode = usePrevious(findGPPostcode);
  const [findGPPostcodeError, setFindGPPostcodeError] = useState<Error>();
  const [foundGPs, setFoundGPs] = useState<IGp[]>([]);
  const [selectedGP, setSelectedGP] = useState<IGp | null>();

  const gpAPI = useAPI("gp");

  const clearFindGPPostcode = useCallback(() => setFindGPPostcode(""), []);

  const clearSelectedGP = useCallback(() => setSelectedGP(undefined), []);

  useEffect(() => {
    if (previousFindGPPostcode !== findGPPostcode) {
      clearSelectedGP();
    }
  }, [clearSelectedGP, findGPPostcode, previousFindGPPostcode]);

  const updateFoundGPs = async (gpPostcode: string): Promise<IGp[] | null> => {
    if (gpPostcode === "") {
      setFoundGPs([]);
      return [];
    }

    setLoading(true);
    try {
      const gps = await gpAPI.findGps(findGPPostcode);
      setFoundGPs(gps);
      return gps;
    } catch (e) {
      setFindGPPostcodeError(e);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const updateFoundGPsAndGetNextPageID = async (): Promise<string> => {
    const gps = await updateFoundGPs(findGPPostcode);
    if (gps && gps.length === 0) {
      return wizardPageIds.noGP;
    }
    return wizardPageIds.selectGP;
  };

  const [amendParticipantFormValidity, setAmendParticipantFormValidity] =
    useState(new FormValidity());
  const [findGPFormValidity, setFindGPFormValidity] = useState(
    new FormValidity()
  );
  const [selectGPFormValidity, setSelectGPFormValidity] = useState(
    new FormValidity()
  );

  const validateFindGPForm = (): boolean => {
    setAmendParticipantFormValidity(findGPFormValidity);
    return findGPFormValidity.valid;
  };

  const validateSelectGPForm = (): boolean => {
    setAmendParticipantFormValidity(selectGPFormValidity);
    return selectGPFormValidity.valid;
  };

  const validateNameChangingForm = (): boolean => {
    const currentValidity = new FormValidity();

    const firstNameError = hasWords(
      amendingDetailFormFirstName || "",
      T(
        "containers.participantDetailsManager.sections.changeDetails.name.errors.emptyFirstName"
      )
    );
    currentValidity.addError(firstNameError, ids.amendingDetailForms.firstName);

    const lastNameError = hasWords(
      amendingDetailFormLastName || "",
      T(
        "containers.participantDetailsManager.sections.changeDetails.name.errors.emptyLastName"
      )
    );
    currentValidity.addError(lastNameError, ids.amendingDetailForms.lastName);

    setAmendParticipantFormValidity(currentValidity);
    return currentValidity.valid;
  };

  const validateContactChangingForm = (): boolean => {
    const currentValidity = new FormValidity();

    const phoneNumberError = isValidUKPhoneNumber(
      amendingDetailFormPhoneNumber || "",
      T(
        "containers.participantDetailsManager.sections.changeDetails.contact.errors.invalidPhone"
      )
    );
    currentValidity.addError(phoneNumberError, ids.amendingDetailForms.phone);

    const mobilePhoneNumberError = isValidUKMobilePhoneNumber(
      amendingDetailFormMobilePhoneNumber || "",
      T(
        "containers.participantDetailsManager.sections.changeDetails.contact.errors.invalidMobilePhone"
      )
    );
    currentValidity.addError(
      mobilePhoneNumberError,
      ids.amendingDetailForms.mobilePhone
    );

    const emailError = isValidEmail(
      amendingDetailFormEmail || "",
      T(
        "containers.participantDetailsManager.sections.changeDetails.contact.errors.invalidEmail"
      )
    );
    currentValidity.addError(emailError, ids.amendingDetailForms.email);

    setAmendParticipantFormValidity(currentValidity);
    return currentValidity.valid;
  };

  const validatePostalAddressChangingForm = (): boolean => {
    const currentValidity = new FormValidity();

    amendingDetailFormPostalAddressValidateAddress(currentValidity);

    setAmendParticipantFormValidity(currentValidity);
    return currentValidity.valid;
  };

  const onSubmitForm = async (
    e: SyntheticEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setLoading(true);

    const participantDetailsAmended = Object.values(amendedParticipant).some(
      (val) => val !== undefined
    );
    await onConfirm(
      foundParticipant,
      participantDetailsAmended ? amendedParticipant : undefined
    )
      .catch(setSubmissionError)
      .finally(() => setLoading(false));
  };

  const clearAmendParticipantFormErrors = (): void => {
    setAmendParticipantFormValidity(new FormValidity());
  };

  const onWizardPageChanged = (): void => {
    clearAmendParticipantFormErrors();
  };

  return (
    <EventTypeContext.Provider value="Y1">
      <Form onSubmit={onSubmitForm} data-testid="details-manager">
        <Wizard onPageChanged={onWizardPageChanged}>
          <WizardPage
            pageID={wizardPageIds.findParticipantDetails}
            nextEnabled={!loading}
            nextLabel={T(
              "containers.participantDetailsManager.sections.find.nextLabel"
            )}
            nextPage={updateFoundParticipantAndGetNextPageID}
            validate={validatePersonalInformation}
          >
            <Label isPageHeading>
              {T("containers.participantDetailsManager.sections.find.title")}
            </Label>
            <IdentityVerifyForm
              nhsNumber={nhsNumber}
              nhsNumberChanged={setNHSNumber}
              lastName={lastName}
              lastNameChanged={setLastName}
              dateOfBirth={dateOfBirthInput}
              dateOfBirthChanged={setDateOfBirthInput}
              validity={verifyParticipantFormValidity}
              validityUpdated={setFindDetailsValidity}
            />
          </WizardPage>

          <WizardPage pageID={wizardPageIds.confirmParticipantDetails}>
            <Label isPageHeading data-testid="confirm-details-heading">
              {T("containers.participantDetailsManager.sections.confirm.title")}
            </Label>
            <Label>
              {T(
                "containers.participantDetailsManager.sections.confirm.subheading"
              )}
            </Label>
            <br />
            <SummaryList data-testid="participant-details">
              <SummaryList.Row>
                <SummaryList.Key>
                  {T(
                    "containers.participantDetailsManager.sections.confirm.summary.nhsNumber"
                  )}
                </SummaryList.Key>
                <SummaryList.Value
                  aria-label={T(
                    "containers.participantDetailsManager.sections.confirm.summary.nhsNumber"
                  )}
                >
                  {foundParticipant?.nhsNumber}
                </SummaryList.Value>
                <SummaryList.Actions />
              </SummaryList.Row>

              <SummaryList.Row>
                <SummaryList.Key>
                  {T(
                    "containers.participantDetailsManager.sections.confirm.summary.gp"
                  )}
                </SummaryList.Key>
                <SummaryList.Value
                  aria-label={T(
                    "containers.participantDetailsManager.sections.confirm.summary.gp"
                  )}
                >
                  {amendedParticipant.gpPractice?.name ||
                    foundParticipant?.gpPractice.name}
                  <br />
                  {!!foundParticipant && (
                    <Address
                      address={
                        amendedParticipant.gpPractice?.address ||
                        foundParticipant.gpPractice.address
                      }
                      properCase
                    />
                  )}
                </SummaryList.Value>
                <SummaryList.Actions>
                  <JumpTo
                    testid="summary-change-gp"
                    to={wizardPageIds.findGP}
                    text={T(
                      "containers.participantDetailsManager.sections.confirm.actions.change"
                    )}
                  />
                </SummaryList.Actions>
              </SummaryList.Row>

              <SummaryList.Row>
                <SummaryList.Key>
                  {T(
                    "containers.participantDetailsManager.sections.confirm.summary.name"
                  )}
                </SummaryList.Key>
                <SummaryList.Value
                  aria-label={T(
                    "containers.participantDetailsManager.sections.confirm.summary.name"
                  )}
                >
                  {`${
                    amendedParticipant.firstName || foundParticipant?.firstName
                  } ${
                    amendedParticipant.lastName || foundParticipant?.lastName
                  }`}
                </SummaryList.Value>
                <SummaryList.Actions>
                  <JumpTo
                    testid="summary-change-name"
                    to={wizardPageIds.nameChangingForm}
                    text={T(
                      "containers.participantDetailsManager.sections.confirm.actions.change"
                    )}
                  />
                </SummaryList.Actions>
              </SummaryList.Row>

              <SummaryList.Row>
                <SummaryList.Key>
                  {T(
                    "containers.participantDetailsManager.sections.confirm.summary.dob"
                  )}
                </SummaryList.Key>
                <SummaryList.Value
                  aria-label={T(
                    "containers.participantDetailsManager.sections.confirm.summary.dob"
                  )}
                >
                  {foundParticipant?.dateOfBirth.toISODate()}
                </SummaryList.Value>
                <SummaryList.Actions />
              </SummaryList.Row>

              <SummaryList.Row>
                <SummaryList.Key>
                  {T(
                    "containers.participantDetailsManager.sections.confirm.summary.sex"
                  )}
                </SummaryList.Key>
                <SummaryList.Value
                  aria-label={T(
                    "containers.participantDetailsManager.sections.confirm.summary.sex"
                  )}
                >
                  {!!foundParticipant &&
                    formatCapitalisation(foundParticipant.biologicalSex)}
                </SummaryList.Value>
                <SummaryList.Actions />
              </SummaryList.Row>

              <SummaryList.Row>
                <SummaryList.Key>
                  {T(
                    "containers.participantDetailsManager.sections.confirm.summary.contact"
                  )}
                </SummaryList.Key>
                <SummaryList.Value
                  aria-label={T(
                    "containers.participantDetailsManager.sections.confirm.summary.contact"
                  )}
                >
                  {amendedParticipant.phoneNumber ||
                    foundParticipant?.phoneNumber}
                  {needsLineBreak(
                    foundParticipant?.mobilePhoneNumber,
                    amendedParticipant.mobilePhoneNumber
                  ) && <br />}
                  {amendedParticipant.mobilePhoneNumber !== undefined
                    ? amendedParticipant.mobilePhoneNumber
                    : foundParticipant?.mobilePhoneNumber}
                  <br />
                  {amendedParticipant.email !== undefined
                    ? amendedParticipant.email
                    : foundParticipant?.email}
                </SummaryList.Value>
                <SummaryList.Actions>
                  <JumpTo
                    testid="summary-change-contact"
                    to={wizardPageIds.contactChangingForm}
                    text={T(
                      "containers.participantDetailsManager.sections.confirm.actions.change"
                    )}
                  />
                </SummaryList.Actions>
              </SummaryList.Row>

              <SummaryList.Row>
                <SummaryList.Key>
                  {T(
                    "containers.participantDetailsManager.sections.confirm.summary.address"
                  )}
                </SummaryList.Key>
                <SummaryList.Value
                  aria-label={T(
                    "containers.participantDetailsManager.sections.confirm.summary.address"
                  )}
                >
                  {!!foundParticipant && (
                    <Address
                      address={
                        amendedParticipant.mailingAddress ||
                        foundParticipant?.mailingAddress
                      }
                      properCase
                    />
                  )}
                </SummaryList.Value>
                <SummaryList.Actions>
                  <JumpTo
                    testid="summary-change-address"
                    to={wizardPageIds.addressChangingForm}
                    text={T(
                      "containers.participantDetailsManager.sections.confirm.actions.change"
                    )}
                  />
                </SummaryList.Actions>
              </SummaryList.Row>
            </SummaryList>

            <InsetText>
              <p>
                {T(
                  "containers.participantDetailsManager.sections.confirm.callout"
                )}
              </p>
            </InsetText>

            {loading && <Loader />}
            {!loading && (
              <Button
                type="submit"
                data-testid="wizard-submit"
                aria-label={T(
                  "containers.participantDetailsManager.sections.confirm.nextLabel"
                )}
              >
                {T(
                  "containers.participantDetailsManager.sections.confirm.nextLabel"
                )}
              </Button>
            )}
          </WizardPage>

          <WizardPage
            pageID={wizardPageIds.noResults}
            previousPage={wizardPageIds.findParticipantDetails}
            branched
          >
            <Label isPageHeading data-testid="no-participant-heading">
              {T(
                "containers.participantDetailsManager.sections.noParticipant.title"
              )}
            </Label>
            <p>
              {T(
                "containers.participantDetailsManager.sections.noParticipant.paragraph1"
              )}
            </p>
            <p>
              {T(
                "containers.participantDetailsManager.sections.noParticipant.paragraph2"
              )}
            </p>
          </WizardPage>

          <WizardPage
            pageID={wizardPageIds.findGP}
            previousPage={() => {
              clearFindGPPostcode();
              return wizardPageIds.confirmParticipantDetails;
            }}
            nextPage={updateFoundGPsAndGetNextPageID}
            nextLabel={T(
              "containers.participantDetailsManager.sections.changeDetails.findGP.nextLabel"
            )}
            validate={validateFindGPForm}
            branched
          >
            <Label isPageHeading>
              {T(
                "containers.participantDetailsManager.sections.changeDetails.findGP.title"
              )}
            </Label>
            <FindGPForm
              findGPPostcode={findGPPostcode}
              findGPPostcodeChanged={setFindGPPostcode}
              validity={amendParticipantFormValidity}
              validityUpdated={setFindGPFormValidity}
            />
          </WizardPage>

          <WizardPage
            pageID={wizardPageIds.selectGP}
            previousPage={() => {
              clearSelectedGP();
              return wizardPageIds.findGP;
            }}
            nextPage={() => {
              if (!selectedGP) {
                return wizardPageIds.noGP;
              }

              const isOriginalGP =
                !!foundParticipant &&
                isEqual(selectedGP, foundParticipant.gpPractice);

              setAmendedParticipant(
                isOriginalGP
                  ? { ...amendedParticipant, gpPractice: undefined }
                  : { ...amendedParticipant, gpPractice: selectedGP }
              );

              clearFindGPPostcode();
              clearSelectedGP();
              return wizardPageIds.confirmParticipantDetails;
            }}
            nextLabel={T(
              "containers.participantDetailsManager.sections.changeDetails.selectGP.nextLabel"
            )}
            nextEnabled={!findGPPostcodeError}
            validate={validateSelectGPForm}
            branched
          >
            <Label isPageHeading>
              {T(
                "containers.participantDetailsManager.sections.changeDetails.selectGP.title"
              )}
            </Label>
            <ValiditySummary validity={amendParticipantFormValidity} />
            <SummaryList data-testid="postcode-summary">
              <SummaryList.Row>
                <SummaryList.Key className="summary-list-key">
                  {T(
                    "containers.participantDetailsManager.sections.changeDetails.selectGP.summary.title"
                  )}
                </SummaryList.Key>
                <SummaryList.Value>{findGPPostcode}</SummaryList.Value>
                <SummaryList.Actions>
                  <JumpTo
                    testid="postcode-summary-change"
                    to={wizardPageIds.findGP}
                    text={T(
                      "containers.participantDetailsManager.sections.changeDetails.selectGP.summary.action"
                    )}
                  />
                </SummaryList.Actions>
              </SummaryList.Row>
            </SummaryList>
            <SelectGPForm
              gps={foundGPs}
              findGPPostcodeError={findGPPostcodeError}
              selectedGP={selectedGP}
              gpSelectionChanged={setSelectedGP}
              validity={amendParticipantFormValidity}
              validityUpdated={setSelectGPFormValidity}
            />
          </WizardPage>

          <WizardPage
            pageID={wizardPageIds.noGP}
            previousPage={() =>
              selectedGP === null
                ? wizardPageIds.selectGP
                : wizardPageIds.findGP
            }
            branched
          >
            <AmendDetailsNoGPView />
          </WizardPage>

          <WizardPage
            pageID={wizardPageIds.nameChangingForm}
            previousPage={() => {
              setAmendingDetailFormFirstName(
                amendedParticipant.firstName || foundParticipant?.firstName
              );
              setAmendingDetailFormLastName(
                amendedParticipant.lastName || foundParticipant?.lastName
              );
              return wizardPageIds.confirmParticipantDetails;
            }}
            nextPage={() => {
              setAmendedParticipant({
                ...amendedParticipant,
                firstName:
                  amendingDetailFormFirstName === foundParticipant?.firstName
                    ? undefined
                    : amendingDetailFormFirstName,
                lastName:
                  amendingDetailFormLastName === foundParticipant?.lastName
                    ? undefined
                    : amendingDetailFormLastName,
              });
              return wizardPageIds.confirmParticipantDetails;
            }}
            nextLabel={T(
              "containers.participantDetailsManager.sections.changeDetails.name.nextLabel"
            )}
            validate={validateNameChangingForm}
            branched
          >
            {foundParticipant && (
              <>
                <Label isPageHeading>
                  {T(
                    "containers.participantDetailsManager.sections.changeDetails.name.title"
                  )}
                </Label>
                <ValiditySummary validity={amendParticipantFormValidity} />
                <NameInput
                  firstName={amendingDetailFormFirstName}
                  firstNameChanged={setAmendingDetailFormFirstName}
                  firstNameError={
                    amendParticipantFormValidity.getFirstErrorForId(
                      ids.amendingDetailForms.firstName
                    )?.message
                  }
                  lastName={amendingDetailFormLastName}
                  lastNameChanged={setAmendingDetailFormLastName}
                  lastNameError={
                    amendParticipantFormValidity.getFirstErrorForId(
                      ids.amendingDetailForms.lastName
                    )?.message
                  }
                  elementIDs={{
                    firstName: ids.amendingDetailForms.firstName,
                    lastName: ids.amendingDetailForms.lastName,
                  }}
                />
              </>
            )}
          </WizardPage>

          <WizardPage
            pageID={wizardPageIds.contactChangingForm}
            previousPage={() => {
              setAmendingDetailFormPhoneNumber(
                amendedParticipant.phoneNumber || foundParticipant?.phoneNumber
              );
              setAmendingDetailFormMobilePhoneNumber(
                amendedParticipant.mobilePhoneNumber ||
                  foundParticipant?.mobilePhoneNumber
              );
              setAmendingDetailFormEmail(
                amendedParticipant.email || foundParticipant?.email
              );
              return wizardPageIds.confirmParticipantDetails;
            }}
            nextPage={() => {
              setAmendedParticipant({
                ...amendedParticipant,
                phoneNumber:
                  amendingDetailFormPhoneNumber ===
                  foundParticipant?.phoneNumber
                    ? undefined
                    : amendingDetailFormPhoneNumber,
                mobilePhoneNumber:
                  amendingDetailFormMobilePhoneNumber ===
                  foundParticipant?.mobilePhoneNumber
                    ? undefined
                    : amendingDetailFormMobilePhoneNumber,
                email:
                  amendingDetailFormEmail === foundParticipant?.email
                    ? undefined
                    : amendingDetailFormEmail,
              });
              return wizardPageIds.confirmParticipantDetails;
            }}
            nextLabel={T(
              "containers.participantDetailsManager.sections.changeDetails.contact.nextLabel"
            )}
            validate={validateContactChangingForm}
            branched
          >
            {foundParticipant && (
              <>
                <Label isPageHeading>
                  {T(
                    "containers.participantDetailsManager.sections.changeDetails.contact.title"
                  )}
                </Label>
                <ValiditySummary validity={amendParticipantFormValidity} />
                <PersonalPhoneNumberInput
                  phoneNumber={amendingDetailFormPhoneNumber}
                  phoneNumberChanged={setAmendingDetailFormPhoneNumber}
                  phoneNumberError={
                    amendParticipantFormValidity.getFirstErrorForId(
                      ids.amendingDetailForms.phone
                    )?.message
                  }
                  elementIDs={{ phoneNumber: ids.amendingDetailForms.phone }}
                  label={T(
                    "components.personalPhoneNumberInput.fields.phone.label"
                  )}
                  hint={T(
                    "components.personalPhoneNumberInput.fields.phone.hint"
                  )}
                  dataTestId="main-phone-number"
                />
                <PersonalPhoneNumberInput
                  phoneNumber={amendingDetailFormMobilePhoneNumber}
                  phoneNumberChanged={setAmendingDetailFormMobilePhoneNumber}
                  phoneNumberError={
                    amendParticipantFormValidity.getFirstErrorForId(
                      ids.amendingDetailForms.mobilePhone
                    )?.message
                  }
                  elementIDs={{
                    phoneNumber: ids.amendingDetailForms.mobilePhone,
                  }}
                  label={T(
                    "components.personalPhoneNumberInput.fields.mobilePhone.label"
                  )}
                  hint={T(
                    "components.personalPhoneNumberInput.fields.mobilePhone.hint"
                  )}
                  dataTestId="mobile-phone-number"
                />
                <EmailInput
                  email={amendingDetailFormEmail}
                  emailChanged={setAmendingDetailFormEmail}
                  emailError={
                    amendParticipantFormValidity.getFirstErrorForId(
                      ids.amendingDetailForms.email
                    )?.message
                  }
                  elementIDs={{ email: ids.amendingDetailForms.email }}
                />
              </>
            )}
          </WizardPage>

          <WizardPage
            pageID={wizardPageIds.addressChangingForm}
            previousPage={() => {
              setAddressBySetter(
                amendedParticipant.mailingAddress ||
                  foundParticipant?.mailingAddress,
                amendingDetailFormPostalAddressSetter
              );
              return wizardPageIds.confirmParticipantDetails;
            }}
            nextPage={() => {
              setAmendedParticipant({
                ...amendedParticipant,
                mailingAddress: isEqual(
                  amendingDetailFormPostalAddress,
                  foundParticipant?.mailingAddress
                )
                  ? undefined
                  : amendingDetailFormPostalAddress,
              });
              return wizardPageIds.confirmParticipantDetails;
            }}
            nextLabel={T(
              "containers.participantDetailsManager.sections.changeDetails.postalAddress.nextLabel"
            )}
            validate={validatePostalAddressChangingForm}
            branched
          >
            {foundParticipant && (
              <>
                <Label isPageHeading>
                  {T(
                    "containers.participantDetailsManager.sections.changeDetails.postalAddress.title"
                  )}
                </Label>
                <ValiditySummary validity={amendParticipantFormValidity} />
                <PostalAddressInput
                  postalAddress={amendingDetailFormPostalAddress}
                  postalAddressChanged={amendingDetailFormPostalAddressSetter}
                  validity={amendParticipantFormValidity}
                  elementIDs={amendingDetailFormPostalAddressFieldIDs}
                />
              </>
            )}
          </WizardPage>
        </Wizard>
        {submissionError.message !== "" && (
          <ErrorPanel
            label="submission-error"
            title={
              <span id="error-summary-title">
                {T(
                  "containers.participantDetailsManager.errors.submissionErrorTitle"
                )}
              </span>
            }
          >
            <p>{submissionError.message}</p>
          </ErrorPanel>
        )}
      </Form>
    </EventTypeContext.Provider>
  );
};
