import { ValidationFunction } from "./ValidationFunction";

export const isLessThanMaxCharLimitBoolean = (
  string: string,
  maxCharLimit: number
): boolean => {
  return string.length <= maxCharLimit;
};
type MaxCharLimitInput = { string: string; maxCharLimit: number };

export const isLessThanMaxCharLimit: ValidationFunction<MaxCharLimitInput> = (
  input: MaxCharLimitInput,
  message: string
): string =>
  isLessThanMaxCharLimitBoolean(input.string, input.maxCharLimit)
    ? ""
    : message;
