import { AxiosInstance } from "axios";
import { IUser } from "interfaces";
import { T } from "i18n";
import { UserArchiveReason } from "enums/UserArchiveReason";

type mfaType = "SMS" | "TOTP";

export interface IUserClient {
  sync(): Promise<void>;
  list(): Promise<IUser[]>;
  get(email: string): Promise<IUser>;
  update(
    email: string,
    firstName?: string,
    lastName?: string,
    phoneNumber?: string
  ): Promise<void>;
  updateMFA(email: string, mfaType: mfaType): Promise<void>;

  listRoles(): Promise<string[]>;
  addRole(email: string, role: string): Promise<void>;
  removeRole(email: string, role: string): Promise<void>;
  create(
    email: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    roles: string[]
  ): Promise<void>;
  resetPassword(email: string): Promise<void>;
  archiveUser(
    email: string,
    reason: UserArchiveReason,
    note: string
  ): Promise<void>;
  reinstateUser(email: string): Promise<void>;
}

export class UserClient implements IUserClient {
  public constructor(protected readonly http: AxiosInstance) {}

  public async list(): Promise<IUser[]> {
    const response = await this.http.get("/users", {});
    return response.data;
  }

  public async get(email: string): Promise<IUser> {
    const response = await this.http.get(`/user/${email}`);
    return response.data;
  }

  public update(
    email: string,
    firstName?: string,
    lastName?: string,
    phoneNumber?: string
  ): Promise<void> {
    return this.http.patch(`/user/${email}`, {
      firstName,
      lastName,
      phoneNumber,
    });
  }

  public async listRoles(): Promise<string[]> {
    const response = await this.http.get("/roles", {});
    return response.data;
  }

  public async sync(): Promise<void> {
    return this.http.post("/user", {});
  }

  public async addRole(email: string, role: string): Promise<void> {
    return this.http.post(`/user/${email}/role/${role}`, {});
  }

  public async removeRole(email: string, role: string): Promise<void> {
    return this.http.delete(`/user/${email}/role/${role}`, {});
  }

  public async updateMFA(email: string, type: mfaType): Promise<void> {
    return this.http.patch("/user", {
      email,
      type,
    });
  }

  public async resetPassword(email: string): Promise<void> {
    try {
      await this.http.post(`/user/${email}/reset-password`);
    } catch (e) {
      if (e.response?.status === 429) {
        throw new Error(
          T("containers.user.resetPassword.errors.actionLimitExceeded")
        );
      } else {
        throw e;
      }
    }
  }

  public async create(
    email: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    roles: string[]
  ): Promise<void> {
    try {
      await this.http.post("/users", {
        email,
        firstName,
        lastName,
        phoneNumber,
        roles,
      });
    } catch (error) {
      if (error.response?.status === 409) {
        throw new Error(
          T("containers.user.createUser.submissionError.userAlreadyExists")
        );
      }
      throw error;
    }
  }

  public async archiveUser(
    email: string,
    reason: UserArchiveReason,
    note: string
  ): Promise<void> {
    return this.http.post(`/user/${email}/archive`, { reason, note });
  }

  public async reinstateUser(email: string): Promise<void> {
    return this.http.post(`/user/${email}/reinstate`);
  }
}
