import React, { createContext, useContext, useEffect, useState } from "react";
import { BackLink, Button, Hint } from "nhsuk-react-components";
import { T } from "i18n";
import { usePrevious } from "../../hooks/usePrevious";

interface WizardContextType {
  step: number;
  maxStep: number;
  prev: () => void;
  next: () => void;
  goTo: (pageID: string) => void;
  hasNext: () => boolean;
}

const WizardContext = createContext<WizardContextType | undefined>(undefined);

function useWizard(): WizardContextType {
  const context = useContext(WizardContext);
  if (context === undefined) {
    throw new Error("useWizard must be used within a Wizard");
  }
  return context;
}

export interface PageProps extends React.HTMLAttributes<Element> {
  pageID: string;
  validate?: () => Promise<boolean> | Promise<void> | boolean;
  nextPage?: (() => Promise<string> | string) | string;
  nextEnabled?: boolean;
  nextLabel?: string;
  displayProgress?: boolean;
  branched?: boolean;
  previousPage?: (() => Promise<string> | string) | string;
  showNext?: boolean;
}

interface WizardProps {
  children: React.ReactElement<PageProps> | React.ReactElement<PageProps>[];
  onPageChanged?: (from: string | undefined, to: string) => void;
}

type WizardPageNode = {
  step: number;
  page: React.ReactElement<PageProps>;
  prev?: (() => Promise<string> | string) | string;
  next?: (() => Promise<string> | string) | string;
};

export const Wizard = ({
  children,
  onPageChanged,
}: WizardProps): JSX.Element => {
  const childrenMap: { [key: string]: WizardPageNode } = {};
  let currentStep = 0;
  let prev: string | undefined;
  let firstPageID: string | undefined;

  React.Children.forEach(children, (child) => {
    const { pageID, branched } = child.props;

    if (!firstPageID && !child.props.branched) {
      firstPageID = pageID;
    }

    if (branched) {
      const branchPrev = child.props.previousPage;
      const branchNext = child.props.nextPage;
      if (!branchPrev) {
        throw new Error("Branched wizard page must specify previous page");
      }

      // Branched page won't show the current step, as it is not the main flow and the count / max count is confusing
      childrenMap[pageID] = {
        step: 0,
        page: child,
        prev: branchPrev,
        next: branchNext,
      };
    } else {
      const { previousPage: specifiedPrev, nextPage: next } = child.props;
      if (specifiedPrev) {
        throw new Error("Main flow wizard page must not specify previous page");
      }

      currentStep += 1;
      childrenMap[pageID] = { step: currentStep, page: child, prev, next };
      if (prev) {
        const previousPageNode = childrenMap[prev];
        if (!previousPageNode.next) {
          previousPageNode.next = pageID;
        }
      }
      prev = pageID;
    }
  });

  const [step, setStep] = useState(currentStep > 0 ? 1 : 0);
  const [maxStep] = useState(currentStep);
  const [currentPageID, setCurrentPageID] = useState<string | undefined>(
    firstPageID
  );
  const previousPageID = usePrevious(currentPageID);

  useEffect(() => {
    if (currentPageID && onPageChanged && currentPageID !== previousPageID) {
      onPageChanged(previousPageID, currentPageID);
    }
  }, [onPageChanged, currentPageID, previousPageID]);

  const prevPage: () => void = async () => {
    if (currentPageID) {
      const pageNode = childrenMap[currentPageID];
      let prev: string | undefined;
      if (typeof pageNode.prev === "function") {
        prev = await pageNode.prev();
      } else {
        prev = pageNode.prev;
      }
      if (!prev || !Object.prototype.hasOwnProperty.call(childrenMap, prev)) {
        throw new Error("Wizard page previous page not found");
      }
      const prevPageNode = childrenMap[prev];
      setCurrentPageID(prev);
      setStep(prevPageNode.step);
    }
  };

  const nextPage: () => void = async () => {
    if (currentPageID) {
      const pageNode = childrenMap[currentPageID];
      let next: string | undefined;
      if (typeof pageNode.next === "function") {
        next = await pageNode.next();
      } else {
        next = pageNode.next;
      }
      if (!next || !Object.prototype.hasOwnProperty.call(childrenMap, next)) {
        throw new Error("Wizard page next page not found");
      }
      const nextPageNode = childrenMap[next];
      setCurrentPageID(next);
      setStep(nextPageNode.step);
    }
  };

  const goToPage: (pageID: string) => void = (pageID: string) => {
    if (currentPageID) {
      if (!Object.prototype.hasOwnProperty.call(childrenMap, pageID)) {
        throw new Error(
          `Cannot go to wizard page of ID ${pageID}. Assign pageID to WizardPage props`
        );
      }

      const goToPageNode = childrenMap[pageID];
      setCurrentPageID(pageID);
      setStep(goToPageNode.step);
    }
  };

  const hasNext: () => boolean = () => {
    return !!currentPageID && !!childrenMap[currentPageID].next;
  };

  const value: WizardContextType = {
    step,
    maxStep,
    prev: prevPage,
    next: nextPage,
    goTo: goToPage,
    hasNext,
  };
  return (
    <WizardContext.Provider value={value}>
      {currentPageID && childrenMap[currentPageID].page}
    </WizardContext.Provider>
  );
};

export const JumpTo = ({
  text,
  to,
  testid,
  onClick,
}: {
  to: string;
  text: string;
  testid?: string;
  onClick?: () => void;
}): JSX.Element => {
  const { goTo } = useWizard();
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href="#"
      data-testid={testid !== undefined ? testid : "jumpto"}
      onClick={(e) => {
        e.preventDefault();
        if (onClick) {
          onClick();
        }
        goTo(to);
      }}
    >
      {text}
    </a>
  );
};

export const WizardPage: React.FC<PageProps> = ({
  children,
  validate = () => true,
  nextEnabled = true,
  nextLabel = T("containers.wizard.next"),
  displayProgress = false,
  showNext = true,
  branched,
}: PageProps): JSX.Element => {
  const { step, maxStep, prev, next, hasNext } = useWizard();

  const nextAfterValidate = async (): Promise<void> => {
    const isValid = (await validate()) !== false;

    if (isValid) {
      next();
    }
  };

  const content = (
    <>
      {children}
      {((!branched && step !== maxStep) || hasNext()) && showNext && (
        <Button
          disabled={!nextEnabled}
          onClick={nextAfterValidate}
          data-testid="wizard-next"
          aria-label={nextLabel}
          type="button"
        >
          {nextLabel}
        </Button>
      )}
    </>
  );

  return (
    <>
      <div>
        {step !== 1 && (
          <BackLink
            onClick={prev}
            data-testid="wizard-previous"
            aria-label="Previous"
          >
            {T("containers.wizard.previous")}
          </BackLink>
        )}
        {displayProgress && !branched && (
          <Hint data-testid="wizard-progress">
            {T("containers.registration.sections.progressMessage", {
              stepNumber: step,
              maxSteps: maxStep,
            })}
          </Hint>
        )}
      </div>
      {content}
    </>
  );
};

WizardPage.defaultProps = {
  branched: false,
};
