import { AxiosInstance } from "axios";

interface Questions {
  question1: string;
  question2: string;
  question3: string;
  age: string;
}

export interface SurveyQuestions {
  questions: Questions;
}

export interface SurveyResults {
  eligible: boolean;
  age: string;
  question1: string;
  question2: string;
  question3: string;
}

export type SurveyAnswers = Questions;

export interface ISurveyClient {
  evaluate(answers: SurveyAnswers): Promise<SurveyResults>;
  getQuestions(): Promise<SurveyQuestions>;
}

export class SurveyClient implements ISurveyClient {
  public constructor(protected readonly unauthedHttp: AxiosInstance) {}

  public async getQuestions(): Promise<SurveyQuestions> {
    const { data } = await this.unauthedHttp.get("/survey");
    return data;
  }

  public async evaluate(answers: SurveyAnswers): Promise<SurveyResults> {
    const { data } = await this.unauthedHttp.post("/survey/evaluate", answers);
    return data;
  }
}
