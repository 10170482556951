import React from "react";
import { T } from "i18n";
import { VisitStageProps } from "../Visit";
import { VoucherForm } from "./VoucherForm";
import { VisitPerson } from "../VisitPerson";

export function Voucher({
  cohortId,
  person,
  onComplete,
  isComplete,
}: VisitStageProps<"VOUCHER">): JSX.Element {
  if (isComplete) {
    return (
      <>
        {person && <VisitPerson person={person} />}
        <p data-testid="complete">{T("containers.voucher.completed")}</p>
      </>
    );
  }
  return (
    <VoucherForm cohortId={cohortId} person={person} onComplete={onComplete} />
  );
}
