import React from "react";

import { WarningCallout } from "nhsuk-react-components";
import { T } from "i18n";

const title = T("containers.booking.fields.location.bookingNote.title");
const text = T("containers.booking.fields.location.bookingNote.content");

export function BookingHelpCallout(): JSX.Element {
  return (
    <WarningCallout label={title}>
      <WarningCallout.Label>{title}</WarningCallout.Label>
      <p>{text}</p>
    </WarningCallout>
  );
}
