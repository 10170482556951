import { AxiosInstance } from "axios";
import { IDataDeletionStatus } from "../../interfaces";
import { deletionProhibited } from "../../errors/data";

export interface IDataClient {
  checkDelete: (cohortID: string) => Promise<IDataDeletionStatus | null>;
  delete: (cohortID: string) => Promise<void>;
}

export class DataClient implements IDataClient {
  public constructor(protected readonly http: AxiosInstance) {}

  async checkDelete(cohortID: string): Promise<IDataDeletionStatus | null> {
    try {
      const { data } = await this.http.get(`/data/${cohortID}/check`);
      return data;
    } catch (ex: any) {
      if (ex.response?.status === 404) {
        return null;
      }
      throw ex;
    }
  }

  async delete(cohortID: string): Promise<void> {
    try {
      const { data } = await this.http.delete(`/data/${cohortID}`);
      return data;
    } catch (err: any) {
      const { status } = err.response;
      switch (status) {
        case 400:
          throw new Error(`${deletionProhibited}: ${err.response.data}`);
        default:
          throw err;
      }
    }
  }
}
