import { AxiosInstance } from "axios";
import { AuditEventType } from "enums/AuditEventType";
import { IAuditEvent } from "interfaces/IAuditEvent";
import { DateTime } from "luxon";
import fileDownload from "js-file-download";

export interface IAuditClient {
  getAuditLog(
    eventTypes: Set<AuditEventType>,
    from: DateTime,
    to: DateTime
  ): Promise<IAuditEvent[]>;
  downloadAuditLog(
    eventTypes: Set<AuditEventType>,
    from: DateTime,
    to: DateTime
  ): Promise<void>;
}

export class AuditClient implements IAuditClient {
  public constructor(protected readonly http: AxiosInstance) {}

  getAuditLog(
    eventTypes: Set<AuditEventType>,
    from: DateTime,
    to: DateTime
  ): Promise<IAuditEvent[]> {
    return this.http
      .get("/audit-log", {
        params: {
          eventTypes: Array.from(eventTypes),
          from: from.toISO(),
          to: to.toISO(),
        },
      })
      .then((response) =>
        response.data.map(({ timestamp, ...rest }: { timestamp: string }) => ({
          ...rest,
          timestamp: DateTime.fromISO(timestamp),
        }))
      );
  }

  downloadAuditLog(
    eventTypes: Set<AuditEventType>,
    from: DateTime,
    to: DateTime
  ): Promise<void> {
    return this.http
      .get("/audit-log/download", {
        params: {
          eventTypes: Array.from(eventTypes),
          from: from.toISO(),
          to: to.toISO(),
        },
      })
      .then((response) => {
        const filename = `AUDIT_LOG_${from.toISODate()}_${to.toISODate()}@${DateTime.now().toISODate()}.csv`;
        fileDownload(response.data, filename);
      });
  }
}
