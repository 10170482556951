import React from "react";
import { FormValidity } from "components/FormValidity";
import { IAddress, IAddressFieldIDs, IAddressSetter } from "interfaces";
import { Input } from "nhsuk-react-components";
import { PostcodeInput } from "./PostcodeInput";
import { T } from "i18n";

interface IAddressInputProps
  extends IAddress,
    IAddressSetter,
    IAddressFieldIDs {
  componentName: string;
  addressValidity: FormValidity;
}

export const AddressInput = (props: IAddressInputProps): JSX.Element => {
  const {
    address1,
    address2,
    county,
    postcode,
    townOrCity,

    address1ID,
    address2ID,
    countyID,
    postcodeID,
    townOrCityID,

    componentName,
    addressValidity,

    setAddress1,
    setAddress2,
    setCounty,
    setPostcode,
    setTownOrCity,
  } = props;

  return (
    <>
      <Input
        type="text"
        id={address1ID}
        data-testid="address1"
        label={T(`components.${componentName}.fields.address1.label`)}
        hint={T(`components.${componentName}.fields.address1.hint`)}
        aria-label={T(`components.${componentName}.fields.address1.label`)}
        value={address1}
        onChange={(e) => setAddress1(e.currentTarget.value)}
        error={addressValidity.getFirstErrorForId(address1ID)?.message}
        width="20"
      />

      <Input
        type="text"
        id={address2ID}
        data-testid="address2"
        label={T(`components.${componentName}.fields.address2.label`)}
        hint={T(`components.${componentName}.fields.address2.hint`)}
        aria-label={T(`components.${componentName}.fields.address2.label`)}
        value={address2}
        onChange={(e) => setAddress2(e.currentTarget.value)}
        error={addressValidity.getFirstErrorForId(address2ID)?.message}
        width="20"
      />

      <Input
        type="text"
        id={townOrCityID}
        data-testid="town"
        label={T(`components.${componentName}.fields.town.label`)}
        hint={T(`components.${componentName}.fields.town.hint`)}
        aria-label={T(`components.${componentName}.fields.town.label`)}
        value={townOrCity}
        onChange={(e) => setTownOrCity(e.currentTarget.value)}
        error={addressValidity.getFirstErrorForId(townOrCityID)?.message}
        width="20"
      />

      <Input
        type="text"
        data-testid="county"
        id={countyID}
        label={T(`components.${componentName}.fields.county.label`)}
        hint={T(`components.${componentName}.fields.county.hint`)}
        aria-label={T(`components.${componentName}.fields.county.label`)}
        value={county}
        onChange={(e) => setCounty(e.currentTarget.value)}
        error={addressValidity.getFirstErrorForId(countyID)?.message}
        width="20"
      />

      <PostcodeInput
        type="text"
        data-testid="postcode"
        id={postcodeID}
        label={T(`components.${componentName}.fields.postcode.label`)}
        hint={T(`components.${componentName}.fields.postcode.hint`)}
        aria-label={T(`components.${componentName}.fields.postcode.label`)}
        value={postcode}
        onChange={setPostcode}
        error={addressValidity.getFirstErrorForId(postcodeID)?.message}
        width="10"
      />
    </>
  );
};
