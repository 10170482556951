import React from "react";
import { Input } from "nhsuk-react-components";
import { T } from "i18n";

interface NameInputElementIDs {
  firstName?: string;
  lastName?: string;
}

interface NameInputProps {
  firstName?: string;
  firstNameChanged?: (newVal: string) => void;
  firstNameError?: string;
  lastName?: string;
  lastNameChanged?: (newVal: string) => void;
  lastNameError?: string;
  elementIDs?: NameInputElementIDs;
}

export const NameInput = ({
  firstName,
  firstNameChanged,
  firstNameError,
  lastName,
  lastNameChanged,
  lastNameError,
  elementIDs,
}: NameInputProps): JSX.Element => {
  return (
    <>
      <Input
        type="text"
        data-testid="firstName"
        id={elementIDs?.firstName}
        label={T("components.nameInput.fields.firstName.label")}
        hint={T("components.nameInput.fields.firstName.hint")}
        aria-label={T("components.nameInput.fields.firstName.label")}
        value={firstName}
        onChange={(e) =>
          firstNameChanged && firstNameChanged(e.currentTarget.value)
        }
        error={firstNameError}
        width="20"
      />
      <Input
        type="text"
        data-testid="lastName"
        id={elementIDs?.lastName}
        label={T("components.nameInput.fields.lastName.label")}
        hint={T("components.nameInput.fields.lastName.hint")}
        aria-label={T("components.nameInput.fields.lastName.label")}
        value={lastName}
        onChange={(e) =>
          lastNameChanged && lastNameChanged(e.currentTarget.value)
        }
        error={lastNameError}
        width="20"
      />
    </>
  );
};
