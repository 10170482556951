import React from "react";
import { ErrorMessage } from "./ErrorMessage";
import { Card } from "nhsuk-react-components";

interface ErrorPanelProps {
  title?: React.ReactNode;
  label?: string;
}

export const ErrorPanel = ({
  title,
  children,
  label = "error",
}: React.PropsWithChildren<ErrorPanelProps>): JSX.Element => (
  <Card>
    <Card.Content>
      <ErrorMessage title={title} label={label}>
        {children}
      </ErrorMessage>
    </Card.Content>
  </Card>
);
