import React from "react";
import { ActiveUser } from "interfaces";
import { Checkboxes, Table } from "nhsuk-react-components";
import { T } from "i18n";
import { AllRoles } from "auth";

interface ActiveUserTableProps {
  users?: ActiveUser[];
  onUserClicked: (id: string) => void;
}

export function ActiveUserTable({
  users,
  onUserClicked,
}: ActiveUserTableProps): JSX.Element {
  const roleCells = () =>
    AllRoles.map((role) => (
      <Table.Cell
        style={{ writingMode: "vertical-rl", verticalAlign: "middle" }}
        key={role}
      >
        {role}
      </Table.Cell>
    ));

  const activeRows = () =>
    users?.map((user) => {
      const includesRoles = AllRoles.map((role) =>
        user.roles.includes(role) ? (
          <Table.Cell key={`${role}checked`}>
            <Checkboxes>
              <Checkboxes.Box value={role} data-testid={role} checked disabled>
                {" "}
              </Checkboxes.Box>
            </Checkboxes>
          </Table.Cell>
        ) : (
          <Table.Cell key={`${role}unchecked`}>
            <Checkboxes.Box value={role} data-testid={role} disabled>
              {" "}
            </Checkboxes.Box>
          </Table.Cell>
        )
      );

      return (
        <Table.Row
          key={user.id}
          data-testid={`row-${user.id}`}
          style={{ cursor: "pointer" }}
          onClick={() => onUserClicked(user.id)}
        >
          <Table.Cell>{user.id}</Table.Cell>
          {includesRoles}
        </Table.Row>
      );
    });

  return (
    <Table data-testid="user-table">
      <Table.Head>
        <Table.Row>
          <Table.Cell>{T("containers.user.table.header.username")}</Table.Cell>
          {roleCells()}
        </Table.Row>
      </Table.Head>
      <Table.Body>{activeRows()}</Table.Body>
    </Table>
  );
}
