import { DateTime } from "luxon";
import { IDateTimeInputValue } from "../interfaces";

export const dateTimeInputToDateTime = ({
  date: { day, month, year },
  time: { hour, minute },
}: IDateTimeInputValue): DateTime =>
  DateTime.fromObject({
    year: parseInt(year, 10),
    month: parseInt(month, 10),
    day: parseInt(day, 10),
    hour: parseInt(hour, 10),
    minute: parseInt(minute, 10),
  });
