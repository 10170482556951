import React, { useEffect, useState } from "react";
import {
  Button,
  ErrorSummary,
  Form,
  Input,
  SummaryList,
} from "nhsuk-react-components";
import { DateTime } from "luxon";
import { Duration } from "../../api/appointment";
import { IAvailabilityLocation, IPerson } from "../../interfaces";
import { Loader } from "../../components";
import { LocationPicker } from "./LocationPicker";
import { T } from "../../i18n";
import { isValidPostcode } from "../../validations";
import { useAPI } from "../../api";
import { useParams } from "react-router";

interface LocationSearchProps {
  accessibilityRequired: boolean;
  from: DateTime;
  to: DateTime;
  duration: Duration;
  error: string;
  setSearchPostcode: (postcode?: string) => void;
  setError: (message: string) => void;
  setSlotsFound: (val: boolean) => void;
  updateLocation: (location: IAvailabilityLocation) => void;
  locationsFetched?: (locations: IAvailabilityLocation[]) => void;
  setShowNext: (state: boolean) => void;
}

export function LocationSearch({
  accessibilityRequired,
  from,
  to,
  duration,
  updateLocation,
  locationsFetched,
  setShowNext,
  error,
  setError,
  setSlotsFound,
  setSearchPostcode,
}: LocationSearchProps): JSX.Element {
  const peopleAPI = useAPI("people");
  const appointmentAPI = useAPI("appointment");
  const [person, setPerson] = useState<IPerson>();

  const { cohortId } = useParams<{ cohortId: string }>();
  const [postcode, setPostcode] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [postcodeError, setPostcodeError] = useState<string>("");
  useEffect(() => {
    setLoading(true);
    // if this call fails just revert to not pre-populating the postcode
    peopleAPI
      .getPerson(cohortId)
      .then(setPerson)
      .catch(() => {
        // avoid unhandled auth error if user refreshes page in booking flow
      })
      .finally(() => setLoading(false));
  }, [peopleAPI, cohortId]);

  useEffect(() => {
    setPostcode(person?.mailingAddress.postcode || "");
  }, [person]);

  const [locations, setLocations] = useState<
    IAvailabilityLocation[] | undefined
  >(undefined);

  useEffect(() => {
    setShowNext(!loading && !!locations && locations.length > 0);
  }, [setShowNext, loading, locations]);

  useEffect(() => {
    setSlotsFound(!!locations && locations.length > 0);
  }, [locations, setSlotsFound]);

  const onSubmit: React.FormEventHandler = (e) => {
    e.preventDefault();
    const postcodeValidationError = isValidPostcode(
      postcode || "",
      T("components.postcodeSearchInput.errors.invalid")
    );
    setPostcodeError(postcodeValidationError);
    if (postcodeValidationError !== "") {
      return;
    }
    setLoading(true);
    setSearchPostcode(postcode);
    appointmentAPI
      .findAppointmentAvailability(
        from,
        to,
        accessibilityRequired,
        undefined,
        duration,
        postcode
      )
      .then((availability) => {
        setLocations(availability.locations);
        locationsFetched && locationsFetched(availability.locations);
      })
      .catch(() => {
        setError(
          T("containers.booking.fields.location.errors.failedToLoadSlots")
        );
      })
      .finally(() => setLoading(false));
  };

  if (loading) {
    return <Loader panel={false} />;
  }

  if (locations) {
    return (
      <>
        <SummaryList>
          <SummaryList.Row>
            <SummaryList.Key>
              {T("components.postcodeSearchInput.postcode.key")}
            </SummaryList.Key>
            <SummaryList.Value data-testid="choose-mobile-clinic-postcode">
              {postcode}
            </SummaryList.Value>
            <SummaryList.Actions>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                data-testid="choose-mobile-clinic-postcode-change"
                onClick={(e) => {
                  e.preventDefault();
                  setLocations(undefined);
                }}
              >
                {T("components.postcodeSearchInput.postcode.change")}
              </a>
            </SummaryList.Actions>
          </SummaryList.Row>
        </SummaryList>
        <LocationPicker
          locations={locations}
          error={error}
          updateLocation={(location) => {
            updateLocation(location);
          }}
        />
      </>
    );
  }

  return (
    <>
      {error !== "" && (
        <ErrorSummary>
          <ErrorSummary.Title>
            {T("containers.booking.fields.location.errors.title")}
          </ErrorSummary.Title>
          <ErrorSummary.List>
            <ErrorSummary.Item href="#locations">{error}</ErrorSummary.Item>
          </ErrorSummary.List>
        </ErrorSummary>
      )}
      <Form onSubmit={onSubmit}>
        <Input
          type="text"
          error={postcodeError}
          value={postcode}
          disabled={loading}
          onChange={(e) => {
            setPostcode(e.currentTarget.value);
          }}
          width={10}
          label={T("components.postcodeSearchInput.label")}
          hint={T("components.postcodeSearchInput.hint")}
        />
        <Button disabled={loading} type="submit">
          {T("components.postcodeSearchInput.search")}
        </Button>
      </Form>
    </>
  );
}
