import React, { useCallback, useState } from "react";
import {
  ActionLink,
  BackLink,
  Button,
  ErrorMessage,
  Fieldset,
  Form,
  Select,
  Textarea,
} from "nhsuk-react-components";
import { Link } from "react-router-dom";
import { T } from "i18n";
import { VisitStageProps } from "../Visit";
import { VisitStatus } from "../../../enums";
import { useAPI } from "api";

const visitStatusStringLookup: { [key in VisitStatus]: string } = {
  IN_PROGRESS: T("containers.visit.abort.select.status.inProgress"),
  COMPLETED: T("containers.visit.abort.select.status.completed"),
  ABORTED_SELF_DECISION: T("containers.visit.abort.select.status.selfDecision"),
  ABORTED_INELIGIBLE: T("containers.visit.abort.select.status.ineligible"),
  ABORTED_NOT_CONSENTED: T("containers.visit.abort.select.status.notConsented"),
  ABORTED_OPERATIONAL: T("containers.visit.abort.select.status.operational"),
  ABORTED_TECHNICAL: T("containers.visit.abort.select.status.technical"),
  ABORTED_OTHER: T("containers.visit.abort.select.status.other"),
};

export function Abort({
  cohortId,
  onComplete,
  visit: { unitCode, status, stage },
}: VisitStageProps<"ABORT_APPOINTMENT">): JSX.Element {
  const [abortStatus, setAbortStatus] = useState<VisitStatus>();
  const [abortComment, setAbortComment] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [aborted, setAborted] = useState<boolean>(
    status !== VisitStatus.IN_PROGRESS && status !== VisitStatus.COMPLETED
  );
  const [error, setError] = useState<Error>();
  const visitAPI = useAPI("visit");

  const onAbort = useCallback(() => {
    if (!abortStatus) {
      return;
    }
    setLoading(true);
    visitAPI
      .updateStatus(cohortId, abortStatus, abortComment)
      .then(() => {
        setAborted(true);
        onComplete();
      })
      .catch(setError)
      .finally(() => {
        setLoading(false);
      });
  }, [
    setLoading,
    visitAPI,
    abortStatus,
    abortComment,
    setError,
    cohortId,
    onComplete,
  ]);

  const charCount = abortComment.length;
  const MAX_CHAR_COUNT = 255;
  const charCountError =
    abortComment.length > MAX_CHAR_COUNT
      ? T("containers.visit.abort.provideReasonError")
      : undefined;

  const submitDisabled =
    loading || charCountError !== undefined || abortStatus === undefined;

  const abortStatuses = Object.keys(VisitStatus)
    .filter(
      (abortStatus) =>
        abortStatus !== "IN_PROGRESS" && abortStatus !== "COMPLETED"
    )
    .map((abortStatus) => (
      <Select.Option
        data-testid={`abort-status-${abortStatus}`}
        key={abortStatus}
        value={abortStatus}
      >
        {
          visitStatusStringLookup[
            VisitStatus[abortStatus as keyof typeof VisitStatus]
          ]
        }
      </Select.Option>
    ));

  return (
    <>
      <BackLink
        href={`/visit/${cohortId}?stage=${stage}`}
        data-testid="abort-back-link"
        aria-label={T("containers.wizard.previous")}
      >
        {T("containers.wizard.previous")}
      </BackLink>
      <h1>{T("containers.visit.abort.title")}</h1>

      {aborted && (
        <>
          <p data-testid="appointment-aborted-text">
            {T("containers.visit.abort.text")}
          </p>
          <ActionLink
            data-testid="next-stage"
            asElement={Link}
            to={`/visit/${cohortId}`}
          >
            {T(`containers.visit.links.next`, {
              step: T(`stages.${stage}`),
            })}
          </ActionLink>
        </>
      )}
      {!aborted && (
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            onAbort();
          }}
          disableErrorFromComponents
        >
          <Select
            label={T("containers.visit.abort.select.label")}
            data-testid="abort-select"
            value={abortStatus}
            defaultValue=""
            onChange={(e) =>
              setAbortStatus(e.currentTarget.value as VisitStatus)
            }
          >
            <Select.Option key="0" value="" disabled>
              {T("containers.visit.abort.select.placeholder")}
            </Select.Option>
            {abortStatuses}
          </Select>
          <Fieldset aria-describedby="abort-hint">
            <Textarea
              data-testid="abort-comment"
              hint={T("containers.visit.abort.provideReasonHint", {
                charactersLeft: MAX_CHAR_COUNT - charCount,
              })}
              error={charCountError}
              value={abortComment}
              rows={4}
              onChange={(e) => setAbortComment(e.currentTarget.value)}
            />
            <Fieldset.Legend>
              {T("containers.visit.abort.provideReason")}
            </Fieldset.Legend>
          </Fieldset>
          {error && (
            <ErrorMessage data-testid="abort-visit-error">
              {error.message}
            </ErrorMessage>
          )}
          <Button
            data-testid="abort-button"
            type="submit"
            disabled={submitDisabled}
            style={{ background: "#d5281b" }}
          >
            {T("containers.visit.abort.abortButton")}
          </Button>
        </Form>
      )}
    </>
  );
}
