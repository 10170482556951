import React, { useCallback, useEffect, useState } from "react";
import { AppointmentSummary } from "containers";
import { BackLink, Button, Select } from "nhsuk-react-components";
import { ErrorPanel, Loader } from "components";
import { Bookability, IAppointment } from "interfaces";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { T } from "i18n";
import { useAPI } from "api";
import { AppointmentCancelReason } from "enums/AppointmentCancelReason";

interface CancelAppointmentProps {
  bookedAppointment: IAppointment;
}

export function CancelAppointment({
  bookedAppointment,
}: CancelAppointmentProps): JSX.Element {
  const { cohortId } = useParams<{ cohortId: string }>();
  const [reason, setReason] = useState<AppointmentCancelReason>();
  const [cancelLoading, setCancelLoading] = useState<boolean>(false);
  const [cancelError, setCancelError] = useState<string>();
  const appointmentAPI = useAPI("appointment");
  const { push } = useHistory();

  const cancelAppointment = useCallback((): void => {
    if (!reason) {
      return;
    }
    setCancelLoading(true);
    appointmentAPI
      .cancelAppointment(cohortId, reason)
      .then(() => {
        setCancelLoading(false);
        push(`/participant/${cohortId}`);
      })
      .catch(({ message }) => setCancelError(message));
  }, [cohortId, appointmentAPI, reason, push]);

  return (
    <>
      <h1>{T("containers.participant.appointments.actions.cancel.title")}</h1>
      {cancelError && (
        <ErrorPanel title="cancel error" label="cancel error">
          {cancelError}
        </ErrorPanel>
      )}

      <AppointmentSummary id={cohortId} appointment={bookedAppointment} />
      <Select
        label={T(
          "containers.participant.appointments.actions.cancel.reasonLabel"
        )}
        value={reason || ""}
        onChange={(e) =>
          setReason(e.currentTarget.value as AppointmentCancelReason)
        }
        data-testid="appointment-cancel-reason"
      >
        <Select.Option value="" disabled={true}>
          {T(
            "containers.participant.appointments.actions.cancel.reasonPlaceholder"
          )}
        </Select.Option>
        {Object.values(AppointmentCancelReason)
          .filter(
            (reason) => reason !== AppointmentCancelReason.OUTSIDE_OF_ENGLAND
          )
          .map((reason) => (
            <Select.Option key={reason} value={reason}>
              {T(
                `containers.participant.appointments.actions.cancel.reason.${reason}`
              )}
            </Select.Option>
          ))}
      </Select>
      <Button
        className="nhsuk-button-margin-right"
        disabled={!reason || cancelLoading}
        aria-label={T(
          "containers.participant.appointments.actions.cancel.confirm"
        )}
        data-testid="appointment-action-confirm"
        onClick={() => cancelAppointment()}
      >
        {T("containers.participant.appointments.actions.cancel.confirm")}
      </Button>
      <Button
        className="nhsuk-button-margin-right"
        disabled={cancelLoading}
        aria-label={T(
          "containers.participant.appointments.actions.cancel.return"
        )}
        data-testid="appointment-action-return"
        href={`/participant/${cohortId}`}
      >
        {T("containers.participant.appointments.actions.cancel.return")}
      </Button>
    </>
  );
}

export function CancelRebook(): JSX.Element {
  const { cohortId } = useParams<{ cohortId: string }>();
  const [bookedAppointment, setBookedAppointment] = useState<IAppointment>();
  const [appointmentsLoading, setAppointmentsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const { push } = useHistory();
  const [confirmCancelFlow, setConfirmCancelFlow] = useState<boolean>(false);
  const appointmentAPI = useAPI("appointment");

  const [canBook, setCanBook] = useState<Bookability>();
  const [canBookLoading, setCanBookLoading] = useState<boolean>(true);

  const getAppointments = useCallback((): void => {
    setAppointmentsLoading(true);
    appointmentAPI
      .getAppointments(cohortId)
      .then((appointments) => {
        setBookedAppointment(
          appointments.find(({ status }) => status === "BOOKED")
        );
      })
      .catch(({ message }) => setError(message))
      .finally(() => setAppointmentsLoading(false));
  }, [cohortId, appointmentAPI]);

  useEffect(() => {
    getAppointments();
  }, [getAppointments, cohortId]);

  // Check can book
  const getCanBook = useCallback((): void => {
    setCanBookLoading(true);
    appointmentAPI
      .checkCanBook(cohortId)
      .then((result) => setCanBook(result))
      .catch(({ message }) => setError(message))
      .finally(() => setCanBookLoading(false));
  }, [cohortId, appointmentAPI]);

  useEffect(() => {
    getCanBook();
  }, [getCanBook, cohortId]);

  // Error
  if (error) {
    const errorTitle = T(
      "containers.participant.appointments.actions.cancelOrRebook.error"
    );
    return (
      <main className="nhsuk-main-wrapper">
        <ErrorPanel title={errorTitle} label={errorTitle}>
          {error}
        </ErrorPanel>
        <Button href={`/participant/${cohortId}`}>
          {T("containers.participant.appointments.actions.cancel.return")}
        </Button>
      </main>
    );
  }

  // Loading
  const loading = appointmentsLoading || canBookLoading;
  if (!loading && bookedAppointment === undefined) {
    return <Redirect to={`/participant/${cohortId}`} />;
  }

  const bookFrom =
    canBook?.bookable && canBook.window ? `&from=${canBook.window.from}` : "";

  // Cancel appointment
  const handleRebookButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (canBook && !canBook.bookable) {
      event.preventDefault();
      setError(
        T(
          canBook.reason === "EXCLUDED"
            ? "containers.participant.appointments.actions.book.unable.reason.excluded"
            : "containers.participant.appointments.actions.cancelOrRebook.notBookableError"
        )
      );
      return;
    }
  };

  return (
    <main className="nhsuk-main-wrapper">
      <BackLink
        onClick={() => push(`/participant/${cohortId}`)}
        data-testid="status-change-back-link"
        aria-label={T("containers.wizard.previous")}
      >
        {T("containers.wizard.previous")}
      </BackLink>
      {loading && <Loader />}
      {!loading && bookedAppointment !== undefined && confirmCancelFlow && (
        <CancelAppointment bookedAppointment={bookedAppointment} />
      )}
      {!loading && bookedAppointment !== undefined && !confirmCancelFlow && (
        <>
          <h1>
            {T(
              "containers.participant.appointments.actions.cancelOrRebook.title"
            )}
          </h1>
          <p>
            {T(
              "containers.participant.appointments.actions.cancelOrRebook.information"
            )}
          </p>
          <Button
            className="nhsuk-button-margin-right"
            data-testid="rebook-button"
            onClick={handleRebookButtonClick}
            href={`/registration/${cohortId}/appointment?appointmentType=${bookedAppointment.type}&isRebooking=true${bookFrom}`}
          >
            {T(
              "containers.participant.appointments.actions.cancelOrRebook.rebook"
            )}
          </Button>
          <Button
            className="nhsuk-button-margin-right"
            data-testid="confirm-cancel-flow-button"
            onClick={() => {
              setConfirmCancelFlow(true);
            }}
          >
            {T(
              "containers.participant.appointments.actions.cancelOrRebook.cancel"
            )}
          </Button>
        </>
      )}
    </main>
  );
}
