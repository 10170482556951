import React from "react";
import { ArchivedUser } from "interfaces";
import { T } from "i18n";
import { Button, SummaryList } from "nhsuk-react-components";
import { DateTime } from "luxon";
import { formatCapitalisation } from "formatters";

interface ArchivedUserProfileProps {
  user: ArchivedUser;
  reinstate: () => void;
  disableReinstate: boolean;
}

export function ArchivedUserProfile({
  user,
  reinstate,
  disableReinstate,
}: ArchivedUserProfileProps): JSX.Element {
  const archivedAt = DateTime.fromISO(user.archiveInfo.archivedAt);
  return (
    <>
      <h1>{T("containers.user.archived.profile.title")}</h1>
      <SummaryList>
        <SummaryList.Row>
          <SummaryList.Key>
            {T("containers.user.archived.profile.name")}
          </SummaryList.Key>
          <SummaryList.Value
            aria-label={T("containers.user.archived.profile.name")}
          >
            {user.firstName}
            &nbsp;
            {user.lastName}
          </SummaryList.Value>
        </SummaryList.Row>
        <SummaryList.Row>
          <SummaryList.Key>
            {T("containers.user.archived.profile.contactDetails")}
          </SummaryList.Key>
          <SummaryList.Value
            aria-label={T("containers.user.archived.profile.contactDetails")}
          >
            {user.id}
            {user.phoneNumber && (
              <>
                <br />
                {user.phoneNumber}
              </>
            )}
          </SummaryList.Value>
        </SummaryList.Row>
        <SummaryList.Row>
          <SummaryList.Key>
            {T("containers.user.archived.profile.userRoles")}
          </SummaryList.Key>
          <SummaryList.Value
            aria-label={T("containers.user.archived.profile.userRoles")}
          >
            {user.roles.map((role, index) => {
              const lineBreak = index !== user.roles.length ? <br /> : null;
              return (
                <div key={role}>
                  {role}
                  {lineBreak}
                </div>
              );
            })}
          </SummaryList.Value>
        </SummaryList.Row>
        <SummaryList.Row>
          <SummaryList.Key>
            {T("containers.user.archived.profile.archivedDate")}
          </SummaryList.Key>
          <SummaryList.Value
            aria-label={T("containers.user.archived.profile.archivedDate")}
          >
            {archivedAt.toFormat("EEEE dd MMMM yyyy")}
            <br />
            {archivedAt.toFormat("hh:mma")}
          </SummaryList.Value>
        </SummaryList.Row>
        <SummaryList.Row>
          <SummaryList.Key>
            {T("containers.user.archived.profile.archivedBy")}
          </SummaryList.Key>
          <SummaryList.Value
            aria-label={T("containers.user.archived.profile.archivedBy")}
          >
            {user.archiveInfo.archivedBy}
          </SummaryList.Value>
        </SummaryList.Row>
        <SummaryList.Row>
          <SummaryList.Key>
            {T("containers.user.archived.profile.reason")}
          </SummaryList.Key>
          <SummaryList.Value
            aria-label={T("containers.user.archived.profile.reason")}
          >
            {formatCapitalisation(user.archiveInfo.reason, "_")}
          </SummaryList.Value>
        </SummaryList.Row>
        <SummaryList.Row>
          <SummaryList.Key>
            {T("containers.user.archived.profile.notes")}
          </SummaryList.Key>
          <SummaryList.Value
            aria-label={T("containers.user.archived.profile.notes")}
          >
            {user.archiveInfo.note}
          </SummaryList.Value>
        </SummaryList.Row>
      </SummaryList>
      <Button disabled={disableReinstate} onClick={reinstate}>
        {T("containers.user.archived.profile.reinstate")}
      </Button>
    </>
  );
}
