import "./Results.scss";
import React, { useEffect, useState } from "react";
import { Checkboxes, Fieldset, Label } from "nhsuk-react-components";
import { ErrorPanel, Loader } from "components";
import { ICase, ICaseList } from "interfaces";
import { ResultsTable } from "./ResultsTable";
import { T } from "i18n";
import { useAPI } from "api";
import { getCaseStatusProgress } from "./CaseStatusProgress";
import {
  CaseSearch,
  CaseSearchFilters,
  defaultSearchfilters,
} from "./CaseSearch";

function sortCaseListByMostRecent(caseList: ICaseList): ICaseList {
  caseList.cases.sort((a: ICase, b: ICase) => {
    return b.reportDate.toSeconds() - a.reportDate.toSeconds();
  });
  return caseList;
}

export const Results = (): JSX.Element => {
  const [includeUnread, setIncludeUnread] = useState(true);
  const [includeInProgress, setIncludeInProgress] = useState(false);
  const [includeClosed, setIncludeClosed] = useState(false);
  const [searchFilters, setSearchFilters] =
    useState<CaseSearchFilters>(defaultSearchfilters);
  const [caseList, setCaseList] = useState<ICaseList>({ cases: [] });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const resultsApi = useAPI("results");

  useEffect(() => {
    setLoading(true);
    resultsApi
      // TODO: Get pagination in, this is purely a quick-fix to display counts for types quickly with no back-end changes
      // Once pagination is in we can get rid of this approach
      .getCases(true, true)
      .then(sortCaseListByMostRecent)
      .then(setCaseList)
      .catch(({ message }) => setError(message))
      .finally(() => setLoading(false));
  }, [resultsApi]);

  if (error) {
    return (
      <ErrorPanel
        title={T("containers.results.table.noResults")}
        label={T("containers.results.table.noResults")}
      >
        {error}
      </ErrorPanel>
    );
  }

  const StatusesPane = (): JSX.Element => (
    <Fieldset>
      <Label bold size="m">
        {T("containers.results.filters")}
      </Label>
      <Checkboxes name="statuses" className="checkboxes">
        <Checkboxes.Box
          checked={includeUnread}
          onClick={() => setIncludeUnread(!includeUnread)}
          data-testid="results-status-unread"
        >
          {`${T("containers.results.unread")} (${
            caseList.cases.filter(
              (value) => getCaseStatusProgress(value) === "UNREAD"
            ).length
          })`}
        </Checkboxes.Box>
        <Checkboxes.Box
          checked={includeInProgress}
          onClick={() => setIncludeInProgress(!includeInProgress)}
          data-testid="results-status-in-progress"
        >
          {`${T("containers.results.inProgress")} (${
            caseList.cases.filter(
              (value) => getCaseStatusProgress(value) === "IN_PROGRESS"
            ).length
          })`}
        </Checkboxes.Box>
        <Checkboxes.Box
          checked={includeClosed}
          onClick={() => setIncludeClosed(!includeClosed)}
          data-testid="results-status-closed"
        >
          {`${T("containers.results.closed")} (${
            caseList.cases.filter(
              (value) => getCaseStatusProgress(value) === "CLOSED"
            ).length
          })`}
        </Checkboxes.Box>
      </Checkboxes>
    </Fieldset>
  );

  return (
    <main className="nhsuk-main-wrapper">
      <Label isPageHeading>{T("containers.results.title")}</Label>
      <StatusesPane />
      <CaseSearch onSearchParamsChanged={setSearchFilters} />
      {loading ? (
        <Loader />
      ) : (
        <ResultsTable
          caseList={caseList}
          showUnread={includeUnread}
          showInProgress={includeInProgress}
          showClosed={includeClosed}
          searchFilters={searchFilters}
        />
      )}
    </main>
  );
};
