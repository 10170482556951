import { DateTime } from "luxon";
import { IDateInputValue } from "interfaces";

export const formatDateOfBirth = (d: DateTime): string => {
  return d.toFormat("d LLLL yyyy");
};

export const formatDateOfBirthInput = (d: IDateInputValue): string => {
  const date = DateTime.fromISO(
    `${d.year}-${d.month.padStart(2, "0")}-${d.day.padStart(2, "0")}`
  );
  return formatDateOfBirth(date);
};
