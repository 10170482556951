import "./SearchInput.scss";
import React, { HTMLProps, MutableRefObject } from "react";
import { FormElementProps } from "nhsuk-react-components/dist/util/types/FormTypes";
import { Input } from "nhsuk-react-components";
import { InputWidth } from "nhsuk-react-components/dist/util/types/NHSUKTypes";

interface SearchProps extends HTMLProps<HTMLInputElement>, FormElementProps {
  inputRef?: MutableRefObject<HTMLInputElement | null>;
  width?: InputWidth;
  disableErrorLine?: boolean;
}

export const SearchInput = (props: SearchProps): JSX.Element => (
  <Input className="nhsuk-search" {...props} />
);
