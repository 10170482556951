import React, { SyntheticEvent, useState } from "react";
import {
  Button,
  ErrorSummary,
  Fieldset,
  Form,
  Input,
} from "nhsuk-react-components";
import { T } from "i18n";
import { hasWords } from "../../validations";
import { useAPI } from "../../api";
import { useAuth } from "../../auth";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";

interface UpdateProfileProps {
  onProfileUpdated?(): void;
}

export function UpdateProfile({
  onProfileUpdated,
}: UpdateProfileProps): JSX.Element {
  const { user, refresh } = useAuth();
  const [firstName, setFirstName] = useState(user?.firstName || "");
  const [lastName, setLastName] = useState(user?.lastName || "");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();

  const { id: email } = useParams<{ id: string }>();

  const { push } = useHistory();

  const userAPI = useAPI("user");

  const onSubmitForm = async (
    e: SyntheticEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    if (!validate()) {
      return;
    }

    setLoading(true);
    try {
      await userAPI.update(email, firstName, lastName);
      // Navigate to /sign-in to re-fetch user info and redirect to the post login page
      await refresh();
      push("/sign-in");
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const validate = (): boolean => {
    setError(undefined);
    setFirstNameError("");
    setLastNameError("");

    let valid = true;

    const firstNameHasWords = hasWords(
      firstName,
      T("containers.user.updateProfile.firstName.errors.empty")
    );
    if (firstNameHasWords !== "") {
      valid = false;
      setFirstNameError(firstNameHasWords);
    }

    const lastNameHasWords = hasWords(
      lastName,
      T("containers.user.updateProfile.lastName.errors.empty")
    );
    if (lastNameHasWords !== "") {
      valid = false;
      setLastNameError(lastNameHasWords);
    }

    return valid;
  };

  return (
    <main className="nhsuk-main-wrapper">
      <Form onSubmit={onSubmitForm}>
        <Fieldset>
          <Fieldset.Legend isPageHeading>
            {T("containers.user.updateProfile.title")}
          </Fieldset.Legend>
          <Input
            type="text"
            data-testid="firstName"
            label={T("containers.user.updateProfile.firstName.label")}
            aria-label={T("containers.user.updateProfile.firstName.label")}
            value={firstName}
            onChange={(e) => setFirstName(e.currentTarget.value)}
            error={firstNameError}
            width="20"
          />
          <Input
            type="text"
            data-testid="lastName"
            label={T("containers.user.updateProfile.lastName.label")}
            aria-label={T("containers.user.updateProfile.lastName.label")}
            value={lastName}
            onChange={(e) => setLastName(e.currentTarget.value)}
            error={lastNameError}
            width="20"
          />
        </Fieldset>
        {error && (
          <ErrorSummary
            aria-label={T(
              "containers.user.updateProfile.submissionError.label"
            )}
            role="alert"
            tabIndex={-1}
          >
            <ErrorSummary.Title data-testid="error-summary-title">
              {T("containers.user.updateProfile.submissionError.label")}
            </ErrorSummary.Title>
            <ErrorSummary.Body data-testid="error-summary-body">
              {error.message}
            </ErrorSummary.Body>
          </ErrorSummary>
        )}
        <Button
          data-testid="update-profile-button"
          aria-label={T("containers.user.updateProfile.actions.submit")}
          disabled={loading}
        >
          {T("containers.user.updateProfile.actions.submit")}
        </Button>
      </Form>
    </main>
  );
}
