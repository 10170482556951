import React from "react";
import { SummaryList } from "nhsuk-react-components";

export function ReadOnlyFormField({
  label,
  value,
}: {
  label: string;
  value?: string;
}): JSX.Element {
  const displayValue = value || "";
  if (label === "") {
    return (
      <div className="readonly-answer" data-testid="readonly-field-value">
        {displayValue}
      </div>
    );
  }
  return (
    <div className="readonly-answer">
      {label !== "" && (
        <SummaryList.Key
          style={{ width: "400px" }}
          data-testid="readonly-field-key"
        >
          {label}
        </SummaryList.Key>
      )}
      <SummaryList.Value data-testid="readonly-field-value">
        {displayValue}
      </SummaryList.Value>
    </div>
  );
}
