import React, { useCallback, useEffect, useState } from "react";
import { ErrorPanel, Loader } from "components";
import { IPerson } from "interfaces";
import { ParticipateForm } from "./ParticipateForm";
import { ParticipateResult } from "./ParticipateResult";
import { T } from "i18n";
import { VisitStageProps } from "../../Visit";
import { useAPI } from "api";

export function Participate({
  cohortId,
  visit,
  onComplete,
}: VisitStageProps<"CREATE_PARTICIPANT">): JSX.Element {
  const peopleAPI = useAPI("people");
  const participantAPI = useAPI("participant");

  const [person, setPerson] = useState<IPerson>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();

  const getPerson = useCallback((): Promise<void> => {
    setLoading(true);
    return peopleAPI
      .getPerson(cohortId)
      .then(setPerson)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [cohortId, peopleAPI]);

  const createParticipant = useCallback((): Promise<void> => {
    setLoading(true);
    return participantAPI
      .createParticipant(cohortId, visit.unitCode)
      .then(getPerson)
      .then(onComplete)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [participantAPI, cohortId, visit.unitCode, getPerson, onComplete]);

  useEffect((): void => {
    getPerson();
  }, [getPerson]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <ErrorPanel
        title={T("containers.participate.errors.title")}
        label={T("containers.participate.errors.title")}
      >
        {error.message}
      </ErrorPanel>
    );
  }

  if (person?.studyId) {
    return <ParticipateResult person={person} />;
  }

  return (
    <ParticipateForm
      person={person}
      disabled={loading}
      onSubmit={createParticipant}
    />
  );
}
