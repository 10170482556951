import React from "react";
import { Restricted } from "auth/components";
import { Button, Table } from "nhsuk-react-components";
import { T } from "i18n";
import { ISelfReportedCancerHistory } from "interfaces/ISelfReportedCancerStatus";
import { CallState } from "helpers/AsyncCall";
import { ErrorPanel, Loader } from "components";
import { formatAppointmentDate } from "formatters";
import {
  useFetchSelfReportedCancerHistory,
  useUpdateSelfReportedCancerStatus,
} from "api/people/selfReportedCancerStatus";

interface HistoryTableProps {
  entries: ISelfReportedCancerHistory;
}

const HistoryTable = ({ entries }: HistoryTableProps) => {
  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.Cell>
            {T("components.person.studyEndpointHistory.table.event")}
          </Table.Cell>
          <Table.Cell>
            {T("components.person.studyEndpointHistory.table.user")}
          </Table.Cell>
          <Table.Cell>
            {T("components.person.studyEndpointHistory.table.date")}
          </Table.Cell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {entries.length === 0 && (
          <Table.Row>
            <Table.Cell colSpan={3}>
              {T("components.person.studyEndpointHistory.table.empty")}
            </Table.Cell>
          </Table.Row>
        )}
        {entries.map((entry) => (
          <Table.Row key={entry.timestamp.toString()}>
            <Table.Cell>
              {T(
                `components.person.studyEndpointHistory.table.reported${entry.selfReportedCancer}`
              )}
            </Table.Cell>
            <Table.Cell>{entry.username}</Table.Cell>
            <Table.Cell>{formatAppointmentDate(entry.timestamp)}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

const isMarked = (sortedHistory: ISelfReportedCancerHistory): boolean => {
  if (sortedHistory.length === 0) {
    return false;
  }
  return sortedHistory[sortedHistory.length - 1].selfReportedCancer;
};

interface StudyEndpointSelfReportedCancerProps {
  cohortId: string;
}

export const StudyEndpointSelfReportedCancer = ({
  cohortId,
}: StudyEndpointSelfReportedCancerProps) => {
  const [updateStatus, updateSelfReportedCancerHistory] =
    useUpdateSelfReportedCancerStatus();
  const selfReportedCancerHistory = useFetchSelfReportedCancerHistory(
    cohortId,
    // this is a bit ugly but forces a refetch after an update is successful
    [CallState.NotCalled, CallState.Ok].includes(updateStatus.state)
  );

  switch (selfReportedCancerHistory.state) {
    case CallState.Error:
      return (
        <ErrorPanel title="Error" label="Error">
          {selfReportedCancerHistory.error.message}
        </ErrorPanel>
      );
    case CallState.Loading:
      return <Loader />;
    case CallState.NotCalled:
      return <></>;
    case CallState.Ok: {
      const sortedHistory = selfReportedCancerHistory.result.sort(
        (a, b) => a.timestamp.toSeconds() - b.timestamp.toSeconds()
      );
      const marked = isMarked(sortedHistory);
      return (
        <>
          <HistoryTable entries={sortedHistory} />
          <Restricted allowedRoles={["KCL Research Nurse"]} mode="allowRoles">
            <Button
              disabled={updateStatus.state === CallState.Loading}
              onClick={() => updateSelfReportedCancerHistory(cohortId, !marked)}
              data-testid="self-reported-cancer-button"
            >
              {T(
                marked
                  ? "components.person.studyEndpoint.removeSelfReportedCancer"
                  : "components.person.studyEndpoint.markSelfReportedCancer"
              )}
            </Button>
          </Restricted>
        </>
      );
    }
  }
};
