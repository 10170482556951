import Auth, { CognitoUser } from "@aws-amplify/auth";

import axios from "axios";
import { AuthClient, AuthEvents } from "./AuthProvider";
import { IUser } from "./IUser";
import { env } from "config";

async function fromCognitoUser(
  user: CognitoUser | undefined
): Promise<IUser | undefined> {
  if (user === undefined) {
    return undefined;
  }

  const userSession = user.getSignInUserSession();
  const baseURL = `${env.REACT_APP_API_URL || ""}/api/v1`;

  const token = userSession?.getAccessToken().getJwtToken();
  if (!token) {
    return undefined;
  }

  const { data } = await axios.get(`${baseURL}/user`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  return data;
}

async function checkMFA(
  user: any,
  events: AuthEvents
): Promise<IUser | CognitoUser | undefined> {
  const result = await Auth.getPreferredMFA(user, { bypassCache: true });
  switch (result) {
    case "NOMFA": {
      events.update("REGISTER_TOTP");
      const code = await Auth.setupTOTP(user);
      events.setDeviceRegistrationCode(
        `otpauth://totp/AWSCognito:${user.username}?secret=${code}&issuer=islington`
      );
      return user;
    }
  }
  return fromCognitoUser(user);
}

async function catchChallenges(
  user: any,
  events: AuthEvents
): Promise<IUser | CognitoUser | undefined> {
  if (user.challengeName) {
    events.update(user.challengeName);
    return user;
  }
  return checkMFA(user, events);
}

export const cognitoAuthClient: AuthClient<CognitoUser> = {
  isClientUser: (user): user is CognitoUser =>
    (user as CognitoUser).completeNewPasswordChallenge !== undefined,
  onInit: async (events) =>
    checkMFA(await Auth.currentAuthenticatedUser(), events),
  onSignIn: async (username, password, events) =>
    catchChallenges(await Auth.signIn(username, password), events),
  onCompleteNewPassword: async (user, newPassword, events) =>
    catchChallenges(await Auth.completeNewPassword(user, newPassword), events),
  onConfirmSignIn: async (user, code, mfaType) =>
    fromCognitoUser(await Auth.confirmSignIn(user, code, mfaType)),
  onSignOut: async () => Auth.signOut(),
  onConfirmDevice: async (user, code) => {
    await Auth.verifyTotpToken(user, code);
    await Auth.setPreferredMFA(user, "TOTP");
    return fromCognitoUser(user);
  },
  getCurrentUser: async () =>
    fromCognitoUser(await Auth.currentAuthenticatedUser()),
};
