import axios, { AxiosInstance } from "axios";
import { DateTime } from "luxon";
import { T } from "i18n";

interface ParticipantAuthInputI {
  nhsNumber: string;
  invitationCode?: string;
  dateOfBirth?: DateTime;
  lastName?: string;
}

export class ParticipantAuth {
  private accessToken?: string;
  private expiry?: DateTime;
  private input?: ParticipantAuthInputI;
  private http: AxiosInstance;

  public constructor(http: AxiosInstance) {
    this.http = http;
  }

  private async getNewToken(registering?: boolean): Promise<void> {
    if (!this.input) {
      throw new Error("must register first");
    }
    let accessToken;
    let expiresIn;
    return this.http
      .post("/registrationToken", {
        invitation_code: this.input.invitationCode,
        nhs_number: this.input.nhsNumber,
        registering,
      })
      .then((response) => {
        accessToken = response.data.access_token;
        expiresIn = response.data.expires_in;
        this.expiry = DateTime.now().plus({ seconds: expiresIn });
        this.accessToken = accessToken;
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          const { response } = error;
          if (
            response?.status === 400 &&
            response?.data.error === "AlreadyRegistered"
          ) {
            throw new Error(T("errors.registration.alreadyRegistered"));
          }
          throw new Error(
            T("errors.participantAuth.unrecognisedInvitationCode")
          );
        }

        throw error;
      });
  }

  private async getNewY1Token(): Promise<void> {
    if (!this.input) {
      throw new Error("must enter details first");
    }
    let accessToken;
    let expiresIn;
    return this.http
      .post("/y1BookingToken", {
        nhs_number: this.input.nhsNumber,
        date_of_birth: this.input.dateOfBirth?.toISODate(),
        last_name: this.input.lastName,
      })
      .then((response) => {
        accessToken = response.data.access_token;
        expiresIn = response.data.expires_in;
        this.expiry = DateTime.now().plus({ seconds: expiresIn });
        this.accessToken = accessToken;
      })
      .catch((error) => {
        throw error;
      });
  }

  public async getToken(
    input?: ParticipantAuthInputI,
    registering?: boolean
  ): Promise<string | undefined> {
    if (input) {
      if (input !== this.input) {
        this.expiry = undefined;
      }
      this.input = input;
    }
    if (!this.expiry || this.expiry <= DateTime.now()) {
      if (this.input && !this.input.invitationCode && !registering) {
        await this.getNewY1Token();
      } else {
        await this.getNewToken(registering);
      }
    }
    return this.accessToken;
  }
}
