import React, { useContext, useEffect, useState } from "react";
import debounce from "lodash/debounce";
import {
  Button,
  Col,
  ErrorMessage,
  InsetText,
  Row,
} from "nhsuk-react-components";
import { IAccessibility, IAppointmentSlot } from "interfaces";
import { T } from "i18n";
import {
  appointmentAlreadyExists,
  noAppointmentToRebook,
  slotAlreadyBooked,
} from "../../errors/createAppointment";
import { useAPI } from "api";
import { useHistory } from "react-router-dom";
import { BookingContext } from "./Booking";

interface BookingCreateProps {
  cohortId?: string;
  slot?: IAppointmentSlot;
  accessibility: IAccessibility;
  unitLocationDirections?: string;
}

export const BookingCreate = ({
  cohortId,
  slot,
  accessibility,
  unitLocationDirections,
}: BookingCreateProps): JSX.Element => {
  const history = useHistory();
  const appointmentAPI = useAPI("appointment");

  const [disabled, setDisabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  useEffect(() => setDisabled(!cohortId || !slot), [cohortId, slot]);

  const { isRebooking } = useContext(BookingContext);

  const bookAppointment = async (): Promise<void> => {
    if (cohortId && slot) {
      setLoading(true);
      setError(undefined);
      const operation = isRebooking ? "rebookAppointment" : "createAppointment";
      appointmentAPI[operation](cohortId, slot, accessibility)
        .then((appointment) => {
          history.push(`/appointment/${cohortId}/confirmation`, {
            appointment,
            unitLocationDirections,
            isRebooking,
          });
        })
        .catch(({ message }) => {
          switch (message) {
            case slotAlreadyBooked:
              setError(
                T("containers.booking.actions.book.errors.slotAlreadyBooked")
              );
              break;
            case appointmentAlreadyExists:
              setError(
                T(
                  "containers.booking.actions.book.errors.appointmentAlreadyExist"
                )
              );
              break;
            case noAppointmentToRebook:
              setError(
                T(
                  "containers.booking.actions.rebook.errors.noAppointmentToRebook",
                  {
                    number: T("contact.phoneNumber"),
                  }
                )
              );
              break;
            default:
              setError(
                T("containers.booking.actions.book.errors.unknown", {
                  number: T("contact.phoneNumber"),
                })
              );
              break;
          }
          setLoading(false);
        });
    }
  };
  const debouncedBookAppointment = debounce(bookAppointment, 300, {
    leading: true,
    trailing: false,
  });

  const bookingLabel = isRebooking
    ? T("containers.booking.actions.rebook.label")
    : T("containers.booking.actions.book.label");

  return (
    <>
      <Row>
        <Col width="full">
          {error !== undefined && (
            <ErrorMessage data-testid="booking-create-error">
              {JSON.stringify(error)}
            </ErrorMessage>
          )}
          <Button
            data-testid="booking-create-confirm"
            onClick={debouncedBookAppointment}
            disabled={disabled || loading}
          >
            {loading
              ? T("containers.booking.actions.book.loading")
              : bookingLabel}
          </Button>
        </Col>
      </Row>
      {isRebooking && (
        <InsetText>
          <p>{T("containers.booking.actions.rebook.footnote")}</p>
        </InsetText>
      )}
    </>
  );
};
