import { useAuth } from "../useAuth";
import { useEffect } from "react";

export const AuthAction = ({ type }: { type: "signIn" | "signOut" }): null => {
  const { [type]: authAction } = useAuth();
  useEffect(() => {
    const action = async (): Promise<void> => {
      try {
        await (authAction as () => Promise<any>)();
      } catch (ex) {
        console.error(ex);
      }
    };
    action();
    // eslint-disable-next-line
  }, []);
  return null;
};
