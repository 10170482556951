import React from "react";
import { Select } from "nhsuk-react-components";
import { T } from "i18n";
import { Month } from "helpers/month";

interface MonthSelectProps {
  value?: Month;
  onChange: (val: Month) => void;
  style?: React.CSSProperties;
}

export const MonthSelect = ({
  value,
  onChange,
  style,
}: MonthSelectProps): JSX.Element => {
  return (
    <Select
      defaultValue={0}
      value={value}
      style={style}
      onChange={(e) => onChange(parseInt(e.currentTarget.value, 10) as Month)}
    >
      <Select.Option value={0} disabled={true}>
        {T("components.monthSelect.placeholder")}
      </Select.Option>
      {Array.from({ length: 12 }, (_, i) => i + 1).map((monthNumber) => (
        <Select.Option key={monthNumber} value={monthNumber}>
          {T(`months.${monthNumber}`)}
        </Select.Option>
      ))}
    </Select>
  );
};
