import React, { useCallback, useEffect, useState } from "react";
import { ECRF, Loader } from "components";
import { DateInput as ECRFDateInput } from "../../../components/ecrf/inputs";
import { ErrorMessage } from "nhsuk-react-components";
import { EventType, IForm } from "interfaces";
import { FormAnswer } from "@grailbio/components-ecrf";
import { T } from "i18n";
import { VisitStageProps } from "../Visit";
import { useAPI } from "api";
import { VisitPerson } from "../VisitPerson";

const FORM_TYPE = "BLOOD_DRAW";

type BloodDrawProps = VisitStageProps<`${EventType}_BLOOD_DRAW`>;

export function BloodDraw({
  stage,
  cohortId,
  onComplete,
  isComplete,
  person,
}: BloodDrawProps): JSX.Element {
  const formAPI = useAPI("forms");

  const [form, setForm] = useState<IForm>();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<Error>();

  const eventType = stage.split("_BLOOD_DRAW")[0];

  const getBloodDrawForm = useCallback((): void => {
    formAPI
      .getForm(cohortId, FORM_TYPE, eventType)
      .then(setForm)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [formAPI, cohortId, eventType]);

  useEffect(() => {
    setLoading(true);
    getBloodDrawForm();
  }, [formAPI, cohortId, onComplete, getBloodDrawForm]);

  const onSubmit = (answers: FormAnswer[]): void => {
    if (!form) {
      return;
    }
    setError(undefined);
    setSubmitting(true);
    formAPI
      .saveForm(cohortId, {
        ...form,
        answers,
      })
      .then(onComplete)
      .then(getBloodDrawForm)
      .catch(setError)
      .finally(() => setSubmitting(false));
  };

  if (isComplete) {
    return (
      <>
        {person && <VisitPerson person={person} />}
        <p data-testid="complete">{T("containers.bloodDraw.completed")}</p>
      </>
    );
  }

  const apiNamePrefix = eventType.toLowerCase();

  const formSubmittedWithoutBloodDrawCollected =
    form?.answers?.some?.(
      (answer) =>
        answer.apiName === `${apiNamePrefix}_blood_draw_collected` &&
        answer.values?.includes("NO")
    ) || false;
  if (formSubmittedWithoutBloodDrawCollected) {
    return (
      <p data-testid="abort-suggestion">
        {T("containers.bloodDraw.abortSuggestion")}
      </p>
    );
  }

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
          <h1>{T("containers.bloodDraw.title")}</h1>
          {person && <VisitPerson person={person} />}
          {form && (
            <ECRF
              showTitle={false}
              showSectionNames={false}
              definition={form.definition}
              onSubmit={onSubmit}
              initialAnswers={form.answers || []}
              disabled={submitting}
              renderOverride={{
                [`${apiNamePrefix}_blood_draw_adverse_event_start_date`]:
                  ECRFDateInput,
                [`${apiNamePrefix}_blood_draw_adverse_event_end_date`]:
                  ECRFDateInput,
              }}
            />
          )}
          {error && <ErrorMessage>{error.message}</ErrorMessage>}
        </>
      )}
    </>
  );
}
