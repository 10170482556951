import React, { Ref, forwardRef, useImperativeHandle, useState } from "react";
import { FormValidity, ValiditySummary } from "../../components";
import { SurveyAnswers, SurveyQuestions } from "../../api";
import { SurveyQuestion } from "./SurveyQuestion";
import { T } from "i18n";
import { ValidateFormHandle } from "../../validations/ValidateFormHandle";
import { WarningCallout } from "nhsuk-react-components";
import { hasWords } from "../../validations";

interface AgeFormProps {
  survey: SurveyQuestions;
  answers: SurveyAnswers;
  onAnswerChanged: (answers: { [key: string]: string }) => void;
}

export const AgeForm = forwardRef(
  (
    { survey, answers, onAnswerChanged }: AgeFormProps,
    ref: Ref<ValidateFormHandle>
  ) => {
    const { questions } = survey;
    const [ageValidity, setAgeValidity] = useState<FormValidity>(
      new FormValidity()
    );

    const ids = {
      ageQuestion: "ageQuestion",
    };

    useImperativeHandle(ref, () => ({
      validate() {
        const validity: FormValidity = new FormValidity();

        const ageQuestionError = hasWords(
          answers.age,
          T("containers.survey.questionUnansweredError")
        );
        validity.addError(ageQuestionError, ids.ageQuestion);

        setAgeValidity(validity);

        return validity.valid;
      },
    }));

    return (
      <>
        <h1 data-testid="survey-age-questions-title">
          {T("containers.survey.age.title")}
        </h1>
        <ValiditySummary validity={ageValidity} />
        <p data-testid="survey-age-questions-instructions">
          {T("containers.survey.age.description")}
        </p>
        <SurveyQuestion
          id={ids.ageQuestion}
          text={questions.age}
          error={ageValidity.getFirstErrorForId(ids.ageQuestion)?.message}
          onChange={(value) => {
            onAnswerChanged({ age: value });
          }}
          value={answers.age}
        />
        <WarningCallout label={T("containers.survey.age.warning.title")}>
          <WarningCallout.Label>
            {T("containers.survey.age.warning.title")}
          </WarningCallout.Label>
          <p>{T("containers.survey.age.warning.body")}</p>
        </WarningCallout>
      </>
    );
  }
);
