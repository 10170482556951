import "./TopBar.scss";
import React, { useCallback, useState } from "react";
import {
  BodyText,
  Button,
  Checkboxes,
  Container,
  Select,
  SummaryList,
  Textarea,
  Card,
} from "nhsuk-react-components";
import { CaseStatus } from "enums";
import {
  IAccessibility,
  IAccessibilityOptions,
  IAddress,
  IPerson,
  ReportResult,
} from "interfaces";
import { T } from "i18n";
import { Address } from "../../../components";
import { formatDateOfBirth } from "../../../formatters";

const StatusSelector = ({
  status,
  setStatus,
}: {
  status: CaseStatus;
  setStatus: (status: CaseStatus) => void;
}): JSX.Element => {
  return (
    <Select
      data-testid="select-case-status"
      value={status}
      onChange={(e) => {
        setStatus(e.currentTarget.value as CaseStatus);
      }}
    >
      <Select.Option key={CaseStatus.OPEN} value={CaseStatus.OPEN}>
        {T(`containers.resultsCase.summary.status.${CaseStatus.OPEN}`)}
      </Select.Option>
      <Select.Option key={CaseStatus.CLOSED} value={CaseStatus.CLOSED}>
        {T(`containers.resultsCase.summary.status.${CaseStatus.CLOSED}`)}
      </Select.Option>
    </Select>
  );
};

function getAccessibilityText(
  accessibilityOptions: IAccessibility | undefined
): string {
  if (accessibilityOptions === undefined) {
    return "";
  } else {
    const options: (keyof IAccessibilityOptions)[] = [
      "parking",
      "wheelchair",
      "stepFree",
      "visual",
      "hearing",
      "signLanguage",
      "interpreter",
    ];

    let accessibilityText = options
      .filter(
        (option: keyof IAccessibilityOptions) =>
          accessibilityOptions.options[option]
      )
      .map((option) => {
        if (option === "interpreter") {
          return (
            T(
              "containers.resultsCase.summary.details.accessibility.interpreter"
            ) +
            T(
              `interpreterLanguages.${accessibilityOptions.interpreterLanguage}`
            )
          );
        } else {
          return T(`components.accessibilityRequirements.${option}`);
        }
      })
      .join("\n");
    accessibilityText =
      accessibilityText.length !== 0
        ? accessibilityText
        : accessibilityText.concat(
            T("components.accessibilityRequirements.none")
          );
    return accessibilityText;
  }
}

export const TopBar = ({
  participantDetails,
  save,
  caseStatus,
  closeNote,
  setCloseNote,
  appointmentAddress,
  appointmentAccessibility,
  y0Result,
  y1Result,
  y2Result,
}: {
  save: (status: CaseStatus) => Promise<void>;
  caseStatus: CaseStatus;
  participantDetails: IPerson;
  closeNote: string;
  setCloseNote: (input: string) => void;
  appointmentAddress: IAddress | undefined;
  appointmentAccessibility: IAccessibility | undefined;
  y0Result: ReportResult | undefined;
  y1Result: ReportResult | undefined;
  y2Result: ReportResult | undefined;
}): JSX.Element => {
  const [saving, setSaving] = useState<boolean>(false);
  const [status, setStatus] = useState<CaseStatus>(caseStatus);
  const [participantNotified, setParticipantNotified] = useState<boolean>(
    () => status === CaseStatus.CLOSED
  );

  const onSaveHandler = useCallback((): void => {
    setSaving(true);
    save(status)
      .catch(() => {
        // ignore
      })
      .finally(() => setSaving(false));
  }, [status, save, setSaving]);

  return (
    <Card className="participant-details">
      <Card.Content>
        <SummaryList label="results-top-bar">
          <SummaryList.Row>
            <SummaryList.Key>
              {T("containers.resultsCase.summary.details.dob")}
            </SummaryList.Key>
            <SummaryList.Value
              aria-label={T("containers.resultsCase.summary.details.dob")}
            >
              {formatDateOfBirth(participantDetails.dateOfBirth)}
            </SummaryList.Value>
            <SummaryList.Actions />
          </SummaryList.Row>
          <SummaryList.Row>
            <SummaryList.Key>
              {T("containers.resultsCase.summary.details.nhs")}
            </SummaryList.Key>
            <SummaryList.Value
              aria-label={T("containers.resultsCase.summary.details.nhs")}
            >
              {participantDetails.nhsNumber}
            </SummaryList.Value>
            <SummaryList.Actions />
          </SummaryList.Row>
          <SummaryList.Row>
            <SummaryList.Key>
              {T("containers.resultsCase.summary.details.cohortId")}
            </SummaryList.Key>
            <SummaryList.Value
              aria-label={T("containers.resultsCase.summary.details.cohortId")}
            >
              {participantDetails.cohortId}
            </SummaryList.Value>
            <SummaryList.Actions />
          </SummaryList.Row>
          <SummaryList.Row>
            <SummaryList.Key>
              {T("containers.resultsCase.summary.details.studyId")}
            </SummaryList.Key>
            <SummaryList.Value
              aria-label={T("containers.resultsCase.summary.details.studyId")}
            >
              {participantDetails.studyId}
            </SummaryList.Value>
            <SummaryList.Actions />
          </SummaryList.Row>
          <SummaryList.Row>
            <SummaryList.Key>
              {T("containers.resultsCase.summary.details.accessibility.name")}
            </SummaryList.Key>
            <SummaryList.Value
              aria-label={T(
                "containers.resultsCase.summary.details.accessibility.name"
              )}
            >
              <BodyText>
                {getAccessibilityText(appointmentAccessibility)}
              </BodyText>
            </SummaryList.Value>
            <SummaryList.Actions />
          </SummaryList.Row>
          <SummaryList.Row>
            <SummaryList.Key>
              {T("containers.resultsCase.summary.details.appointmentAddress")}
            </SummaryList.Key>
            <SummaryList.Value
              aria-label={T(
                "containers.resultsCase.summary.details.appointmentAddress"
              )}
            >
              {appointmentAddress && (
                <Address address={appointmentAddress} properCase />
              )}
            </SummaryList.Value>
            <SummaryList.Actions />
          </SummaryList.Row>
          <SummaryList.Row>
            <SummaryList.Key>
              {T("containers.resultsCase.summary.details.y0Result")}
            </SummaryList.Key>
            <SummaryList.Value
              aria-label={T("containers.resultsCase.summary.details.y0Result")}
            >
              {T("containers.resultsCase.summary.details.result", {
                context: y0Result,
              })}
            </SummaryList.Value>
            <SummaryList.Actions />
          </SummaryList.Row>
          <SummaryList.Row>
            <SummaryList.Key>
              {T("containers.resultsCase.summary.details.y1Result")}
            </SummaryList.Key>
            <SummaryList.Value
              aria-label={T("containers.resultsCase.summary.details.y1Result")}
            >
              {T("containers.resultsCase.summary.details.result", {
                context: y1Result,
              })}
            </SummaryList.Value>
            <SummaryList.Actions />
          </SummaryList.Row>
          <SummaryList.Row>
            <SummaryList.Key>
              {T("containers.resultsCase.summary.details.y2Result")}
            </SummaryList.Key>
            <SummaryList.Value
              aria-label={T("containers.resultsCase.summary.details.y2Result")}
            >
              {T("containers.resultsCase.summary.details.result", {
                context: y2Result,
              })}
            </SummaryList.Value>
            <SummaryList.Actions />
          </SummaryList.Row>
        </SummaryList>
        <div className="save-row">
          <StatusSelector
            status={status}
            setStatus={(status) => {
              setParticipantNotified(false);
              setStatus(status);
            }}
          />
          <Button
            disabled={
              saving || (status === CaseStatus.CLOSED && !participantNotified)
            }
            onClick={onSaveHandler}
            data-testid="save-case-button"
          >
            {T("containers.resultsCase.summary.saveButton")}
          </Button>
        </div>
        {status === CaseStatus.CLOSED && (
          <Container>
            <Checkboxes>
              <Checkboxes.Box
                checked={participantNotified}
                onChange={(e) =>
                  setParticipantNotified(e.currentTarget.checked)
                }
                data-testid="confirm-participant-notified"
              >
                {T("containers.resultsCase.summary.confirmParticipantNotified")}
              </Checkboxes.Box>
            </Checkboxes>
          </Container>
        )}
        {status === CaseStatus.CLOSED && participantNotified && (
          <Container>
            <Textarea
              label={T("containers.resultsCase.note.label")}
              aria-label={T("containers.resultsCase.note.label")}
              data-testid="closeNote"
              onChange={(e) => setCloseNote(e.currentTarget.value)}
              value={closeNote}
              maxLength={2000}
            />
          </Container>
        )}
      </Card.Content>
    </Card>
  );
};
