import "./AppointmentTimeSlot.scss";
import React from "react";
import { DateTime } from "luxon";
import { T } from "i18n";
import { formatTime } from "formatters";

type Props = {
  datetime: DateTime;
  available: number;
};

export const AppointmentTimeSlot = ({
  datetime,
  available,
}: Props): JSX.Element => {
  return (
    <div data-testid="slot" className="time-slot">
      <div data-testid="time" className="time">
        {formatTime(datetime)}
      </div>
      <div className="progress" data-testid="available">
        {T("components.appointmentTimeSlot.available", {
          available,
        })}
      </div>
    </div>
  );
};
