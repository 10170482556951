import React, { useEffect, useState } from "react";
import {
  AsyncCall,
  CallState,
  err,
  notCalled,
  ok,
} from "../../helpers/AsyncCall";
import { ErrorPanel, Loader } from "../../components";
import { IPersonDeceasedStatus } from "../../interfaces";
import { T } from "i18n";
import { Table } from "nhsuk-react-components";
import { useAPI } from "api";

interface PersonDeceasedStatusProps {
  cohortId: string;
}

export const PersonDeceasedStatus = ({
  cohortId,
}: PersonDeceasedStatusProps): JSX.Element => {
  const [deceasedStatus, setDeceasedStatus] =
    useState<AsyncCall<IPersonDeceasedStatus>>(notCalled);
  const personApi = useAPI("people");

  useEffect((): void => {
    personApi
      .getPersonDeceasedStatus(cohortId)
      .then((result) => setDeceasedStatus(ok(result)))
      .catch((result) => setDeceasedStatus(err(result)));
  }, [personApi, cohortId]);

  switch (deceasedStatus.state) {
    case CallState.Error:
      return (
        <ErrorPanel
          data-testid="deceased-error"
          title="Error"
          label="Deceased Error"
        >
          {deceasedStatus.error.message}
        </ErrorPanel>
      );
    case CallState.Loading:
      return <Loader />;
    case CallState.NotCalled:
      return <></>;
    case CallState.Ok:
      return (
        <>
          <h4 data-testid="person-deceased-status-title">
            {T("components.person.deceased.title")}
          </h4>
          <Table data-testid="person-deceased-status-table">
            <Table.Head>
              <Table.Row>
                <Table.Cell>
                  {T("components.person.deceased.table.event")}
                </Table.Cell>
                <Table.Cell width="20%">
                  {T("components.person.deceased.table.user")}
                </Table.Cell>
                <Table.Cell width="20%">
                  {T("components.person.deceased.table.date")}
                </Table.Cell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.Cell data-testid="deceased-status">
                  {T("components.person.deceased.table.markedTrue")}
                </Table.Cell>
                <Table.Cell data-testid="deceased-status-username">
                  {deceasedStatus.result.username}
                </Table.Cell>
                <Table.Cell data-testid="deceased-status-timestamp">
                  {deceasedStatus.result.timestamp.toFormat("dd/LL/yyyy HH:mm")}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </>
      );
  }
};
