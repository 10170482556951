import React from "react";
import { Link } from "react-router-dom";
import { T } from "i18n";
import { useAuth } from "../auth";

export const AuthenticatedUser = (): JSX.Element | null => {
  const { user, error } = useAuth();

  if (user === undefined) {
    return null;
  }

  return (
    <div className="nhsuk-header__menu-account">
      <div className="nhsuk-account-user">
        <span
          className="nhsuk-account-user__username"
          aria-label="display-name"
        >
          {user.id}
        </span>
        <Link to="/sign-out" aria-label="sign-out">
          {T("components.login.signOut")}
        </Link>
      </div>
      {error && (
        <div className="nhsuk-account-error">
          <pre>{error}</pre>
        </div>
      )}
    </div>
  );
};
