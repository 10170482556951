import React from "react";
import { DateTime } from "luxon";
import { IndividualConsent } from "api";
import { SummaryList } from "nhsuk-react-components";
import { T } from "i18n";

export interface ConsentSummaryProps {
  consent: IndividualConsent;
}

function getFormattedDate(isoDate: string): string {
  const localDate = DateTime.fromISO(isoDate);
  return localDate.toFormat("dd LLL yyyy, h:mm a");
}

export const ConsentResult = ({
  consent,
}: ConsentSummaryProps): JSX.Element => (
  <SummaryList data-testid="individual-consent">
    <SummaryList.Row>
      <SummaryList.Key>
        {T("containers.consent.summary.version")}
      </SummaryList.Key>
      <SummaryList.Value>{consent.version}</SummaryList.Value>
    </SummaryList.Row>
    <SummaryList.Row>
      <SummaryList.Key>
        {T("containers.consent.summary.signedAt")}
      </SummaryList.Key>
      <SummaryList.Value data-testid="individual-consent-signed">
        {getFormattedDate(consent.signedAt)}
      </SummaryList.Value>
    </SummaryList.Row>
    <SummaryList.Row>
      <SummaryList.Key>
        {T("containers.consent.summary.researchOptIn.text")}
      </SummaryList.Key>
      <SummaryList.Value>
        {consent.researchOptIn
          ? T("containers.consent.summary.researchOptIn.yes")
          : T("containers.consent.summary.researchOptIn.no")}
      </SummaryList.Value>
    </SummaryList.Row>
    <SummaryList.Row>
      <SummaryList.Key>{T("containers.consent.summary.name")}</SummaryList.Key>
      <SummaryList.Value>{`${consent.firstName} ${consent.lastName}`}</SummaryList.Value>
    </SummaryList.Row>
    <SummaryList.Row>
      <SummaryList.Key>
        {T("containers.consent.summary.witnessedAt")}
      </SummaryList.Key>
      <SummaryList.Value data-testid="individual-consent-witnessed">
        {getFormattedDate(consent.witnessedAt)}
      </SummaryList.Value>
    </SummaryList.Row>
  </SummaryList>
);
