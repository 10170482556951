import React, { useEffect, useState } from "react";
import { ErrorMessage, SummaryList } from "nhsuk-react-components";
import { IStudyEvent } from "interfaces";
import { Loader } from "components";
import { T } from "i18n";
import { useAPI } from "api";

export function StudyEvents(props: { cohortId: string }): JSX.Element {
  const { cohortId } = props;
  const studyEventsAPI = useAPI("studyEvents");
  const [studyEvents, setStudyEvents] = useState<IStudyEvent[]>([]);
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    studyEventsAPI
      .getStudyEvents(cohortId)
      .then(setStudyEvents)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [studyEventsAPI, cohortId]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <ErrorMessage
        title={T("components.person.studyEvents.errors.loadStudyEvents")}
      >
        {error.message}
      </ErrorMessage>
    );
  }

  if (studyEvents.length === 0) {
    return <></>;
  }

  return (
    <>
      <h3>{T("components.person.studyEvents.title")}</h3>
      <SummaryList>
        {studyEvents.map(({ eventType, readinessDate }) =>
          readinessDate ? (
            <SummaryList.Row key={eventType}>
              <SummaryList.Key>{eventType}</SummaryList.Key>
              <SummaryList.Value>
                {T("components.person.studyEvents.canBook", {
                  from: readinessDate.toFormat("cccc d LLLL yyyy"),
                  to: readinessDate
                    .plus({ weeks: 12 })
                    .toFormat("cccc d LLLL yyyy"),
                })}
              </SummaryList.Value>
            </SummaryList.Row>
          ) : null
        )}
      </SummaryList>
    </>
  );
}
