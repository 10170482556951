import { AxiosInstance } from "axios";
import { CreatePersonNote, INote, NoteCreated } from "../../interfaces";
import { DateTime } from "luxon";

export interface INoteClient {
  createNote: (note: CreatePersonNote) => Promise<NoteCreated>;
  getNotes: (cohortId: string) => Promise<INote[]>;
}

export class NoteClient implements INoteClient {
  public constructor(protected readonly http: AxiosInstance) {}

  async createNote(note: CreatePersonNote): Promise<NoteCreated> {
    const response = await this.http.post(
      `/people/${note.cohortId}/notes`,
      note
    );

    return response.data;
  }

  async getNotes(cohortId: string): Promise<INote[]> {
    const response = await this.http.get(`/people/${cohortId}/notes`);
    const notes = response.data.map((note: { createdAt: string }) => ({
      ...note,
      createdAt: DateTime.fromISO(note.createdAt),
    }));
    return notes;
  }
}
