import React, { useEffect } from "react";
import { DateTime } from "luxon";
import { FormFieldProps } from "@grailbio/components-ecrf";
import { IDateInputValue } from "interfaces";
import { ReadOnlyFormField } from "./ReadOnlyFormField";
import { DateInput as StandardDateInput } from "../../DateInput";
import { dateInputToDateTime } from "helpers";
import { useDate } from "hooks";

function dateInputToDateString(dateTime: IDateInputValue): string {
  const { day, month, year } = dateTime;
  if ([day, month, year].every((s) => s === "")) {
    return "";
  }
  try {
    const value = dateInputToDateTime(dateTime);
    if (value.isValid) {
      return value.toISODate();
    }
  } catch {
    // error is thrown for empty/invalid inputs
  }
  return "INVALID";
}

interface DateInputProps extends FormFieldProps {
  RenderDateInput?: typeof StandardDateInput;
}

export function DateInput(props: DateInputProps): JSX.Element {
  const {
    definition,
    value,
    setValue,
    readOnly,
    disabled,
    RenderDateInput = StandardDateInput,
  } = props;
  const { label } = definition;

  const [date, error, setter] = useDate();

  useEffect(() => {
    if (value !== "") {
      const parsed = DateTime.fromISO(value);
      if (parsed.isValid) {
        setter({
          day: parsed.day.toString(),
          month: parsed.month.toString(),
          year: parsed.year.toString(),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const candidateValue = dateInputToDateString(date);
    if (value !== candidateValue) {
      setValue(candidateValue);
    }
  }, [date, value, setValue]);

  if (readOnly) {
    return (
      <ReadOnlyFormField
        label={label}
        value={DateTime.fromISO(value).toFormat("dd/MM/yyyy")}
      />
    );
  }

  return (
    <RenderDateInput
      data-testid="dateinputfield"
      label={label}
      disabled={definition.systemPopulated || disabled}
      setDate={setter}
      aria-label={label}
      date={date}
      error={error}
    />
  );
}
