import React, { useCallback, useEffect, useState } from "react";
import { INote } from "interfaces";
import { T } from "i18n";
import { useAPI } from "api";
import { Notes } from "components/Notes";
import {
  AsyncCall,
  CallState,
  err,
  loading,
  notCalled,
  ok,
} from "helpers/AsyncCall";
import { ErrorPanel } from "components";

interface PersonNotesProps {
  cohortId: string;
}

export const PersonNotes = ({ cohortId }: PersonNotesProps): JSX.Element => {
  const [notes, setNotes] = useState<AsyncCall<INote[]>>(notCalled);
  const noteAPI = useAPI("note");
  const getNotes = useCallback((): void => {
    noteAPI
      .getNotes(cohortId)
      .then((notes) => setNotes(ok(notes)))
      .catch(({ message }) => {
        setNotes(err(message));
      });
  }, [cohortId, noteAPI]);

  useEffect(() => {
    setNotes(loading);
    getNotes();
  }, [getNotes]);

  const createNote = (content: string): Promise<any> => {
    return noteAPI.createNote({ cohortId, content }).then(() => getNotes());
  };

  const page = (content: React.ReactElement) => {
    return (
      <>
        <h4>{T("containers.participant.notes.title")}</h4>
        {content}
      </>
    );
  };
  switch (notes.state) {
    case CallState.NotCalled:
      return page(<></>);
    case CallState.Loading:
      return page(<p>{T("containers.participant.notes.loading")}</p>);
    case CallState.Error:
      return page(
        <ErrorPanel
          title={T("containers.participant.notes.errors.load")}
          label={T("containers.participant.notes.errors.load")}
        >
          {notes.error}
        </ErrorPanel>
      );
    case CallState.Ok:
      return page(<Notes notes={notes.result} createNote={createNote} />);
  }
};
