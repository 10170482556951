import React from "react";

import { AccessibilityIconProps } from "./types";

export function WheelchairIcon({
  title,
  stroke = 4,
  color = "#005EB8",
  size = 16,
}: React.PropsWithChildren<AccessibilityIconProps>): JSX.Element {
  return (
    <svg height={size} width={size} viewBox="0 0 240 240" aria-label={title}>
      <g>
        <path
          fill="none"
          stroke={color}
          strokeWidth={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M157,167.8c-6.8,25.6-30.2,44.5-58,44.5c-33.1,0-60-26.9-60-60c0-27.8,18.9-51.1,44.5-58"
        />
        <circle
          fill="none"
          stroke={color}
          strokeWidth={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          cx="99"
          cy="44.4"
          r="21"
        />
        <line
          fill="none"
          stroke={color}
          strokeWidth={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          x1="99"
          y1="100.6"
          x2="159"
          y2="100.6"
        />
        <path
          fill="none"
          stroke={color}
          strokeWidth={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M99,66.1v69.9c0,5,4,9,9,9h45.8c3.2,0,6.2,1.7,7.8,4.5L189,197l18-10.1"
        />
      </g>
    </svg>
  );
}
