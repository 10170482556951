export enum VisitStatus {
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  ABORTED_SELF_DECISION = "ABORTED_SELF_DECISION",
  ABORTED_INELIGIBLE = "ABORTED_INELIGIBLE",
  ABORTED_NOT_CONSENTED = "ABORTED_NOT_CONSENTED",
  ABORTED_OPERATIONAL = "ABORTED_OPERATIONAL",
  ABORTED_TECHNICAL = "ABORTED_TECHNICAL",
  ABORTED_OTHER = "ABORTED_OTHER",
}
