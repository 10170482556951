import Auth from "@aws-amplify/auth";
import { env } from "config";

const amplifyConf = {
  region: env.REACT_APP_AWS_REGION,
  userPoolId: env.REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
};

Auth.configure(amplifyConf);
