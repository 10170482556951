import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, WarningCallout } from "nhsuk-react-components";
import { DateTime } from "luxon";
import { Duration } from "../../api/appointment";
import { ErrorPanel, Loader, Schedule, WeekSelector } from "components";
import {
  IAppointment,
  ISchedule,
  IUnitCapacitiesUpdateInput,
  IUnitCapacityPerDay,
  IUnitCapacityUpdateInput,
} from "interfaces";
import { T } from "i18n";
import { useAPI } from "api";
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router";
import { LocationState } from "./UnitLocation";

export function UnitCapacity(): JSX.Element {
  const history = useHistory();
  const location = useLocation<LocationState>();

  const { unitCode } = useParams<{ unitCode: string }>();
  const [capacities, setCapacities] = useState<IUnitCapacityPerDay[]>();
  const [appointments, setAppointments] = useState<IAppointment[]>();
  const [locations, setLocations] = useState<ISchedule[]>();
  const [submissionError, setSubmissionError] = useState("");
  const [startDateOfWeek, setStartDateOfWeek] = useState(
    // FIXME(ctang): The use of now here potentially incorrectly filters out results
    //  eg. when it is 1/7 00:00 BST, as the server considers dates as UTC, results of 1/7 are returned, but not results
    //  from 30/6 23:00 UTC to 30/6 23:59 UTC which should be included as part of the results
    DateTime.now().startOf("week")
  );
  const [loading, setLoading] = useState(false);

  const onAddCapacityClick = (): void => {
    const path = `/unit/${unitCode}/capacity/add`;
    history.push(path);
  };

  const unitAPI = useAPI("unit");

  const loadData = useCallback(
    (useFullScreenLoader = true) => {
      if (useFullScreenLoader) {
        setLoading(true);
      }
      const start = startDateOfWeek;
      const end = startDateOfWeek.plus({ days: 6 });

      let duration: Duration = 30;
      const y1StartWeekMonday = DateTime.fromISO(
        process.env.REACT_APP_Y1_START_DATE || "2022-08-29T00:00:00+01:00"
      ).startOf("week");
      if (y1StartWeekMonday <= start) {
        duration = 15;
      }
      unitAPI
        .findCapacities(unitCode, start, end, duration)
        .then(({ availabilities, locations }) => {
          setCapacities(availabilities);
          setLocations(locations);
          setSubmissionError("");
        })
        .catch((err) =>
          setSubmissionError(T("containers.unit.capacity.errors.unableToLoad"))
        )
        .finally(() => {
          if (useFullScreenLoader) {
            setLoading(false);
          }
        });
    },
    [unitAPI, unitCode, startDateOfWeek]
  );

  const loadAppointments = useCallback(() => {
    setLoading(true);
    const start = startDateOfWeek;
    const end = startDateOfWeek.plus({ days: 6 });
    unitAPI
      .getAppointmentsByDateRange(unitCode, start, end, "BOOKED")
      .then((appointments) => {
        setAppointments(appointments);
        setSubmissionError("");
      })
      .catch(() =>
        setSubmissionError(
          T("containers.unit.capacity.errors.unableToLoadAppointments")
        )
      )
      .finally(() => {
        setLoading(false);
      });
  }, [unitAPI, unitCode, startDateOfWeek]);

  useEffect(() => {
    loadData();
    loadAppointments();
  }, [loadData, loadAppointments]);

  const updateStatuses = async (
    input: Omit<IUnitCapacitiesUpdateInput, "unitCode" | "newCapacity">
  ): Promise<void> => {
    return unitAPI
      .updateUnitCapacities({
        unitCode,
        ...input,
      })
      .then(() => {
        loadData();
      });
  };

  const bulkUpdateCapacities = (
    input: Omit<IUnitCapacityUpdateInput, "unitCode">[]
  ): Promise<void> => {
    const updatedInput: IUnitCapacityUpdateInput[] = input.map((val) => ({
      ...val,
      unitCode: unitCode,
    }));
    return unitAPI
      .bulkUpdateUnitCapacitiesWithinADay(updatedInput)
      .then(() => loadData(false));
  };

  let schedule: JSX.Element = <></>;
  if (submissionError === "" && locations && capacities && appointments) {
    const dates = Array.from(Array(7)).map((v, i) =>
      startDateOfWeek.plus({ days: i })
    );
    schedule = (
      <Schedule
        dates={dates}
        locations={locations}
        capacities={capacities}
        appointments={appointments}
        bulkUpdateCapacities={bulkUpdateCapacities}
        updateStatuses={updateStatuses}
      />
    );
  }

  return (
    <main className="nhsuk-main-wrapper">
      {location.state?.error && (
        <WarningCallout>
          <WarningCallout.Label>
            {T("containers.unit.capacity.errors.cache.label")}
          </WarningCallout.Label>
          <p>{location.state.error}</p>
        </WarningCallout>
      )}

      <Card>
        <Card.Content>
          <Button
            aria-label={T("containers.unit.capacity.actions.add")}
            onClick={(event: React.MouseEvent) => {
              onAddCapacityClick();
            }}
          >
            {T("containers.unit.capacity.actions.add")}
          </Button>

          <WeekSelector date={startDateOfWeek} setDate={setStartDateOfWeek} />

          {loading ? (
            <Loader />
          ) : (
            <>
              <>{schedule}</>
            </>
          )}
          {submissionError !== "" && (
            <ErrorPanel
              label="submission-error"
              title={
                <span id="error-summary-title">
                  {T("containers.unit.capacity.errors.submissionErrorTitle")}
                </span>
              }
            >
              <p>{submissionError}</p>
            </ErrorPanel>
          )}
        </Card.Content>
      </Card>
    </main>
  );
}
