import { env } from "config";

export const createScriptURL = (s: string): string => {
  const uuidRegExp =
    /[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/;
  const regExp = new RegExp(
    `blob:${env.REACT_APP_SELF_URL}/${uuidRegExp.source}#toolbar=0&navpanes=0&scrollbar=0`
  );
  if (regExp.test(s)) {
    return s;
  }
  return "";
};
