export class AssetStore<T> {
  private constructor(private name: string, private db: IDBDatabase) {}

  static async open<T>(name: string, version = 1): Promise<AssetStore<T>> {
    return new Promise((resolve, reject) => {
      const request = window.indexedDB.open(name, version);
      request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        const target = event.target as IDBRequest;
        const db: IDBDatabase = target.result;
        db.createObjectStore(name);
      };
      request.onerror = (event) => reject(event);
      request.onsuccess = () =>
        resolve(new AssetStore(name, request.result as IDBDatabase));
    });
  }

  async put(key: string, value: T): Promise<IDBValidKey> {
    return AssetStore.wrap(this.store().put(value, key));
  }

  async get(key: string): Promise<T | undefined> {
    return AssetStore.wrap(this.store().get(key));
  }

  private store(): IDBObjectStore {
    return this.db.transaction([this.name], "readwrite").objectStore(this.name);
  }

  private static wrap<T>(request: IDBRequest<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      request.onsuccess = () => resolve(request.result);
      request.onerror = (event: any) => reject(event);
    });
  }
}
