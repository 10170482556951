import React, { useCallback, useEffect, useState } from "react";
import { Button } from "nhsuk-react-components";
import { ErrorPanel, Loader } from "components";
import { IPerson, IVisit } from "interfaces";
import { Redirect, useHistory, useParams } from "react-router";
import { Stage, VisitStatus } from "enums";
import { T } from "i18n";
import { useAPI } from "api";
import { useSearchParams } from "hooks";

import "./Visit.scss";

export interface VisitStageProps<S extends Stage> {
  cohortId: string;
  stage: S;
  visit: IVisit;
  person?: IPerson;
  onComplete: () => void;
  isComplete?: boolean;
}

interface VisitProps {
  stages: { [key in Stage]: React.FC<VisitStageProps<key>> };
}

export const Visit = ({ stages }: VisitProps): JSX.Element => {
  const history = useHistory();
  const { cohortId } = useParams<{ cohortId: string }>();
  const { stage } = useSearchParams<{ stage?: Stage }>();

  const visitAPI = useAPI("visit");
  const [visit, setVisit] = useState<IVisit>();

  const peopleAPI = useAPI("people");
  const [person, setPerson] = useState<IPerson>();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();

  const loadVisit = useCallback((): Promise<void> => {
    setLoading(true);
    return visitAPI
      .getLatestVisit(cohortId)
      .then(setVisit)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [cohortId, visitAPI]);

  useEffect(() => {
    loadVisit();
  }, [loadVisit, stage]);

  useEffect((): void => {
    setLoading(true);
    peopleAPI
      .getPerson(cohortId)
      .then(setPerson)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [cohortId, stage, peopleAPI]);

  const navigateStage = (stage: Stage): void => {
    history.push(`/visit/${visit?.cohortId}?stage=${stage}`);
  };

  if (error) {
    return (
      <ErrorPanel
        title={T("containers.visit.errors.loadVisitError")}
        label={T("containers.visit.errors.loadVisitError")}
      >
        {error.message}
      </ErrorPanel>
    );
  }

  if (loading || !visit) {
    return <Loader />;
  }

  if (stage === undefined || !Object.keys(stages).includes(stage)) {
    return <Redirect to={`/visit/${cohortId}?stage=${visit.stage}`} />;
  }

  const VisitStage = stages[stage] as React.FC<VisitStageProps<Stage>>;

  const isVisitFinished = visit.finishedAt !== null;
  const isStageComplete = visit.stage !== stage || isVisitFinished;
  const isCheckoutStage = stage === "CHECKOUT";
  const isAbortStage = stage === "ABORT_APPOINTMENT";
  const isTerminalStage = isAbortStage || isCheckoutStage;
  const nextStage = isVisitFinished ? "CHECKOUT" : visit.stage;

  return (
    <div className="nhsuk-main-wrapper">
      <VisitStage
        {...{
          cohortId,
          stage,
          visit,
          person,
          onComplete: loadVisit,
          isComplete: isStageComplete,
        }}
      />

      <div className="visit-navigation">
        {isStageComplete && !isTerminalStage && (
          <Button
            type="submit"
            data-testid="next-stage-link"
            onClick={() => navigateStage(nextStage)}
          >
            {T(`containers.visit.links.next`, {
              step: T(`stages.${nextStage}`),
            })}
          </Button>
        )}

        <hr />

        {visit.status === VisitStatus.IN_PROGRESS && !isTerminalStage && (
          <div className="abort-link">
            <Button
              type="submit"
              style={{ background: "#d5281b" }}
              data-testid="abort-link"
              onClick={() => navigateStage("ABORT_APPOINTMENT")}
            >
              {T("containers.visit.abort.abortButton")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
