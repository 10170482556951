import { CaseStatus } from "../../../enums";
import { ICase } from "../../../interfaces";

export type CaseStatusProgress = `UNREAD` | "IN_PROGRESS" | "CLOSED";

export function getCaseStatusProgress(caseItem: ICase): CaseStatusProgress {
  if (caseItem.status === CaseStatus.CLOSED) {
    return CaseStatus.CLOSED;
  }
  if (caseItem.progress === 0) {
    return "UNREAD";
  }
  return "IN_PROGRESS";
}
