import React, { useEffect, useState } from "react";
import {
  IAccessibilityInput,
  IAccessibilityInputOptions,
  Loader,
} from "../../components";
import { ISelectableInterpreterLanguage } from "../../interfaces";
import { Label, SummaryList } from "nhsuk-react-components";
import { T } from "i18n";
import { Trans } from "react-i18next";
import { summariseAccessibility } from "./AccessibilitySummary";
import { useAPI } from "../../api";
import { useEventType } from "../../hooks";

type AccessibilityOptionsRequiringCallCentre = Pick<
  IAccessibilityInputOptions,
  "interpreter" | "visual" | "signLanguage"
>;

export type AccessibilityRequiringCallCentre = {
  options: AccessibilityOptionsRequiringCallCentre;
  interpreterLanguage: ISelectableInterpreterLanguage;
};

type CallCentreHandlingAccessibilityProps = {
  cohortId: string;
  accessibility: AccessibilityRequiringCallCentre;
};

export const CallCentreHandlingAccessibility = ({
  cohortId,
  accessibility: { interpreterLanguage, options },
}: CallCentreHandlingAccessibilityProps): JSX.Element => {
  const accessibilityToSummarise: IAccessibilityInput = {
    options: {
      interpreter: options.interpreter,
      visual: options.visual,
      hearing: false,
      signLanguage: options.signLanguage,
      none: false,
      wheelchair: false,
      parking: false,
      stepFree: false,
    },
    interpreterLanguage,
  };

  const [name, setName] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [loadPersonError, setLoadPersonError] = useState<Error>();

  const eventType = useEventType();

  const peopleAPI = useAPI("people");

  useEffect(() => {
    setLoading(true);
    peopleAPI
      .getPerson(cohortId)
      .then((person) => {
        setName(`${person.firstName} ${person.lastName}`);
      })
      .catch(setLoadPersonError)
      .finally(() => setLoading(false));
  }, [cohortId, peopleAPI]);

  if (loading) {
    return <Loader panel={false} />;
  }

  const summary = summariseAccessibility(accessibilityToSummarise).map(
    ([option, summary]) => <p key={option}>{summary}</p>
  );

  return (
    <>
      <Label data-testid="call-centre-handling-accessibility-title" size="xl">
        {T("containers.booking.callCentreHandlingAccessibility.title", {
          context: eventType,
        })}
      </Label>
      <SummaryList>
        {name && !loadPersonError ? (
          <SummaryList.Row>
            <SummaryList.Key>
              {T("containers.booking.fields.name.label")}
            </SummaryList.Key>
            <SummaryList.Value data-testid="call-centre-handling-accessibility-name">
              {name}
            </SummaryList.Value>
          </SummaryList.Row>
        ) : (
          <></>
        )}
        <SummaryList.Row>
          <SummaryList.Key>
            {T("containers.booking.fields.cohortId.label")}
          </SummaryList.Key>
          <SummaryList.Value data-testid="call-centre-handling-accessibility-cohort-id">
            {cohortId}
          </SummaryList.Value>
        </SummaryList.Row>
        <SummaryList.Row>
          <SummaryList.Key>
            {T("containers.booking.accessibilitySummary.label")}
          </SummaryList.Key>
          <SummaryList.Value data-testid="call-centre-handling-accessibility-summary">
            {summary}
          </SummaryList.Value>
        </SummaryList.Row>
      </SummaryList>
      <Label data-testid="call-centre-handling-accessibility-message">
        <Trans
          i18nKey="containers.booking.callCentreHandlingAccessibility.message"
          values={{ cohortId }}
        />
      </Label>
    </>
  );
};
