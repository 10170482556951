import React from "react";
import { ActionLink, Table } from "nhsuk-react-components";
import { IPerson, IVisit } from "interfaces";
import { Link } from "react-router-dom";
import { T } from "i18n";
import { formatTime } from "formatters";

interface VisitWithPerson {
  visit: IVisit;
  person: IPerson;
}

export interface VisitsInProgressProps {
  unitScheduleEntries: VisitWithPerson[];
}

export const VisitsInProgress = ({
  unitScheduleEntries,
}: VisitsInProgressProps): JSX.Element => {
  const visits = unitScheduleEntries.filter(
    ({ visit }) => visit && !visit.finishedAt
  );

  if (visits.length === 0) {
    return <p>{T("containers.checkIn.fields.appointments.noInProgress")}</p>;
  }

  return (
    <Table aria-label="visits">
      <Table.Head>
        <Table.Row>
          <Table.Cell>
            {T("containers.checkIn.fields.appointments.headings.participant")}
          </Table.Cell>
          <Table.Cell>
            {T("containers.checkIn.fields.appointments.headings.cohortId")}
          </Table.Cell>
          <Table.Cell>
            {T("containers.checkIn.fields.appointments.headings.time")}
          </Table.Cell>
          <Table.Cell>Current activity</Table.Cell>
          <Table.Cell />
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {visits.map(
          ({ visit, person }) =>
            visit && (
              <Table.Row key={person.cohortId} label="visit">
                <Table.Cell>
                  {`${person.firstName} ${person.lastName}`}
                </Table.Cell>
                <Table.Cell>
                  <Link
                    to={`/participant/${person.cohortId}`}
                    aria-label={T(
                      "containers.checkIn.fields.appointments.actions.participantPage.label"
                    )}
                    data-testid={`in-progress-cohort-id-${person.cohortId}`}
                  >
                    {person.cohortId}
                  </Link>
                </Table.Cell>
                <Table.Cell>{formatTime(visit.startedAt)}</Table.Cell>
                <Table.Cell>
                  <ActionLink
                    asElement={Link}
                    data-testid={`in-progress-link-${person.cohortId}`}
                    aria-label={T(
                      "containers.checkIn.fields.appointments.actions.checkIn.label"
                    )}
                    className="nhsuk-u-margin-bottom-0"
                    to={`/visit/${person.cohortId}`}
                  >
                    {T(`stages.${visit.stage}`)}
                  </ActionLink>
                </Table.Cell>
              </Table.Row>
            )
        )}
      </Table.Body>
    </Table>
  );
};
