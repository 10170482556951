import React, { ComponentProps, useEffect, useMemo, useState } from "react";
import { DateInput } from "./DateInput";
import { DateTime } from "luxon";
import { Radios } from "nhsuk-react-components";
import { T } from "../i18n";
import { dateInputToDateTime } from "../helpers";

type DatePickerRadioOptions = undefined | "TODAY" | "OTHER";

export const DatePickerWithDefault = (
  props: ComponentProps<typeof DateInput>
): JSX.Element => {
  const {
    label,
    date,
    error,

    setDate,
    disabled = false,
  } = props;

  const today = useMemo(() => DateTime.now(), []);

  const [selectedOption, setSelectedOption] =
    useState<DatePickerRadioOptions>();

  useEffect(() => {
    if (selectedOption === undefined) {
      const passedInDate = dateInputToDateTime(date);
      if (!passedInDate.isValid) {
        return;
      }
      if (today.startOf("day") <= passedInDate.startOf("day")) {
        setSelectedOption("TODAY");
        return;
      }
      setSelectedOption("OTHER");
    }
  }, [date, selectedOption, setSelectedOption, setDate, today]);

  useEffect(() => {
    if (selectedOption === "TODAY") {
      setDate({
        day: today.day.toString(),
        month: today.month.toString(),
        year: today.year.toString(),
      });
    }
  }, [selectedOption, setDate, today]);

  const todayLabel = T("components.dateInput.fields.today", {
    date: today.toFormat("cccc d LLLL yyyy"),
  });
  const otherLabel = T("components.dateInput.fields.other");

  return (
    <>
      <Radios label={label} value={selectedOption} error={error}>
        <Radios.Radio
          onChange={(e) => setSelectedOption("TODAY")}
          aria-label={todayLabel}
          value="TODAY"
          checked={selectedOption === "TODAY"}
        >
          {todayLabel}
        </Radios.Radio>
        <Radios.Radio
          onChange={(e) => setSelectedOption("OTHER")}
          aria-label={otherLabel}
          conditional={
            <DateInput date={date} setDate={setDate} disabled={disabled} />
          }
          value="OTHER"
          checked={selectedOption === "OTHER"}
        >
          {otherLabel}
        </Radios.Radio>
      </Radios>
    </>
  );
};
