import React from "react";
import { Address, JumpTo } from "components";
import { DateTime } from "luxon";
import { Duration } from "api/appointment";
import { IAddress } from "interfaces";
import { Restricted } from "auth/components";
import { SummaryList } from "nhsuk-react-components";
import { T } from "i18n";
import { formatTime } from "formatters";

type BookingSummaryProps = {
  location?: {
    address: IAddress;
    onChange: () => void;
  };
  dateAndTime?: {
    from: DateTime;
    onChange: () => void;
  };
  duration?: {
    duration: Duration;
    onChange: () => void;
  };
};

export const BookingSummary = ({
  location,
  dateAndTime,
  duration,
}: BookingSummaryProps): JSX.Element => {
  if (!location && !dateAndTime) {
    return <></>;
  }

  return (
    <SummaryList>
      {location && (
        <SummaryList.Row>
          <SummaryList.Key>
            {T("containers.booking.fields.location.label")}
          </SummaryList.Key>
          <SummaryList.Value data-testid="booking-summary-address">
            <Address address={location.address} />
          </SummaryList.Value>
          <SummaryList.Actions>
            <JumpTo
              to="LocationPicker"
              testid="booking-summary-change-location"
              text={T("containers.booking.fields.slot.actions.change.label")}
              onClick={location.onChange}
            />
          </SummaryList.Actions>
        </SummaryList.Row>
      )}
      {dateAndTime && (
        <SummaryList.Row>
          <SummaryList.Key>
            {T("containers.booking.fields.slot.label")}
          </SummaryList.Key>
          <SummaryList.Value data-testid="booking-summary-date-and-time">
            <p>
              {dateAndTime.from.toFormat("cccc dd LLLL yyyy")}
              <br />
              {formatTime(dateAndTime.from, "h:mma").toLowerCase()}
            </p>
          </SummaryList.Value>
          <SummaryList.Actions>
            <JumpTo
              to="SlotPicker"
              testid="booking-summary-change-date-and-time"
              text={T("containers.booking.fields.slot.actions.change.label")}
              onClick={dateAndTime.onChange}
            />
          </SummaryList.Actions>
        </SummaryList.Row>
      )}
      {duration && (
        <Restricted
          allowedRoles={[
            "KCL Admin",
            "Call Centre Agent",
            "EMS Central Team",
            "EMS Unit Staff",
          ]}
          mode="allowRoles"
        >
          <SummaryList.Row>
            <SummaryList.Key>
              {T("containers.booking.fields.slot.tabs.title")}
            </SummaryList.Key>
            <SummaryList.Value data-testid="booking-summary-duration">
              {T("containers.booking.fields.slot.duration", {
                duration: duration.duration.toString(),
              })}
            </SummaryList.Value>
            <SummaryList.Actions>
              <JumpTo
                to="SlotPicker"
                testid="booking-summary-change-duration"
                text={T("containers.booking.fields.slot.actions.change.label")}
                onClick={duration.onChange}
              />
            </SummaryList.Actions>
          </SummaryList.Row>
        </Restricted>
      )}
    </SummaryList>
  );
};
