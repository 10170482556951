import React from "react";
import { Button } from "nhsuk-react-components";
import { IPerson } from "interfaces/IPerson";
import { T } from "i18n";
import { VisitPerson } from "containers/Visit/VisitPerson";

interface CreateParticipantFormProps {
  disabled?: boolean;
  onSubmit: () => void;
  person?: IPerson;
}

export const ParticipateForm = ({
  disabled = false,
  onSubmit,
  person,
}: CreateParticipantFormProps): JSX.Element => (
  <>
    <h1>{T("containers.participate.form.title")}</h1>
    {person && <VisitPerson person={person} />}
    <p>{T("containers.participate.form.message")}</p>
    <Button
      data-testid="assign-study-id"
      disabled={disabled}
      onClick={onSubmit}
    >
      {T("containers.participate.form.actions.submit")}
    </Button>
  </>
);
