const V3_0_25JUN2021_ENGLISH = "v3.0 25JUN2021 English" as const;
const V3_0_25JUN2021_BENGALI = "v3.0 25JUN2021 Bengali" as const;
const V3_0_25JUN2021_GUJARATI = "v3.0 25JUN2021 Gujarati" as const;
const V3_0_25JUN2021_PANJABI = "v3.0 25JUN2021 Panjabi" as const;
const V3_0_25JUN2021_URDU = "v3.0 25JUN2021 Urdu" as const;

export const consentDocVersions = [
  V3_0_25JUN2021_ENGLISH,
  V3_0_25JUN2021_BENGALI,
  V3_0_25JUN2021_GUJARATI,
  V3_0_25JUN2021_PANJABI,
  V3_0_25JUN2021_URDU,
] as const;

export type ConsentDocVersion = typeof consentDocVersions[number];
