import React from "react";
import { ArchivedUser } from "interfaces";
import { Table } from "nhsuk-react-components";
import { T } from "i18n";
import { DateTime } from "luxon";

interface ArchivedUserTableProps {
  users?: ArchivedUser[];
  onUserClicked: (id: string) => void;
}

export function ArchivedUserTable({
  users,
  onUserClicked,
}: ArchivedUserTableProps): JSX.Element {
  const archivedRows = () => {
    if (!users) {
      return <></>;
    }
    return users.map((user) => {
      const archivedAt = DateTime.fromISO(user.archiveInfo.archivedAt);
      return (
        <Table.Row
          key={user.id}
          style={{ cursor: "pointer" }}
          onClick={() => onUserClicked(user.id)}
        >
          <Table.Cell>
            {user.firstName ||
              (user.lastName && (
                <>
                  {[user.firstName, user.lastName].join(" ")}
                  <br />
                </>
              ))}
            {user.id}
          </Table.Cell>
          <Table.Cell>
            {archivedAt.toFormat("EEEE dd MMMM yyyy")}
            <br />
            {archivedAt.toFormat("hh:mma")}
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <Table data-testid="archived-user-table">
      <Table.Head>
        <Table.Row>
          <Table.Cell>{T("containers.user.table.header.user")}</Table.Cell>
          <Table.Cell>{T("containers.user.table.header.archived")}</Table.Cell>
        </Table.Row>
      </Table.Head>
      <Table.Body>{archivedRows()}</Table.Body>
    </Table>
  );
}
