import { DateTime } from "luxon";
import { IDateInputValue } from "../interfaces";

export const dateInputToDateTime = (date: IDateInputValue): DateTime =>
  DateTime.utc(
    parseInt(date.year, 10),
    parseInt(date.month, 10),
    parseInt(date.day, 10)
  );

export const dateTimeToDateInput = (date: DateTime): IDateInputValue => ({
  year: date.toFormat("yyyy"),
  month: date.toFormat("LL"),
  day: date.toFormat("dd"),
});
