import React, { useState } from "react";
import {
  AsyncCall,
  CallState,
  err,
  loading,
  notCalled,
  ok,
} from "helpers/AsyncCall";
import { BackLink, Button, Input, InsetText } from "nhsuk-react-components";
import { ErrorPanel, Loader } from "components";
import { T } from "i18n";
import { Trans } from "react-i18next";
import { useAPI } from "api";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";

export const RemoveOutsideEngland = (): JSX.Element => {
  const { cohortId } = useParams<{ cohortId: string }>();
  const { push } = useHistory();

  const [confirmText, setConfirmText] = useState("");

  const personAPI = useAPI("people");
  const [updateStatus, setUpdateStatus] =
    useState<AsyncCall<undefined>>(notCalled);

  const handleConfirm = (): void => {
    setUpdateStatus(loading);
    personAPI
      .setOutsideOfEngland(cohortId, false)
      .then(() => setUpdateStatus(ok(undefined)))
      .catch((error) => setUpdateStatus(err(error)));
  };

  return (
    <main className="nhsuk-main-wrapper">
      <BackLink
        onClick={() => push(`/participant/${cohortId}`)}
        data-testid="remove-outside-england-back-link"
        aria-label={T("containers.wizard.previous")}
      >
        {T("containers.wizard.previous")}
      </BackLink>

      <h1>{T("containers.removeOutsideEngland.title")}</h1>

      <Trans i18nKey="containers.removeOutsideEngland.instruction" />

      <Input
        disabled={updateStatus.state === CallState.Ok}
        type="text"
        data-testid="confirm-remove-outside-england-input"
        value={confirmText}
        onChange={(e) => setConfirmText(e.currentTarget.value)}
        width="20"
      />
      <Button
        disabled={
          confirmText !== "confirm" ||
          [CallState.Loading, CallState.Ok].includes(updateStatus.state)
        }
        data-testid="confirm-remove-outside-england-button"
        onClick={handleConfirm}
      >
        {T("containers.outsideEngland.button")}
      </Button>

      {updateStatus.state === CallState.Loading && <Loader />}
      {updateStatus.state === CallState.Error && (
        <ErrorPanel
          title={T("containers.removeOutsideEngland.error")}
          label={T("containers.removeOutsideEngland.error")}
        >
          {updateStatus.error.message}
        </ErrorPanel>
      )}
      {updateStatus.state === CallState.Ok && (
        <InsetText data-testid="remove-outside-england-confirmation">
          {T("containers.removeOutsideEngland.confirmation")}
        </InsetText>
      )}
    </main>
  );
};
