import React, { useMemo, useState } from "react";
import { Card, DateInput, Radios } from "nhsuk-react-components";
import { DateTime } from "luxon";
import { IDateInputValue } from "interfaces";
import { T } from "i18n";
import { dateInputToDateTime, dateTimeToDateInput } from "helpers";

const todayOption = "TODAY";
const otherOption = "OTHER";
type RadioOption = "" | "TODAY" | "OTHER";

const initialRadioSelected = (
  submittedDate: IDateInputValue,
  today: DateTime
): RadioOption => {
  if (Object.values(submittedDate).every((val) => val === "")) {
    return "";
  }
  const passedInDate = dateInputToDateTime(submittedDate);
  if (today.startOf("day") <= passedInDate.startOf("day")) {
    return todayOption;
  }
  return otherOption;
};

export const ParticipantReferralSubmission = ({
  submittedDate,
  onSubmitDateChange,
  error,
}: {
  submittedDate: IDateInputValue;
  onSubmitDateChange: (value: IDateInputValue) => void;
  error: string;
}): JSX.Element => {
  const today = useMemo(() => DateTime.now(), []);
  const [selectedRadio, setSelectedRadio] = useState<RadioOption>(
    initialRadioSelected(submittedDate, today)
  );
  const handleSelectToday = (): void => {
    onSubmitDateChange(dateTimeToDateInput(today));
    setSelectedRadio(todayOption);
  };
  const handleSelectOther = (): void => {
    onSubmitDateChange({
      year: "",
      month: "",
      day: "",
    });
    setSelectedRadio(otherOption);
  };

  const todayLabel = T("containers.resultsCase.referralCard.submittedToday", {
    date: DateTime.now().toFormat("dd LLLL yyyy"),
  });
  return (
    <Card feature>
      <Card.Content>
        <Card.Heading>
          {T("containers.resultsCase.referralCard.title")}
        </Card.Heading>
        <h4>
          {T("containers.resultsCase.referralCard.documentsToInclude.header")}
        </h4>
        <ul>
          <li>
            {T("containers.resultsCase.referralCard.documentsToInclude.report")}
          </li>
          <li>
            {T(
              "containers.resultsCase.referralCard.documentsToInclude.proforma"
            )}
          </li>
        </ul>
        <h4>{T("containers.resultsCase.referralCard.referralStatus")}</h4>
        <Radios
          name="submitted"
          label={T("containers.resultsCase.referralCard.submitted")}
          aria-label={T("containers.resultsCase.referralCard.submitted")}
          value={selectedRadio}
        >
          <Radios.Radio
            data-testid="referralSubmittedToday"
            onChange={handleSelectToday}
            aria-label={todayLabel}
            value={todayOption}
            checked={selectedRadio === todayOption}
          >
            {todayLabel}
          </Radios.Radio>
          <Radios.Radio
            data-testid="referralSubmittedOther"
            onChange={handleSelectOther}
            aria-label={T("containers.resultsCase.referralCard.other")}
            value={otherOption}
            checked={selectedRadio === otherOption}
          >
            {T("containers.resultsCase.referralCard.other")}
          </Radios.Radio>
        </Radios>
        {selectedRadio === otherOption && (
          <DateInput
            aria-label={T("containers.resultsCase.referralCard.submitted")}
            data-testid="referralSubmitted"
            value={submittedDate}
            error={error}
            onChange={(e) => onSubmitDateChange(e.target.value)}
          />
        )}
      </Card.Content>
    </Card>
  );
};
