import React, { useEffect, useState } from "react";
import {
  AsyncCall,
  CallState,
  err,
  loading,
  notCalled,
  ok,
} from "helpers/AsyncCall";
import { Button, Radios } from "nhsuk-react-components";
import { ContactStatus } from "api/people";
import { ErrorPanel, Loader } from "components";
import { IPerson } from "interfaces";
import { T } from "i18n";
import { VisitPerson } from "containers/Visit/VisitPerson";
import { useAPI } from "api";

import { Redirect, useHistory, useParams } from "react-router";

export function ContactManagement(): JSX.Element {
  const { cohortId } = useParams<{ cohortId: string }>();
  const personAPI = useAPI("people");
  const [person, setPerson] = useState<AsyncCall<IPerson>>(notCalled);
  const [contactStatus, setContactStatus] =
    useState<AsyncCall<ContactStatus>>(notCalled);

  const [saveCall, setSaveCall] = useState<AsyncCall<void>>(notCalled);

  const history = useHistory();

  const save = (): void => {
    if (contactStatus.state !== CallState.Ok) {
      return;
    }
    setSaveCall(loading);
    personAPI
      .setContactStatus(cohortId, contactStatus.result)
      .then(() => setSaveCall(ok<void>(undefined)))
      .catch((error) => setSaveCall(err(error)));
  };

  const cancel = (): void => {
    history.push(`/participant/${cohortId}`);
  };

  useEffect(() => {
    setPerson(loading);
    personAPI
      .getPerson(cohortId)
      .then((person) => setPerson(ok(person)))
      .catch((error) => setPerson(err(error)));

    setContactStatus(loading);
    personAPI
      .getContactStatus(cohortId)
      .then((result) => {
        setContactStatus(ok(result));
      })
      .catch((error) => setContactStatus(err(error)));
  }, [personAPI, cohortId]);

  if (saveCall.state === CallState.Ok) {
    return <Redirect to={`/participant/${cohortId}`} />;
  }

  switch (person.state) {
    case CallState.NotCalled:
      return <></>;
    case CallState.Loading:
      return <Loader />;
    case CallState.Error:
      return (
        <ErrorPanel
          data-testid="notifications-error"
          title="Error"
          label="Notification Error"
        >
          {person.error.message}
        </ErrorPanel>
      );
  }

  switch (contactStatus.state) {
    case CallState.NotCalled:
      return <></>;
    case CallState.Loading:
      return <Loader />;
    case CallState.Error:
      return (
        <ErrorPanel
          data-testid="notifications-error"
          title="Error"
          label="Notification Error"
        >
          {contactStatus.error.message}
        </ErrorPanel>
      );
  }

  return (
    <>
      <h1 style={{ marginTop: "50px" }}>
        {T("components.person.contactStatus.edit.title")}
      </h1>
      <VisitPerson person={person.result} />
      <Radios
        value={contactStatus.result.canEmail ? "yes" : "no"}
        inline
        label={T("components.person.contactStatus.email")}
        aria-label={T("components.person.contactStatus.email")}
        data-testid="emailRadio"
      >
        <Radios.Radio
          value="yes"
          defaultChecked={contactStatus.result.canEmail}
          onChange={(e) =>
            setContactStatus(
              ok({
                canEmail: e.currentTarget.value === "yes",
                canSMS: contactStatus.result.canSMS,
              })
            )
          }
          data-testid="emailRadioYes"
        >
          {T("components.person.contactStatus.edit.enabled")}
        </Radios.Radio>
        <Radios.Radio
          value="no"
          defaultChecked={!contactStatus.result.canEmail}
          onChange={(e) =>
            setContactStatus(
              ok({
                canEmail: e.currentTarget.value === "yes",
                canSMS: contactStatus.result.canSMS,
              })
            )
          }
          data-testid="emailRadioNo"
        >
          {T("components.person.contactStatus.edit.optOut")}
        </Radios.Radio>
      </Radios>
      <Radios
        value={contactStatus.result.canSMS ? "yes" : "no"}
        inline
        label={T("components.person.contactStatus.sms")}
        aria-label={T("components.person.contactStatus.sms")}
        data-testid="smsRadio"
      >
        <Radios.Radio
          value="yes"
          defaultChecked={contactStatus.result.canSMS}
          onChange={(e) =>
            setContactStatus(
              ok({
                canEmail: contactStatus.result.canEmail,
                canSMS: e.currentTarget.value === "yes",
              })
            )
          }
          data-testid="smsRadioYes"
        >
          {T("components.person.contactStatus.edit.enabled")}
        </Radios.Radio>
        <Radios.Radio
          value="no"
          defaultChecked={!contactStatus.result.canSMS}
          onChange={(e) =>
            setContactStatus(
              ok({
                canEmail: contactStatus.result.canEmail,
                canSMS: e.currentTarget.value === "yes",
              })
            )
          }
          data-testid="smsRadioNo"
        >
          {T("components.person.contactStatus.edit.optOut")}
        </Radios.Radio>
      </Radios>
      {saveCall.state === CallState.Error && (
        <ErrorPanel
          data-testid="notifications-error"
          title="Error"
          label="Notification Error"
        >
          {saveCall.error.message}
        </ErrorPanel>
      )}
      <Button
        style={{ marginRight: "25px" }}
        onClick={save}
        disabled={saveCall.state === CallState.Loading}
        data-testid="save"
      >
        {T("components.person.contactStatus.edit.save")}
      </Button>
      <Button
        secondary
        onClick={cancel}
        disabled={saveCall.state === CallState.Loading}
        data-testid="cancel"
      >
        {T("components.person.contactStatus.edit.cancel")}
      </Button>
    </>
  );
}
