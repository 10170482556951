import React from "react";
import { Select } from "nhsuk-react-components";
import { T } from "i18n";
import { DateTime } from "luxon";

interface StudyYearSelectProps {
  value?: string;
  onChange: (val: string) => void;
  style: React.CSSProperties;
}

export const StudyYearSelect = ({
  value,
  onChange,
  style,
}: StudyYearSelectProps): JSX.Element => {
  const studyYears = Array.from(
    { length: DateTime.now().year - 2020 + 1 },
    (_, i) => 2020 + i
  );
  return (
    <Select
      defaultValue=""
      value={value}
      style={style}
      onChange={(e) => onChange(e.currentTarget.value)}
    >
      <Select.Option value="" disabled={true}>
        {T("components.studyYearSelect.placeholder")}
      </Select.Option>
      {studyYears.map((studyYear) => (
        <Select.Option key={studyYear} value={studyYear}>
          {studyYear}
        </Select.Option>
      ))}
    </Select>
  );
};
