import React, { HTMLProps } from "react";
import { FormElementProps } from "nhsuk-react-components/dist/util/types/FormTypes";
import { Input } from "nhsuk-react-components";
import { InputWidth } from "nhsuk-react-components/dist/util/types/NHSUKTypes";

interface PostcodeInputProps
  extends Omit<HTMLProps<HTMLInputElement>, "onChange">,
    FormElementProps {
  width?: InputWidth;
  onChange?(postcode: string): void;
}

export function PostcodeInput(props: PostcodeInputProps): JSX.Element {
  const { onChange } = props;
  return (
    <Input
      {...props}
      type="text"
      onChange={(e) => {
        const newValue = e.currentTarget.value.toUpperCase();
        if (/^$|^([A-Z\d]+ ?[A-Z\d]*)$/.test(newValue)) {
          onChange && onChange(newValue);
        }
      }}
    />
  );
}
