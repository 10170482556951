import React from "react";
import { IPerson } from "interfaces";
import { T } from "i18n";
import { VisitPerson } from "containers/Visit/VisitPerson";

interface VideoResultProps {
  person?: IPerson;
}

export const VideoResult = (props: VideoResultProps): JSX.Element => {
  const { person } = props;

  return (
    <>
      <h1>{T("containers.video.title")}</h1>
      {person && <VisitPerson person={person} />}
      <p>{T("containers.video.successScreen.message")}</p>
    </>
  );
};
