import React, { SyntheticEvent, useState } from "react";
import {
  Button,
  Checkboxes,
  Col,
  DateInput,
  Fieldset,
  Form,
  Input,
  Row,
  Table,
} from "nhsuk-react-components";
import { ErrorPanel, TimeInput } from "components";
import { ITimeInputValue, IUnitCapacityRowInput } from "interfaces";
import { T } from "i18n";
import {
  cacheBustFailed,
  dateInPast,
  overlapsExistingCapacity,
} from "errors/unitManagement";
import { dateInputToString, timeInputToString } from "helpers";
import { useAPI } from "api";
import { useDate, useTime } from "hooks";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { capitaliseFirstLetter } from "formatters";

export function AddUnitCapacity(): JSX.Element {
  const { unitCode } = useParams<{ unitCode: string }>();
  const history = useHistory();
  // This is used on server to only apply to certain days of the week
  // Note https://golang.org/pkg/time/#Weekday has Sunday as 0
  const [days, setDays] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [capacity, setCapacity] = useState("");
  const [capacityError, setCapacityError] = useState("");
  const [submissionError, setSubmissionError] = useState("");
  const [editRows, setEditRows] = useState<IUnitCapacityRowInput[]>([]);

  const [
    activeFromInput,
    activeFromError,
    activeFromSetter,
    validateActiveFrom,
    isActiveFromValid,
  ] = useDate();

  const [
    activeToInput,
    activeToError,
    activeToSetter,
    validateActiveTo,
    isActiveToValid,
  ] = useDate();

  const [startTime, , startTimeSetter, validateStartTime, isStartTimeValid] =
    useTime();

  const [endTime, , endTimeSetter, validateEndTime, isEndTimeValid] = useTime();

  const onCancelClick = (): void => {
    const path = `/unit/${unitCode}/capacity`;
    history.push(path);
  };

  const unitAPI = useAPI("unit");

  const onSubmitForm = async (
    e: SyntheticEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    const formHasErrors =
      !validateActiveFrom() ||
      !validateActiveTo() ||
      !validateStartTime() ||
      !validateEndTime();
    const selectedWeekdays = days
      .map((value, index) => (value ? index : -1))
      .filter((value) => value !== -1);
    if (!formHasErrors) {
      unitAPI
        .createUnitAvailability({
          unitCode,
          from: dateInputToString(activeFromInput),
          to: dateInputToString(activeToInput),
          days: selectedWeekdays,
          capacities: editRows,
        })
        .then(() => {
          const path = `/unit/${unitCode}/capacity`;
          history.push(path);
        })
        .catch(({ message }) => {
          switch (message) {
            case overlapsExistingCapacity:
              setSubmissionError(
                T("containers.unit.capacity.add.errors.overlaps")
              );
              break;
            case dateInPast:
              setSubmissionError(
                T("containers.unit.capacity.add.errors.inPast")
              );
              break;
            case cacheBustFailed:
              history.push({
                pathname: `/unit/${unitCode}/capacity`,
                state: {
                  error: T("containers.unit.capacity.errors.cache.error"),
                },
              });
              break;
            default:
              setSubmissionError(capitaliseFirstLetter(message));
              break;
          }
        });
    }
  };

  const formIsFilled = (): boolean => {
    return (
      isActiveFromValid &&
      isActiveToValid &&
      isStartTimeValid &&
      isEndTimeValid &&
      editRows.length > 0
    );
  };

  const removeRow = (index: number): void => {
    const oldArray = [...editRows];
    oldArray.splice(index, 1);
    setEditRows(oldArray);
  };

  const addRow = (
    start: ITimeInputValue,
    end: ITimeInputValue,
    capacity: string
  ): void => {
    let formHasErrors = !validateStartTime() || !validateEndTime();
    const integerCapacity = parseInt(capacity, 10);
    if (isNaN(integerCapacity)) {
      formHasErrors = true;
      setCapacityError(T("containers.unit.capacity.add.errors.invalidNumber"));
    }
    if (!formHasErrors) {
      setEditRows((oldArray) => [
        ...oldArray,
        {
          startTime: timeInputToString(start),
          endTime: timeInputToString(end),
          capacity: integerCapacity,
        },
      ]);
    }
  };

  const renderedRows = editRows.map((item, index) => (
    <Table.Row key={item.startTime}>
      <Table.Cell>{item.startTime}</Table.Cell>
      <Table.Cell>{item.endTime}</Table.Cell>
      <Table.Cell>{item.capacity}</Table.Cell>
      <Table.Cell>
        <Button
          type="button"
          onClick={() => removeRow(index)}
          secondary
          data-testid="delete"
        >
          {T("containers.unit.capacity.add.actions.deleteRow")}
        </Button>
      </Table.Cell>
    </Table.Row>
  ));

  const rows = [
    ...renderedRows,
    <Table.Row key="edit-row">
      <Table.Cell>
        <TimeInput
          setTime={startTimeSetter}
          ariaLabel={T("containers.unit.capacity.add.inputs.startTime.label")}
          {...startTime}
        />
      </Table.Cell>
      <Table.Cell>
        <TimeInput
          setTime={endTimeSetter}
          ariaLabel={T("containers.unit.capacity.add.inputs.endTime.label")}
          {...endTime}
        />
      </Table.Cell>
      <Table.Cell>
        <Input
          type="text"
          name="capacity"
          label={T("containers.unit.capacity.add.inputs.capacity.label")}
          aria-label={T("containers.unit.capacity.add.inputs.capacity.label")}
          value={capacity}
          onChange={(e) => {
            setCapacityError("");
            setCapacity(e.currentTarget.value);
          }}
          error={capacityError}
          width="20"
        />
      </Table.Cell>
      <Table.Cell>
        <Button
          type="button"
          onClick={() => addRow(startTime, endTime, capacity)}
          data-testid="add"
        >
          {T("containers.unit.capacity.add.actions.addRow")}
        </Button>
      </Table.Cell>
    </Table.Row>,
  ];
  return (
    <main className="nhsuk-main-wrapper">
      <Form onSubmit={onSubmitForm}>
        <Fieldset>
          <Fieldset.Legend isPageHeading>
            {T("containers.unit.capacity.add.title")}
          </Fieldset.Legend>
        </Fieldset>

        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Cell>
                {T("components.schedule.table.headers.from")}
              </Table.Cell>
              <Table.Cell>
                {T("components.schedule.table.headers.to")}
              </Table.Cell>
              <Table.Cell>
                {T("components.schedule.table.headers.capacity")}
              </Table.Cell>
              <Table.Cell />
            </Table.Row>
          </Table.Head>
          <Table.Body>{rows}</Table.Body>
        </Table>

        <Row>
          <Col width="one-half">
            <DateInput
              label={T("containers.unit.capacity.add.inputs.activeFrom.label")}
              aria-label={T(
                "containers.unit.capacity.add.inputs.activeFrom.label"
              )}
              value={activeFromInput}
              onChange={(e) => {
                activeFromSetter(e.currentTarget.value);
              }}
              error={activeFromError}
            />
          </Col>
          <Col width="one-half">
            <DateInput
              label={T("containers.unit.capacity.add.inputs.activeTo.label")}
              aria-label={T(
                "containers.unit.capacity.add.inputs.activeTo.label"
              )}
              value={activeToInput}
              onChange={(e) => {
                activeToSetter(e.currentTarget.value);
              }}
              error={activeToError}
            />
          </Col>
        </Row>

        <Fieldset>
          <Fieldset.Legend>
            {T("containers.unit.capacity.add.inputs.days.label")}
          </Fieldset.Legend>
          <Checkboxes name="days" id="days">
            <Row>
              <Col width="one-quarter">
                <Checkboxes.Box
                  value="day1"
                  default={days[1]}
                  onChange={(e) => {
                    const constNewdays = days;
                    constNewdays[1] = !constNewdays[1];
                    setDays(constNewdays);
                  }}
                >
                  {T("containers.unit.capacity.add.inputs.days.monday.label")}
                </Checkboxes.Box>
              </Col>

              <Col width="one-quarter">
                <Checkboxes.Box
                  value="day2"
                  default={days[2]}
                  onChange={(e) => {
                    const constNewdays = days;
                    constNewdays[2] = !constNewdays[2];
                    setDays(constNewdays);
                  }}
                >
                  {T("containers.unit.capacity.add.inputs.days.tuesday.label")}
                </Checkboxes.Box>
              </Col>

              <Col width="one-quarter">
                <Checkboxes.Box
                  value="day3"
                  default={days[3]}
                  onChange={(e) => {
                    const constNewdays = days;
                    constNewdays[3] = !constNewdays[3];
                    setDays(constNewdays);
                  }}
                >
                  {T(
                    "containers.unit.capacity.add.inputs.days.wednesday.label"
                  )}
                </Checkboxes.Box>
              </Col>

              <Col width="one-quarter">
                <Checkboxes.Box
                  value="day4"
                  default={days[4]}
                  onChange={(e) => {
                    const constNewdays = days;
                    constNewdays[4] = !constNewdays[4];
                    setDays(constNewdays);
                  }}
                >
                  {T("containers.unit.capacity.add.inputs.days.thursday.label")}
                </Checkboxes.Box>
              </Col>

              <Col width="one-quarter">
                <Checkboxes.Box
                  value="day5"
                  default={days[5]}
                  onChange={(e) => {
                    const constNewdays = days;
                    constNewdays[5] = !constNewdays[5];
                    setDays(constNewdays);
                  }}
                >
                  {T("containers.unit.capacity.add.inputs.days.friday.label")}
                </Checkboxes.Box>
              </Col>

              <Col width="one-quarter">
                <Checkboxes.Box
                  value="day6"
                  default={days[6]}
                  onChange={(e) => {
                    const constNewdays = days;
                    constNewdays[6] = !constNewdays[6];
                    setDays(constNewdays);
                  }}
                >
                  {T("containers.unit.capacity.add.inputs.days.saturday.label")}
                </Checkboxes.Box>
              </Col>

              <Col width="one-quarter">
                <Checkboxes.Box
                  value="day7"
                  default={days[0]}
                  onChange={(e) => {
                    const constNewdays = days;
                    constNewdays[0] = !constNewdays[0];
                    setDays(constNewdays);
                  }}
                >
                  {T("containers.unit.capacity.add.inputs.days.sunday.label")}
                </Checkboxes.Box>
              </Col>
            </Row>
          </Checkboxes>
        </Fieldset>

        <Button
          secondary
          aria-label="Cancel"
          onClick={(event: React.MouseEvent) => {
            onCancelClick();
          }}
        >
          {T("containers.unit.capacity.add.actions.cancel")}
        </Button>
        <Button type="submit" disabled={!formIsFilled()} aria-label="Save">
          {T("containers.unit.capacity.add.actions.save")}
        </Button>
        {submissionError !== "" && (
          <ErrorPanel
            label="submission-error"
            title={
              <span id="error-summary-title">
                {T("errors.submissionErrorTitle")}
              </span>
            }
          >
            <p>{submissionError}</p>
          </ErrorPanel>
        )}
      </Form>
    </main>
  );
}
