import React, { useEffect, useState } from "react";
import { AppointmentType } from "../../enums";
import {
  Button,
  Label,
  ReadingWidth,
  WarningCallout,
} from "nhsuk-react-components";
import { DateTime } from "luxon";
import { ErrorPanel, Loader } from "components";
import { Bookability, IPerson } from "../../interfaces";
import { T } from "../../i18n";
import { Trans } from "react-i18next";
import { UpdateAsParticipantPayload } from "../../api/people";
import { ParticipantDetailsManager } from "./ParticipantDetailsManager";
import { isEnglishPostcode } from "../../validations";
import { isInvitee } from "../../auth/hasRole";
import { macroCaseToCamelCase } from "../../helpers/letterCase";
import { useAPI } from "../../api";
import { useAuth } from "../../auth";
import { useHistory } from "react-router-dom";
import { ParticipantAppointmentManagement } from "./ParticipantAppointmentManagement";
import { useQueryBookingClosed } from "../../hooks/useQueryBookingClosed";
import { BookingClosed } from "../../components/BookingClosed";

const EligibilityDeclined = (props: { eventType: string }): JSX.Element => {
  const { eventType } = props;
  // marking eventTag as Y1 if no Y1 event yet because participant sees
  // the same screen as if they had had a Y1 event but were not ready
  const impliedEventType = eventType === "" ? "Y1" : eventType;
  const eventTag = impliedEventType.toLowerCase();
  return (
    <main className="nhsuk-main-wrapper">
      <ReadingWidth>
        <Label isPageHeading data-testid={`not-${eventTag}-eligible-heading`}>
          {T(
            `containers.participantDetailsManager.sections.notYxEligible.title`
          )}
        </Label>
        <p data-testid={`not-${eventTag}-eligible-paragraph1`}>
          {T(
            `containers.participantDetailsManager.sections.notYxEligible.paragraph1`,
            { context: impliedEventType }
          )}
        </p>
        <p data-testid={`not-${eventTag}-eligible-paragraph2`}>
          {T(
            `containers.participantDetailsManager.sections.notYxEligible.paragraph2`,
            { context: impliedEventType }
          )}
        </p>
        <p data-testid={`not-${eventTag}-eligible-paragraph3`}>
          {T(
            `containers.participantDetailsManager.sections.notYxEligible.paragraph3`,
            { context: impliedEventType }
          )}
        </p>
      </ReadingWidth>
    </main>
  );
};

const EligibilityConfirmed = (props: {
  cohortId: string;
  eventType: string;
  person: IPerson;
  bookability: Bookability;
}): JSX.Element => {
  const { cohortId, eventType, bookability, person } = props;
  const eventTag = eventType.toLowerCase();

  const [continueClicked, setContinueClicked] = useState(false);
  const [error, setError] = useState<Error>();

  const { push } = useHistory();
  const { user } = useAuth();

  const onContinue = (): void => {
    setContinueClicked(true);

    if (bookability.bookable && !bookability.hasExistingAppointment) {
      if (
        bookability.type === eventType &&
        eventType !== AppointmentType.D1 &&
        bookability.window
      ) {
        let appointmentPageURL = `/registration/${cohortId}/appointment?appointmentType=${eventType}&from=${bookability.window.from}`;
        if (!user) {
          appointmentPageURL += `&to=${bookability.window.to}`;
        }
        push(appointmentPageURL);
      } else {
        setError(
          new Error(
            T(
              `containers.participantDetailsManager.sections.yxEligible.errors.checkCanBook.bookableButNot${eventType}`
            )
          )
        );
      }
    }
  };

  return (
    <main className="nhsuk-main-wrapper">
      <ReadingWidth>
        {!continueClicked &&
          person?.firstName !== null &&
          bookability?.bookable && (
            <>
              <Label isPageHeading data-testid={`${eventTag}-eligible-heading`}>
                {person?.firstName !== ""
                  ? T(
                      `containers.participantDetailsManager.sections.yxEligible.personalisedTitle`,
                      {
                        name: person?.firstName,
                      }
                    )
                  : T(
                      `containers.participantDetailsManager.sections.yxEligible.defaultTitle`
                    )}
              </Label>
              <p>
                {T(
                  `containers.participantDetailsManager.sections.yxEligible.paragraph1`,
                  { context: eventType }
                )}
              </p>
              <p>
                {T(
                  `containers.participantDetailsManager.sections.yxEligible.paragraph2`,
                  { context: eventType }
                )}
              </p>
              <WarningCallout
                label={T(
                  `containers.participantDetailsManager.sections.yxEligible.warning.title`
                )}
              >
                <WarningCallout.Label>
                  {T(
                    `containers.participantDetailsManager.sections.yxEligible.warning.title`
                  )}
                </WarningCallout.Label>
                <p>
                  {T(
                    `containers.participantDetailsManager.sections.yxEligible.warning.body`,
                    { context: eventType }
                  )}
                </p>
              </WarningCallout>
              <Button
                data-testid={`${eventTag}-continue-button`}
                onClick={onContinue}
              >
                {T(
                  `containers.participantDetailsManager.sections.yxEligible.actions.continue`
                )}
              </Button>
            </>
          )}
        {bookability?.bookable === false && (
          <>
            <Label isPageHeading data-testid="not-bookable-title">
              {T(
                `containers.participantDetailsManager.sections.yxEligible.checkCanBook.${macroCaseToCamelCase(
                  bookability.reason
                )}.title`
              )}
            </Label>
            <Label data-testid="not-bookable-message">
              <Trans
                i18nKey={`containers.participantDetailsManager.sections.yxEligible.checkCanBook.${macroCaseToCamelCase(
                  bookability.reason
                )}.description`}
                components={{
                  nhsGalleriLink: (
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://www.nhs-galleri.org/"
                    >
                      nhs-galleri-link
                    </a>
                  ),
                }}
              />
            </Label>
          </>
        )}
        {error && (
          <ErrorPanel
            title={T(
              `containers.participantDetailsManager.sections.yxEligible.errors.checkCanBook.title`
            )}
          >
            {error.message}
          </ErrorPanel>
        )}
      </ReadingWidth>
    </main>
  );
};

const AddressOutsideOfEngland = (): JSX.Element => {
  return (
    <main className="nhsuk-main-wrapper">
      <ReadingWidth>
        <Label data-testid="address-outside-england-title" isPageHeading>
          {T(
            "containers.participantDetailsManager.sections.addressOutsideOfEngland.title"
          )}
        </Label>
        <p>
          {T(
            "containers.participantDetailsManager.sections.addressOutsideOfEngland.paragraph1"
          )}
        </p>
        <p>
          {T(
            "containers.participantDetailsManager.sections.addressOutsideOfEngland.paragraph2"
          )}
        </p>
      </ReadingWidth>
    </main>
  );
};

export const ParticipantVerification = (): JSX.Element => {
  const { user } = useAuth();

  const studyEventsAPI = useAPI("studyEvents");
  const peopleAPI = useAPI("people");
  const appointmentAPI = useAPI("appointment");
  const [cohortId, setCohortId] = useState<string>("");
  const [isEligibleForNextAppt, setIsEligibleForNextAppt] =
    useState<boolean>(false);
  const [latestStudyEventType, setLatestStudyEventType] = useState<string>("");
  const [postcode, setPostcode] = useState<string>("");
  const [bookability, setBookability] = useState<Bookability>();
  const [person, setPerson] = useState<IPerson>();
  const { bookingClosed, bookingClosedError } = useQueryBookingClosed();

  useEffect(() => {
    if (cohortId !== "")
      appointmentAPI.checkCanBook(cohortId).then(setBookability);
  }, [appointmentAPI, cohortId]);

  useEffect(() => {
    if (cohortId !== "") peopleAPI.getPerson(cohortId).then(setPerson);
  }, [peopleAPI, cohortId]);

  const setEventsStatus = async (cohortID: string): Promise<void> => {
    const studyEvents = await studyEventsAPI.getStudyEvents(cohortID);

    const sortedEvents = studyEvents.sort((a, b) => {
      return (
        b.predictedActionDate.toMillis() - a.predictedActionDate.toMillis()
      );
    });
    const closestFutureEvent = sortedEvents
      .slice()
      .filter((event) => DateTime.now() < event.predictedActionDate)
      .reverse()[0];
    const latestCurrentEvent = sortedEvents.filter(
      (event) => DateTime.now() > event.predictedActionDate
    )[0];
    if (latestCurrentEvent) {
      setLatestStudyEventType(latestCurrentEvent.eventType);
      setIsEligibleForNextAppt(true);
    } else if (closestFutureEvent) {
      setLatestStudyEventType(closestFutureEvent.eventType);
      setIsEligibleForNextAppt(false);
    } else {
      // if there are no study events, default to Y1
      setLatestStudyEventType(AppointmentType.Y1);
      setIsEligibleForNextAppt(false);
    }
  };

  const confirmDetailsAndNextEventEligibility = async (
    details: IPerson | undefined,
    updatePersonDetail?: UpdateAsParticipantPayload
  ): Promise<string> => {
    if (!details) {
      return "";
    }

    setPostcode(details.mailingAddress.postcode);
    if (updatePersonDetail) {
      updatePersonDetail.cohortId = details.cohortId;
      await peopleAPI.updatePersonAsParticipant(updatePersonDetail).then(() => {
        if (updatePersonDetail.mailingAddress?.postcode) {
          setPostcode(updatePersonDetail.mailingAddress.postcode);
        }
      });
    }

    setCohortId(details.cohortId);

    await setEventsStatus(details.cohortId);

    return cohortId;
  };

  if (bookingClosedError !== null) {
    return (
      <main className="nhsuk-main-wrapper">
        <ReadingWidth>
          <ErrorPanel
            title={T("components.bookingClosed.error.title")}
            label="booking-closed-error"
          >
            {bookingClosedError}
          </ErrorPanel>
        </ReadingWidth>
      </main>
    );
  } else if (bookingClosed === undefined) {
    return <Loader />;
  } else if (bookingClosed) {
    return <BookingClosed />;
  } else if (cohortId !== "") {
    if (!bookability || !person) {
      return <Loader />;
    }
    if ((postcode !== "" && isEnglishPostcode(postcode)) || !isInvitee(user)) {
      if (
        bookability.bookable &&
        bookability.type !== AppointmentType.D1 &&
        isEligibleForNextAppt &&
        bookability.hasExistingAppointment
      ) {
        return (
          <ParticipantAppointmentManagement
            person={person}
            bookability={bookability}
          />
        );
      } else if (isEligibleForNextAppt) {
        return (
          <EligibilityConfirmed
            cohortId={cohortId}
            eventType={latestStudyEventType}
            bookability={bookability}
            person={person}
          />
        );
      }
      return <EligibilityDeclined eventType={latestStudyEventType} />;
    }
    return <AddressOutsideOfEngland />;
  }

  return (
    <main className="nhsuk-main-wrapper">
      <ReadingWidth>
        <ParticipantDetailsManager
          onConfirm={confirmDetailsAndNextEventEligibility}
        />
      </ReadingWidth>
    </main>
  );
};
