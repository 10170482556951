import React from "react";
import { ISearchResult } from "interfaces";
import { SearchResult } from "containers/Search/SearchResult";
import { T } from "i18n";

interface SearchResultsProps {
  results: ISearchResult[];
}

export const SearchResults = ({ results }: SearchResultsProps): JSX.Element => (
  <div aria-label={T("containers.search.results.label")}>
    {results.length === 0 && <h5>{T("containers.search.noResult")}</h5>}
    {results.length > 0 &&
      results.map((person) => (
        <SearchResult key={person.cohortId} person={person} />
      ))}
  </div>
);
