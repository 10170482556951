import { AxiosInstance } from "axios";
import { DateTime } from "luxon";
import { IVisit } from "interfaces/IVisit";
import { Stage, VisitStatus } from "enums";

export interface IVisitClient {
  getLatestVisit(cohortId: string): Promise<IVisit>;
  updateStatus(
    cohortId: string,
    status: VisitStatus,
    comment: string
  ): Promise<void>;
}

export interface APIVisit extends Omit<IVisit, "startedAt" | "finishedAt"> {
  startedAt: string;
  finishedAt: string | null;
}

export const apiVisitToVisit = (input: APIVisit): IVisit => {
  return {
    ...input,
    startedAt: DateTime.fromISO(input.startedAt),
    finishedAt: input.finishedAt ? DateTime.fromISO(input.finishedAt) : null,
    stage: input.stage as Stage,
  };
};

export class VisitClient implements IVisitClient {
  public constructor(protected readonly http: AxiosInstance) {}

  getLatestVisit(cohortId: string): Promise<IVisit> {
    return this.http
      .get(`/visits/${cohortId}/latest`)
      .then((response) => apiVisitToVisit(response.data));
  }

  updateStatus(
    cohortId: string,
    status: VisitStatus,
    comment: string
  ): Promise<void> {
    return this.http.put(`/visits/${cohortId}/latest`, {
      status,
      comment,
    });
  }
}
