import React, { SyntheticEvent, useEffect, useState } from "react";
import {
  BackLink,
  Button,
  Checkboxes,
  ErrorSummary,
  Fieldset,
  Form,
  Input,
} from "nhsuk-react-components";
import { Loader, PhoneNumberInput } from "components";
import { T } from "i18n";
import { hasWords, isValidEmail, isValidPhoneNumber } from "../../validations";
import { useAPI } from "api";
import { useHistory } from "react-router-dom";

export function CreateUser(): JSX.Element {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [roles, setRoles] = useState<string[]>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<Error>();
  const [allRoles, setAllRoles] = useState<string[]>();
  const [loadingRoles, setLoadingRoles] = useState<boolean>(true);
  const { push } = useHistory();

  const userAPI = useAPI("user");

  useEffect(() => {
    setLoadingRoles(true);
    userAPI
      .listRoles()
      .then(setAllRoles)
      .finally(() => setLoadingRoles(false));
  }, [userAPI]);

  const onSubmitForm = async (
    e: SyntheticEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    if (!validate()) {
      return;
    }

    setSubmitting(true);
    try {
      await userAPI.create(email, firstName, lastName, phoneNumber, roles);
      push("/user");
    } catch (e) {
      setError(e);
    } finally {
      setSubmitting(false);
    }
  };

  const validate = (): boolean => {
    setError(undefined);
    setEmailError("");
    setPhoneNumberError("");
    let valid = true;
    const emailHasWords = hasWords(
      email,
      T("containers.user.createUser.email.errors.invalid")
    );
    if (emailHasWords !== "") {
      valid = false;
      setEmailError(emailHasWords);
    }
    const validEmailError = isValidEmail(
      email,
      T("containers.user.createUser.email.errors.invalid")
    );
    if (validEmailError !== "") {
      valid = false;
      setEmailError(validEmailError);
    }
    const validPhoneNumberError = isValidPhoneNumber(
      phoneNumber,
      T("containers.user.createUser.phoneNumber.errors.invalid")
    );
    if (validPhoneNumberError !== "") {
      valid = false;
      setPhoneNumberError(validPhoneNumberError);
    }
    if (phoneNumber.split(" ")[1][0] === "0") {
      valid = false;
      setPhoneNumberError(
        T("containers.user.createUser.phoneNumber.errors.numberStartsWith")
      );
    }

    return valid;
  };

  if (loadingRoles) {
    return <Loader />;
  }

  return (
    <main className="nhsuk-main-wrapper">
      <BackLink
        data-testid="go-back"
        aria-label={T("containers.user.createUser.actions.back")}
        onClick={() => push("/user")}
      >
        {T("containers.user.createUser.actions.back")}
      </BackLink>
      <Form onSubmit={onSubmitForm}>
        <Fieldset>
          <Fieldset.Legend isPageHeading>
            {T("containers.user.createUser.title")}
          </Fieldset.Legend>
          <Input
            type="text"
            data-testid="email"
            label={T("containers.user.createUser.email.label")}
            aria-label={T("containers.user.createUser.email.label")}
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            error={emailError}
            width="20"
          />
          <Input
            type="text"
            data-testid="firstName"
            label={T("containers.user.createUser.firstName.label")}
            aria-label={T("containers.user.createUser.firstName.label")}
            value={firstName}
            onChange={(e) => setFirstName(e.currentTarget.value)}
            error=""
            width="20"
          />
          <Input
            type="text"
            data-testid="email"
            label={T("containers.user.createUser.lastName.label")}
            aria-label={T("containers.user.createUser.lastName.label")}
            value={lastName}
            onChange={(e) => setLastName(e.currentTarget.value)}
            error=""
            width="20"
          />
          <PhoneNumberInput
            phoneNumber={phoneNumber}
            onChange={setPhoneNumber}
            error={phoneNumberError}
            label={T("containers.user.createUser.phoneNumber.label")}
            aria-label={T("containers.user.createUser.phoneNumber.label")}
            data-testid="phone-number"
            defaultCallingCode="+44"
          />
          <Checkboxes
            label={T("containers.user.createUser.actions.selectRoles")}
          >
            {allRoles?.map((role) => (
              <Checkboxes.Box
                aria-label={role}
                value={role}
                key={role}
                onClick={(e) => {
                  if (roles.includes(role)) {
                    setRoles(
                      roles.filter((existingRole) => existingRole !== role)
                    );
                  } else {
                    setRoles([...roles, role]);
                  }
                }}
              >
                {role}
              </Checkboxes.Box>
            ))}
          </Checkboxes>
        </Fieldset>
        {error && (
          <ErrorSummary
            aria-label={T("containers.user.createUser.submissionError.label")}
            role="alert"
            tabIndex={-1}
          >
            <ErrorSummary.Title>
              {T("containers.user.createUser.submissionError.label")}
            </ErrorSummary.Title>
            <ErrorSummary.Body data-testid="error-body">
              {error.message}
            </ErrorSummary.Body>
          </ErrorSummary>
        )}
        <Button
          aria-label={T("containers.user.createUser.actions.submit")}
          disabled={submitting}
        >
          {T("containers.user.createUser.actions.submit")}
        </Button>
      </Form>
    </main>
  );
}
