import "./ECRF.scss";

import React, { useState } from "react";
import { Button, Checkboxes } from "nhsuk-react-components";
import { Form, FormAnswer, FormDefinition } from "@grailbio/components-ecrf";
import { FormField } from "./FormField";
import { T } from "i18n";

type SummaryProps = {
  definition: FormDefinition;
  answers: FormAnswer[];
  onSubmit: () => void;
  disabled?: boolean;
  title?: string;
  instruction?: string;
  submitLabel?: string;
};

export const FormSummary = ({
  definition,
  answers,
  onSubmit,
  disabled = false,
  title = T("containers.ecrf.summary.title"),
  instruction = T("containers.ecrf.summary.instruction"),
  submitLabel = T("components.ecrf.actions.submit"),
}: SummaryProps): JSX.Element => {
  const [summaryConfirmed, setSummaryConfirmed] = useState(false);
  return (
    <div className="ecrf" data-testid="formSummary">
      <h2>{title}</h2>
      <p>{instruction}</p>
      <div className="summary">
        <Form
          showTitle={false}
          showSectionNames={false}
          definition={definition}
          state={answers}
          readOnly
          renderFormField={FormField}
        />
      </div>
      <Checkboxes>
        <Checkboxes.Box
          value="confirmSummary"
          data-testid="confirmSummary"
          onChange={() =>
            setSummaryConfirmed((summaryConfirmed) => !summaryConfirmed)
          }
        >
          {T("containers.ecrf.summary.checkbox")}
        </Checkboxes.Box>
      </Checkboxes>
      <br />
      <Button
        type="submit"
        disabled={!summaryConfirmed || disabled}
        onClick={(
          e:
            | React.MouseEvent<HTMLAnchorElement>
            | React.MouseEvent<HTMLButtonElement>
        ) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        {submitLabel}
      </Button>
    </div>
  );
};
