import React from "react";
import { IFieldSorter, SortableKey } from "interfaces";

interface SortArrowsProps<T> {
  field: SortableKey<T>;
  currentSorter: IFieldSorter<T>;
  setCurrentSorter: (currentSorter: IFieldSorter<T>) => void;
  className?: string;
}

export function SortArrows<T>({
  field,
  currentSorter,
  setCurrentSorter,
  className = "sort-arrows",
}: SortArrowsProps<T>): JSX.Element {
  const showArrow = (query: IFieldSorter<T>): boolean => {
    if (query.field !== currentSorter.field) return true;
    return query.isDescending !== currentSorter.isDescending;
  };
  const ascSorter: IFieldSorter<T> = {
    field,
    isDescending: false,
  };
  const descSorter: IFieldSorter<T> = {
    field,
    isDescending: true,
  };

  return (
    <div className={className}>
      {showArrow(ascSorter) && (
        <div
          className="sort-arrow-asc"
          onClick={() => {
            setCurrentSorter(ascSorter);
          }}
          data-testid={`sort-arrow-asc-${field}`}
        ></div>
      )}
      {showArrow(descSorter) && (
        <div
          className="sort-arrow-desc"
          onClick={() => {
            setCurrentSorter(descSorter);
          }}
          data-testid={`sort-arrow-desc-${field}`}
        ></div>
      )}
    </div>
  );
}
