import React, { ComponentPropsWithoutRef } from "react";
import { IDateInputValue, IDateSetter } from "../interfaces";
import { DateInput as NHSDateInput } from "nhsuk-react-components";

type NHSDateInputProps = ComponentPropsWithoutRef<typeof NHSDateInput>;

export interface DateInputProps
  extends Omit<NHSDateInputProps, "value" | "onChange"> {
  date: IDateInputValue;
  setDate: IDateSetter;
}

export function DateInput({
  date,
  setDate,
  ...props
}: DateInputProps): JSX.Element {
  return (
    <NHSDateInput
      data-testid="nhs-date-input"
      value={date}
      onChange={(e) => setDate(e.currentTarget.value)}
      {...props}
    />
  );
}
