import React from "react";
import { Input } from "nhsuk-react-components";
import { T } from "i18n";

interface EmailInputElementIDs {
  email?: string;
}

interface EmailInputProps {
  email?: string;
  emailChanged?: (newVal: string) => void;
  emailError?: string;
  elementIDs?: EmailInputElementIDs;
}

export const EmailInput = ({
  email,
  emailChanged,
  emailError,
  elementIDs,
}: EmailInputProps): JSX.Element => {
  return (
    <Input
      type="text"
      data-testid="email"
      id={elementIDs?.email}
      name="email"
      label={T("components.emailInput.fields.email.label")}
      hint={T("components.emailInput.fields.email.hint")}
      aria-label={T("components.emailInput.fields.email.label")}
      value={email}
      onChange={(e) => emailChanged && emailChanged(e.currentTarget.value)}
      error={emailError}
      width="20"
    />
  );
};
