import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { ErrorPanel, Loader } from "../../../components";
import { T } from "i18n";
import { compactParticipantID } from "../../../helpers/compactParticipantID";
import { useAPI } from "api";

const formatReportFilename = (
  studyID: string,
  reportISODate?: string
): string => {
  const compactStudyID = compactParticipantID(studyID);
  if (!reportISODate) {
    return compactStudyID;
  }
  return `${DateTime.fromISO(reportISODate, { setZone: true }).toFormat(
    "dd-MM-yyyy"
  )}_${compactStudyID}`;
};

export const ReportPDF = ({
  caseReference,
  reportISODate,
  studyID,
}: {
  caseReference: string;
  reportISODate?: string;
  studyID: string;
}): JSX.Element => {
  const [src, setSrc] = useState<string>();
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [pdfError, setLoadPDFError] = useState<Error>();

  const resultsApi = useAPI("results");

  useEffect(() => {
    setLoadingPDF(true);
    resultsApi
      .getReportPDF(caseReference)
      .then((pdf) => {
        setSrc(URL.createObjectURL(pdf));
      })
      .catch(setLoadPDFError)
      .finally(() => setLoadingPDF(false));
  }, [caseReference, resultsApi]);

  if (loadingPDF) {
    return <Loader />;
  }

  if (pdfError) {
    return <ErrorPanel title={pdfError.message} />;
  }

  return (
    <>
      <a
        data-testid="pdfDownloadLink"
        href={src}
        download={formatReportFilename(studyID, reportISODate)}
      >
        {T("containers.resultsCase.reportPDF.download")}
      </a>
      <br />
      <div style={{ margin: "auto" }}>
        <embed
          data-testid="result-pdf"
          type="application/pdf"
          height="1000"
          width="800"
          src={`${src}#toolbar=0&navpanes=0&scrollbar=0`}
        />
      </div>
    </>
  );
};
