import { useCallback, useEffect, useState } from "react";
import { useAPI } from "../api";

export const useQueryBookingClosed = (): {
  bookingClosed: boolean | undefined;
  bookingClosedError: string | null;
} => {
  const [isBookingClosed, setIsBookingClosed] = useState<boolean>();
  const [error, setError] = useState<string | null>(null);
  const infoAPI = useAPI("info");

  const updateBookingClosed = useCallback(async (): Promise<void> => {
    try {
      const closed = await infoAPI.queryBookingClosed();
      setIsBookingClosed(closed);
      setError(null);
    } catch (error) {
      setError("Failed to determine information about bookings availability");
    }
  }, [infoAPI]);

  useEffect(() => {
    updateBookingClosed();
  }, [updateBookingClosed]);

  return { bookingClosed: isBookingClosed, bookingClosedError: error };
};
