import React from "react";
import { Card } from "nhsuk-react-components";

export const Loader = ({ panel = true }: { panel?: boolean }): JSX.Element => {
  const loader = <div data-testid="loading">Loading</div>;
  if (panel) {
    return (
      <Card>
        <Card.Content>{loader}</Card.Content>
      </Card>
    );
  }
  return loader;
};
