import { IPhoneNumber } from "../../interfaces";
import { getCallingCodesSet } from "./getCallingCodeSet";

const callingCodeSet = getCallingCodesSet();

const possiblyStartsWithCallingCode = new RegExp(/^[+][0-9]/);

export const splitPhoneNumber = (
  phoneNumber: string,
  defaultCallingCode: string
): IPhoneNumber => {
  let fullNumber = phoneNumber.replace(/^00/, "+");

  if (possiblyStartsWithCallingCode.test(fullNumber)) {
    // due to the space we need to be greedy for the longest calling code possible
    for (let i = 4; i > 0; i--) {
      const codeCandidate = fullNumber.slice(0, i + 1);
      if (callingCodeSet.has(codeCandidate)) {
        return {
          callingCode: codeCandidate,
          nationalNumber: fullNumber.slice(i + 1).trimStart(),
        };
      }
    }
  }

  fullNumber = fullNumber.replace(/^0/, "");
  return { callingCode: defaultCallingCode, nationalNumber: fullNumber };
};
