import React, {
  ElementRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { AgeForm } from "./AgeForm";
import { ErrorPanel, Loader, Wizard, WizardPage } from "components";
import { SurveyAnswers, SurveyQuestions, SurveyResults, useAPI } from "api";
import { SurveyResultsEligible } from "./SurveyResultsEligible";
import { SurveyResultsIneligible } from "./SurveyResultIneligible";
import { T } from "i18n";
import { TakingPartForm } from "./TakingPartForm";

interface TakingPartProps {
  onContinue?(): void;
}

type TakingPartFormValidateHandle = ElementRef<typeof TakingPartForm>;
type AgeFormValidateHandle = ElementRef<typeof AgeForm>;

export const Survey = ({ onContinue }: TakingPartProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [evaluating, setEvaluating] = useState<boolean>(false);
  const [survey, setSurvey] = useState<SurveyQuestions>();
  const [answers, setAnswers] = useState<SurveyAnswers>({
    question1: "",
    question2: "",
    question3: "",
    age: "",
  });
  const [results, setResults] = useState<SurveyResults>();
  const [error, setError] = useState<string>();
  const surveyClient = useAPI("survey");

  useEffect(() => {
    setLoading(true);
    surveyClient
      .getQuestions()
      .then(setSurvey)
      .catch(({ message }) => setError(message))
      .finally(() => setLoading(false));
  }, [surveyClient]);

  const onEvaluate = useCallback(
    (answers: SurveyAnswers): Promise<void> => {
      setEvaluating(true);
      return surveyClient
        .evaluate(answers)
        .then((results) => {
          setResults(results);
        })
        .catch(({ message }) => setError(message))
        .finally(() => setEvaluating(false));
    },
    [setEvaluating, setResults, setError, surveyClient]
  );

  const takingPartFormRef = useRef<TakingPartFormValidateHandle>(null);
  const ageFormRef = useRef<AgeFormValidateHandle>(null);

  const validateTakingPartForm: () => boolean = () => {
    if (takingPartFormRef.current) {
      return takingPartFormRef.current.validate();
    }
    return false;
  };

  const validateAgeForm: () => boolean = () => {
    if (ageFormRef.current) {
      return ageFormRef.current.validate();
    }
    return false;
  };

  if (error) {
    return (
      <ErrorPanel
        title={T("containers.survey.errors.title")}
        label={T("containers.survey.errors.title")}
      >
        <p>{error}</p>
      </ErrorPanel>
    );
  }

  if (loading || !survey) {
    return <Loader />;
  }

  return (
    <Wizard>
      <WizardPage
        pageID="takingPartForm"
        nextLabel={T("containers.survey.actions.continue")}
        validate={validateTakingPartForm}
      >
        <TakingPartForm
          ref={takingPartFormRef}
          survey={survey}
          answers={answers}
          onAnswerChanged={(changedAnswers) => {
            setAnswers((originalAnswers) => ({
              ...originalAnswers,
              ...changedAnswers,
            }));
          }}
        />
      </WizardPage>
      <WizardPage
        pageID="ageForm"
        nextLabel={T("containers.survey.actions.continue")}
        nextEnabled={!evaluating}
        validate={() => {
          const valid = validateAgeForm();
          if (!valid) return false;
          return onEvaluate(answers);
        }}
      >
        <AgeForm
          ref={ageFormRef}
          survey={survey}
          answers={answers}
          onAnswerChanged={(changedAnswers) => {
            setAnswers((originalAnswers) => ({
              ...originalAnswers,
              ...changedAnswers,
            }));
          }}
        />
      </WizardPage>
      <WizardPage pageID="results">
        {results?.eligible ? (
          <SurveyResultsEligible
            onContinue={() => onContinue && onContinue()}
          />
        ) : (
          results && <SurveyResultsIneligible results={results} />
        )}
      </WizardPage>
    </Wizard>
  );
};
