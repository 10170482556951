import "./RegistrationForm.scss";
import React, {
  FormEvent,
  KeyboardEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  Button,
  Details,
  Form,
  Hint,
  Input,
  Label,
  SummaryList,
} from "nhsuk-react-components";
import { MaskedInput } from "nhsuk-react-components-extensions";

import {
  Address,
  ErrorPanel,
  FindGPForm,
  JumpTo,
  Loader,
  NoGPView,
  SelectGPForm,
  Wizard,
  WizardPage,
} from "components";
import { BiologicalSex } from "enums";
import { ContactForm } from "./ContactForm";
import {
  EventTypeContext,
  useAddress,
  usePrevious,
  useScrollToTop,
} from "hooks";
import { FormValidity, ValiditySummary } from "components/FormValidity";
import { IDateInputValue, IGp, IRegistration } from "interfaces";
import { PersonalDetailForm } from "./PersonalDetailForm";
import { T } from "i18n";
import { dateInputToDateTime, nhsNumberInputToString } from "helpers";
import { formatCapitalisation, formatDateOfBirthInput } from "formatters";
import { hasWords, isValidNHSNumber } from "validations";
import { useAPI } from "api";

function doNotAllowFormSubmissionOnEnterKey(e: KeyboardEvent): void {
  e.key === "Enter" && e.preventDefault();
}

const ids = {
  nhsNumber: "nhs-number-input",
  invitationCode: "invitation-code-input",
};

interface FindMyNHSNumberProps {
  displayHint?: boolean;
}

export const FindMyNHSNumber = ({
  displayHint,
}: FindMyNHSNumberProps): JSX.Element => (
  <Details data-testid="find-nhs-number">
    <Details.Summary>{T("components.findMyNHSNumber.title")}</Details.Summary>
    <Details.Text>
      {displayHint && (
        <p>{T("containers.registration.fields.nhsNumber.hint")}</p>
      )}
      <p>{T("components.findMyNHSNumber.documents.body")}</p>
      <ul>
        <li>{T("components.findMyNHSNumber.documents.items.prescriptions")}</li>
        <li>{T("components.findMyNHSNumber.documents.items.testResults")}</li>
        <li>
          {T(
            "components.findMyNHSNumber.documents.items.hospitalReferralLetters"
          )}
        </li>
        <li>
          {T("components.findMyNHSNumber.documents.items.appointmentLetters")}
        </li>
        <li>
          {T("components.findMyNHSNumber.documents.items.yourNHSMedicalCard")}
        </li>
      </ul>
      <p>
        {T("components.findMyNHSNumber.link.body")}
        &nbsp;
        <a
          href="https://www.nhs.uk/nhs-services/online-services/find-nhs-number/"
          target="_blank"
          rel="noreferrer"
        >
          {T("components.findMyNHSNumber.link.action")}
        </a>
      </p>
    </Details.Text>
  </Details>
);

interface RegistrationProps {
  disableErrorFromComponents?: boolean;
  onSubmit: (registration: IRegistration) => Promise<string>;
  dataSharingAgreementVersion: string;
}

const RegistrationProgress = ({
  step: stepNumber,
}: {
  step: number;
}): JSX.Element => {
  return (
    <Hint data-testid="wizard-progress">
      {T("containers.registration.sections.progressMessage", {
        stepNumber,
        maxSteps: 10,
      })}
    </Hint>
  );
};

export const RegistrationForm = (props: RegistrationProps): JSX.Element => {
  useScrollToTop();

  const {
    disableErrorFromComponents = false,
    onSubmit,
    dataSharingAgreementVersion,
  } = props;

  const [nhsNumber, setNHSNumber] = useState<string>("");

  const [invitationCode, setInvitationCode] = useState<string>("");

  const [invitationError, setInvitationError] = useState<Error>(new Error(""));

  const [firstName, setFirstName] = useState<string>("");

  const [lastName, setLastName] = useState<string>("");

  const [dateOfBirthInput, setDateOfBirthInput] = useState<IDateInputValue>({
    day: "",
    month: "",
    year: "",
  });

  const [biologicalSex, setBiologicalSex] = useState<BiologicalSex>();

  const [submissionError, setSubmissionError] = useState<Error>(new Error(""));

  const [defaultAddress] = useAddress();
  const [houseAddress, setHouseAddress] = useState(defaultAddress);

  const [email, setEmail] = useState<string>("");

  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const [mobilePhoneNumber, setMobilePhoneNumber] = useState<string>("");

  const api = useAPI("participantAuth");

  const [validity, setValidity] = useState<FormValidity>(new FormValidity());
  const [findGPFormValidity, setFindGPFormValidity] = useState(
    new FormValidity()
  );
  const [selectGPFormValidity, setSelectGPFormValidity] = useState(
    new FormValidity()
  );
  const [personalDetailFormValidity, setPersonalDetailFormValidity] = useState(
    new FormValidity()
  );
  const [contactFormValidity, setContactFormValidity] = useState(
    new FormValidity()
  );

  const validateFindGp = (): boolean => {
    setValidity(findGPFormValidity);
    return findGPFormValidity.valid;
  };

  const validateSelectGp = (): boolean => {
    setValidity(selectGPFormValidity);
    return selectGPFormValidity.valid;
  };

  const validateRegister = async (): Promise<boolean> => {
    const validity = new FormValidity();
    const nhsNumberError = isValidNHSNumber(
      nhsNumber,
      T("containers.registration.fields.nhsNumber.errors.invalid")
    );
    validity.addError(nhsNumberError, ids.nhsNumber);

    const invitationCodeError = hasWords(
      invitationCode,
      T("containers.registration.fields.invitationCode.errors.invalid")
    );
    validity.addError(invitationCodeError, ids.invitationCode);

    if (validity.valid) {
      let inviteError = new Error("");

      await api.getToken({ nhsNumber, invitationCode }, true).catch((error) => {
        inviteError = error;
      });

      setInvitationError(inviteError);
      validity.addError(inviteError.message, ids.invitationCode);
    }

    setValidity(validity);
    return validity.valid;
  };

  const validatePersonalInformation = (): boolean => {
    setValidity(personalDetailFormValidity);
    return personalDetailFormValidity.valid;
  };

  const validateContactInformation = (): boolean => {
    setValidity(contactFormValidity);
    return contactFormValidity.valid;
  };

  const [findGpPostcode, findGpPostcodeSetter] = useState<string>("");
  const previousFindGpPostcode = usePrevious(findGpPostcode);
  const [findGpPostcodeError, findGpPostcodeErrorSetter] = useState<Error>();
  const [foundGps, setFoundGps] = useState<IGp[]>([]);

  const gpAPI = useAPI("gp");

  const updateFoundGps = async (gpPostcode: string): Promise<IGp[] | null> => {
    if (gpPostcode === "") {
      setFoundGps([]);
      return [];
    }

    setLoading(true);
    try {
      const gps = await gpAPI.findGps(findGpPostcode);
      setFoundGps(gps);
      return gps;
    } catch (e) {
      findGpPostcodeErrorSetter(e);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const [selectedGP, setSelectedGP] = useState<IGp | null>();

  const clearSelectedGp = useCallback(() => {
    setSelectedGP(undefined);
  }, []);

  useEffect(() => {
    if (previousFindGpPostcode !== findGpPostcode) {
      clearSelectedGp();
    }
  }, [clearSelectedGp, findGpPostcode, previousFindGpPostcode]);

  const [loading, setLoading] = useState<boolean>(false);

  const onSubmitForm = async (
    e: SyntheticEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setLoading(true);

    if (!selectedGP) {
      throw new Error("GP not selected");
    }

    await onSubmit({
      invitationCode,
      biologicalSex: BiologicalSex[biologicalSex as keyof typeof BiologicalSex],
      dateOfBirth: dateInputToDateTime(dateOfBirthInput),
      firstName,
      lastName,
      nhsNumber,
      mailingAddress: houseAddress,
      gpPractice: selectedGP,
      email,
      phoneNumber,
      mobilePhoneNumber,
      dataSharingAgreementVersion,
    })
      .catch(setSubmissionError)
      .finally(() => setLoading(false));
  };

  const wizardPageIds = {
    findGP: "findGP",
    selectGP: "selectGP",
    noGP: "noGP",
    invitation: "invitation",
    personalDetails: "personalDetails",
    contact: "contact",
    summary: "summary",
  };

  const updateFoundGpsAndGetNextPageID = async (): Promise<string> => {
    const gps = await updateFoundGps(findGpPostcode);
    if (gps && gps.length === 0) {
      return wizardPageIds.noGP;
    }
    return wizardPageIds.selectGP;
  };

  const clearErrors = (): void => {
    setValidity(new FormValidity());
  };

  const onWizardPageChanged = (): void => {
    clearErrors();
  };

  return (
    <EventTypeContext.Provider value="D1">
      <Form
        onKeyDown={doNotAllowFormSubmissionOnEnterKey}
        onSubmit={onSubmitForm}
        data-testid="registration-form"
        disableErrorFromComponents={disableErrorFromComponents}
      >
        <Wizard onPageChanged={onWizardPageChanged}>
          <WizardPage
            pageID={wizardPageIds.findGP}
            validate={validateFindGp}
            nextLabel={T("containers.registration.sections.findGp.nextLabel")}
            nextPage={updateFoundGpsAndGetNextPageID}
            nextEnabled={!loading}
          >
            <RegistrationProgress step={1} />
            <Label isPageHeading>
              {T("containers.registration.sections.findGp.title")}
            </Label>
            <FindGPForm
              findGPPostcode={findGpPostcode}
              findGPPostcodeChanged={findGpPostcodeSetter}
              validity={validity}
              validityUpdated={setFindGPFormValidity}
            />
          </WizardPage>

          <WizardPage
            pageID={wizardPageIds.selectGP}
            validate={validateSelectGp}
            nextLabel={T("containers.registration.sections.selectGp.nextLabel")}
            nextEnabled={!findGpPostcodeError}
            nextPage={() =>
              !selectedGP ? wizardPageIds.noGP : wizardPageIds.invitation
            }
          >
            <RegistrationProgress step={2} />
            <Label isPageHeading>
              {T("containers.registration.sections.selectGp.title")}
            </Label>
            <ValiditySummary validity={validity} />
            <SummaryList data-testid="postcode-summary">
              <SummaryList.Row>
                <SummaryList.Key className="summary-list-key">
                  {T("containers.registration.sections.selectGp.summary.title")}
                </SummaryList.Key>
                <SummaryList.Value>{findGpPostcode}</SummaryList.Value>
                <SummaryList.Actions>
                  <JumpTo
                    testid="postcode-summary-change"
                    to={wizardPageIds.findGP}
                    text={T(
                      "containers.registration.sections.selectGp.summary.action"
                    )}
                  />
                </SummaryList.Actions>
              </SummaryList.Row>
            </SummaryList>
            <SelectGPForm
              gps={foundGps}
              findGPPostcodeError={findGpPostcodeError}
              selectedGP={selectedGP}
              gpSelectionChanged={setSelectedGP}
              validity={validity}
              validityUpdated={setSelectGPFormValidity}
            />
          </WizardPage>

          <WizardPage
            pageID={wizardPageIds.noGP}
            previousPage={() =>
              selectedGP === null
                ? wizardPageIds.selectGP
                : wizardPageIds.findGP
            }
            branched
          >
            <NoGPView />
          </WizardPage>

          <WizardPage
            pageID={wizardPageIds.invitation}
            validate={validateRegister}
            nextLabel={T("containers.registration.sections.continue")}
          >
            <RegistrationProgress step={3} />
            <Label isPageHeading>
              {T("containers.registration.sections.invite.title")}
            </Label>
            <ValiditySummary validity={validity} />
            <MaskedInput
              type="text"
              data-testid="nhsNumber"
              id={ids.nhsNumber}
              label={T("containers.registration.fields.nhsNumber.label")}
              hint={T("containers.registration.fields.nhsNumber.hint")}
              aria-label={T("containers.registration.fields.nhsNumber.label")}
              value={nhsNumber}
              onChange={(e: FormEvent<HTMLInputElement>) =>
                setNHSNumber(nhsNumberInputToString(e.currentTarget.value))
              }
              error={validity.getFirstErrorForId(ids.nhsNumber)?.message}
              mask="999 999 9999"
              maskChar=""
              width="10"
            />
            <FindMyNHSNumber />
            <Input
              type="text"
              data-testid="invitationCode"
              id={ids.invitationCode}
              label={T("containers.registration.fields.invitationCode.label")}
              hint={T("containers.registration.fields.invitationCode.hint")}
              aria-label={T(
                "containers.registration.fields.invitationCode.label"
              )}
              value={invitationCode}
              onChange={(e) => setInvitationCode(e.currentTarget.value)}
              error={validity.getFirstErrorForId(ids.invitationCode)?.message}
              width="10"
            />
            {invitationError.message !== "" && (
              <ErrorPanel
                label="invitation-error"
                title={
                  <span id="error-summary-title">
                    {T("containers.registration.errors.invitationErrorTitle")}
                  </span>
                }
              >
                <p>{invitationError.message}</p>
              </ErrorPanel>
            )}
          </WizardPage>
          <WizardPage
            pageID={wizardPageIds.personalDetails}
            validate={validatePersonalInformation}
            nextLabel={T("containers.registration.sections.continue")}
          >
            <RegistrationProgress step={4} />
            <Label isPageHeading>
              {T("containers.registration.sections.aboutYou.title")}
            </Label>
            <PersonalDetailForm
              firstName={firstName}
              firstNameChanged={setFirstName}
              lastName={lastName}
              lastNameChanged={setLastName}
              dateOfBirthInput={dateOfBirthInput}
              dateOfBirthInputChanged={setDateOfBirthInput}
              biologicalSex={biologicalSex}
              biologicalSexChanged={setBiologicalSex}
              validity={validity}
              validityUpdated={setPersonalDetailFormValidity}
            />
          </WizardPage>
          <WizardPage
            pageID={wizardPageIds.contact}
            validate={validateContactInformation}
            nextLabel={T("containers.registration.sections.continue")}
          >
            <RegistrationProgress step={5} />
            <Label isPageHeading>
              {T("containers.registration.sections.contactInformation.title")}
            </Label>
            <ContactForm
              phoneNumber={phoneNumber}
              phoneNumberChanged={setPhoneNumber}
              mobilePhoneNumber={mobilePhoneNumber}
              mobilePhoneNumberChanged={setMobilePhoneNumber}
              email={email}
              emailChanged={setEmail}
              postalAddress={houseAddress}
              postalAddressChanged={setHouseAddress}
              validity={validity}
              validityUpdated={setContactFormValidity}
            />
          </WizardPage>
          <WizardPage pageID={wizardPageIds.summary}>
            <RegistrationProgress step={6} />
            <Label isPageHeading>
              {T("containers.registration.sections.confirm.title")}
            </Label>
            <Label>
              {T("containers.registration.sections.confirm.subheading")}
            </Label>
            <SummaryList>
              <SummaryList.Row>
                <SummaryList.Key>
                  {T(
                    "containers.registration.sections.confirm.summary.nhsNumber"
                  )}
                </SummaryList.Key>
                <SummaryList.Value
                  aria-label={T(
                    "containers.registration.sections.confirm.summary.nhsNumber"
                  )}
                >
                  {nhsNumber}
                </SummaryList.Value>
                <SummaryList.Actions>
                  <JumpTo
                    to={wizardPageIds.invitation}
                    text={T(
                      "containers.registration.sections.confirm.actions.change"
                    )}
                  />
                </SummaryList.Actions>
              </SummaryList.Row>
              {selectedGP && (
                <SummaryList.Row>
                  <SummaryList.Key>
                    {T("containers.registration.sections.confirm.summary.gp")}
                  </SummaryList.Key>
                  <SummaryList.Value
                    aria-label={T(
                      "containers.registration.sections.confirm.summary.gp"
                    )}
                  >
                    {selectedGP.name}
                    <br />
                    <Address address={selectedGP.address} properCase />
                  </SummaryList.Value>
                  <SummaryList.Actions>
                    <JumpTo
                      to={wizardPageIds.selectGP}
                      text={T(
                        "containers.registration.sections.confirm.actions.change"
                      )}
                    />
                  </SummaryList.Actions>
                </SummaryList.Row>
              )}

              <SummaryList.Row>
                <SummaryList.Key>
                  {T("containers.registration.sections.confirm.summary.name")}
                </SummaryList.Key>
                <SummaryList.Value
                  aria-label={T(
                    "containers.registration.sections.confirm.summary.name"
                  )}
                >
                  {`${firstName} ${lastName}`}
                </SummaryList.Value>
                <SummaryList.Actions>
                  <JumpTo
                    to={wizardPageIds.personalDetails}
                    text={T(
                      "containers.registration.sections.confirm.actions.change"
                    )}
                  />
                </SummaryList.Actions>
              </SummaryList.Row>
              <SummaryList.Row>
                <SummaryList.Key>
                  {T("containers.registration.sections.confirm.summary.dob")}
                </SummaryList.Key>
                <SummaryList.Value
                  aria-label={T(
                    "containers.registration.sections.confirm.summary.dob"
                  )}
                >
                  {formatDateOfBirthInput(dateOfBirthInput)}
                </SummaryList.Value>
                <SummaryList.Actions>
                  <JumpTo
                    to={wizardPageIds.personalDetails}
                    text={T(
                      "containers.registration.sections.confirm.actions.change"
                    )}
                  />
                </SummaryList.Actions>
              </SummaryList.Row>
              <SummaryList.Row>
                <SummaryList.Key>
                  {T("containers.registration.sections.confirm.summary.sex")}
                </SummaryList.Key>
                <SummaryList.Value
                  aria-label={T(
                    "containers.registration.sections.confirm.summary.sex"
                  )}
                >
                  {biologicalSex &&
                    formatCapitalisation(biologicalSex as BiologicalSex)}
                </SummaryList.Value>
                <SummaryList.Actions>
                  <JumpTo
                    to={wizardPageIds.personalDetails}
                    text={T(
                      "containers.registration.sections.confirm.actions.change"
                    )}
                  />
                </SummaryList.Actions>
              </SummaryList.Row>
              <SummaryList.Row>
                <SummaryList.Key>
                  {T(
                    "containers.registration.sections.confirm.summary.contact"
                  )}
                </SummaryList.Key>
                <SummaryList.Value
                  aria-label={T(
                    "containers.registration.sections.confirm.summary.contact"
                  )}
                >
                  {phoneNumber}
                  {mobilePhoneNumber && (
                    <>
                      <br />
                      {mobilePhoneNumber}
                    </>
                  )}
                  <br />
                  {email}
                </SummaryList.Value>
                <SummaryList.Actions>
                  <JumpTo
                    to={wizardPageIds.contact}
                    text={T(
                      "containers.registration.sections.confirm.actions.change"
                    )}
                  />
                </SummaryList.Actions>
              </SummaryList.Row>
              <SummaryList.Row>
                <SummaryList.Key>
                  {T(
                    "containers.registration.sections.confirm.summary.address"
                  )}
                </SummaryList.Key>
                <SummaryList.Value
                  aria-label={T(
                    "containers.registration.sections.confirm.summary.address"
                  )}
                >
                  <Address address={houseAddress} properCase />
                </SummaryList.Value>
                <SummaryList.Actions>
                  <JumpTo
                    to={wizardPageIds.contact}
                    text={T(
                      "containers.registration.sections.confirm.actions.change"
                    )}
                  />
                </SummaryList.Actions>
              </SummaryList.Row>
            </SummaryList>
            {loading && <Loader />}
            {!loading && (
              <Button
                type="submit"
                data-testid="wizard-submit"
                aria-label={T("containers.registration.sections.continue")}
              >
                {T("containers.registration.sections.continue")}
              </Button>
            )}
          </WizardPage>
        </Wizard>
        {submissionError.message !== "" && (
          <ErrorPanel
            label="submission-error"
            title={
              <span id="error-summary-title">
                {T("containers.registration.errors.submissionErrorTitle")}
              </span>
            }
          >
            <p>{submissionError.message}</p>
          </ErrorPanel>
        )}
      </Form>
    </EventTypeContext.Provider>
  );
};
