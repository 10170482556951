import React from "react";
import { T } from "i18n";

export const Acknowledgement = (): JSX.Element => (
  <main className="nhsuk-main-wrapper" data-testid="acknowledgement">
    <h3>{T("containers.acknowledgement.title")}</h3>
    <ul>
      <li>
        Contains information from NHS Digital, licenced under the current
        version of the Open Government Licence
      </li>
    </ul>
  </main>
);
