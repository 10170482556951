import React from "react";
import {
  AccessibilityRequirements,
  Address,
  ErrorPanel,
  Loader,
} from "components";
import { ActionLink, Button, Table } from "nhsuk-react-components";
import { AsyncCall, CallState } from "helpers/AsyncCall";
import { Bookability, IAppointment } from "interfaces";
import { DateTime } from "luxon";
import { Link, Redirect } from "react-router-dom";
import { Restricted } from "../../auth/components";
import { T } from "i18n";
import { Tag } from "nhsuk-react-components-extensions";
import { formatAppointmentDate } from "formatters";
import { macroCaseToCamelCase } from "../../helpers/letterCase";

interface ParticipantAppointmentsProps {
  cohortId: string;
  appointments: AsyncCall<IAppointment[]>;
  canBook: AsyncCall<Bookability>;
  onBookAppointment: () => void;
}

enum AppointmentCheckInStatus {
  TODAY_BOOKED,
  TODAY_IN_PROGRESS,
  NOT_TODAY,
}

function isAppointmentToday(appointmentDate: DateTime): boolean {
  const now = DateTime.now();
  return appointmentDate.toISODate() === now.toISODate();
}

function getAppointmentStatus(
  appointment: IAppointment
): AppointmentCheckInStatus {
  if (!isAppointmentToday(appointment.appointmentStartTime)) {
    return AppointmentCheckInStatus.NOT_TODAY;
  }

  if (appointment.status === "BOOKED") {
    return AppointmentCheckInStatus.TODAY_BOOKED;
  }

  if (appointment.status === "ATTENDED" && !!appointment.inProgress) {
    return AppointmentCheckInStatus.TODAY_IN_PROGRESS;
  }

  return AppointmentCheckInStatus.NOT_TODAY;
}

export function PersonAppointments({
  cohortId,
  appointments,
  canBook,
  onBookAppointment,
}: ParticipantAppointmentsProps): JSX.Element {
  if (
    canBook.state === CallState.Ok &&
    canBook.result.bookable &&
    !canBook.result.hasExistingAppointment
  ) {
    let appointmentPageURL = `/registration/${cohortId}/appointment?appointmentType=${canBook.result.type}`;
    if (canBook.result.window?.from) {
      appointmentPageURL += `&from=${canBook.result.window.from}`;
    }
    return <Redirect to={appointmentPageURL} />;
  }

  const AppointmentStatus = ({
    appointment,
  }: {
    appointment: IAppointment;
  }): JSX.Element => {
    switch (getAppointmentStatus(appointment)) {
      case AppointmentCheckInStatus.TODAY_BOOKED:
        return (
          <ActionLink
            asElement={Link}
            data-testid="check-in-link"
            to={`/check-in/${appointment.unitCode}/confirm/${appointment.cohortId}`}
            className="action-button"
            aria-label={T(
              "containers.participant.appointments.actions.checkIn"
            )}
          >
            {T("containers.participant.appointments.actions.checkIn")}
          </ActionLink>
        );
      case AppointmentCheckInStatus.TODAY_IN_PROGRESS:
        return (
          <ActionLink
            asElement={Link}
            data-testid="continue-visit-link"
            to={`/visit/${appointment.cohortId}`}
            className="action-button"
            aria-label={T(
              "containers.participant.appointments.actions.continue"
            )}
          >
            {T("containers.participant.appointments.actions.continue")}
          </ActionLink>
        );
      case AppointmentCheckInStatus.NOT_TODAY:
        return <Tag>{appointment.status}</Tag>;
    }
  };

  const BookAppointment = (): JSX.Element => {
    let callRender: JSX.Element = <></>;
    switch (canBook.state) {
      case CallState.Loading:
        callRender = <Loader />;
        break;
      case CallState.Error:
        callRender = (
          <ErrorPanel
            data-testid="booking-error"
            title="booking error"
            label="booking error"
          >
            {canBook.error.message}
          </ErrorPanel>
        );
        break;
      case CallState.Ok:
        if (!canBook.result.bookable)
          callRender = (
            <ErrorPanel
              title={T(
                "containers.participant.appointments.actions.book.unable.title"
              )}
              label={T(
                "containers.participant.appointments.actions.book.unable.title"
              )}
            >
              {T(
                `containers.participant.appointments.actions.book.unable.reason.${macroCaseToCamelCase(
                  canBook.result.reason
                )}`
              )}
            </ErrorPanel>
          );
        else if (
          canBook.result.bookable &&
          canBook.result.hasExistingAppointment
        )
          callRender = (
            <ErrorPanel
              title={T(
                "containers.participant.appointments.actions.book.unable.title"
              )}
              label={T(
                "containers.participant.appointments.actions.book.unable.title"
              )}
            >
              {T(
                `containers.participant.appointments.actions.book.unable.reason.existingAppointment`
              )}
            </ErrorPanel>
          );
        break;
    }

    return (
      <>
        <Button
          onClick={onBookAppointment}
          data-testid="book-appointment"
          aria-label={T(
            "containers.participant.appointments.actions.book.label"
          )}
        >
          {T("containers.participant.appointments.actions.book.label")}
        </Button>
        {callRender}
      </>
    );
  };

  const DisplayAppointment = ({
    appointment,
  }: {
    appointment: IAppointment;
  }): JSX.Element => (
    <Table.Row
      label="appointment"
      data-testid={`appointment-row-${appointment.appointmentStartTime.toFormat(
        "HH:mm"
      )}`}
    >
      <Table.Cell label="appointment-time">
        {formatAppointmentDate(appointment.appointmentStartTime)}
      </Table.Cell>
      <Table.Cell label="appointment-location">
        <Address address={appointment.appointmentLocation} />
      </Table.Cell>
      <Table.Cell label="appointment-type">
        <Tag
          data-testid={`appointment-type-${appointment.appointmentStartTime.toFormat(
            "HH:mm"
          )}`}
        >
          {appointment.type}
        </Tag>
      </Table.Cell>
      <Table.Cell label="appointment-accessibility-requirements">
        <AccessibilityRequirements appointment={appointment}>
          {T("containers.participant.appointments.headings.requirements")}
        </AccessibilityRequirements>
      </Table.Cell>
      <Table.Cell label="appointment-status">
        <Restricted allowedRoles={["EMS Unit Staff"]} mode="allowRoles">
          <AppointmentStatus appointment={appointment} />
        </Restricted>
        <Restricted mode="blockRoles" blockedRoles={["EMS Unit Staff"]}>
          <Tag
            data-testid={`appointment-status-${appointment.appointmentStartTime.toFormat(
              "HH:mm"
            )}`}
          >
            {appointment.status}
          </Tag>
        </Restricted>
      </Table.Cell>
      <Table.Cell label="appointment-action">
        {appointment.status === "BOOKED" && (
          <Button
            aria-label={T(
              "containers.participant.appointments.actions.cancelOrRebook.label"
            )}
            data-testid="appointment-action-cancel"
            href={`/appointment/${cohortId}/cancel`}
          >
            {T(
              "containers.participant.appointments.actions.cancelOrRebook.label"
            )}
          </Button>
        )}
      </Table.Cell>
    </Table.Row>
  );

  const DisplayAppointments = ({
    call,
  }: {
    call: AsyncCall<IAppointment[]>;
  }): JSX.Element => {
    switch (call.state) {
      case CallState.NotCalled:
        return <></>;
      case CallState.Error:
        return (
          <Table.Row data-testid="appointments-error">
            <Table.Cell colSpan={columns}>{call.error.message}</Table.Cell>
          </Table.Row>
        );
      case CallState.Loading:
        return (
          <Table.Row data-testid="appointments-loading">
            <Table.Cell colSpan={columns}>
              {T("containers.participant.appointments.loading")}
            </Table.Cell>
          </Table.Row>
        );
      case CallState.Ok:
        return call.result.length !== 0 ? (
          <>
            {call.result.map((appt, index) => (
              <DisplayAppointment appointment={appt} key={String(index)} />
            ))}
          </>
        ) : (
          <Table.Row data-testid="appointments-empty">
            <Table.Cell colSpan={columns}>
              {T("containers.participant.appointments.empty")}
            </Table.Cell>
          </Table.Row>
        );
    }
  };

  const columns = 5;

  return (
    <>
      <h4>{T("containers.participant.appointments.title")}</h4>
      {appointments.state === CallState.Ok &&
        !appointments.result.some(({ status }) => status === "BOOKED") && (
          <BookAppointment />
        )}
      <Table label="appointments" data-testid="appointment-table">
        <Table.Head>
          <Table.Row>
            <Table.Cell>
              {T("containers.participant.appointments.headings.time")}
            </Table.Cell>
            <Table.Cell>
              {T("containers.participant.appointments.headings.location")}
            </Table.Cell>
            <Table.Cell>
              {T("containers.participant.appointments.headings.type")}
            </Table.Cell>
            <Table.Cell>
              {T("containers.participant.appointments.headings.requirements")}
            </Table.Cell>
            <Table.Cell>
              {T("containers.participant.appointments.headings.status")}
            </Table.Cell>
            <Table.Cell />
          </Table.Row>
        </Table.Head>
        <Table.Body>
          <DisplayAppointments call={appointments} />
        </Table.Body>
      </Table>
    </>
  );
}
