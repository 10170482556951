import fileDownload from "js-file-download";
import { AxiosInstance } from "axios";
import { DateTime } from "luxon";
import { ReportType } from "enums";

export interface IReportClient {
  getReport: (type: ReportType, from: DateTime, to: DateTime) => Promise<void>;
}

export class ReportClient implements IReportClient {
  public constructor(protected readonly http: AxiosInstance) {}

  public async getReport(
    type: ReportType,
    from: DateTime,
    to: DateTime
  ): Promise<void> {
    await this.http
      .get(
        `/report/${type.toString()}/${from.toISODate()}/${to.toISODate()}`,
        {}
      )
      .then((response) => {
        const filename = `${type}_${from.toISODate()}_${to.toISODate()}@${DateTime.now().toISODate()}.csv`;
        fileDownload(response.data, filename);
      });
  }
}
