import React, {
  ElementRef,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { AccessibilityInput, Wizard, WizardPage } from "components";
import { AccessibilitySummary } from "./AccessibilitySummary";
import { AppointmentRegion } from "enums";
import { BookingCreate } from "./BookingCreate";
import { BookingSummary } from "./BookingSummary";
import { CallCentreHandlingAccessibility } from "./CallCentreHandlingAccessibility";
import { DateTime } from "luxon";
import { EventTypeContext, useSearchParams } from "../../hooks";
import { Label, ReadingWidth } from "nhsuk-react-components";
import { IAvailabilityLocation } from "interfaces";
import { RegionLocationPicker } from "./RegionLocationPicker";
import { RegionSelector } from "./RegionSelector";
import { SlotPicker } from "./SlotPicker";
import { T } from "i18n";
import { dateFromISOString } from "helpers";
import { isInvitee } from "../../auth/hasRole";
import { useAPI } from "api";
import { useAuth } from "../../auth";
import { useParams } from "react-router";
import { BookingProgress } from "./BookingProgress";
import { BookingTitle } from "./BookingTitle";
import { requiresCallCentre } from "./requiresCallCentre";
import {
  bookingReducer,
  defaultDuration,
  initialBookingState,
  initLocation,
  resetDateSlot,
  resetLocation,
  setAccessibility,
  setDate,
  setDuration,
  setRegion,
  setSlot,
  updateLocation,
} from "./bookingReducer";

type AccessibilityInputValidateHandle = ElementRef<typeof AccessibilityInput>;

const callCentreCallCentreHandlingAccessibilityPage =
  "CallCentreHandlingAccessibility";

const locationPickerPage = "LocationPicker";
const accessibilityPage = "Accessibility";

export const D1BookingFlow = (): JSX.Element => {
  const { user } = useAuth();

  const { cohortId } = useParams<{ cohortId: string }>();
  const { from, to } = useSearchParams<{
    from?: string;
    to?: string;
  }>();

  const [bookingState, dispatch] = useReducer(
    bookingReducer,
    initialBookingState()
  );

  const {
    region,
    accessibility,
    location,
    date: selectedDate,
    slot,
    duration,
  } = bookingState;

  const [locationShowNext, setLocationShowNext] = useState(false);
  const [locationError, setLocationError] = useState("");

  const [slotShowNext, setSlotShowNext] = useState(false);
  const [slotError, setSlotError] = useState("");

  const maxDays = parseInt(
    process.env.REACT_APP_BOOK_APPOINTMENT_WITHIN_NEXT_DAYS || "90",
    10
  );

  const earliestPossibleBookingDate = useMemo(() => {
    let earliestDate = DateTime.utc().startOf("day");
    if (from) {
      earliestDate = DateTime.max(earliestDate, dateFromISOString(from));
    }
    return earliestDate;
  }, [from]);

  const maxDateInRange = useMemo(() => {
    // FIXME(ctang): The use of UTC now here potentially incorrectly filters out results
    //  eg. when it is 1/7 00:00 BST, it will be 30/6 23:00 UTC. This will be 30/6 and we want to include results
    //  from 1/7 as well
    let maxDate = DateTime.utc().startOf("day").plus({ days: maxDays });
    if (to) {
      maxDate = dateFromISOString(to);
    }
    return maxDate;
  }, [maxDays, to]);

  const appointmentAPI = useAPI("appointment");

  useEffect(() => {
    if (region === undefined) {
      appointmentAPI
        .getRegion(cohortId)
        .then((region) => {
          dispatch(setRegion(region));
        })
        .catch(() => {
          dispatch(setRegion(AppointmentRegion.UNKNOWN));
        });
    }
  }, [appointmentAPI, cohortId, region]);

  // these calls are in useEffect dependency arrays so needs to be stable over re-renders
  // n.b. dispatch is guaranteed stable
  const stableSetAccessibility = useCallback(
    (val) => dispatch(setAccessibility(val)),
    []
  );
  const accessibilityInputRef = useRef<AccessibilityInputValidateHandle>(null);

  const validateAccessibilitySearchInput = (): boolean => {
    if (accessibilityInputRef.current) {
      return accessibilityInputRef.current.validate();
    }
    return false;
  };

  const regionRequired = !!region && region === AppointmentRegion.UNKNOWN;

  const accessibilityNextPage =
    isInvitee(user) && requiresCallCentre(accessibility.options)
      ? callCentreCallCentreHandlingAccessibilityPage
      : locationPickerPage;

  const [slotsLoading, setSlotsLoading] = useState<boolean>(false);

  const resetToLocation = (): void => {
    dispatch(resetLocation());
  };

  const dateAndTimeSelected = (): boolean => {
    if (slot) {
      setSlotError("");
      return true;
    }

    if (!selectedDate) {
      setSlotError(T("containers.booking.fields.slot.errors.dateNotSelected"));
    } else {
      setSlotError(T("containers.booking.fields.slot.errors.timeNotSelected"));
    }

    return false;
  };

  const locationIsSelected = (): boolean => {
    location
      ? setLocationError("")
      : setLocationError(
          T("containers.booking.fields.location.errors.locationNotSelected")
        );

    return !!location;
  };

  const clearErrors = (): void => {
    setSlotError("");
    setLocationError("");
  };

  useEffect(() => {
    if (region === undefined || location === undefined) {
      return;
    }
    setSlotsLoading(true);
    appointmentAPI
      .findAppointmentAvailability(
        earliestPossibleBookingDate,
        maxDateInRange,
        !accessibility.options.none,
        region,
        duration,
        undefined
      )
      .then(({ locations }) => {
        let loc = locations.find((val) => {
          return (
            val.address.address1 === location.address.address1 &&
            val.unitCode === location.unitCode
          );
        });
        if (loc === undefined && location !== undefined) {
          // if location was previously defined then a switch to
          // undefined is caused by duration change. As we want to
          // still show the same location page we just update the slots
          // to indicate none are available
          loc = { ...location, appointmentSlots: [] };
        }
        dispatch(initLocation(loc));
      })
      .catch(setSlotError)
      .finally(() => setSlotsLoading(false));
    // eslint-disable-next-line
  }, [
    duration,
    earliestPossibleBookingDate,
    maxDateInRange,
    accessibility.options.none,
    region,
    appointmentAPI,
  ]);

  const onWizardPageChanged: (from: string | undefined, to: string) => void = (
    from,
    to
  ) => {
    // Clear errors when page changes. Form validity will be re-evaluated
    // when the continue button is clicked
    clearErrors();
  };

  return (
    <main className="nhsuk-main-wrapper">
      <EventTypeContext.Provider value="D1">
        <ReadingWidth>
          {regionRequired && (
            <RegionSelector
              setRegion={(region) => {
                dispatch(setRegion(region));
              }}
            />
          )}

          {region !== undefined && !regionRequired && (
            <>
              <Wizard onPageChanged={onWizardPageChanged}>
                <WizardPage
                  pageID={accessibilityPage}
                  validate={validateAccessibilitySearchInput}
                  nextPage={accessibilityNextPage}
                  nextLabel={T("containers.booking.actions.wizard.continue")}
                >
                  <BookingProgress step={7} />
                  <Label isPageHeading>
                    {T("containers.booking.fields.search.accessibility.title")}
                  </Label>
                  <AccessibilityInput
                    ref={accessibilityInputRef}
                    accessibility={accessibility}
                    setAccessibility={stableSetAccessibility}
                    descriptionText={T("containers.booking.description", {
                      durationText: `${duration} to ${duration + 15} minutes`,
                    })}
                  />
                </WizardPage>

                <WizardPage
                  pageID={callCentreCallCentreHandlingAccessibilityPage}
                  previousPage={accessibilityPage}
                  nextEnabled={false}
                  branched
                >
                  <CallCentreHandlingAccessibility
                    accessibility={accessibility}
                    cohortId={cohortId}
                  />
                </WizardPage>

                <WizardPage
                  pageID={locationPickerPage}
                  validate={locationIsSelected}
                  nextLabel={T("containers.booking.actions.wizard.continue")}
                  showNext={locationShowNext}
                >
                  <BookingProgress step={8} />
                  <BookingTitle>
                    {T("containers.booking.fields.location.title")}
                  </BookingTitle>
                  <AccessibilitySummary
                    accessibility={accessibility}
                    accessibilityWizardPageID={accessibilityPage}
                  />
                  <RegionLocationPicker
                    accessibilityRequired={!accessibility.options.none}
                    from={earliestPossibleBookingDate}
                    to={maxDateInRange}
                    duration={duration}
                    error={locationError}
                    setError={setLocationError}
                    updateLocation={(location: IAvailabilityLocation) => {
                      dispatch(updateLocation(location));
                    }}
                    region={region}
                    setShowNext={setLocationShowNext}
                  />
                </WizardPage>

                <WizardPage
                  pageID="SlotPicker"
                  validate={dateAndTimeSelected}
                  nextLabel={T("containers.booking.actions.wizard.continue")}
                  showNext={slotShowNext}
                >
                  <BookingProgress step={9} />
                  <BookingTitle>
                    {T("containers.booking.fields.slot.title")}
                  </BookingTitle>
                  <BookingSummary
                    location={
                      location && {
                        address: location.address,
                        onChange: resetToLocation,
                      }
                    }
                  />
                  {location === undefined && (
                    <Label data-testid="no-appointments">
                      {T("containers.booking.noSlots")}
                    </Label>
                  )}
                  {location !== undefined && (
                    <>
                      <SlotPicker
                        location={location}
                        date={selectedDate}
                        selectedSlot={slot}
                        selectSlot={(s) => dispatch(setSlot(s))}
                        duration={duration}
                        setDuration={(d) => dispatch(setDuration(d))}
                        defaultDuration={defaultDuration()}
                        loading={slotsLoading}
                        error={slotError}
                        setDate={(d) => dispatch(setDate(d))}
                        setError={setSlotError}
                        setShowNext={setSlotShowNext}
                      />
                    </>
                  )}
                </WizardPage>
                <WizardPage pageID="CreateBooking" nextEnabled={false}>
                  <BookingProgress step={10} />
                  <BookingTitle>
                    {T("containers.booking.actions.book.title")}
                  </BookingTitle>
                  <BookingSummary
                    location={
                      slot && {
                        address: slot?.address,
                        onChange: resetToLocation,
                      }
                    }
                    dateAndTime={
                      slot && {
                        from: slot.from,
                        onChange: () => {
                          dispatch(resetDateSlot());
                        },
                      }
                    }
                    duration={
                      slot && {
                        duration,
                        onChange: () => {
                          dispatch(resetDateSlot());
                        },
                      }
                    }
                  />
                  <BookingCreate
                    cohortId={cohortId}
                    slot={slot}
                    accessibility={accessibility}
                    unitLocationDirections={location?.directions}
                  />
                </WizardPage>
              </Wizard>
            </>
          )}
        </ReadingWidth>
      </EventTypeContext.Provider>
    </main>
  );
};
