export type SortableKey<T> = Extract<keyof T, string | number | Date>;

export interface IFieldSorter<T> {
  field: SortableKey<T>;
  isDescending: boolean;
}

type CompareFn<T> = (a: T, b: T) => number;

export function FieldSortFunc<T>(sorter: IFieldSorter<T>): CompareFn<T> {
  return function (a: T, b: T) {
    const directionMod = sorter.isDescending ? -1 : 1;
    if (!b[sorter.field] || a[sorter.field] > b[sorter.field]) {
      return directionMod;
    } else if (!a[sorter.field] || a[sorter.field] < b[sorter.field]) {
      return -directionMod;
    } else {
      return 0;
    }
  };
}
