import React from "react";
import { FeatureFlagName, isFeatureFlagEnabled } from "features";

interface FeatureFlagProps {
  flag: FeatureFlagName;
}

export function FeatureFlag({
  flag,
  children,
}: React.PropsWithChildren<FeatureFlagProps>): JSX.Element {
  if (isFeatureFlagEnabled(flag)) {
    return <>{children}</>;
  }
  return <></>;
}
