import { ValidationFunction } from "./ValidationFunction";

// first test the postcode is of expected input format
const inputRegex = /^([A-Z\d]+ ?[A-Z\d]*)$/;

// taken from https://stackoverflow.com/questions/164979/regex-for-matching-uk-postcodes
// regex for postcode without spaces
const postcodeRegex = /^[A-Z]{1,2}\d[A-Z\d]?\d[A-Z]{2}|GIR ?0A{2}$/;

export const isPostcode = (postcode: string): boolean => {
  if (!inputRegex.test(postcode)) {
    return false;
  }
  const withoutSpaces = postcode.replace(" ", "");
  return postcodeRegex.test(withoutSpaces);
};

export const isValidPostcode: ValidationFunction<string> = (
  postcode: string,
  message: string
): string => (isPostcode(postcode) ? "" : message);

// based on England area codes
// n.b. duplicated in BE appointment validation
const englandPostcodeAreasRegex =
  /^(PE|DE|DN|LE|LN|NG|S|AL|CB|CM|CO|HP|IP|LU|NR|SG|SS|BR|CR|DA|E|EC|EN|HA|IG|KT|N|NW|RM|SE|SM|SW|TW|UB|W|WC|WD|DH|DL|HG|HU|LS|NE|SR|TS|WF|YO|BB|BD|BL|CA|CH|CW|FY|HD|HX|L|LA|M|OL|PR|SK|WA|WN|BN|CT|GU|ME|MK|OX|PO|RG|RH|SL|SO|TN|BA|BH|BS|DT|EX|GL|PL|SN|SP|TA|TQ|TR|B|CV|DY|HR|NN|ST|TF|WR|WS|WV)([0-9]| )/;

export const isEnglishPostcode = (postcode: string): boolean => {
  return (
    isPostcode(postcode.toUpperCase()) &&
    englandPostcodeAreasRegex.test(postcode.toUpperCase())
  );
};
