import "./ECRF.scss";

import React from "react";
import { Button } from "nhsuk-react-components";
import {
  FormFieldProps as ECRFFormFieldProps,
  Form,
  FormAnswer,
  FormDefinition,
  FormFieldProps,
  useForm,
} from "@grailbio/components-ecrf";
import { FormField } from "./FormField";
import { FormSummary } from "./FormSummary";
import { T } from "i18n";
import { Wizard, WizardPage } from "../Wizard/Wizard";

export interface ECRFProps {
  definition: FormDefinition;
  showTitle?: boolean;
  showSectionNames?: boolean;
  initialAnswers?: FormAnswer[];
  disabled?: boolean;
  requireSummaryCheck?: boolean;

  onSubmit(answers: FormAnswer[]): void;
  renderOverride?: {
    [apiName: string]: (props: ECRFFormFieldProps) => JSX.Element;
  };
}

export function ECRF(props: ECRFProps): JSX.Element {
  const {
    definition,
    onSubmit,
    showTitle = false,
    showSectionNames = true,
    disabled = false,
    requireSummaryCheck = false,
    initialAnswers = [],
    renderOverride = {},
  } = props;
  const [answers, complete, setAnswer] = useForm(definition, initialAnswers);

  const FormFieldWithOverride: typeof FormField = (props: FormFieldProps) => {
    const {
      definition: { apiName },
    } = props;
    return <FormField {...props} renderOverride={renderOverride[apiName]} />;
  };

  if (!requireSummaryCheck) {
    return (
      <div className="ecrf">
        <Form
          definition={definition}
          onChange={setAnswer}
          state={answers}
          disabled={disabled}
          showSectionNames={showSectionNames}
          showTitle={showTitle}
          renderFormField={FormFieldWithOverride}
        />
        <br />
        <Button
          type="submit"
          data-testid="ecrf-submit"
          disabled={!complete || disabled}
          onClick={(
            e:
              | React.MouseEvent<HTMLAnchorElement>
              | React.MouseEvent<HTMLButtonElement>
          ) => {
            e.preventDefault();
            onSubmit(answers);
          }}
        >
          {T("components.ecrf.actions.submit")}
        </Button>
      </div>
    );
  }
  return (
    <div className="ecrf">
      <Wizard>
        <WizardPage
          pageID="form"
          validate={() => complete}
          nextEnabled={complete}
          nextLabel={T("components.ecrf.actions.review")}
        >
          <Form
            definition={definition}
            onChange={setAnswer}
            state={answers}
            disabled={disabled}
            showSectionNames
            showTitle={showTitle}
            renderFormField={FormFieldWithOverride}
          />
        </WizardPage>
        <WizardPage pageID="summary" validate={() => complete}>
          <FormSummary
            definition={definition}
            disabled={disabled}
            answers={answers}
            onSubmit={() => onSubmit(answers)}
          />
        </WizardPage>
      </Wizard>
    </div>
  );
}
