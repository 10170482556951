import React, { useCallback, useEffect, useState } from "react";
import { EligibilityForm } from "./EligibilityForm";
import { EligibilityResult } from "./EligibilityResult";
import { ErrorPanel, Loader } from "components";
import { IEligibility } from "interfaces";
import { T } from "i18n";
import { VisitStageProps } from "../../Visit";
import { useAPI } from "api";

export function Eligibility({
  cohortId,
  person,
  onComplete,
}: VisitStageProps<"D1_ELIGIBILITY">): JSX.Element {
  const eligibilityAPI = useAPI("eligibility");

  const [eligibility, setEligibility] = useState<IEligibility>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();

  const onReset = (): void => {
    setLoading(true);
    eligibilityAPI
      .resetEligibility(cohortId)
      .then(() => {
        setEligibility(undefined);
      })
      .catch(setError)
      .finally(() => {
        setLoading(false);
      });
  };

  const getEligibility = useCallback((): Promise<void> => {
    setLoading(true);
    return eligibilityAPI
      .getEligibility(cohortId)
      .then(setEligibility)
      .catch((err) => {
        // TODO(jgalilee): move this into API to return null
        if (err.response?.status !== 404) {
          setError(err);
        }
      })
      .finally(() => setLoading(false));
  }, [cohortId, eligibilityAPI]);

  const onSubmitSuccess = (): void => {
    getEligibility();
    onComplete();
  };

  useEffect((): void => {
    getEligibility();
  }, [getEligibility]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <ErrorPanel
        title={T("containers.eligibility.errors.title")}
        label={T("containers.eligibility.errors.title")}
      >
        {error.message}
      </ErrorPanel>
    );
  }

  return (
    <>
      {eligibility ? (
        <EligibilityResult
          {...eligibility}
          onReset={onReset}
          person={person}
          heading={
            eligibility.eligible
              ? T("containers.eligibility.eligible.title")
              : T("containers.eligibility.ineligible.title")
          }
        />
      ) : (
        <EligibilityForm
          cohortId={cohortId}
          onComplete={onSubmitSuccess}
          person={person}
          heading={T("containers.eligibility.title")}
        />
      )}
    </>
  );
}
