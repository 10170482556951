import React, { useState } from "react";
import { T } from "i18n";
import { Button, ReadingWidth } from "nhsuk-react-components";
import { SubBox, SubCheckboxes } from "components/SubCheckboxes";
import { AuditEventType } from "enums/AuditEventType";
import { MonthSelect } from "components/MonthSelect";
import { StudyYearSelect } from "components/StudyYearSelect";
import { IAuditEvent } from "interfaces/IAuditEvent";
import { useAPI } from "api";
import { Month } from "helpers/month";
import { ErrorPanel } from "components";
import { AuditEvents } from "./AuditEvents";
import { DateTime, Duration } from "luxon";
import { LinkButton } from "components/LinkButton";

const MAX_AUDIT_ROWS_TO_DISPLAY = 1000;

export const AuditLog = (): JSX.Element => {
  const [selectedEvents, setSelectedEvents] = useState<Set<AuditEventType>>(
    new Set()
  );
  const [month, setMonth] = useState<Month>();
  const [year, setYear] = useState<string>();

  const [auditLog, setAuditLog] = useState<IAuditEvent[]>();
  const [auditLogError, setAuditLogError] = useState<Error>();
  const auditAPI = useAPI("audit");
  const getAuditLog = () => {
    if (!month || !year) {
      return;
    }
    const from = DateTime.fromObject({
      year: parseInt(year, 10),
      month,
      day: 1,
      zone: "utc",
    });
    const to = from.plus(Duration.fromObject({ months: 1 }));
    auditAPI
      .getAuditLog(selectedEvents, from, to)
      .then(setAuditLog)
      .catch(setAuditLogError);
  };

  const downloadAuditLog = () => {
    if (!month || !year) {
      return;
    }
    const from = DateTime.fromObject({
      year: parseInt(year, 10),
      month,
      day: 1,
      zone: "utc",
    });
    const to = from.plus(Duration.fromObject({ months: 1 }));
    auditAPI.downloadAuditLog(selectedEvents, from, to);
  };

  const downloadEntireAuditLog = () => {
    const allEvents = new Set(Object.values(AuditEventType));
    const from = DateTime.utc(1970, 1, 1);
    const to = DateTime.now().toUTC();
    auditAPI.downloadAuditLog(allEvents, from, to);
  };

  return (
    <main className="nhsuk-main-wrapper">
      <ReadingWidth>
        <h1>{T("containers.audit.title")}</h1>
        <h3>{T("containers.audit.eventHeading")}</h3>
        <SubCheckboxes
          selectAllLabel={T("containers.audit.events.users.all")}
          selectAllHint={T("containers.audit.events.users.hint")}
          selected={selectedEvents}
          setSelected={setSelectedEvents}
        >
          <SubBox value={AuditEventType.USERS_NEW}>
            {T("containers.audit.events.users.new")}
          </SubBox>
          <SubBox value={AuditEventType.USERS_CHANGED}>
            {T("containers.audit.events.users.changed")}
          </SubBox>
          <SubBox value={AuditEventType.USER_ROLES_CHANGED}>
            {T("containers.audit.events.users.userRole")}
          </SubBox>
          <SubBox value={AuditEventType.USERS_ARCHIVING}>
            {T("containers.audit.events.users.archiving")}
          </SubBox>
        </SubCheckboxes>
        <SubCheckboxes
          selectAllLabel={T("containers.audit.events.signIns.all")}
          selectAllHint={T("containers.audit.events.signIns.hint")}
          selected={selectedEvents}
          setSelected={setSelectedEvents}
        >
          <SubBox value={AuditEventType.SIGN_IN}>
            {T("containers.audit.events.signIns.signIns")}
          </SubBox>
          <SubBox value={AuditEventType.PASSWORD_CHANGE}>
            {T("containers.audit.events.signIns.passwordChange")}
          </SubBox>
          <SubBox value={AuditEventType.FORGOT_PASSWORD}>
            {T("containers.audit.events.signIns.forgotPassword")}
          </SubBox>
        </SubCheckboxes>
        <SubCheckboxes
          selectAllLabel={T("containers.audit.events.roles.all")}
          selectAllHint={T("containers.audit.events.roles.hint")}
          selected={selectedEvents}
          setSelected={setSelectedEvents}
        >
          <SubBox value={AuditEventType.ROLES_NEW}>
            {T("containers.audit.events.roles.new")}
          </SubBox>
          <SubBox value={AuditEventType.ROLES_CHANGED}>
            {T("containers.audit.events.roles.changed")}
          </SubBox>
        </SubCheckboxes>
        <h3>{T("containers.audit.monthHeading")}</h3>
        <div style={{ display: "flex", gap: "24px" }}>
          <MonthSelect
            value={month}
            onChange={setMonth}
            style={{ width: "160px" }}
          />
          <StudyYearSelect
            value={year}
            onChange={setYear}
            style={{ width: "160px" }}
          />
        </div>
        <Button
          disabled={selectedEvents.size === 0 || !year || !month}
          onClick={() => {
            setAuditLogError(undefined);
            getAuditLog();
          }}
        >
          {T("containers.audit.action")}
        </Button>
        {auditLog && auditLog.length > 0 && (
          <Button
            style={{ marginLeft: "24px" }}
            secondary
            onClick={downloadAuditLog}
            aria-label="download-results"
          >
            {T("containers.audit.downloadResults")}
          </Button>
        )}
        <div>
          <LinkButton
            className="link-button-body"
            style={{ marginBottom: "24px" }}
            onClick={downloadEntireAuditLog}
            aria-label="download-all"
          >
            {T("containers.audit.downloadAll")}
          </LinkButton>
        </div>
        {auditLogError && (
          <ErrorPanel title="audit log error" label="audit log error">
            {auditLogError.message}
          </ErrorPanel>
        )}
        {auditLog && auditLog.length < MAX_AUDIT_ROWS_TO_DISPLAY && (
          <>
            <h3 style={{ marginTop: "48px" }}>
              {T("containers.audit.resultsHeading", { num: auditLog.length })}
            </h3>
            <AuditEvents events={auditLog} />
          </>
        )}
        {auditLog && auditLog.length >= MAX_AUDIT_ROWS_TO_DISPLAY && (
          <>
            <h3 style={{ marginTop: "36px", marginBottom: "0" }}>
              {T("containers.audit.tooManyResults.heading")}
            </h3>
            <p className="nhsuk-u-secondary-text-color">
              {T("containers.audit.tooManyResults.instruction")}
            </p>
          </>
        )}
      </ReadingWidth>
    </main>
  );
};
