import React from "react";
import { Input } from "nhsuk-react-components";

interface PersonalPhoneNumberInputElementIDs {
  phoneNumber?: string;
}

interface PersonalPhoneNumberInputProps {
  phoneNumber?: string;
  phoneNumberChanged?: (newVal: string) => void;
  phoneNumberError?: string;
  elementIDs?: PersonalPhoneNumberInputElementIDs;
  label: string;
  hint?: string;
  dataTestId: string;
}

export const PersonalPhoneNumberInput = ({
  phoneNumber,
  phoneNumberChanged,
  phoneNumberError,
  elementIDs,
  label,
  hint,
  dataTestId,
}: PersonalPhoneNumberInputProps): JSX.Element => {
  return (
    <Input
      type="text"
      id={elementIDs?.phoneNumber}
      data-testid={dataTestId}
      name="phone"
      label={label}
      hint={hint}
      aria-label={label}
      value={phoneNumber}
      onChange={(e) =>
        phoneNumberChanged && phoneNumberChanged(e.currentTarget.value)
      }
      error={phoneNumberError}
      width="20"
    />
  );
};
