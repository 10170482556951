import { BiologicalSex } from "../enums";
import { ValidationFunction } from "./ValidationFunction";

export const isValidBiologicalSex: ValidationFunction<string> = (
  sex: string,
  message: string
): string =>
  !Object.values(BiologicalSex)
    .map((val) => val as string)
    .includes(sex)
    ? message
    : "";
