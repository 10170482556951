import "polyfills";

import "./amplify";
import "./index.scss";

import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";

import { App } from "App";
import { Shell } from "Shell";
import { cognitoAuthClient } from "auth";

import { ScrollToTop } from "./components/ScrollToTop";
import { Warning } from "./components";
import { createScriptURL } from "./trustedTypes/createScriptURL";
import { reportWebVitals } from "./reportWebVitals";
import { Settings } from "luxon";
import { defineCustomElements } from "@duetds/date-picker/custom-element";
import * as DOMPurify from "dompurify";

if (window.trustedTypes) {
  window.trustedTypes.createPolicy("default", {
    createScriptURL,
    createHTML: (toEscape) =>
      DOMPurify.sanitize(toEscape, { RETURN_TRUSTED_TYPE: false }),
  });
}

Settings.defaultZoneName = process.env.REACT_APP_TIMEZONE || "Europe/London";

defineCustomElements(window);

ReactDOM.render(
  <React.StrictMode>
    <Warning
      location={window.location}
      disallow={["staging", "sandbox", "test"]}
    />
    <Shell auth={cognitoAuthClient} history={createBrowserHistory()}>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </Shell>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
