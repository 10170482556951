import { useAPI } from "api";
import classNames from "classnames";
import { ErrorPanel } from "components";
import { T } from "i18n";
import { AuditDetail } from "interfaces/IAuditEventV2";
import { Details } from "nhsuk-react-components";
import { useState } from "react";

const ACTION_CREATE = "Create";
const ACTION_UPDATE = "Modify";

interface AuditDetailsProps {
  parentLoading: boolean;
  summary: string;
  resourceType: string;
  actionType: string;
  version: string;
}

export const AuditDetailsContainer = ({
  parentLoading,
  summary,
  resourceType,
  actionType,
  version,
}: AuditDetailsProps): JSX.Element => {
  const auditAPI = useAPI("auditv2");
  const [expanded, setExpanded] = useState(false);
  const [data, setData] = useState<AuditDetail[]>([]);
  const [auditLogError, setAuditLogError] = useState<Error>();

  return (
    <Details className="audit-v2-details">
      <Details.Summary
        className={classNames({ loading: parentLoading })}
        onClick={async () => {
          if (!expanded) {
            try {
              const details = await auditAPI.getAuditDetails(
                resourceType,
                actionType,
                version
              );
              setData(details);
            } catch (e: any) {
              setAuditLogError(e);
              setData([]);
            }
          }
          setExpanded((e) => !e);
        }}
      >
        {summary}
      </Details.Summary>
      {!parentLoading && (
        <Details.Text>
          {expanded && !auditLogError && (
            <ExpandedDetailsContent action={actionType} details={data} />
          )}
          {expanded && auditLogError && (
            <ErrorPanel title="Invalid query" label="invalid-query-error">
              {auditLogError.message}
            </ErrorPanel>
          )}
        </Details.Text>
      )}
    </Details>
  );
};

const ExpandedDetailsContent = ({
  action,
  details,
}: {
  action: string;
  details: AuditDetail[];
}): JSX.Element => {
  details.sort((a, b) => a.field.localeCompare(b.field));
  return (
    <ul>
      {details
        .filter((detail) => detail.new !== "")
        .map((detail) => {
          if (action === ACTION_CREATE) {
            return (
              <li key={detail.field}>
                {T("containers.auditEvents.createField", {
                  field: detail.field.toLowerCase(),
                  value: detail.new,
                })}
              </li>
            );
          } else if (action === ACTION_UPDATE) {
            let translateType = T("containers.auditEvents.updateField", {
              field: detail.field.toLowerCase(),
              old: detail.old,
              new: detail.new,
            });
            if (!detail.old) {
              translateType = T("containers.auditEvents.updateAddingField", {
                field: detail.field.toLowerCase(),
                new: detail.new,
              });
            }
            if (!detail.new) {
              translateType = T("containers.auditEvents.updateRemovingField", {
                field: detail.field.toLowerCase(),
                old: detail.old,
              });
            }
            return <li key={detail.field}>{translateType}</li>;
          } else {
            throw new Error(`Unrecognized action type: ${action}`);
          }
        })}
    </ul>
  );
};
