import React, { useState } from "react";
import { Button, Col, Row } from "nhsuk-react-components";
import { T } from "i18n";
import { Tag } from "nhsuk-react-components-extensions";
import { bloodFormNotComplete, reAttemptNotNeeded } from "errors/blood";
import { useAPI } from "api";

export const BloodReAttemptStatus = ({
  reAttempt,
  setReAttempt,
  cohortId,
  setError,
}: {
  cohortId: string;
  reAttempt: boolean;
  setReAttempt: (newReAttempt: boolean) => void;
  setError: (error: string) => void;
}): JSX.Element => {
  const bloodAPI = useAPI("blood");
  const [isLoading, setLoading] = useState(false);

  const markReAttemptNeeded = (): void => {
    setError("");
    setLoading(true);
    bloodAPI
      .markReAttemptNeeded(cohortId)
      .then(() => setReAttempt(true))
      .catch(({ message }) => {
        switch (message) {
          case bloodFormNotComplete:
            setError(
              T("components.person.blood.reAttempt.error.bloodFormNotComplete")
            );
            break;
          case reAttemptNotNeeded:
            setError(
              T("components.person.blood.reAttempt.error.reAttemptNotNeeded")
            );
            break;
          default:
            setError(message);
            break;
        }
      })
      .finally(() => setLoading(false));
  };

  const removeReAttempt = (): void => {
    setError("");
    setLoading(true);
    bloodAPI
      .removeReAttempt(cohortId)
      .then(() => setReAttempt(false))
      .catch(({ message }) => setError(message))
      .finally(() => setLoading(false));
  };

  return (
    <>
      {reAttempt ? (
        <Row>
          <Col width="one-third">
            <Tag data-testid="re-attempt-needed" color="red">
              {T("components.person.blood.reAttempt.needed")}
            </Tag>
          </Col>
          <Col width="one-third">
            <Button
              data-testid="remove-re-attempt"
              disabled={isLoading}
              onClick={removeReAttempt}
            >
              {T("components.person.blood.reAttempt.remove")}
            </Button>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col width="one-third">
            <Button
              data-testid="add-re-attempt"
              disabled={isLoading}
              onClick={markReAttemptNeeded}
            >
              {T("components.person.blood.reAttempt.add")}
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};
