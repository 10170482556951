import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import {
  BackLink,
  Button,
  Checkboxes,
  ErrorSummary,
  Fieldset,
  Form,
  Input,
  Label,
  Table,
} from "nhsuk-react-components";
import { IUser } from "interfaces";
import { Link } from "react-router-dom";
import { PhoneNumberInput } from "components";
import { T } from "i18n";
import { isValidPhoneNumber } from "validations";
import { useAPI } from "api";
import { useHistory, useParams } from "react-router";
import { AllRoles } from "auth";
import { ArchivedUserProfile } from "containers/User/ArchivedUserProfile";

export function User(): JSX.Element {
  const [user, setUser] = useState<IUser>();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nameUpdateError, setNameUpdateError] = useState<Error>();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [checkPhoneNumber, setCheckPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [checkError, setCheckError] = useState("");
  const [phoneNumberUpdateError, setPhoneNumberUpdateError] = useState<Error>();
  const [loading, setLoading] = useState(false);
  const [updateMFAError, setUpdateMFAError] = useState();

  const userAPI = useAPI("user");
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();

  const loadUser = useCallback((): Promise<void> => {
    return userAPI.get(id).then((user) => {
      setUser(user);
      setFirstName(user.firstName || "");
      setLastName(user.lastName || "");
    });
  }, [userAPI, id]);

  useEffect(() => {
    setLoading(true);
    loadUser().finally(() => setLoading(false));
  }, [loadUser]);

  const addRole = (role: string): Promise<void> => {
    setLoading(true);
    return userAPI
      .addRole(id, role)
      .then(() => loadUser())
      .finally(() => setLoading(false));
  };

  const removeRole = (role: string): Promise<void> => {
    setLoading(true);
    return userAPI
      .removeRole(id, role)
      .then(() => loadUser())
      .finally(() => setLoading(false));
  };

  const reinstate = (): void => {
    setLoading(true);
    userAPI
      .reinstateUser(id)
      .then(loadUser)
      .finally(() => setLoading(false));
  };

  const setMFA = (type: "SMS" | "TOTP"): Promise<void> => {
    setLoading(true);
    return userAPI
      .updateMFA(id, type)
      .catch(() =>
        setUpdateMFAError(
          T("containers.user.profile.updateMFA.errors.description")
        )
      )
      .finally(() => setLoading(false));
  };

  const roleCells = AllRoles.map((role) => (
    <Table.Cell style={{ writingMode: "vertical-lr" }} key={role}>
      {role}
    </Table.Cell>
  ));

  const onSubmitNameUpdateForm = async (
    e: SyntheticEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setLoading(true);
    userAPI
      .update(id, firstName, lastName, undefined)
      .then(() => setNameUpdateError(undefined))
      .catch((err) => {
        setNameUpdateError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmitPhoneNumberForm = async (
    e: SyntheticEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    if (!validatePhoneNumber()) {
      return;
    }
    setLoading(true);
    userAPI
      .update(id, undefined, undefined, phoneNumber)
      .then(() => setPhoneNumberUpdateError(undefined))
      .catch((err) => {
        setPhoneNumberUpdateError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validatePhoneNumber = (): boolean => {
    setPhoneNumberUpdateError(undefined);
    let valid = true;
    const validPhoneNumberError = isValidPhoneNumber(
      phoneNumber,
      T("containers.user.updatePhoneNumber.phoneNumber.errors.invalid")
    );

    if (validPhoneNumberError !== "") {
      valid = false;
      setPhoneNumberError(validPhoneNumberError);
    } else if (phoneNumber.split(" ")[1][0] === "0") {
      valid = false;
      setPhoneNumberError(
        T("containers.user.createUser.phoneNumber.errors.numberStartsWith")
      );
    } else if (phoneNumber !== checkPhoneNumber) {
      valid = false;
      setPhoneNumberError(
        T("containers.user.updatePhoneNumber.phoneNumber.errors.different")
      );
    } else {
      setPhoneNumberError("");
    }
    if (!isChecked) {
      valid = false;
      setCheckError(T("containers.user.updatePhoneNumber.confirm.error"));
    } else {
      setCheckError("");
    }
    return valid;
  };

  if (user) {
    let content: JSX.Element;
    if (user.status === "ARCHIVED") {
      content = (
        <ArchivedUserProfile
          user={user}
          reinstate={reinstate}
          disableReinstate={loading}
        />
      );
    } else {
      const includesRoles = AllRoles.map((role) =>
        user.roles.includes(role) ? (
          <Table.Cell key={`${role}checked`}>
            <Checkboxes>
              <Checkboxes.Box
                value={role}
                data-testid={role}
                checked
                disabled={loading}
                onClick={() => removeRole(role)}
              >
                {" "}
              </Checkboxes.Box>
            </Checkboxes>
          </Table.Cell>
        ) : (
          <Table.Cell key={`${role}unchecked`}>
            <Checkboxes>
              <Checkboxes.Box
                value={role}
                data-testid={role}
                onClick={() => addRole(role)}
                disabled={loading}
              >
                {" "}
              </Checkboxes.Box>
            </Checkboxes>
          </Table.Cell>
        )
      );

      const row = (
        <Table.Row key={user.id}>
          <Table.Cell>{user.id}</Table.Cell>
          {includesRoles}
        </Table.Row>
      );

      content = (
        <>
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Cell>
                  {T("containers.user.profile.roleTable.header.username")}
                </Table.Cell>
                {roleCells}
              </Table.Row>
            </Table.Head>
            <Table.Body>{row}</Table.Body>
          </Table>
          <Link
            data-testid="reset-password-button"
            className="nhsuk-button"
            to={`/user/${user.id}/reset-password`}
          >
            {T("containers.user.profile.actions.resetPassword")}
          </Link>
          <Button
            data-testid="archive-user-button"
            style={{ background: "#d5281b", marginLeft: "24px" }}
            onClick={() => {
              push(`/user/${user.id}/archive`);
            }}
          >
            {T("containers.user.profile.actions.archive")}
          </Button>
          <Label isPageHeading>
            {T("containers.user.profile.updateMFA.title")}
          </Label>
          <Button
            className="nhsuk-u-margin-right-5"
            data-testid="setTOTPMFA"
            onClick={() => setMFA("TOTP")}
            disabled={loading}
          >
            {loading
              ? T("containers.user.profile.actions.setTOTP.loading")
              : T("containers.user.profile.actions.setTOTP.label")}
          </Button>
          <Button
            data-testid="setSMSMFA"
            onClick={() => setMFA("SMS")}
            disabled={loading}
          >
            {loading
              ? T("containers.user.profile.actions.setSMS.loading")
              : T("containers.user.profile.actions.setSMS.label")}
          </Button>
          {updateMFAError && (
            <ErrorSummary
              aria-label={T("containers.user.profile.updateMFA.errors.label")}
              role="alert"
              tabIndex={-1}
            >
              <ErrorSummary.Title>
                {T("containers.user.profile.updateMFA.errors.label")}
              </ErrorSummary.Title>
              <ErrorSummary.Body data-testid="error-body">
                {updateMFAError}
              </ErrorSummary.Body>
            </ErrorSummary>
          )}
          <Form onSubmit={onSubmitNameUpdateForm}>
            <Fieldset>
              <Fieldset.Legend>Change Users Name</Fieldset.Legend>
              <Input
                type="text"
                data-testid="firstName"
                label={T("containers.user.updateProfile.firstName.label")}
                aria-label={T("containers.user.updateProfile.firstName.label")}
                value={firstName}
                onChange={({ currentTarget: { value } }) => setFirstName(value)}
                error=""
                width="20"
              />
              <Input
                type="text"
                data-testid="lastName"
                label={T("containers.user.updateProfile.lastName.label")}
                aria-label={T("containers.user.updateProfile.lastName.label")}
                value={lastName}
                onChange={({ currentTarget: { value } }) => setLastName(value)}
                error=""
                width="20"
              />
            </Fieldset>
            {nameUpdateError && (
              <ErrorSummary
                aria-label={T(
                  "containers.user.updateProfile.submissionError.label"
                )}
                role="alert"
                tabIndex={-1}
              >
                <ErrorSummary.Title data-testid="error-summary-title">
                  {T("containers.user.updateProfile.submissionError.label")}
                </ErrorSummary.Title>
                <ErrorSummary.Body data-testid="error-summary-body">
                  {nameUpdateError.message}
                </ErrorSummary.Body>
              </ErrorSummary>
            )}
            <Button
              data-testid="update-name-button"
              aria-label={T("containers.user.updateProfile.actions.submit")}
              disabled={loading}
            >
              Change
            </Button>
          </Form>
          <Form onSubmit={onSubmitPhoneNumberForm}>
            <Fieldset>
              <Fieldset.Legend>
                {T("containers.user.updatePhoneNumber.title")}
              </Fieldset.Legend>
              <PhoneNumberInput
                phoneNumber={user.phoneNumber || ""}
                onChange={() => {
                  /**/
                }}
                error=""
                label={T(
                  "containers.user.updatePhoneNumber.existingPhoneNumber.label"
                )}
                aria-label={T(
                  "containers.user.updatePhoneNumber.existingPhoneNumber.label"
                )}
                data-testid="existing-phone-number"
                disabled
                defaultCallingCode="+44"
              />
              <PhoneNumberInput
                phoneNumber={phoneNumber}
                onChange={setPhoneNumber}
                error={phoneNumberError}
                label={T("containers.user.updatePhoneNumber.phoneNumber.label")}
                aria-label={T(
                  "containers.user.updatePhoneNumber.phoneNumber.label"
                )}
                data-testid="phone-number"
                defaultCallingCode="+44"
              />
              <PhoneNumberInput
                phoneNumber={checkPhoneNumber}
                onChange={setCheckPhoneNumber}
                error=""
                label={T(
                  "containers.user.updatePhoneNumber.checkPhoneNumber.label"
                )}
                aria-label={T(
                  "containers.user.updatePhoneNumber.checkPhoneNumber.label"
                )}
                data-testid="check-phone-number"
                defaultCallingCode="+44"
              />
              <Checkboxes name="confirm" id="confirm" error={checkError}>
                <Checkboxes.Box
                  value="accept"
                  checked={isChecked}
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>
                  ): void => setIsChecked(event.currentTarget.checked)}
                  data-testid="change-phone-number-confirm"
                >
                  {T("containers.user.updatePhoneNumber.confirm.label")}
                </Checkboxes.Box>
              </Checkboxes>
            </Fieldset>
            {phoneNumberUpdateError && (
              <ErrorSummary
                aria-label={T(
                  "containers.user.updatePhoneNumber.submissionError.label"
                )}
                role="alert"
                tabIndex={-1}
              >
                <ErrorSummary.Title>
                  {T("containers.user.updatePhoneNumber.submissionError.label")}
                </ErrorSummary.Title>
                <ErrorSummary.Body data-testid="error-body">
                  {phoneNumberUpdateError.message}
                </ErrorSummary.Body>
              </ErrorSummary>
            )}
            <Button
              type="submit"
              aria-label={T("containers.user.updatePhoneNumber.actions.submit")}
              disabled={loading}
              data-testid="submit-update-phone-number"
            >
              {T("containers.user.updatePhoneNumber.actions.submit")}
            </Button>
          </Form>
        </>
      );
    }

    return (
      <main className="nhsuk-main-wrapper">
        <BackLink
          onClick={() => push(`/user`)}
          data-testid="user-profile-back-link"
          aria-label={T("containers.wizard.previous")}
        >
          {T("containers.wizard.previous")}
        </BackLink>
        {content}
      </main>
    );
  }
  return <></>;
}
