import React, { FormEvent, useState } from "react";
import { Input, Select, Table } from "nhsuk-react-components";
import { IUnitCapacityPerDay, IUnitCapacityStatus } from "interfaces";
import { T } from "i18n";
import { formatTime } from "formatters";

interface UnitCapacityRowProps {
  capacity: IUnitCapacityPerDay;
  error: boolean;
  disabled: boolean;
  updateCapacity: (newCapacity: number) => void;
  updateStatus: (newStatus: IUnitCapacityStatus) => void;
  setInvalidCapacity: () => void;
}

const errorLabel = T(
  "containers.unit.capacity.errors.update.invalidNumber.label"
);

export const UnitCapacityRow = (props: UnitCapacityRowProps): JSX.Element => {
  const {
    capacity,
    error,
    disabled,
    updateCapacity,
    updateStatus,
    setInvalidCapacity,
  } = props;
  const [capacityInput, setCapacityInput] = useState(String(capacity.capacity));
  const [status, setStatus] = useState(capacity.status);

  const onCapacityChange = (e: FormEvent<HTMLInputElement>): void => {
    setCapacityInput(e.currentTarget.value);
    const integerCapacity = parseInt(e.currentTarget.value, 10);
    if (isNaN(integerCapacity)) {
      setInvalidCapacity();
      return;
    }
    updateCapacity(integerCapacity);
  };

  const onStatusChange = (e: FormEvent<HTMLSelectElement>): void => {
    const newStatus = e.currentTarget.value as IUnitCapacityStatus;
    setStatus(newStatus);
    updateStatus(newStatus);
  };

  return (
    <Table.Row>
      <Table.Cell
        aria-label={T("containers.unit.capacity.table.activeFrom.label")}
      >
        {formatTime(capacity.activeFrom)}
      </Table.Cell>
      <Table.Cell
        aria-label={T("containers.unit.capacity.table.activeTo.label")}
      >
        {formatTime(capacity.activeTo)}
      </Table.Cell>
      <Table.Cell
        aria-label={T("containers.unit.capacity.table.capacity.label")}
      >
        <Input
          type="text"
          name="number"
          disabled={disabled}
          aria-label={T("containers.unit.capacity.update.capacity.label")}
          value={capacityInput}
          onChange={onCapacityChange}
          error={error ? errorLabel : undefined}
          width="5"
        />
      </Table.Cell>
      <Table.Cell>
        <Select
          aria-label={T("containers.unit.capacity.update.status.label")}
          disabled={disabled}
          value={status}
          onChange={onStatusChange}
        >
          <Select.Option data-testid="status-option" value="PUBLISHED">
            {T("containers.unit.capacity.status.PUBLISHED")}
          </Select.Option>
          <Select.Option data-testid="status-option" value="DRAFT">
            {T("containers.unit.capacity.status.DRAFT")}
          </Select.Option>
        </Select>
      </Table.Cell>
    </Table.Row>
  );
};
