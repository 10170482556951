import React, { useEffect, useState } from "react";
import { T } from "i18n";
import { Table } from "nhsuk-react-components";
import { ICommunication } from "interfaces/ICommunication";
import { AsyncCall, CallState, err, notCalled, ok } from "helpers/AsyncCall";
import { formatAppointmentDate, formatCapitalisation } from "formatters";
import { useAPI } from "api";

interface CommunicationRowsProps {
  communications: ICommunication[];
}

const CommunicationRows: React.FunctionComponent<CommunicationRowsProps> = ({
  communications,
}) => {
  if (communications.length === 0) {
    return (
      <Table.Row>
        <Table.Cell colSpan={columns}>
          {T("containers.participant.communications.empty")}
        </Table.Cell>
      </Table.Row>
    );
  }
  return (
    <>
      {communications
        .sort((a, b) => b.sentAt.toMillis() - a.sentAt.toMillis())
        .map((communication) => (
          <Table.Row
            key={`${communication.channel}-${communication.type}-${communication.sentAt}`}
            data-testid={`communication-row`}
          >
            <Table.Cell>
              {formatAppointmentDate(communication.sentAt)}
            </Table.Cell>
            <Table.Cell>
              {T(
                `containers.participant.communications.channel.${communication.channel}`
              )}
            </Table.Cell>
            <Table.Cell>
              {formatCapitalisation(communication.type, "_")}
            </Table.Cell>
          </Table.Row>
        ))}
    </>
  );
};

const columns = 3;

const CommunicationsTable: React.FunctionComponent = (props) => {
  return (
    <>
      <h4>{T("containers.participant.communications.title")}</h4>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Cell>
              {T("containers.participant.communications.headings.time")}
            </Table.Cell>
            <Table.Cell>
              {T("containers.participant.communications.headings.channel")}
            </Table.Cell>
            <Table.Cell>
              {T("containers.participant.communications.headings.type")}
            </Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>{props.children}</Table.Body>
      </Table>
    </>
  );
};

interface PersonCommunicationsProps {
  cohortId: string;
}

export const PersonCommunications: React.FunctionComponent<
  PersonCommunicationsProps
> = ({ cohortId }) => {
  const [communications, setCommunications] =
    useState<AsyncCall<ICommunication[]>>(notCalled);

  const personAPI = useAPI("people");

  useEffect(() => {
    personAPI
      .getPersonCommunications(cohortId)
      .then((res) => setCommunications(ok(res)))
      .catch((error) => setCommunications(err(error)));
  }, [cohortId, personAPI]);

  switch (communications.state) {
    case CallState.NotCalled:
      return <CommunicationsTable />;
    case CallState.Error:
      return (
        <CommunicationsTable>
          <Table.Row>
            <Table.Cell colSpan={columns}>
              {communications.error.message}
            </Table.Cell>
          </Table.Row>
        </CommunicationsTable>
      );
    case CallState.Loading:
      return (
        <CommunicationsTable>
          <Table.Row>
            <Table.Cell colSpan={columns}>
              {T("containers.participant.communications.loading")}
            </Table.Cell>
          </Table.Row>
        </CommunicationsTable>
      );
    case CallState.Ok:
      return (
        <CommunicationsTable>
          <CommunicationRows communications={communications.result} />
        </CommunicationsTable>
      );
  }
};
