import React, { useMemo } from "react";
import { AccessibilityRequirements } from "components";
import { ActionLink, Table } from "nhsuk-react-components";
import { IAppointment, IBloodDrawStatus, IPerson } from "../../interfaces";
import { Link } from "react-router-dom";
import { T } from "i18n";
import { Tag } from "nhsuk-react-components-extensions";
import { formatTime } from "formatters";

export interface AppointmentListEntry {
  appointment: IAppointment;
  person: IPerson;
  blood: IBloodDrawStatus;
}

interface AppointmentListProps {
  unitScheduleEntries: AppointmentListEntry[];
  unitCode: string;
}

export const AppointmentList = ({
  unitScheduleEntries,
  unitCode,
}: AppointmentListProps): JSX.Element => {
  const appointments = useMemo(
    () =>
      unitScheduleEntries.filter(
        ({ appointment }) => appointment.status === "BOOKED"
      ),
    [unitScheduleEntries]
  );
  return (
    <>
      {appointments.length === 0 && (
        <p>{T("containers.checkIn.fields.appointments.noAppointments")}</p>
      )}

      {appointments.length > 0 && (
        <Table aria-label="appointments">
          <Table.Head>
            <Table.Row>
              <Table.Cell>
                {T(
                  "containers.checkIn.fields.appointments.headings.participant"
                )}
              </Table.Cell>
              <Table.Cell>
                {T("containers.checkIn.fields.appointments.headings.cohortId")}
              </Table.Cell>
              <Table.Cell>
                {T("containers.checkIn.fields.appointments.headings.time")}
              </Table.Cell>
              <Table.Cell>
                {T("containers.checkIn.fields.appointments.headings.duration")}
              </Table.Cell>
              <Table.Cell>
                {T("containers.checkIn.fields.appointments.headings.type")}
              </Table.Cell>
              <Table.Cell>
                {T(
                  "containers.checkIn.fields.appointments.headings.accessibility"
                )}
              </Table.Cell>
              <Table.Cell />
              <Table.Cell>
                {T("containers.checkIn.fields.appointments.headings.action")}
              </Table.Cell>
              <Table.Cell />
            </Table.Row>
          </Table.Head>
          <Table.Body data-testid="check-in-appointment-list">
            {appointments.map(
              ({ appointment, person, blood }) =>
                appointment && (
                  <Table.Row
                    key={`${
                      person.cohortId
                    } ${appointment.appointmentStartTime.toISO()}`}
                    label="appointment"
                    data-testid="check-in-appointment-row"
                  >
                    <Table.Cell>
                      {`${person.firstName} ${person.lastName}`}
                    </Table.Cell>
                    <Table.Cell>
                      <Link
                        to={`/participant/${person.cohortId}`}
                        aria-label={T(
                          "containers.checkIn.fields.appointments.actions.participantPage.label"
                        )}
                        data-testid={`check-in-cohort-id-${person.cohortId}`}
                      >
                        {person.cohortId}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      {formatTime(appointment.appointmentStartTime)}
                    </Table.Cell>
                    <Table.Cell>
                      {T("containers.booking.fields.slot.duration", {
                        duration: appointment.appointmentEndTime.diff(
                          appointment.appointmentStartTime,
                          "minutes"
                        ).minutes,
                      })}
                    </Table.Cell>
                    <Table.Cell>
                      <Tag data-testid={`appointment-type-${person.cohortId}`}>
                        {appointment.type}
                      </Tag>
                    </Table.Cell>
                    <Table.Cell label="appointment-accessibility-requirements">
                      <AccessibilityRequirements appointment={appointment}>
                        {T(
                          "containers.participant.appointments.headings.requirements"
                        )}
                      </AccessibilityRequirements>
                    </Table.Cell>
                    <Table.Cell>
                      {blood.reAttempt && (
                        <Tag data-testid="re-attempt-needed" color="red">
                          {T("components.person.blood.reAttempt.needed")}
                        </Tag>
                      )}
                      {blood.reDraw && (
                        <Tag data-testid="re-draw-needed" color="red">
                          {T("components.person.blood.reDraw.needed")}
                        </Tag>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <ActionLink
                        asElement={Link}
                        data-testid={`check-in-link-${person.cohortId}`}
                        to={`/check-in/${unitCode}/confirm/${person.cohortId}`}
                        className="action-button"
                        aria-label={T(
                          "containers.checkIn.fields.appointments.actions.checkIn.label"
                        )}
                      >
                        {T(
                          "containers.checkIn.fields.appointments.actions.checkIn.label"
                        )}
                      </ActionLink>
                    </Table.Cell>
                  </Table.Row>
                )
            )}
          </Table.Body>
        </Table>
      )}
    </>
  );
};
