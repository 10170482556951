import React, { useCallback, useEffect, useState } from "react";
import { ErrorPanel, Loader } from "components";
import { IVideo } from "interfaces";
import { T } from "i18n";
import { VideoForm } from "./VideoForm";
import { VideoResult } from "./VideoResult";
import { VisitStageProps } from "../../Visit";
import { useAPI } from "api";

export const Video = ({
  cohortId,
  person,
  onComplete,
}: VisitStageProps<"D1_VIDEO">): JSX.Element => {
  const videoAPI = useAPI("video");

  const [videos, setVideos] = useState<IVideo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error>();

  const getVideoSubmissions = useCallback((): Promise<void> => {
    setLoading(true);
    return videoAPI
      .getVideoSubmissions(cohortId)
      .then(setVideos)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [cohortId, videoAPI]);

  const onSubmitSuccess = (): void => {
    getVideoSubmissions();
    onComplete();
  };

  useEffect((): void => {
    getVideoSubmissions();
  }, [getVideoSubmissions]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <ErrorPanel
        label={T("containers.video.errors.title")}
        title={T("containers.video.errors.title")}
      >
        {error.message}
      </ErrorPanel>
    );
  }

  if (videos.length > 0) {
    return <VideoResult person={person} />;
  }

  return (
    <VideoForm
      filename="NHS_Galleri_Trial_Animation_v2.mp4"
      onSubmitSuccess={onSubmitSuccess}
      cohortId={cohortId}
      person={person}
    />
  );
};
