import React from "react";
import { IPerson, IVisit } from "../../interfaces";
import { Link } from "react-router-dom";
import { T } from "i18n";
import { Table } from "nhsuk-react-components";
import { VisitStatus } from "../../enums";
import { formatTime } from "formatters";

export interface VisitWithPerson {
  visit: IVisit;
  person: IPerson;
}

export interface CompletedVisitsProps {
  unitScheduleEntries: VisitWithPerson[];
}

function filterAbortStatuses(status: VisitStatus): string {
  if (status === VisitStatus.IN_PROGRESS) {
    return T("containers.visit.abort.select.status.inProgress");
  }
  if (status === VisitStatus.COMPLETED) {
    return T("containers.visit.abort.select.status.completed");
  }
  return T("containers.visit.abort.select.status.aborted");
}

export const CompletedVisits = ({
  unitScheduleEntries,
}: CompletedVisitsProps): JSX.Element => {
  const visits = unitScheduleEntries
    .filter((entry) => entry.visit && entry.visit.finishedAt)
    .sort((a, b): number => {
      if (a.visit?.finishedAt && b.visit?.finishedAt) {
        if (a.visit.finishedAt > b.visit.finishedAt) {
          return 1;
        }
        if (b.visit.finishedAt > a.visit.finishedAt) {
          return -1;
        }
      }
      return 0;
    });

  if (visits.length === 0)
    return <p>{T("containers.checkIn.fields.appointments.noCompleted")}</p>;

  return (
    <Table aria-label="completed-visits">
      <Table.Head>
        <Table.Row>
          <Table.Cell>
            {T("containers.checkIn.fields.appointments.headings.participant")}
          </Table.Cell>
          <Table.Cell>
            {T("containers.checkIn.fields.appointments.headings.cohortId")}
          </Table.Cell>
          <Table.Cell>
            {T(
              "containers.checkIn.fields.appointments.headings.visitCompletedTime"
            )}
          </Table.Cell>
          <Table.Cell>Current activity</Table.Cell>
          <Table.Cell />
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {visits.map(
          ({ visit, person }) =>
            visit && (
              <Table.Row
                key={person.cohortId}
                aria-label="completed-visit"
                data-testid={`completed-visit-${person.cohortId}`}
              >
                <Table.Cell>
                  {`${person.firstName} ${person.lastName}`}
                </Table.Cell>
                <Table.Cell>
                  <Link
                    to={`/participant/${person.cohortId}`}
                    aria-label={T(
                      "containers.checkIn.fields.appointments.actions.participantPage.label"
                    )}
                    data-testid={`completed-cohort-id-${person.cohortId}`}
                  >
                    {person.cohortId}
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  {visit.finishedAt && formatTime(visit.finishedAt)}
                </Table.Cell>
                <Table.Cell data-testid="completed-status">
                  {filterAbortStatuses(visit.status)}
                </Table.Cell>
              </Table.Row>
            )
        )}
      </Table.Body>
    </Table>
  );
};
