export enum CallState {
  NotCalled,
  Loading,
  Ok,
  Error,
}

export type NotCalled = {
  state: CallState.NotCalled;
};

export type Loading = {
  state: CallState.Loading;
};

export type Ok<T> = {
  state: CallState.Ok;
  result: T;
};

export type Err = {
  state: CallState.Error;
  error: Error;
};

export type AsyncCall<T> = NotCalled | Loading | Ok<T> | Err;

export const loading: Loading = { state: CallState.Loading };
export const notCalled: NotCalled = { state: CallState.NotCalled };
export function ok<T>(result: T): Ok<T> {
  return { state: CallState.Ok, result };
}
export function err(error: Error): Err {
  return { state: CallState.Error, error };
}
