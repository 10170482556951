import React, { SyntheticEvent, useState } from "react";
import {
  AddressInput,
  DateTimeInput,
  ErrorPanel,
  FormValidity,
} from "components";
import { Button, Fieldset, Form, Textarea } from "nhsuk-react-components";
import { T } from "i18n";
import {
  activeToBeforeActiveFrom,
  cacheBustFailed,
  overlapsExistingLocation,
} from "errors/unitManagement";
import { dateTimeInputToDateTime } from "helpers";
import { useAPI } from "api";
import { useAddress, useDateTime } from "hooks";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";

export function AddUnitLocation(): JSX.Element {
  const MAX_DIRECTION_NOTE_LENGTH = 2000;

  const { unitCode } = useParams<{ unitCode: string }>();
  const history = useHistory();

  const [
    activeFromInput,
    activeFromError,
    activeFromSetter,
    validateActiveFrom,
    isActiveFromValid,
  ] = useDateTime();
  const [
    activeToInput,
    activeToError,
    activeToSetter,
    validateAcitveTo,
    isActiveToValid,
  ] = useDateTime();

  const [
    unitAddress,
    unitAddressSetter,
    unitAddressIDs,
    validateUnitAddress,
    isAddressValid,
  ] = useAddress();

  const [directions, setDirections] = useState<string>();

  const [submissionError, setSubmissionError] = useState("");

  const onCancelClick = (): void => {
    const path = `/unit/${unitCode}/location`;
    history.push(path);
  };

  const formIsFilled = (): boolean => {
    return isActiveFromValid && isActiveToValid && isAddressValid;
  };

  const unitAPI = useAPI("unit");

  const [formValidity, setFormValidity] = useState<FormValidity>(
    new FormValidity()
  );

  const onSubmitForm = async (
    e: SyntheticEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    const validity = new FormValidity();
    validateUnitAddress(validity);
    setFormValidity(validity);
    const formHasErrors =
      !validateAcitveTo() || !validateActiveFrom() || !validity.valid;
    if (!formHasErrors) {
      unitAPI
        .createUnitLocation(
          {
            unitCode,
            address: unitAddress,
            activeFrom: dateTimeInputToDateTime(activeFromInput),
            activeTo: dateTimeInputToDateTime(activeToInput),
          },
          directions
        )
        .then(() => {
          const path = `/unit/${unitCode}/location`;
          history.push(path);
        })
        .catch(({ message }) => {
          switch (message) {
            case overlapsExistingLocation:
              setSubmissionError(
                T("containers.unit.location.add.errors.overlaps")
              );
              break;
            case activeToBeforeActiveFrom:
              setSubmissionError(
                T(
                  "containers.unit.location.add.errors.activeToBeforeActiveFrom"
                )
              );
              break;
            case cacheBustFailed:
              history.push({
                pathname: `/unit/${unitCode}/location`,
                state: {
                  error: T("containers.unit.location.add.errors.cache.error"),
                },
              });
              break;
            default:
              setSubmissionError(T("errors.unknown"));
          }
        });
    }
  };

  return (
    <main className="nhsuk-main-wrapper">
      <Form onSubmit={onSubmitForm}>
        <Fieldset>
          <Fieldset.Legend isPageHeading>
            {T("containers.unit.location.add.title")}
          </Fieldset.Legend>
        </Fieldset>

        <Fieldset>
          <Fieldset.Legend>
            {T("containers.unit.location.add.inputs.activeFrom")}
          </Fieldset.Legend>
          <DateTimeInput
            {...activeFromInput}
            {...activeFromSetter}
            error={activeFromError}
            ariaLabel="Start"
          />
        </Fieldset>

        <Fieldset>
          <Fieldset.Legend>
            {T("containers.unit.location.add.inputs.activeTo")}
          </Fieldset.Legend>
          <DateTimeInput
            {...activeToInput}
            {...activeToSetter}
            error={activeToError}
            ariaLabel="End"
          />
        </Fieldset>

        <div data-testid="unit-address-input">
          <AddressInput
            {...unitAddress}
            {...unitAddressSetter}
            {...unitAddressIDs}
            componentName="addressInput"
            addressValidity={formValidity}
          />
        </div>

        <Fieldset>
          <Fieldset.Legend size="m">
            {T("containers.unit.location.add.directions.subheading")}
          </Fieldset.Legend>
          <Textarea
            data-testid="directions-input"
            label={T(
              "containers.unit.location.add.directions.inputs.directions.label"
            )}
            rows={5}
            maxLength={MAX_DIRECTION_NOTE_LENGTH}
            onChange={(e) => setDirections(e.currentTarget.value)}
          />
        </Fieldset>

        <Button
          className="nhsuk-button-margin-right"
          secondary
          aria-label="Cancel"
          onClick={(event: React.MouseEvent) => {
            onCancelClick();
          }}
        >
          {T("containers.unit.add.actions.cancel")}
        </Button>
        <Button type="submit" disabled={!formIsFilled()} aria-label="Save">
          {T("containers.unit.add.actions.save")}
        </Button>

        {submissionError !== "" && (
          <ErrorPanel
            label="submission-error"
            title={
              <span id="error-summary-title">
                {T("errors.submissionErrorTitle")}
              </span>
            }
          >
            <p>{submissionError}</p>
          </ErrorPanel>
        )}
      </Form>
    </main>
  );
}
