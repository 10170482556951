import React from "react";
import { Address } from "components";
import { Button, Card, Col, Label, Row } from "nhsuk-react-components";
import { IUnitLocation } from "interfaces";
import { T } from "i18n";

interface LocationsListProps {
  locations: IUnitLocation[];
  onSelect: (location: IUnitLocation) => void;
}

export const LocationsList = ({
  locations,
  onSelect,
}: LocationsListProps): JSX.Element => (
  <>
    <Label size="m">{T("containers.checkIn.fields.location.title")}</Label>

    {locations.length === 0 && (
      <Row aria-label={T("containers.checkIn.fields.location.label")}>
        <Col width="full">
          <p data-testid="check-in-locations-empty">
            {T("containers.checkIn.fields.location.empty")}
          </p>
        </Col>
      </Row>
    )}

    {locations.map((location) => (
      <Card key={location.unitCode}>
        <Card.Content>
          <Row
            key={location.unitCode}
            aria-label={T("containers.checkIn.fields.location.label")}
          >
            <Col width="one-third">
              <p
                data-testid={`check-in-locations-location-${location.unitCode}-label`}
              >
                <Address address={location.address} />
              </p>
            </Col>
            <Col width="one-third">
              <Button
                data-testid={`check-in-locations-location-${location.unitCode}-select`}
                aria-label={T(
                  "containers.checkIn.fields.location.actions.select.label"
                )}
                onClick={() => onSelect(location)}
              >
                {T("containers.checkIn.fields.location.actions.select.label")}
              </Button>
            </Col>
          </Row>
        </Card.Content>
      </Card>
    ))}
  </>
);
