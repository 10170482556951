import React, { useMemo } from "react";
import { CallCentreNote, Loader, RegistrationClosed } from "components";
import { FindMyNHSNumber } from "../Registration/RegistrationForm";
import { IUser, useAuth } from "auth";
import { Link } from "react-router-dom";
import { ReadingWidth } from "nhsuk-react-components";
import { Restricted } from "../../auth/components";
import { T } from "i18n";
import { WelcomeEMSStaff } from "./WelcomeEMSStaff";
import { useQueryRegistrationClosed } from "../../hooks";

const WelcomeVisitor = (): JSX.Element => (
  <div data-testid="welcome-visitor">
    <h1>{T("containers.welcome.title")}</h1>
    <p>{T("containers.welcome.description")}</p>
    <Link
      className="nhsuk-button"
      to="/registration"
      data-testid="registration-start"
    >
      {T("containers.welcome.action")}
    </Link>
    <h2>{T("containers.welcome.instructions.title")}</h2>
    <p>{T("containers.welcome.instructions.description")}</p>
    <ul>
      <li>{T("containers.welcome.instructions.items.gpDetails")}</li>
      <li>{T("containers.welcome.instructions.items.yourNHSNumber")}</li>
      <li>{T("containers.welcome.instructions.items.invitationCode")}</li>
      <li>{T("containers.welcome.instructions.items.dateOfBirth")}</li>
      <li>{T("containers.welcome.instructions.items.contactDetails")}</li>
    </ul>
    <FindMyNHSNumber displayHint />
    <h4>{T("containers.welcome.moreInformation.title")}</h4>
    <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
      <li>
        <a href="https://www.nhs-galleri.org/">
          {T("containers.welcome.moreInformation.links.visitOurWebsite")}
        </a>
      </li>
      <li>
        <a href="https://www.nhs-galleri.org/taking-part/your-data-in-the-trial">
          {T("containers.welcome.moreInformation.links.aboutYourData")}
        </a>
      </li>
      <li>
        <a href="mailto:participant_help@nhs-galleri.org">
          {T("containers.welcome.moreInformation.links.contactUs")}
        </a>
      </li>
    </ul>
    <CallCentreNote />
  </div>
);

interface WelcomeAuthenticatedProps {
  user: IUser;
}

const WelcomeUser = ({ user }: WelcomeAuthenticatedProps): JSX.Element => {
  // Because a user can have multiple roles, the current implementation is to push panels of every user roles in the
  // main area of display for the welcome screen

  const userGreeting = (user: IUser): string => {
    const name =
      user.firstName || user.lastName
        ? [user.firstName, user.lastName].join(" ")
        : user.id;
    return T("containers.welcomeAuthenticated.title", { user: name });
  };

  return (
    <div data-testid="welcome-user">
      <h1>{userGreeting(user)}</h1>
      <Restricted mode="hasRoles">
        <Restricted mode="allowRoles" allowedRoles={["EMS Unit Staff"]}>
          <WelcomeEMSStaff data-testid="welcome-ems-staff" />
        </Restricted>

        <Restricted mode="blockRoles" blockedRoles={["EMS Unit Staff"]}>
          <p>{T("containers.welcomeAuthenticated.roles")}</p>
        </Restricted>
      </Restricted>

      <Restricted mode="noRoles">
        <p>{T("containers.welcomeAuthenticated.noRoles")}</p>
      </Restricted>
    </div>
  );
};

export const Welcome = (): JSX.Element => {
  const { user } = useAuth();
  const registrationClosed = useQueryRegistrationClosed();

  const content = useMemo((): JSX.Element => {
    if (registrationClosed === undefined) {
      return <Loader />;
    }
    if (registrationClosed && !user) {
      return <RegistrationClosed />;
    }

    if (user !== undefined) {
      return <WelcomeUser user={user} />;
    }
    return <WelcomeVisitor />;
  }, [registrationClosed, user]);

  return (
    <main className="nhsuk-main-wrapper">
      <ReadingWidth>{content}</ReadingWidth>
    </main>
  );
};
