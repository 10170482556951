import { AxiosInstance } from "axios";

export interface IInfoClient {
  queryRegistrationClosed(): Promise<boolean>;
}

export class InfoClient implements IInfoClient {
  public constructor(protected readonly http: AxiosInstance) {}

  async queryRegistrationClosed(): Promise<boolean> {
    const response = await this.http.get("/info/registration-closed");
    return response.data;
  }

  async queryBookingClosed(): Promise<boolean> {
    const response = await this.http.get("/info/booking-closed");
    return response.data;
  }
}
