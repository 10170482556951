import React, { useEffect, useState } from "react";
import omit from "lodash/omit";
import { ActionLink, BackLink, Col, Label, Row } from "nhsuk-react-components";
import { BackToTop } from "./BackToTop";
import { BeforeYouBegin } from "./BeforeYouBegin";
import { CallParticipant } from "./CallParticipant";
import {
  CaseContact,
  CaseFieldState,
  ECaseContactType,
  ECaseField,
  IAppointment,
  ICaseState,
  IDateInputValue,
  IPerson,
  ReportResult,
} from "interfaces";
import { CaseStatus } from "enums";
import { CheckboxCard } from "./CheckboxCard";
import { DateTime } from "luxon";
import { ErrorPanel, Loader } from "components";
import { ParticipantReferralLog } from "./ParticipantReferralLog";
import { ParticipantReferralSubmission } from "./ParticipantReferralSubmission";
import { ReferralDates, ReferralErrors } from "./participantReferralTypes";
import { T } from "i18n";
import { TopBar } from "./TopBar";
import { dateInputToString, stringToDateInput } from "helpers";
import { env } from "config";
import { formatAddressToArray } from "formatters";
import { invalidDate } from "errors/result";
import { isValidDateBoolean, isValidDateInThePast } from "validations";
import { useAPI } from "api";
import { useLocation, useParams } from "react-router-dom";
import { IResult } from "../../../interfaces/IResult";

export interface ResultLocationState {
  reportISODate?: string;
  studyID: string;
}

const calledSuccessfully = (caseContacts: CaseContact[]): boolean => {
  return caseContacts
    .map((caseContact) => caseContact.type)
    .includes(ECaseContactType.CaseContactCallSuccessful);
};

export const Result = (): JSX.Element => {
  const { caseReference } = useParams<{ caseReference: string }>();
  const [caseState, setCaseState] = useState<ICaseState>();
  const [newContactHistoryEntries, setNewContactHistoryEntries] = useState<
    CaseContact[]
  >([]);
  const [apiError, setAPIError] = useState<Error>();
  const [appointment, setAppointment] = useState<IAppointment>();
  const [idConfirmation, setIDConfirmation] = useState<string>();
  const [participantDoc, setParticipantDoc] = useState<DateTime>();
  const [gpMailed, setGPMailed] = useState<DateTime>();
  const [ecrfFormSubmitted, setECRFFormSubmitted] = useState<DateTime>();
  const [participantDetails, setParticipantDetails] = useState<IPerson>();
  const [closeNote, setCloseNote] = useState<string>("");
  const location = useLocation<ResultLocationState>();
  const [loading, setLoading] = useState<boolean>(true);
  const [y0Result, setY0Result] = useState<ReportResult>();
  const [y1Result, setY1Result] = useState<ReportResult>();
  const [y2Result, setY2Result] = useState<ReportResult>();

  const [referralDates, setReferralDates] = useState<ReferralDates>({
    submitted: { year: "", month: "", day: "" },
    rejected: { year: "", month: "", day: "" },
    accepted: { year: "", month: "", day: "" },
  });
  const [referralErrors, setReferralErrors] = useState<ReferralErrors>({
    acceptedError: "",
    rejectedError: "",
    submittedError: "",
  });
  const handleReferralSubmissionDateChange = (value: IDateInputValue): void => {
    setReferralDates({ ...referralDates, submitted: value });
  };
  const handleReferralAcceptedDateChange = (value: IDateInputValue): void => {
    setReferralDates({ ...referralDates, accepted: value });
  };
  const handleReferralRejectedDateChange = (value: IDateInputValue): void => {
    setReferralDates({ ...referralDates, rejected: value });
  };

  function loadState(state: ICaseState): void {
    setCaseState(state);
    setNewContactHistoryEntries([]);

    if (state.state.PARTICIPANT_DOCS_MAILED !== "") {
      setParticipantDoc(DateTime.fromISO(state.state.PARTICIPANT_DOCS_MAILED));
    }
    if (state.state.GP_DOCS_MAILED !== "") {
      setGPMailed(DateTime.fromISO(state.state.GP_DOCS_MAILED));
    }
    if (state.state.ECRF_FORM_SUBMITTED !== "") {
      setECRFFormSubmitted(DateTime.fromISO(state.state.ECRF_FORM_SUBMITTED));
    }

    setCloseNote(state.state.CLOSE_NOTE);

    setReferralDates({
      submitted: stringToDateInput(state.state.REFERRAL_SUBMITTED),
      rejected: stringToDateInput(state.state.REFERRAL_REJECTED),
      accepted: stringToDateInput(state.state.REFERRAL_ACCEPTED),
    });
  }

  function validate(): boolean {
    const submittedError =
      referralDates.submitted.year === "" &&
      referralDates.submitted.month === "" &&
      referralDates.submitted.year === ""
        ? ""
        : isValidDateInThePast(
            referralDates.submitted,
            T("containers.resultsCase.referralCard.dateError")
          );
    const rejectedError =
      referralDates.rejected.year === "" &&
      referralDates.rejected.month === "" &&
      referralDates.rejected.year === ""
        ? ""
        : isValidDateInThePast(
            referralDates.rejected,
            T("containers.resultsCase.referralCard.dateError")
          );
    const acceptedError =
      referralDates.accepted.year === "" &&
      referralDates.accepted.month === "" &&
      referralDates.accepted.year === ""
        ? ""
        : isValidDateInThePast(
            referralDates.accepted,
            T("containers.resultsCase.referralCard.dateError")
          );
    setReferralErrors({
      submittedError,
      rejectedError,
      acceptedError,
    });
    return ![submittedError, rejectedError, acceptedError].some(
      (val) => val !== ""
    );
  }

  const getState = (): CaseFieldState => {
    return {
      [ECaseField.IDConfirmation]: idConfirmation || "",
      [ECaseField.ReferralAccepted]: isValidDateBoolean(referralDates.accepted)
        ? dateInputToString(referralDates.accepted)
        : "",
      [ECaseField.ReferralRejected]: isValidDateBoolean(referralDates.rejected)
        ? dateInputToString(referralDates.rejected)
        : "",
      [ECaseField.ReferralSubmitted]: isValidDateBoolean(
        referralDates.submitted
      )
        ? dateInputToString(referralDates.submitted)
        : "",
      [ECaseField.ParticipantDocsMailed]: participantDoc?.toISO()
        ? participantDoc?.toISO()
        : "",
      [ECaseField.GPDocsMailed]: gpMailed?.toISO() ? gpMailed?.toISO() : "",
      [ECaseField.ECRFFormSubmitted]: ecrfFormSubmitted?.toISO()
        ? ecrfFormSubmitted?.toISO()
        : "",
      [ECaseField.CloseNote]: closeNote,
    };
  };

  function preReferralLogComplete(): boolean {
    const contactedPreviously = caseState?.contactHistory
      ? calledSuccessfully(caseState.contactHistory)
      : false;
    const contactedThisTime = calledSuccessfully(newContactHistoryEntries);
    const called = contactedPreviously || contactedThisTime;
    const otherFields = omit(getState(), [
      ECaseField.ReferralAccepted,
      ECaseField.ReferralRejected,
      ECaseField.CloseNote,
    ]);
    return called && Object.values(otherFields).every((val) => val !== "");
  }

  const resultsApi = useAPI("results");
  const personApi = useAPI("people");
  const appointmentApi = useAPI("appointment");

  const save = (status: CaseStatus): Promise<void> => {
    if (!validate()) {
      return Promise.reject(new Error(invalidDate));
    }
    const state = getState();
    return resultsApi
      .saveCase(caseReference, status, state, newContactHistoryEntries)
      .then(loadState)
      .catch(setAPIError);
  };

  function compare(a: IAppointment, b: IAppointment) {
    if (a.appointmentStartTime < b.appointmentStartTime) return 1;
    else if (a.appointmentStartTime > b.appointmentStartTime) return -1;
    else return 0;
  }

  useEffect(() => {
    setLoading(true);
    async function initialiseData() {
      try {
        const data = await resultsApi.getCaseState(caseReference);
        const [personData, appointmentData] = await Promise.all([
          personApi.getPerson(data.cohortId),
          appointmentApi.getAppointments(data.cohortId),
        ]);
        loadState(data);
        setParticipantDetails(personData);
        const appointments: IAppointment[] = appointmentData;
        const sortedAppointments = appointments
          .filter((a) => a.status === "ATTENDED")
          .sort(compare);
        setAppointment(sortedAppointments[0]);

        const results = await Promise.allSettled([
          resultsApi.getResultForVisit(data.cohortId, "D1"),
          resultsApi.getResultForVisit(data.cohortId, "Y1"),
          resultsApi.getResultForVisit(data.cohortId, "Y2"),
        ]);
        results.forEach((r) => {
          if (isFulfilled(r)) {
            switch ((r.value as IResult).visitType) {
              case "D1":
                setY0Result(r.value.reportResult);
                break;
              case "Y1":
                setY1Result(r.value.reportResult);
                break;
              case "Y2":
                setY2Result(r.value.reportResult);
                break;
            }
          }
        });
      } catch (e) {
        setAPIError(e);
      } finally {
        setLoading(false);
      }
    }

    initialiseData();
  }, [resultsApi, personApi, appointmentApi, caseReference]);

  if (loading) {
    return <Loader />;
  }

  if (apiError) {
    return <ErrorPanel title={apiError.message} />;
  }

  if (participantDetails && !participantDetails.studyId) {
    return <ErrorPanel title={T("containers.resultsCase.noStudyId")} />;
  }

  const gpDocsContent = (): JSX.Element => (
    <Row>
      <Col width="one-half">
        <Label bold>{T("containers.resultsCase.document.toMail")}</Label>
        <ul>
          <li>{T("containers.resultsCase.GpDocCard.documents.gpLetter")}</li>
          <li>
            {T("containers.resultsCase.GpDocCard.documents.galleriTestReport")}
          </li>
        </ul>
      </Col>
      {participantDetails && (
        <Col width="one-half">
          <Label bold>{T("containers.resultsCase.document.mailTo")}</Label>
          {formatAddressToArray(participantDetails.gpPractice.address).map(
            (line) => (
              <p style={{ marginBottom: 0 }} key={line}>
                {line}
              </p>
            )
          )}
        </Col>
      )}
      <br />
      <DocmailLink />
    </Row>
  );

  const DocmailLink = (): JSX.Element => (
    <ActionLink href="https://docmail.co.uk" target="_blank">
      {T("containers.resultsCase.document.openDocmail")}
    </ActionLink>
  );

  const participantDocsContent = (): JSX.Element => (
    <>
      <Row>
        <Col width="one-half">
          <Label bold>
            {T(
              "containers.resultsCase.participantDocCard.documents.successful.label"
            )}
          </Label>
          <ul>
            <li>
              {T(
                "containers.resultsCase.participantDocCard.documents.successful.participantLetter"
              )}
            </li>
            <li>
              {T(
                "containers.resultsCase.participantDocCard.documents.successful.participantQuestionnaire"
              )}
            </li>
          </ul>
        </Col>
        <Col width="one-half">
          <Label bold>
            {T(
              "containers.resultsCase.participantDocCard.documents.unsuccessful.label"
            )}
          </Label>
          <ul>
            <li>
              {T(
                "containers.resultsCase.participantDocCard.documents.unsuccessful.participantLetter"
              )}
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        {participantDetails && (
          <Col width="one-half">
            <Label bold>{T("containers.resultsCase.document.mailTo")}</Label>
            {formatAddressToArray(participantDetails.mailingAddress).map(
              (line) => (
                <p style={{ marginBottom: 0 }} key={line}>
                  {line}
                </p>
              )
            )}
            <br />
            <DocmailLink />
          </Col>
        )}
      </Row>
    </>
  );

  const ecrfContent = (): JSX.Element => (
    <>
      <ActionLink
        href={`${env.REACT_APP_EDC_CLIENT_URL}/c/${participantDetails?.studyId}/forms`}
        target="_blank"
      >
        {T("containers.resultsCase.ECRFCard.openLink")}
      </ActionLink>
      <h5>{T("containers.resultsCase.ECRFCard.instruction.title")}</h5>
      <ol>
        <li>{T("containers.resultsCase.ECRFCard.instruction.makeSure")}</li>
        <li>
          {T("containers.resultsCase.ECRFCard.instruction.clickOnCreateForm")}
        </li>
        <li>
          {T("containers.resultsCase.ECRFCard.instruction.selectTestResult")}
        </li>
      </ol>
    </>
  );

  return (
    <main className="nhsuk-main-wrapper">
      <BackLink href="/results" aria-label={T("containers.wizard.previous")}>
        {T("containers.wizard.previous")}
      </BackLink>
      <Label isPageHeading>
        {`${participantDetails?.firstName} ${participantDetails?.lastName}`}
      </Label>
      {participantDetails && caseState && (
        <TopBar
          participantDetails={participantDetails}
          save={save}
          caseStatus={caseState.status}
          closeNote={closeNote}
          setCloseNote={setCloseNote}
          appointmentAddress={appointment?.appointmentLocation}
          appointmentAccessibility={appointment?.accessibility}
          y0Result={y0Result}
          y1Result={y1Result}
          y2Result={y2Result}
        />
      )}
      <BeforeYouBegin
        answer={idConfirmation}
        setAnswer={setIDConfirmation}
        caseReference={caseReference}
        studyID={location.state.studyID}
        reportISODate={location.state.reportISODate}
      />
      {idConfirmation === "YES" ? (
        <>
          {caseState && participantDetails && (
            <CallParticipant
              caseReference={caseReference}
              existingContactHistory={caseState.contactHistory}
              newContactHistoryEntries={newContactHistoryEntries}
              setNewContactHistoryEntries={setNewContactHistoryEntries}
              participantName={`${participantDetails.firstName} ${participantDetails.lastName}`}
              participantPhoneNumber={participantDetails.phoneNumber}
              participantMobilePhoneNumber={
                participantDetails.mobilePhoneNumber
              }
              participantAddress={participantDetails.mailingAddress}
            />
          )}
          <BackToTop />
          <CheckboxCard
            title={T("containers.resultsCase.ECRFCard.title")}
            content={ecrfContent()}
            checkBoxLabel={T("containers.resultsCase.ECRFCard.checkbox")}
            checkboxTime={ecrfFormSubmitted}
            setCheckboxTime={setECRFFormSubmitted}
            testPrefix="ecrf"
          />
          <BackToTop />
          <CheckboxCard
            title={T("containers.resultsCase.participantDocCard.title")}
            content={participantDocsContent()}
            checkBoxLabel={T(
              "containers.resultsCase.participantDocCard.checkbox"
            )}
            checkboxTime={participantDoc}
            setCheckboxTime={setParticipantDoc}
            testPrefix="participantDoc"
          />
          <BackToTop />
          <ParticipantReferralSubmission
            submittedDate={referralDates.submitted}
            onSubmitDateChange={handleReferralSubmissionDateChange}
            error={referralErrors.submittedError}
          />
          <BackToTop />
          <CheckboxCard
            title={T("containers.resultsCase.GpDocCard.title")}
            content={gpDocsContent()}
            checkBoxLabel={T("containers.resultsCase.GpDocCard.checkbox")}
            checkboxTime={gpMailed}
            setCheckboxTime={setGPMailed}
            testPrefix="gpDoc"
          />
          <BackToTop />
          {preReferralLogComplete() && (
            <>
              <ParticipantReferralLog
                dates={referralDates}
                errors={referralErrors}
                onAcceptedDateChange={handleReferralAcceptedDateChange}
                onRejectedDateChange={handleReferralRejectedDateChange}
              />
              <BackToTop />
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </main>
  );

  function isFulfilled(
    res: PromiseSettledResult<any>
  ): res is PromiseFulfilledResult<any> {
    return res.status === "fulfilled";
  }
};
