import React, { useState } from "react";
import { Button, Fieldset, Form, Input, Radios } from "nhsuk-react-components";
import { ErrorPanel } from "../../../components";
import { IPerson, IVoucher } from "interfaces";
import { T } from "i18n";
import { VoucherStatus, VoucherTypes, voucherTypes } from "enums";
import {
  anyWords,
  isValidVoucherStatus,
  isValidVoucherType,
} from "validations";
import { useAPI } from "../../../api";
import { VisitPerson } from "../VisitPerson";

interface VoucherFormProps {
  cohortId: string;
  person?: IPerson;
  onComplete?: () => void;
}

export const VoucherForm = ({
  cohortId,
  person,
  onComplete,
}: VoucherFormProps): JSX.Element => {
  const [barcode, setBarcode] = useState("");
  const [status, setStatus] = useState<VoucherStatus>("DECLINED");
  const [statusError, setStatusError] = useState("");
  const [type, setType] = useState<VoucherTypes>("ASDA");
  const [typeError, setTypeError] = useState("");
  const [barcodeError, setBarcodeError] = useState("");

  const vouchersAPI = useAPI("vouchers");

  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<Error>();

  const submitVoucher = async (voucher: IVoucher): Promise<void> => {
    setSubmitting(true);
    setSubmitError(undefined);
    return vouchersAPI
      .createVoucher(cohortId, voucher)
      .then(() => onComplete && onComplete())
      .catch(setSubmitError)
      .finally(() => setSubmitting(false));
  };

  const onSubmitForm = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();

    const typeErr = isValidVoucherType(
      type,
      T("containers.vouchers.fields.type.errors.invalid")
    );

    if (typeErr !== "") {
      setTypeError(typeErr);
      return;
    }

    const statusErr = isValidVoucherStatus(
      status,
      T("containers.vouchers.fields.status.errors.invalid")
    );

    if (statusErr !== "") {
      setStatusError(statusErr);
      return;
    }

    if (!anyWords(barcode) && status !== "DECLINED") {
      setBarcodeError(T("containers.vouchers.fields.barcode.errors.empty"));
      return;
    }

    if (status === "DECLINED") {
      return submitVoucher({ status });
    }

    return submitVoucher({ barcode, status, type });
  };

  return (
    <Form disabled={submitting} onSubmit={onSubmitForm}>
      <Fieldset>
        <Fieldset.Legend isPageHeading>
          {T("containers.vouchers.title")}
        </Fieldset.Legend>
      </Fieldset>
      {person && <VisitPerson person={person} />}
      <Radios
        name="status"
        label={T("containers.vouchers.fields.status.label")}
        aria-label={T("containers.vouchers.fields.status.label")}
        inline
        value={status}
        error={statusError}
      >
        <Radios.Radio
          checked={status === "DECLINED"}
          onChange={(e) => setStatus(e.currentTarget.value as VoucherStatus)}
          aria-label={T("containers.vouchers.fields.status.options.declined")}
          value="DECLINED"
          data-testid="decline-voucher"
        >
          {T("containers.vouchers.fields.status.options.declined")}
        </Radios.Radio>

        <Radios.Radio
          checked={status === "ACCEPTED"}
          onChange={(e) => setStatus(e.currentTarget.value as VoucherStatus)}
          aria-label={T("containers.vouchers.fields.status.options.accepted")}
          value="ACCEPTED"
          data-testid="accept-voucher"
        >
          {T("containers.vouchers.fields.status.options.accepted")}
        </Radios.Radio>
      </Radios>
      {status === "ACCEPTED" && (
        <>
          <Radios
            name="type"
            label={T("containers.vouchers.fields.type.label")}
            aria-label={T("containers.vouchers.fields.type.label")}
            inline
            value={type}
            error={typeError}
          >
            {voucherTypes.map((givenType) => (
              <Radios.Radio
                key={givenType}
                checked={type === givenType}
                onChange={(event) =>
                  setType(event.currentTarget.value as VoucherTypes)
                }
                aria-label={T(
                  `containers.vouchers.fields.type.options.${givenType.toLowerCase()}`
                )}
                value={givenType}
              >
                {T(
                  `containers.vouchers.fields.type.options.${givenType.toLowerCase()}`
                )}
              </Radios.Radio>
            ))}
          </Radios>
          <Input
            type="text"
            maxLength={255}
            disabled={submitting}
            label={T("containers.vouchers.fields.barcode.label")}
            hint={T("containers.vouchers.fields.barcode.hint")}
            aria-label={T("containers.vouchers.fields.barcode.label")}
            value={barcode}
            error={barcodeError}
            onChange={(e) => setBarcode(e.currentTarget.value)}
            width="10"
          />
        </>
      )}

      {submitError && (
        <ErrorPanel
          label={T("containers.vouchers.errors.title")}
          title={T("containers.vouchers.errors.title")}
        >
          {submitError.message}
        </ErrorPanel>
      )}

      <Button
        type="submit"
        disabled={
          (!anyWords(barcode) && status !== "DECLINED") ||
          (barcode !== "" && status === "DECLINED") ||
          submitting
        }
      >
        {submitting
          ? T("containers.vouchers.actions.submitting")
          : T("containers.vouchers.actions.submit")}
      </Button>
    </Form>
  );
};
