import React from "react";

import { AccessibilityIconProps } from "./types";

export function SignLanguageIcon({
  title,
  stroke = 4,
  color = "#005EB8",
  size = 16,
}: React.PropsWithChildren<AccessibilityIconProps>): JSX.Element {
  return (
    <svg width={size} height={size} viewBox="0 0 240 240" aria-label={title}>
      <g id="Sign_language">
        <path
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M131.09,120.6l-11.32-8.71c-4.57-3.67-5.7-9.93-2.48-14s9.57-4.33,14.18-.64l18.44,14.93"
        />
        <path
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M141,104.86,114,83.39c-4.61-3.68-5.74-9.94-2.53-14s9.57-4.33,14.19-.66l30,24.41L143.51,83,116.58,61.54c-4.57-3.67-5.71-9.94-2.49-14s9.57-4.33,14.19-.65l26.09,21.13,13.37,10.34L140.81,56.92c-4.57-3.68-5.7-9.94-2.49-14s9.57-4.33,14.19-.65l26.1,21.13,17,13.38c1.23,1-.84-5.72-1.39-9.31L190.49,49a9.15,9.15,0,0,1,6.59-12.08,11.33,11.33,0,0,1,13,7.62l7.24,49a14.72,14.72,0,0,0,5.12,9.18c12.41,13.63,10.21,23.55-1.21,37.88-7,8.74-16.63,17.25-27.69,16.8-10.36-.43-19-2.43-24.43-6.24"
        />
        <path
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M95.44,181.33l25.55-.11c6.36,0,11.5,4.54,11.49,10.1h0c0,5.57-5.19,10.06-11.51,10.06l-37.09-.12H99.35s-22-.42-40.18-.12c-13.89.24-21.87-4.63-30.87-11.25-9.59-7.08-12-20.74-11.93-32.79.07-19.73,4.91-29.55,24.53-32.63h0a15.82,15.82,0,0,0,10.48-4.26L90.51,83.92a12.21,12.21,0,0,1,16.07,2.37h0A9.85,9.85,0,0,1,104,100.88l-15.61,13c-2.88,2.65-9.13,6.89-7.44,6.9l23.31.22,36.17-.15c6.36,0,11.5,4.55,11.48,10.11h0c0,5.55-5.19,10-11.51,10.06l-37.09-.13,18.2.34,36.16-.17c6.36,0,11.49,4.56,11.48,10.12h0c0,5.56-5.19,10.06-11.51,10.06l-37.06-.17-17.12.36,41.65-.27c6.35,0,11.49,4.54,11.48,10.11h0c0,5.56-5.19,10.06-11.55,10l-37.1-.12"
        />
      </g>
    </svg>
  );
}
