import "./BackToTop.scss";
import React, { HTMLProps } from "react";
import { ArrowLeftIcon } from "nhsuk-react-components";
import { LinkButton } from "components/LinkButton";
import { T } from "i18n";

interface BaseIconSVGProps extends HTMLProps<SVGSVGElement> {
  iconType?: string;
  crossOrigin?: "" | "anonymous" | "use-credentials";
}

const ArrowUpIcon = (props: BaseIconSVGProps): JSX.Element => (
  <ArrowLeftIcon style={{ transform: "rotate(90deg)" }} {...props} />
);

const scrollToTop = (): void => {
  window.scrollTo(0, 0);
};

export const BackToTop = (): JSX.Element => {
  return (
    <div className="back-to-top">
      <ArrowUpIcon className="back-to-top__icon" />
      <LinkButton onClick={scrollToTop}>
        {T("components.backToTop.label")}
      </LinkButton>
    </div>
  );
};
