import React from "react";
import { T } from "../i18n";
import { ReadingWidth } from "nhsuk-react-components";

export const BookingClosed = (): JSX.Element => {
  return (
    <main className="nhsuk-main-wrapper">
      <ReadingWidth>
        <p>{T("components.bookingClosed.paragraph")}</p>
      </ReadingWidth>
    </main>
  );
};
