import { AppointmentRegion } from "enums";
import { AxiosInstance } from "axios";
import {
  Bookability,
  IAccessibility,
  IAddress,
  IAppointment,
  IAppointmentSlot,
  IAppointmentSlotWithAvailability,
  IAvailability,
  IUnitLocation,
} from "interfaces";
import { DateTime } from "luxon";
import {
  appointmentAlreadyExists,
  noAppointmentToRebook,
  slotAlreadyBooked,
} from "errors/createAppointment";
import { AppointmentCancelReason } from "enums/AppointmentCancelReason";

export interface APIAppointment
  extends Omit<IAppointment, "appointmentStartTime" | "appointmentEndTime"> {
  appointmentStartTime: string;
  appointmentEndTime: string;
}

interface APIRegion {
  region?: AppointmentRegion;
}

interface AppointmentSlot {
  from: string;
  to: string;
  available: number;
}

interface Availability {
  address: IAddress;
  appointmentSlots: AppointmentSlot[];
  unitCode: string;
  directions?: string;
}

const apiAvailabilityToAvailability = (input: {
  locations: Availability[];
}): IAvailability => {
  if (input.locations) {
    const locations = input.locations.map((location) => {
      if (location.appointmentSlots) {
        const appointmentSlots = location.appointmentSlots
          .filter((slot) => slot.available > 0)
          .map(
            ({
              from,
              to,
              available,
            }: AppointmentSlot): IAppointmentSlotWithAvailability => ({
              from: DateTime.fromISO(from),
              to: DateTime.fromISO(to),
              available,
            })
          );
        return {
          address: location.address,
          appointmentSlots,
          unitCode: location.unitCode,
          directions: location.directions,
        };
      }
      return {
        address: location.address,
        appointmentSlots: [],
        unitCode: location.unitCode,
        directions: location.directions,
      };
    });
    return { locations };
  }
  return {
    locations: [],
  };
};

export const apiAppointmentToAppointment = (
  input: APIAppointment
): IAppointment => {
  const appointmentStartTime = DateTime.fromISO(input.appointmentStartTime);
  const appointmentEndTime = DateTime.fromISO(input.appointmentEndTime);

  return {
    ...input,
    appointmentStartTime,
    appointmentEndTime,
  };
};

function apiRegionToRegion(apiRegion: APIRegion): AppointmentRegion {
  return apiRegion.region || AppointmentRegion.UNKNOWN;
}

export type Duration = 15 | 30 | 45 | 60 | 90;
export interface IAppointmentClient {
  createAppointment: (
    cohortId: string,
    slot: IAppointmentSlot,
    accessibility: IAccessibility
  ) => Promise<IAppointment>;
  rebookAppointment: (
    cohortId: string,
    slot: IAppointmentSlot,
    accessibility: IAccessibility
  ) => Promise<IAppointment>;
  cancelAppointment: (
    cohortId: string,
    reason: AppointmentCancelReason
  ) => Promise<void>;
  getAppointments: (cohortId: string) => Promise<IAppointment[]>;
  findAppointmentAvailability: (
    from: DateTime,
    to: DateTime,
    accessible: boolean,
    region: AppointmentRegion | undefined,
    duration: Duration,
    postcode?: string
  ) => Promise<IAvailability>;
  getAppointmentsByDateRange: (
    location: IUnitLocation,
    startDate: DateTime,
    endDate: DateTime,
    status: string
  ) => Promise<IAppointment[]>;
  checkCanBook: (cohortId: string) => Promise<Bookability>;
  getRegion: (cohortId: string) => Promise<AppointmentRegion>;
}

export class AppointmentClient implements IAppointmentClient {
  public constructor(protected readonly http: AxiosInstance) {}

  public async getRegion(cohortId: string): Promise<AppointmentRegion> {
    return this.http
      .get<APIRegion>(`/appointments/${cohortId}/region`)
      .then((response) => apiRegionToRegion(response.data));
  }

  public async createAppointment(
    cohortId: string,
    slot: IAppointmentSlot,
    accessibility: IAccessibility
  ): Promise<IAppointment> {
    return this.postAppointment("/appointments", cohortId, slot, accessibility);
  }

  public async rebookAppointment(
    cohortId: string,
    slot: IAppointmentSlot,
    accessibility: IAccessibility
  ): Promise<IAppointment> {
    return this.postAppointment(
      `/appointments/${cohortId}/rebook`,
      cohortId,
      slot,
      accessibility
    ).catch((error) => {
      const status = error.response?.status;
      if (status === 404) {
        throw new Error(noAppointmentToRebook);
      }
      throw error;
    });
  }

  private async postAppointment(
    path: string,
    cohortId: string,
    slot: IAppointmentSlot,
    accessibility: IAccessibility
  ): Promise<IAppointment> {
    return this.http
      .post<APIAppointment>(path, {
        unitCode: slot.unitCode,
        cohortId,
        from: slot.from.toJSON(),
        to: slot.to.toJSON(),
        interpreterLanguage: accessibility.interpreterLanguage,
        ...accessibility.options,
      })
      .then((response) => apiAppointmentToAppointment(response.data))
      .catch((error) => {
        const status = error.response?.status;
        if (status === 422) {
          throw new Error(slotAlreadyBooked);
        } else if (status === 409) {
          throw new Error(appointmentAlreadyExists);
        }
        throw error;
      });
  }

  public async cancelAppointment(
    cohortId: string,
    reason: AppointmentCancelReason
  ): Promise<void> {
    await this.http.put(`/appointments/${cohortId}`, {
      status: "CANCELLED",
      reason,
    });
  }

  // TODO: Review the pattern we use for "boolean with a reason why no".
  // The Promise<void> and checking the error meshes together
  // our regular error handling and the reason why you cannot book,
  // additionally a "check" function returning void is confusing
  public async checkCanBook(cohortId: string): Promise<Bookability> {
    return this.http
      .get<Bookability>(`/appointments/${cohortId}/check`)
      .then((response) => response.data);
  }

  public async getAppointments(cohortId: string): Promise<IAppointment[]> {
    const response = await this.http.get(`/appointments/${cohortId}`);
    return response.data.map(apiAppointmentToAppointment);
  }

  public async findAppointmentAvailability(
    from: DateTime,
    to: DateTime,
    accessible: boolean,
    region: AppointmentRegion | undefined,
    duration: Duration,
    postcode?: string
  ): Promise<IAvailability> {
    const response = await this.http.get("/slots", {
      params: {
        start: from.toISODate(),
        end: to.toISODate(),
        accessible,
        region,
        duration,
        postcode,
      },
    });
    return apiAvailabilityToAvailability(response.data);
  }

  public async getAppointmentsByDateRange(
    unitLocation: IUnitLocation,
    startDate: DateTime,
    endDate: DateTime,
    status: string
  ): Promise<IAppointment[]> {
    const response = await this.http.get("/appointments", {
      params: {
        unitCode: unitLocation.unitCode,
        startDate: startDate.toISODate(),
        endDate: endDate.toISODate(),
        status,
      },
    });
    return response.data.map(apiAppointmentToAppointment);
  }
}
