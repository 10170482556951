export enum AuditEventType {
  ROLES_NEW = "ROLES_NEW",
  ROLES_CHANGED = "ROLES_CHANGED",
  USERS_NEW = "USERS_NEW",
  USERS_CHANGED = "USERS_CHANGED",
  USERS_ARCHIVING = "USERS_ARCHIVING",
  USER_ROLES_CHANGED = "USER_ROLES_CHANGED",
  SIGN_IN = "SIGN_IN",
  PASSWORD_CHANGE = "PASSWORD_CHANGE",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
}
