import { VoucherTypes } from "enums";

import { ValidationFunction } from "./ValidationFunction";

const validTypes: VoucherTypes[] = [
  "ASDA",
  "SAINSBURY",
  "TESCO",
  "MORRISONS",
  "ARGOS",
];

function isVoucherType(givenType: any): givenType is VoucherTypes {
  return validTypes.includes(givenType as any);
}

export const isValidVoucherType: ValidationFunction<string> = (
  type: unknown,
  message: string
): string => (isVoucherType(type) ? "" : message);
