import { ISelectableInterpreterLanguage } from "../interfaces";
import { T } from "../i18n";

export const getInterpreterLanguageName = (
  language: ISelectableInterpreterLanguage
): string => {
  if (language === "NOT_APPLICABLE") {
    return language;
  }
  return T(`interpreterLanguages.${language}`);
};
