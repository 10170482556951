import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { resources } from "./resources";

i18next.use(initReactI18next).init({
  lng: "en",
  fallbackLng: "en",
  resources,
  saveMissing: process.env.NODE_ENV === "test",
});
export const T = i18next.t.bind(i18next);
