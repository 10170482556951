import { AxiosInstance } from "axios";
import { IEligibility } from "interfaces";

export interface IEligibilityClient {
  getEligibility(cohortId: string): Promise<IEligibility>;
  resetEligibility(cohortId: string): Promise<void>;
}

export class EligibilityClient implements IEligibilityClient {
  public constructor(protected readonly http: AxiosInstance) {}

  getEligibility(cohortId: string): Promise<IEligibility> {
    return this.http
      .get(`/people/${cohortId}/eligibility`)
      .then((response) => response.data);
  }

  resetEligibility(cohortId: string): Promise<void> {
    return this.http.delete(`/people/${cohortId}/eligibility`);
  }
}
