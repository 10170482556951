import React from "react";
import { SurveyResults } from "api";
import { T } from "i18n";
import { Trans } from "react-i18next";

interface SurveyResultsIneligibleProps {
  results: SurveyResults;
}

export const SurveyResultsIneligible = ({
  results,
}: SurveyResultsIneligibleProps): JSX.Element => {
  const phoneNumber = T("contact.phoneNumber");
  const email = T("contact.email");

  return (
    <>
      <h1 data-testid="survey-ineligible-title">
        {T("containers.survey.ineligible.title")}
      </h1>
      <p data-testid="survey-ineligible-description">
        {T("containers.survey.ineligible.description")}
      </p>
      <ul>
        {results.question1 && (
          <li data-testid="ineligible-question1-reason">{results.question1}</li>
        )}
        {results.question2 && (
          <li data-testid="ineligible-question2-reason">{results.question2}</li>
        )}
        {results.question3 && (
          <li data-testid="ineligible-question3-reason">{results.question3}</li>
        )}
        {results.age && <li data-testid="age-reason">{results.age}</li>}
      </ul>
      <h3>{T("containers.survey.ineligible.moreInformation.title")}</h3>
      <p>
        <Trans
          i18nKey="containers.survey.ineligible.moreInformation.description"
          values={{ email, phoneNumber }}
          /* eslint-disable-next-line jsx-a11y/anchor-has-content */
          components={{ mailto: <a href={`mailto:${email}`} /> }}
        />
      </p>
    </>
  );
};
