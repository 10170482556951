import React from "react";
import { AddressInput } from "../AddressInput";
import { FormValidity } from "../FormValidity";
import { IAddress, IAddressFieldIDs, IAddressSetter } from "../../interfaces";
import { Label } from "nhsuk-react-components";
import { T } from "i18n";

interface PostalAddressInputProps {
  postalAddress: IAddress;
  postalAddressChanged: IAddressSetter;
  validity?: FormValidity;
  elementIDs: IAddressFieldIDs;
}

export const PostalAddressInput = ({
  postalAddress,
  postalAddressChanged,
  validity = new FormValidity(),
  elementIDs,
}: PostalAddressInputProps): JSX.Element => {
  return (
    <>
      <Label size="m">{T("components.postalAddressInput.title")}</Label>
      <Label className="nhsuk-hint" style={{ marginBottom: "20px" }}>
        {T("components.postalAddressInput.subheading")}
      </Label>
      <AddressInput
        {...postalAddress}
        {...elementIDs}
        {...postalAddressChanged}
        addressValidity={validity}
        componentName="addressInput"
      />
    </>
  );
};
