import { AxiosInstance } from "axios";
import { IGp } from "interfaces";

export interface IGpClient {
  findGps: (input: string) => Promise<IGp[]>;
}

export class GpClient implements IGpClient {
  public constructor(protected readonly http: AxiosInstance) {}

  public async findGps(input: string): Promise<IGp[]> {
    const { data } = await this.http.get(`/practice/search/${input}`, {});
    return data;
  }
}
