import React from "react";
import { T } from "../i18n";
import { Textarea } from "nhsuk-react-components";

type LimitedTextAreaProps = React.ComponentProps<typeof Textarea> & {
  maxLength: number;
  value?: string;
};

export function LimitedTextArea({
  maxLength,
  ...rest
}: LimitedTextAreaProps): JSX.Element {
  const { value } = rest;
  return (
    <Textarea
      hint={T("components.textArea.charactersRemaining", {
        charactersLeft: maxLength - (value ? value.length : 0),
      })}
      maxLength={maxLength}
      {...rest}
    />
  );
}
