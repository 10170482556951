import React, { useEffect } from "react";
import { Address } from "../Address";
import { ErrorMessage } from "../ErrorMessage";
import { Fieldset, Label, Radios } from "nhsuk-react-components";
import { FormValidity } from "../FormValidity";
import { IGp } from "../../interfaces";
import { T } from "i18n";

interface SelectGPFormProps {
  gps: IGp[];
  findGPPostcodeError?: Error;
  selectedGP?: IGp | null;
  gpSelectionChanged?: (gp: IGp | null) => void;
  validity?: FormValidity;
  validityUpdated?: (validity: FormValidity) => void;
}

const ids = {
  selectGPAddress: "gp-address-selection",
};

export const SelectGPForm = ({
  gps,
  findGPPostcodeError,
  selectedGP,
  gpSelectionChanged,
  validity = new FormValidity(),
  validityUpdated,
}: SelectGPFormProps): JSX.Element => {
  useEffect(() => {
    const currentValidity = new FormValidity();

    const noSelectionError =
      selectedGP === undefined
        ? T("components.selectGPForm.selection.error")
        : "";
    currentValidity.addError(noSelectionError, ids.selectGPAddress);

    validityUpdated && validityUpdated(currentValidity);
  }, [selectedGP, validityUpdated]);

  const GPList = (): JSX.Element => {
    return (
      <>
        {gps.map((gp) => (
          <React.Fragment key={gp.orgId}>
            <Radios.Radio
              value={gp.orgId ? gp.orgId : ""}
              key={gp.orgId}
              data-testid={`radio-${gp.orgId}`}
              onChange={(e) =>
                e.currentTarget.checked &&
                gpSelectionChanged &&
                gpSelectionChanged(gp)
              }
              checked={selectedGP?.orgId === gp.orgId}
            >
              <Label data-testid={`radio-${gp.orgId}-label`} size="m">
                {gp.name}
              </Label>
              <Address address={gp.address} />
            </Radios.Radio>
          </React.Fragment>
        ))}
      </>
    );
  };

  if (findGPPostcodeError) {
    return (
      <ErrorMessage
        label="search-gp-error"
        title={
          <span id="error-summary-title">
            {T("components.selectGPForm.errors.title")}
          </span>
        }
      >
        {findGPPostcodeError.message}
      </ErrorMessage>
    );
  }

  return (
    <>
      <Label bold>
        {gps.length === 0
          ? T("components.selectGPForm.selection.noGpsFound")
          : T("components.selectGPForm.selection.label")}
      </Label>
      <Fieldset>
        <Radios
          id={ids.selectGPAddress}
          data-testid="select-gp"
          name="GP List"
          aria-label="GP List"
          error={validity.getFirstErrorForId(ids.selectGPAddress)?.message}
        >
          <GPList />
          <Radios.Divider>
            {T("components.selectGPForm.selection.or")}
          </Radios.Divider>
          <Radios.Radio
            key="gpNotListed"
            checked={selectedGP === null}
            onChange={(e) => gpSelectionChanged && gpSelectionChanged(null)}
            data-testid="radio-gpNotListed"
          >
            <Label>{T("components.selectGPForm.selection.notListed")}</Label>
          </Radios.Radio>
        </Radios>
      </Fieldset>
    </>
  );
};
