import { useHistory, useLocation } from "react-router-dom";
import { useCallback, useMemo } from "react";

export function useSearchParamsState(
  name: string,
  defaultValue: string | undefined
): readonly [
  searchParamState: string | undefined,
  setSearchParamState: (newValue: string | undefined) => void
] {
  const location = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const searchParamState = useMemo(() => {
    return searchParams.get(name) ?? defaultValue;
  }, [defaultValue, name, searchParams]);

  const setSearchParamState = useCallback(
    (newValue) => {
      const newSearchParams = new URLSearchParams(location.search);
      if (newValue === undefined) {
        newSearchParams.delete(name);
      } else {
        newSearchParams.set(name, newValue);
      }
      history.replace({ search: newSearchParams.toString() });
    },
    [name, location.search, history]
  );

  return [searchParamState, setSearchParamState];
}
