import { IAddress } from "interfaces";
import { formatCapitalisation } from "./formatCapitalisation";

export const formatAddress = (
  address: IAddress,
  properCase = true,
  separator = ", "
): string => {
  return formatAddressToArray(address, properCase).join(separator);
};

export const formatAddressToArray = (
  address: IAddress,
  properCase = true
): string[] => {
  return [
    properCase ? formatCapitalisation(address.address1) : address.address1,
    properCase ? formatCapitalisation(address.address2) : address.address2,
    properCase ? formatCapitalisation(address.townOrCity) : address.townOrCity,
    properCase ? formatCapitalisation(address.county) : address.county,
    address.postcode,
  ].filter((value) => value !== "");
};
