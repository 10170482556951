import React, { useState } from "react";
import { Button, Input, Label } from "nhsuk-react-components";
import { MFA, useAuth } from "auth";
import { T } from "i18n";

export const Code = ({ mfaType }: { mfaType: MFA }): JSX.Element => {
  const { isLoading, error, confirmSignIn } = useAuth();
  const [code, setCode] = useState("");

  const stringError = (): string => {
    if (!error) {
      return "";
    }
    switch (error.code) {
      case "CodeMismatchException":
        return T("containers.login.code.errors.codeMismatch");
      default:
        return T("containers.login.code.errors.unknown");
    }
  };

  const description = (): string => {
    switch (mfaType) {
      case "SMS_MFA":
        return T("containers.login.code.SMS");
      case "SOFTWARE_TOKEN_MFA":
        return T("containers.login.code.TOTP");
    }
  };

  return (
    <>
      <Label className="nhsuk-u-margin-top-5" size="m">
        {T("containers.login.code.title")}
      </Label>
      <Label className="nhsuk-u-margin-top-5">{description()}</Label>
      <Input
        type="text"
        data-testid="code"
        label={T("containers.login.code.label")}
        aria-label={T("containers.login.code.label")}
        value={code}
        onChange={(e) => setCode(e.currentTarget.value)}
        error={stringError()}
        width="20"
      />
      <Button
        disabled={isLoading || code === ""}
        data-testid="confirmCode"
        onClick={() => confirmSignIn(code, mfaType)}
      >
        {isLoading
          ? T("containers.login.code.button.loading")
          : T("containers.login.code.button.label")}
      </Button>
    </>
  );
};
