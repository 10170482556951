import React from "react";
import { Button } from "nhsuk-react-components";
import { T } from "../../i18n";
import { useHistory } from "react-router-dom";

interface DeleteInviteeProps {
  cohortId: string;
}

export const DeleteInvitee = ({
  cohortId,
}: DeleteInviteeProps): JSX.Element => {
  const history = useHistory();

  return (
    <>
      <h4>{T("components.person.deleteData.title")}</h4>
      <Button
        data-testid="delete-invitee-button"
        style={{ background: "#d5281b" }}
        onClick={() => {
          history.push(`/data/${cohortId}/delete`);
        }}
      >
        {T("components.person.deleteData.button")}
      </Button>
    </>
  );
};
