import React, { useEffect, useState } from "react";
import { AppointmentRegion } from "enums";
import { DateTime } from "luxon";
import { Duration } from "../../api/appointment";
import { IAvailabilityLocation } from "interfaces";
import { Loader } from "components";
import { LocationPicker } from "./LocationPicker";
import { T } from "i18n";
import { useAPI } from "api";

type RegionLocationPickerProps = {
  accessibilityRequired: boolean;
  from: DateTime;
  to: DateTime;
  duration: Duration;
  region?: AppointmentRegion;
  error: string;
  setError: (message: string) => void;
  updateLocation: (location: IAvailabilityLocation) => void;
  setShowNext: (state: boolean) => void;
};

function sortLocationsByClosestDate(
  locations: IAvailabilityLocation[]
): IAvailabilityLocation[] {
  return [...locations].sort(
    (a, b): number =>
      (a.appointmentSlots[0]?.from?.toMillis() || 0) -
      (b.appointmentSlots[0]?.from?.toMillis() || 0)
  );
}

export const RegionLocationPicker = ({
  accessibilityRequired,
  from,
  to,
  duration,
  region,
  error,
  setError,
  updateLocation,
  setShowNext,
}: RegionLocationPickerProps): JSX.Element => {
  const appointmentAPI = useAPI("appointment");

  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState<IAvailabilityLocation[]>([]);

  setShowNext(!loading && locations.length > 0);

  useEffect(() => {
    if (region === undefined) {
      setError(T("containers.booking.fields.search.errors.regionNotSelected"));
      return;
    }
    setLoading(true);
    appointmentAPI
      .findAppointmentAvailability(
        from,
        to,
        accessibilityRequired,
        region,
        duration
      )
      .then(({ locations }) => {
        setLocations(sortLocationsByClosestDate(locations));
      })
      .catch(() => {
        setError(
          T("containers.booking.fields.location.errors.failedToLoadSlots")
        );
      })
      .finally(() => setLoading(false));
  }, [
    appointmentAPI,
    region,
    from,
    to,
    accessibilityRequired,
    setError,
    duration,
  ]);

  if (loading) {
    return <Loader panel={false} />;
  }

  return (
    <LocationPicker
      locations={locations}
      updateLocation={updateLocation}
      error={error}
    />
  );
};
