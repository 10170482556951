import React from "react";
import { ActionLink } from "nhsuk-react-components";
import { Link } from "react-router-dom";
import { T } from "i18n";
import { useAuth } from "../../auth";

export const WelcomeEMSStaff = (): JSX.Element => {
  const { user } = useAuth();

  if (!user || !user.roles.includes("EMS Unit Staff")) {
    return <></>;
  }

  return (
    <div data-testid="welcome-ems-staff">
      <ActionLink asElement={Link} data-testid="search-link" to="/search">
        {T("containers.welcomeAuthenticated.portals.emsUnitStaff.links.search")}
      </ActionLink>
      <ActionLink
        asElement={Link}
        data-testid="register-link"
        to="/registration"
      >
        {T(
          "containers.welcomeAuthenticated.portals.emsUnitStaff.links.register"
        )}
      </ActionLink>
      <ActionLink asElement={Link} data-testid="checkin-link" to="/check-in">
        {T(
          "containers.welcomeAuthenticated.portals.emsUnitStaff.links.checkIn"
        )}
      </ActionLink>
    </div>
  );
};
