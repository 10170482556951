import React from "react";

import { AccessibilityIconProps } from "./types";

export function StepFreeIcon({
  title,
  stroke = 4,
  color = "#005EB8",
  size = 16,
}: React.PropsWithChildren<AccessibilityIconProps>): JSX.Element {
  return (
    <svg width={size} height={size} viewBox="0 0 240 240" aria-label={title}>
      <g id="Disabled">
        <g id="disable_person" data-name="disable person">
          <path
            fill="none"
            stroke={color}
            strokeWidth={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M120.24,68.41a24,24,0,1,0-24.1-23.9A23.88,23.88,0,0,0,120.24,68.41Z"
          />
          <g>
            <path
              fill="none"
              stroke={color}
              strokeWidth={stroke}
              strokeMiterlimit="round"
              d="M166.44,100.81a6.47,6.47,0,0,0-.3-1,19.59,19.59,0,0,0-1.7-4.3,4.35,4.35,0,0,0-.4-.7c-3.2-5.9-10.7-15.3-27.3-18.4-.3-.1-.6-.1-.8-.2a4.53,4.53,0,0,0-.8-.1,47.93,47.93,0,0,0-6.9-.6v.1a10.87,10.87,0,0,0-1.8-.1h-12.2c-.8,0-1.5.1-2.3.1a36.94,36.94,0,0,0-6.9.6,4.53,4.53,0,0,0-.8.1,3.09,3.09,0,0,0-.8.2c-16.7,3.1-24.2,12.5-27.3,18.4a2.51,2.51,0,0,1-.4.7,22,22,0,0,0-1.7,4.3c-.1.5-.3.9-.3,1a64.41,64.41,0,0,0-1.7,15.9v22.7c0,3.6,3.6,6.5,8.1,6.5s8.1-2.9,8.1-6.5v-14.4s-.54-15.88,0-20.9a20.35,20.35,0,0,1,2.42-6.85,6.19,6.19,0,1,1,9.81,6.34v63a10.43,10.43,0,0,0,7.17,2.69,10.17,10.17,0,0,0,10.4-9.9l-.2-4.7h5.5l.2,54.7a10.42,10.42,0,0,0,20.8-.1l-.3-67.5v-4.3l-.2-43.5a14.43,14.43,0,0,1,3.3,3.2c.6.7,1.4,1.7,1.6,2a12,12,0,0,1,1.9,4.6c1.9,4.6,1,20.9,1,20.9v14.4c0,3.6,3.6,6.5,8.1,6.5s8.1-2.9,8.1-6.5v-22.6A50.13,50.13,0,0,0,166.44,100.81Z"
            />
            <path
              fill="none"
              stroke={color}
              strokeWidth={stroke}
              strokeMiterlimit="round"
              d="M96.79,105h-.08a6.16,6.16,0,0,1-3.16-.86V219.59h6.92V166.72"
            />
            <path
              fill="none"
              stroke={color}
              strokeWidth={stroke}
              strokeMiterlimit="round"
              d="M92.74,94.61a17.17,17.17,0,0,0-1.6,2,6.65,6.65,0,0,0-.48.75,6.41,6.41,0,0,0-.17,1.46,6.22,6.22,0,0,0,3.06,5.31"
            />
            <path
              fill="none"
              stroke={color}
              strokeWidth={stroke}
              strokeMiterlimit="round"
              d="M96.79,105a6.12,6.12,0,0,0,3.68-1.28"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
