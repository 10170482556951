import React, { useCallback, useEffect, useState } from "react";
import { Button } from "nhsuk-react-components";
import { isActiveUser, isArchivedUser, IUser } from "interfaces";
import { T } from "i18n";
import { useAPI } from "api";
import { useHistory } from "react-router";
import { TabSet } from "nhsuk-react-components-extensions";
import { ArchivedUserTable } from "containers/User/ArchivedUserTable";
import { ActiveUserTable } from "containers/User/ActiveUserTable";

type Tab = "ACTIVE" | "ARCHIVED";

export function Users(): JSX.Element {
  const [users, setUsers] = useState<IUser[]>();
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState<Tab>("ACTIVE");
  const userAPI = useAPI("user");
  const { push } = useHistory();

  const loadUsers = useCallback((): Promise<void> => {
    return userAPI.list().then((users) => setUsers(users));
  }, [userAPI]);

  useEffect(() => {
    setLoading(true);
    loadUsers().finally(() => setLoading(false));
  }, [loadUsers]);

  const sync = (): void => {
    setLoading(true);
    userAPI
      .sync()
      .then(() => loadUsers())
      .finally(() => setLoading(false));
  };

  const goToUserProfile = (id: string): void => {
    push(`/user/${id}`);
  };

  return (
    <main className="nhsuk-main-wrapper">
      <Button
        className="nhsuk-button-margin-right"
        disabled={loading}
        data-testid="sync"
        onClick={sync}
      >
        {loading
          ? T("containers.user.actions.loading")
          : T("containers.user.actions.sync")}
      </Button>
      <Button
        data-testid="new-user"
        secondary
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          push("/user/new");
        }}
      >
        {T("containers.user.actions.addNewUser")}
      </Button>
      <TabSet style={{ backgroundColor: "inherit" }}>
        <TabSet.Tab style={{ backgroundColor: "inherit" }}>
          <strong>{T("containers.user.tab.header")}</strong>
        </TabSet.Tab>
        <TabSet.Tab
          onClick={() => setTab("ACTIVE")}
          selected={tab === "ACTIVE"}
          active={tab === "ACTIVE"}
        >
          {T("containers.user.tab.active")}
        </TabSet.Tab>
        <TabSet.Tab
          onClick={() => setTab("ARCHIVED")}
          selected={tab === "ARCHIVED"}
          active={tab === "ARCHIVED"}
        >
          {T("containers.user.tab.archived")}
        </TabSet.Tab>
      </TabSet>
      {tab === "ACTIVE" ? (
        <ActiveUserTable
          users={users?.filter(isActiveUser)}
          onUserClicked={goToUserProfile}
        />
      ) : (
        <ArchivedUserTable
          users={users?.filter(isArchivedUser)}
          onUserClicked={goToUserProfile}
        />
      )}
    </main>
  );
}
