import React from "react";
import { IPerson } from "interfaces";
import { SummaryList } from "nhsuk-react-components";
import { T } from "../../i18n";

interface VisitPersonProps {
  person?: IPerson;
}

export const VisitPerson = ({ person }: VisitPersonProps): JSX.Element => (
  <SummaryList>
    <SummaryList.Row>
      <SummaryList.Key>{T("containers.visit.person.name")}</SummaryList.Key>
      <SummaryList.Value data-testid="name">
        {person?.firstName}
        &nbsp;
        {person?.lastName}
      </SummaryList.Value>
    </SummaryList.Row>
    <SummaryList.Row>
      <SummaryList.Key>
        {T("containers.visit.person.dateOfBirth")}
      </SummaryList.Key>
      <SummaryList.Value data-testid="date-of-birth">
        {person?.dateOfBirth.toFormat("dd/LL/yyyy")}
      </SummaryList.Value>
    </SummaryList.Row>
    <SummaryList.Row>
      <SummaryList.Key>{T("containers.visit.person.cohortId")}</SummaryList.Key>
      <SummaryList.Value data-testid="cohort-id">
        {person?.cohortId}
      </SummaryList.Value>
    </SummaryList.Row>
    <SummaryList.Row>
      <SummaryList.Key>
        {T("containers.visit.person.participantId")}
      </SummaryList.Key>
      <SummaryList.Value data-testid="participant-id">
        {person?.studyId}
      </SummaryList.Value>
    </SummaryList.Row>
  </SummaryList>
);
