import React, { useMemo, useState } from "react";
import { Address } from "components";
import { ErrorSummary, Label, Radios } from "nhsuk-react-components";
import { IAvailabilityLocation } from "interfaces";
import { T } from "i18n";
import { formatAddress } from "../../formatters";

type LocationPickerProps = {
  locations: IAvailabilityLocation[];
  error?: string;
  updateLocation?: (location: IAvailabilityLocation) => void;
};

export const LocationPicker = ({
  locations,
  updateLocation,
  error = "",
}: LocationPickerProps): JSX.Element => {
  const [selectedLocationKey, setSelectedLocationKey] = useState<string>();

  const uniqueKeyForLocation = (location: IAvailabilityLocation): string => {
    return `${formatAddress(location.address, false)}-${location.unitCode}`;
  };

  const selectableLocations = useMemo(
    () =>
      locations.map((location) => {
        const key = uniqueKeyForLocation(location);
        return (
          <Radios.Radio
            key={key}
            data-testid={key}
            value={key}
            checked={selectedLocationKey === key}
            onChange={() => {
              setSelectedLocationKey(key);
              updateLocation && updateLocation(location);
            }}
          >
            <Address
              address={location.address}
              wrappers={{ address1: (address1) => <strong>{address1}</strong> }}
            />
          </Radios.Radio>
        );
      }),
    [locations, selectedLocationKey, updateLocation]
  );

  if (locations.length === 0 && error === "") {
    return (
      <p data-testid="no-locations">
        {T("containers.booking.fields.location.errors.noAvailableLocations")}
      </p>
    );
  }

  return (
    <>
      {error !== "" && (
        <ErrorSummary>
          <ErrorSummary.Title>
            {T("containers.booking.fields.location.errors.title")}
          </ErrorSummary.Title>
          <ErrorSummary.List>
            <ErrorSummary.Item href="#locations">{error}</ErrorSummary.Item>
          </ErrorSummary.List>
        </ErrorSummary>
      )}
      <Label size="m">{T("containers.booking.fields.location.subtitle")}</Label>
      <Radios id="locations" error={error}>
        {selectableLocations}
      </Radios>
    </>
  );
};
