import React from "react";
import {
  DateInputWithDefault,
  DateTimeInput,
  EnumSet,
  LongEnumInput,
  ShortEnumInput,
  TextInput,
} from "./inputs";
import { FormFieldProps as ECRFFormFieldProps } from "@grailbio/components-ecrf";

interface FormFieldProps extends ECRFFormFieldProps {
  renderOverride?: (props: ECRFFormFieldProps) => JSX.Element;
}

export function FormField({
  renderOverride,
  ...props
}: FormFieldProps): JSX.Element {
  const { definition } = props;
  let component: JSX.Element;
  if (renderOverride) {
    const Component = renderOverride;
    return <Component {...props} />;
  }
  switch (props.definition.type) {
    case "TEXT":
      component = <TextInput {...props} />;
      break;
    case "INT":
      component = <TextInput {...props} />;
      break;
    case "FLOAT":
      component = <TextInput {...props} />;
      break;
    case "ENUM": {
      const { enumOptions } = definition;
      if (enumOptions && enumOptions.length < 6) {
        component = <ShortEnumInput {...props} />;
        break;
      }
      component = <LongEnumInput {...props} />;
      break;
    }
    case "ENUMSET":
      component = <EnumSet {...props} />;
      break;
    case "DATETIME":
      component = <DateTimeInput {...props} />;
      break;
    case "DATE":
      component = <DateInputWithDefault {...props} />;
      break;
  }
  return <div data-testid={definition.apiName}>{component}</div>;
}
