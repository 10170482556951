import "./ConsentForm.scss";
import React, { useState } from "react";
import {
  Button,
  Col,
  Fieldset,
  Form,
  Input,
  Radios,
  Row,
  Select,
  SummaryList,
} from "nhsuk-react-components";
import {
  ConsentDocVersion,
  consentDocVersions,
} from "../../../../enums/ConsentDocVersion";
import { DateTimePickerWithDefault, ErrorPanel } from "components";
import { IPerson } from "interfaces/IPerson";
import { T } from "i18n";
import { VisitPerson } from "containers/Visit/VisitPerson";
import { dateTimeInputToDateTime } from "helpers";
import { signedAtInFuture, witnessedAtInFuture } from "errors/consent";
import { useAPI } from "api";
import { useAuth } from "../../../../auth";
import { useDateTime } from "hooks";

interface ConsentProps {
  cohortId: string;
  disabled: boolean;
  onSubmit(): void;
  person?: IPerson;
}

export const ConsentForm = (props: ConsentProps): JSX.Element => {
  const { cohortId, disabled, onSubmit, person } = props;
  const { user } = useAuth();
  const [version, setVersion] = useState<ConsentDocVersion>();
  const [firstName, setFirstName] = useState<string>(
    (user && user.firstName) || ""
  );
  const [lastName, setLastName] = useState<string>(
    (user && user.lastName) || ""
  );
  const [researchOptIn, setResearchOptIn] = useState<boolean>();
  const [signedError, setSignedError] = useState<string>("");
  const [witnessError, setWitnessError] = useState<string>("");
  const [error, setError] = useState<string>("");

  const [signedInput, , signedSetter, validateSigned, isSignedValid] =
    useDateTime();

  const [witnessInput, , witnessSetter, validateWitness, isWitnessValid] =
    useDateTime();

  const consentAPI = useAPI("consent");
  const submitConsent = (): void => {
    setError("");
    setWitnessError("");
    setSignedError("");
    const formHasErrors = !validateSigned() || !validateWitness();
    if (
      !formHasErrors &&
      researchOptIn !== undefined &&
      version !== undefined
    ) {
      consentAPI
        .submit({
          cohortId,
          version,
          signedAt: dateTimeInputToDateTime(signedInput).toString(),
          researchOptIn,
          firstName,
          lastName,
          witnessedAt: dateTimeInputToDateTime(witnessInput).toString(),
        })
        .then(() => onSubmit())
        .catch((error) => {
          switch (error.message) {
            case witnessedAtInFuture:
              setWitnessError(T("containers.consent.errors.timeInFuture"));
              break;
            case signedAtInFuture:
              setSignedError(T("containers.consent.errors.timeInFuture"));
              break;
            default:
              setError(T("containers.consent.errors.title"));
              break;
          }
        });
    }
  };

  const formIsFilled =
    isSignedValid &&
    isWitnessValid &&
    version !== undefined &&
    researchOptIn !== undefined;

  const witnessNameExists = user && user.firstName && user.lastName;

  return (
    <>
      <h1 data-testid="consent-title">{T("containers.consent.title")}</h1>
      {person && <VisitPerson person={person} />}

      <Form disabled={disabled} data-testid="consent-form">
        <Fieldset data-testid="document-information">
          <Fieldset.Legend size="m">
            {T("containers.consent.fields.document.title")}
          </Fieldset.Legend>
          <Select
            data-testid="consent-form-version"
            disabled={disabled}
            value={version}
            defaultValue=""
            label={T("containers.consent.fields.version.label")}
            onChange={(e) =>
              setVersion(e.currentTarget.value as ConsentDocVersion)
            }
          >
            <Select.Option value="" disabled>
              {T("containers.consent.fields.version.placeholder")}
            </Select.Option>
            {consentDocVersions.map((version) => (
              <Select.Option key={version} value={version}>
                {version}
              </Select.Option>
            ))}
          </Select>
        </Fieldset>
        <Fieldset data-testid="consent-participant-information">
          <Fieldset.Legend size="l">
            {T("containers.consent.fields.participant.title")}
          </Fieldset.Legend>
          <Fieldset.Legend size="m">
            {T("containers.consent.fields.participantConsentDate.title")}
          </Fieldset.Legend>
          <div data-testid="consent-form-signed-at">
            <DateTimePickerWithDefault
              {...signedInput}
              {...signedSetter}
              error={signedError}
            />
          </div>
        </Fieldset>
        <Fieldset>
          <Fieldset.Legend size="s">
            {T("containers.consent.fields.researchOptIn.text")}
          </Fieldset.Legend>
          <Radios>
            <Radios.Radio
              data-testid="optional-clause-true"
              onChange={() => setResearchOptIn(true)}
              aria-label={T(
                "containers.consent.fields.researchOptIn.options.true"
              )}
              value={(researchOptIn === true).toString()}
            >
              {T("containers.consent.fields.researchOptIn.options.true")}
            </Radios.Radio>
            <Radios.Radio
              data-testid="optional-clause-false"
              onChange={() => setResearchOptIn(false)}
              aria-label={T(
                "containers.consent.fields.researchOptIn.options.false"
              )}
              value={(researchOptIn === false).toString()}
            >
              {T("containers.consent.fields.researchOptIn.options.false")}
            </Radios.Radio>
          </Radios>
        </Fieldset>
        <Fieldset data-testid="consent-witness-information">
          <Fieldset.Legend size="l">
            {T("containers.consent.fields.witness.title")}
          </Fieldset.Legend>
        </Fieldset>
        {witnessNameExists && (
          <SummaryList data-testid="consent-form-witness-name-summary">
            <SummaryList.Row>
              <SummaryList.Key className="consent-witness-summary-list-key">
                {T("containers.consent.fields.name.title")}
              </SummaryList.Key>
              <SummaryList.Value>
                {user && `${user.firstName} ${user.lastName}`}
              </SummaryList.Value>
            </SummaryList.Row>
          </SummaryList>
        )}
        {!witnessNameExists && (
          <Fieldset>
            <Fieldset.Legend size="m">
              {T("containers.consent.fields.name.title")}
            </Fieldset.Legend>
            <Row>
              <Col width="one-half">
                <Input
                  data-testid="consent-form-firstName"
                  width={10}
                  disabled={disabled}
                  value={firstName}
                  type="text"
                  label={T("containers.consent.fields.name.first")}
                  onChange={(event) => setFirstName(event.currentTarget.value)}
                />
              </Col>
              <Col width="one-half">
                <Input
                  data-testid="consent-form-lastName"
                  width={10}
                  disabled={disabled}
                  value={lastName}
                  type="text"
                  label={T("containers.consent.fields.name.last")}
                  onChange={(event) => setLastName(event.currentTarget.value)}
                />
              </Col>
            </Row>
          </Fieldset>
        )}
        <Fieldset>
          <Fieldset.Legend size="m">
            {T("containers.consent.fields.witnessConsentDate.title")}
          </Fieldset.Legend>
          <div data-testid="consent-form-witnessed-at">
            <DateTimePickerWithDefault
              {...witnessInput}
              {...witnessSetter}
              error={witnessError}
            />
          </div>
        </Fieldset>
        <Button
          data-testid="consent-form-submit"
          type="submit"
          aria-label={T("containers.consent.actions.submit")}
          onClick={(event: React.MouseEvent) => {
            event.preventDefault();
            submitConsent();
          }}
          disabled={disabled || !formIsFilled}
        >
          {T("containers.consent.actions.submit")}
        </Button>
        {error !== "" && (
          <ErrorPanel
            label="submission-error"
            title={
              <span id="error-summary-title">
                {T("components.dateTimeInput.error.title")}
              </span>
            }
          >
            <p>{error}</p>
          </ErrorPanel>
        )}
      </Form>
    </>
  );
};
