/* tslint:disable */

import {
  ITimeError,
  ITimeInputValue,
  ITimeSetter,
  ITimeValidator,
} from "../interfaces";
import { T } from "i18n";
import { isValidTime, isValidTimeBoolean } from "../validations";
import { useMemo, useState } from "react";

export const useTime = (): readonly [
  ITimeInputValue,
  ITimeError,
  ITimeSetter,
  ITimeValidator,
  boolean
] => {
  const [time, setTime] = useState<ITimeInputValue>({
    hour: "",
    minute: "",
  });
  const [timeError, setTimeError] = useState("");

  const validateTime = (): boolean => {
    const errMessage = isValidTime(
      time,
      T("components.timeInput.errors.invalid")
    );
    setTimeError(errMessage);
    return errMessage === "";
  };

  const isValid = useMemo(() => {
    return isValidTimeBoolean(time);
  }, [time]);

  return [time, timeError, setTime, validateTime, isValid] as const;
};
