import React, { useCallback, useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { Button, ErrorMessage } from "nhsuk-react-components";
import { ECRF, ErrorPanel, Loader } from "../../components";
import { FormAnswer } from "@grailbio/components-ecrf";
import { IForm } from "../../interfaces";
import { T } from "../../i18n";
import { useAPI } from "../../api";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";

const FORM_TYPE = "PARTICIPANT_STATUS";
const EVENT_TYPE = "D1";

function removeTombstonedOption(form: IForm): IForm {
  const { definition } = form;

  const newDefinition = cloneDeep(definition);
  for (const section of newDefinition.sections) {
    for (const fieldGroup of section.input.fieldGroups) {
      for (const field of fieldGroup.fields) {
        if (field.apiName === "participant_status_status") {
          field.enumOptions = field.enumOptions?.filter(
            (option) => option.value !== "TOMBSTONED"
          );
        }
      }
    }
  }
  return {
    ...form,
    definition: newDefinition,
  };
}

export const Withdrawal = (): JSX.Element => {
  const { cohortId } = useParams<{ cohortId: string }>();
  const history = useHistory();

  const formAPI = useAPI("forms");

  const [form, setForm] = useState<IForm>();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [getFormError, setGetFormError] = useState<Error>();
  const [saveFormError, setSaveFormError] = useState<Error>();

  const getParticipantStatusForm = useCallback((): void => {
    setLoading(true);
    formAPI
      .getForm(cohortId, FORM_TYPE, EVENT_TYPE)
      .then((f) => setForm(removeTombstonedOption(f)))
      .catch(setGetFormError)
      .finally(() => setLoading(false));
  }, [formAPI, cohortId]);

  useEffect(() => {
    getParticipantStatusForm();
  }, [getParticipantStatusForm]);

  const onSubmit = (answers: FormAnswer[]): void => {
    if (!form) {
      throw new Error("form is not set");
    }

    setSaveFormError(undefined);
    setSubmitting(true);
    formAPI
      .saveForm(cohortId, {
        ...form,
        answers,
      })
      .then(() => {
        setSubmitting(false);
        history.push(`/participant/${cohortId}`);
      })
      .catch((err) => {
        setSubmitting(false);
        setSaveFormError(err);
      });
  };

  if (getFormError) {
    return (
      <ErrorPanel title={T("containers.withdrawal.loadingErrorTitle")}>
        {getFormError.message}
      </ErrorPanel>
    );
  }

  if (loading) {
    return <Loader />;
  }

  if (form === undefined) {
    return <ErrorMessage>{T("containers.withdrawal.error")}</ErrorMessage>;
  }

  return (
    <>
      {saveFormError && (
        <ErrorPanel title={T("containers.withdrawal.savingFormErrorTitle")}>
          {T("containers.withdrawal.error")}
        </ErrorPanel>
      )}
      <main className="nhsuk-main-wrapper">
        <ECRF
          definition={form.definition}
          onSubmit={onSubmit}
          initialAnswers={form.answers || []}
          disabled={submitting}
        />
        <Button
          secondary
          onClick={() => {
            history.push(`/participant/${cohortId}`);
          }}
          data-testid="cancel-button"
        >
          {T("containers.withdrawal.cancel")}
        </Button>
      </main>
    </>
  );
};
