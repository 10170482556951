import React, { useMemo } from "react";
import { DateTime } from "luxon";
import { IUnitLocation } from "interfaces";
import { T } from "i18n";
import { UnitPicker } from "./UnitPicker";
import { useHistory } from "react-router-dom";

export function CheckIn(): JSX.Element {
  // FIXME(ctang): The use of now here potentially incorrectly filters out results
  //  eg. when it is 1/7 00:00 BST, as the server considers dates as UTC, results of 1/7 are returned, but not results
  //  from 30/6 23:00 UTC to 30/6 23:59 UTC which should be included as part of the results
  const now = useMemo((): DateTime => DateTime.now(), []);
  const { push } = useHistory();
  return (
    <div className="nhsuk-main-wrapper">
      <h1>
        {T("containers.checkIn.title")}
        <br />
        {now.toFormat("cccc d LLLL yyyy")}
      </h1>
      <UnitPicker
        date={now}
        onSelectUnit={(location: IUnitLocation): void =>
          push({
            pathname: `/check-in/${location.unitCode}/schedule`,
            state: { unitAddressLine1: location.address.address1 },
          })
        }
      />
    </div>
  );
}
