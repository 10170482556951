import React from "react";
import { Loader } from "../../components";
import { Role } from "../IUser";
import { hasRole } from "../hasRole";
import { useAuth } from "../useAuth";

interface CommonProps {
  children: React.ReactNode;
}

interface AllowedRolesProps {
  mode: "allowRoles";
  allowedRoles: Role[];
}

interface BlockedRolesProps {
  mode: "blockRoles";
  blockedRoles: Role[];
}

interface HasRolesProps {
  mode: "hasRoles";
}

interface NoRolesProps {
  mode: "noRoles";
}

interface AuthenticatedProps {
  mode: "authenticated";
}

interface UnauthenticatedProps {
  mode: "unauthenticated";
}

export type AllowedRolesRestrictedProps = CommonProps & AllowedRolesProps;
export type BlockedRolesRestrictedProps = CommonProps & BlockedRolesProps;
export type HasRolesRestrictedProps = CommonProps & HasRolesProps;
export type NoRolesRestrictedProps = CommonProps & NoRolesProps;
export type AuthenticatedRestrictedProps = CommonProps & AuthenticatedProps;
export type UnauthenticatedRestrictedProps = CommonProps & UnauthenticatedProps;

export type RestrictedProps =
  | AllowedRolesRestrictedProps
  | BlockedRolesRestrictedProps
  | HasRolesRestrictedProps
  | NoRolesRestrictedProps
  | AuthenticatedRestrictedProps
  | UnauthenticatedRestrictedProps;

export const Restricted = (props: RestrictedProps): JSX.Element | null => {
  const { user, isLoading } = useAuth();

  const { mode, children } = props;

  // TODO: Preserving the behaviour before refactoring. Might display <Loader> when auth state is loading despite mode
  if (isLoading && mode === "unauthenticated") {
    return <Loader />;
  }

  const restrictedContent = <>{children}</>;

  switch (props.mode) {
    case "authenticated": {
      if (user) {
        return restrictedContent;
      }
      break;
    }
    case "unauthenticated": {
      if (!user) {
        return restrictedContent;
      }
      break;
    }
    case "allowRoles": {
      const { allowedRoles } = props;
      if (user && hasRole(user, allowedRoles)) {
        return restrictedContent;
      }
      break;
    }
    case "blockRoles": {
      const { blockedRoles } = props;
      if (user && !hasRole(user, blockedRoles)) {
        return restrictedContent;
      }
      break;
    }
    case "hasRoles": {
      if (user && user.roles.length > 0) {
        return restrictedContent;
      }
      break;
    }
    case "noRoles": {
      if (user && user.roles.length === 0) {
        return restrictedContent;
      }
      break;
    }
    default:
      break;
  }

  return null;
};
