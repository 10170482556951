import React, { ReactElement, useState } from "react";
import { Card, Checkboxes, Label } from "nhsuk-react-components";
import { DateTime } from "luxon";

export const CheckboxCard = ({
  title,
  content,
  checkBoxLabel,
  checkboxTime,
  setCheckboxTime,
  testPrefix,
}: {
  title: string;
  content: ReactElement;
  checkBoxLabel: string;
  checkboxTime: DateTime | undefined;
  setCheckboxTime: (input: DateTime | undefined) => void;
  testPrefix: string;
}): JSX.Element => {
  const [checkbox, setCheckBox] = useState<boolean>(checkboxTime !== undefined);

  const checkBoxClicked = (): void => {
    checkbox ? setCheckboxTime(undefined) : setCheckboxTime(DateTime.now());
    setCheckBox(!checkbox);
  };

  return (
    <Card feature>
      <Card.Content>
        <Card.Heading>{title}</Card.Heading>
        {content}
        <Checkboxes>
          <Checkboxes.Box
            checked={checkbox}
            onClick={checkBoxClicked}
            data-testid={`${testPrefix}Checkbox`}
          >
            {checkBoxLabel}
          </Checkboxes.Box>
        </Checkboxes>
        {checkboxTime ? (
          <Label data-testid={`${testPrefix}Time`}>
            {checkboxTime.toFormat("dd MMM yyyy hh:mm a")}
          </Label>
        ) : (
          <></>
        )}
      </Card.Content>
    </Card>
  );
};
