import { AxiosInstance, AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { IVideo } from "interfaces";

export interface IVideoClient {
  create(data: ISubmitVideo): Promise<void>;
  getVideoSubmissions(cohortId: string): Promise<IVideo[]>;
  getVideo(
    filename: string,
    onDownloadProgress?: (event: any) => void
  ): Promise<AxiosResponse>;
}

export interface ISubmitVideo {
  cohortId: string;
  version: string;
}

export class VideoClient implements IVideoClient {
  public constructor(protected readonly http: AxiosInstance) {}

  async getVideo(
    filename: string,
    onDownloadProgress?: (event: any) => void
  ): Promise<AxiosResponse> {
    return this.http.get(filename, {
      baseURL: `${window.location.origin}/videos`,
      responseType: "blob",
      timeout: undefined,
      onDownloadProgress,
    });
  }

  async getVideoSubmissions(cohortId: string): Promise<IVideo[]> {
    const { data } = await this.http.get(`/people/${cohortId}/videos`);
    return data.map((video: { submittedAt: string }) => ({
      ...video,
      submittedAt: DateTime.fromISO(video.submittedAt),
    }));
  }

  async create({ cohortId, version }: ISubmitVideo): Promise<void> {
    await this.http.post(`/people/${cohortId}/videos`, {
      cohortId,
      version,
    });
  }
}
