import { useAPI } from "api";
import { useCallback, useEffect, useState } from "react";

export const useQueryRegistrationClosed = (): boolean | undefined => {
  const [isRegistrationClosed, setIsRegistrationClosed] = useState<boolean>();
  const infoAPI = useAPI("info");

  const updateRegistrationClosed = useCallback(async (): Promise<void> => {
    const finished = await infoAPI.queryRegistrationClosed();
    setIsRegistrationClosed(finished);
  }, [infoAPI]);

  useEffect(() => {
    updateRegistrationClosed();
  }, [updateRegistrationClosed]);

  return isRegistrationClosed;
};
