import React, { useEffect, useState } from "react";
import { ECRF, Loader } from "components";
import { ErrorMessage } from "nhsuk-react-components";
import { FormAnswer } from "@grailbio/components-ecrf";
import { IForm, IPerson } from "interfaces";
import { VisitPerson } from "containers/Visit/VisitPerson";
import { useAPI } from "api";

interface EligibilityFormProps {
  cohortId: string;
  onComplete?: () => void;
  person?: IPerson;
  heading?: string;
}

const FORM_TYPE = "PARTICIPANT_ELIGIBILITY";

export function EligibilityForm({
  cohortId,
  onComplete,
  person,
  heading,
}: EligibilityFormProps): JSX.Element {
  const formAPI = useAPI("forms");

  const [form, setForm] = useState<IForm>();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    setLoading(true);
    formAPI
      .getForm(cohortId, FORM_TYPE, "D1")
      .then(setForm)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [formAPI, cohortId, onComplete]);

  const onSubmit = (answers: FormAnswer[]): void => {
    if (!form) {
      return;
    }
    // FIXME(jgalilee): this sets state after parent unmounts component
    setSubmitting(true);
    formAPI
      .saveForm(cohortId, {
        ...form,
        answers,
      })
      .then(onComplete)
      .catch(setError)
      .finally(() => setSubmitting(false));
  };

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
          <h1>{heading}</h1>
          {person && <VisitPerson person={person} />}
          {error && <ErrorMessage>{error.message}</ErrorMessage>}
          {form && (
            <ECRF
              definition={form.definition}
              onSubmit={onSubmit}
              initialAnswers={form.answers || []}
              disabled={submitting}
              requireSummaryCheck
            />
          )}
        </>
      )}
    </>
  );
}
